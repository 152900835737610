// @flow 
import moment from 'moment';
import React, { useState } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Dimmer, Form, Grid, Header, Icon, Loader, Modal, Popup, Progress } from 'semantic-ui-react';
import ModalComponent from '../alertModal/modal';
import { getPaymentPlan } from '../requests';
import { PaymentsTable } from './PaymentsTable';
import { LoanHeader } from './LoanHeader';
import { EditButton } from './EditButton';
import { StopButton } from './StopButton';
import { Payments } from '../paymentPlans/payments/Payments';
import { dayNames, getAllDaysInWeek } from '../../helpers/general';
import { RestartButton } from './RestartButton';
import { StopErrors } from './StopErrors';
import { TermsConditions } from "../termsConditions/TermsConditions"

// styles
import "./LoanDetails.sass";

const defaultForm = {
  loanAmount: "", loanMonthlyPayment: "", loanTerm: 12, startDate: moment().add(1, 'days').format('MM-DD-YYYY'), endDate: moment().add(12, 'months').format('MM-DD-YYYY'), agree: false,
  firstDate: moment().add(1, 'days').add(5, 'weeks').format('MM-DD-YYYY'), lender_id: "", transferDay: "Monday", lender: null, principal: '', rate: '', payment: null,
};
const defaultErrors = {
  loanAmount: null, loanMonthlyPayment: null, loanTerm: null, payment: null,
  endDate: null, startDate: null, firstDate: null, agree: null, transferDay: null, lender_id: null, principal: null, rate: null
};

const dayOptions = [
  { key: 'm', text: 'Monday', value: 'Monday' },
  { key: 't', text: 'Tuesday', value: 'Tuesday' },
  { key: 'w', text: 'Wednesday', value: 'Wednesday' },
  { key: 't', text: 'Thursday', value: 'Thursday' },
  { key: 'f', text: 'Friday', value: 'Friday' },
]

export const LoanDetails = ({ id, quote, type, retailer, customer, detail, create, updatePaymentPlan }) => {
  const fee = 1.95;
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState(defaultErrors);
  const [errorsButton, setErrorsButton] = useState(defaultErrors);
  const [disabled, setDisabled] = useState(true);
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);
  const [lenders, setLenders] = useState([])
  const [lendersBackup, setLendersBackup] = useState([])
  const [step, setStep] = useState(0);
  const [amortization, setAmortization] = useState(null);
  const [showErros, setShowErros] = useState(false);
  const [payments, setPayments] = useState([]);

  // ---------------------------- update form and validate -----------------
  const getValuesFromTable = (value, label) => {
    if (label === 'payment') {
      onEditInput(value, label)
    } else {
      let auxForm = { ...form };
      auxForm['amount'] = value;
      let auxErrors = { ...errors };
      auxErrors = validateForm(value, label, auxErrors, auxForm);
      setErrors({ ...auxErrors });
      validation(auxForm, auxErrors);
    }

  }
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    let flag = true;
    if (label === 'firstDate' || label === 'startDate' || label === 'endDate') {
      if (!moment(value, 'MM-DD-YYYY', true).isValid()) {
        flag = false;
      }
    }
    if (label === 'loanTerm') {
      auxForm['endDate'] = moment(moment(moment(auxForm.firstDate, "MM-DD-YYYY").format("YYYY-MM-DD")).add(value, 'M')).format("MM-DD-YYYY");
    }
    if (label === 'loanMonthlyPayment') {
      auxForm['payment'] = null;
    }
    if (label === 'lender_id') {
      for (const item of lendersBackup) {
        if (value === item.id) {
          auxForm['lender'] = item;
          break;
        }
      }
    }
    if (!flag) {
      return;
    }
    auxForm[label] = value;
    let auxErrors = { ...errors };
    auxErrors = validateForm(value, label, auxErrors, auxForm);
    setErrors({ ...auxErrors });
    auxErrors = { ...defaultErrors };
    setForm({ ...auxForm });
    validation(auxForm, auxErrors);
  }

  const validation = (auxForm, auxErrors) => {
    for (const key in auxForm) {
      if (auxForm.hasOwnProperty.call(auxForm, key)) {
        const element = auxForm[key];
        auxErrors = validateForm(element, key, auxErrors, auxForm);
      }
    }
    setDisabled(false);
    setErrorsButton(auxErrors);
    for (const key in auxErrors) {
      if (auxErrors.hasOwnProperty.call(auxErrors, key)) {
        const element = auxErrors[key];
        if (element != null) {
          setDisabled(true);
        }
      }
    }
  }

  const validateForm = (value, label, errors, auxForm = form) => {
    let firstDate = moment(auxForm.firstDate, "MM-DD-YYYY").format("YYYY-MM-DD");
    let startDate = moment(auxForm.startDate, "MM-DD-YYYY").format("YYYY-MM-DD");
    let endDate = moment(auxForm.endDate, "MM-DD-YYYY").format("YYYY-MM-DD");
    let today = moment().add(1, 'days').format("YYYY-MM-DD");
    switch (label) {
      case 'loanAmount':
      case 'principal':
        errors[label] = !value || isNaN(value) ? "The field must be a numeric" : null;
        errors.loanMonthlyPayment = (+auxForm.loanMonthlyPayment) >= (+value) ? "The field must be a numeric and less than Loan Amount and Principal" : null;
        break;
      case 'rate':
        errors[label] = !value || isNaN(value) ? "The field must be a numeric" : null;
        break;
      case 'loanMonthlyPayment':
        errors[label] = !value || isNaN(value) || (+value) >= (+auxForm.loanAmount) ? "The field must be a numeric and less than Loan Amount and Principal" : null;
        break;
      case 'loanTerm':
        errors[label] = !value || !Number.isInteger(+value) || +value <= 0 ? "The field must be an integer higher than 0" : null;
        errors.firstDate = moment(firstDate).isAfter(moment(endDate)) ? 'First date must be less than ' + auxForm.endDate : null;
        break;
      case 'dueDate':
        errors[label] = !value || !moment(value, 'MM-DD-YYYY', true).isValid() ? "The field must be a date" : null;
        break;
      case 'startDate':
        if (type === 'customer') {
          errors[label] = !value || !moment(value, 'MM-DD-YYYY', true).isValid() ? "The field must be a date" : null;
          value = moment(value, "MM-DD-YYYY").format("YYYY-MM-DD");
          const days = moment(moment(firstDate).add(1, 'days')).diff(value, 'days');
          let payDays = getAllDaysInWeek([], new Date(value + ' 00:00:00'), days, dayNames[auxForm.transferDay]);
          if (moment(value).isAfter(moment(endDate)) || payDays.length < 5 || moment(value).isAfter(moment(firstDate)) || moment(value).isBefore(today)) {
            errors[label] = 'Start date must be 5 ' + auxForm.transferDay + ' before first date and after today date';
          }
          if (!moment(value).isAfter(moment(endDate)) && payDays.length >= 5) {
            errors.firstDate = null;
          }
        }
        break;
      case 'firstDate':
        if (type === 'customer') {
          errors[label] = !value || !moment(value, 'MM-DD-YYYY', true).isValid() ? "The field must be a date" : null;
          value = moment(value, "MM-DD-YYYY").format("YYYY-MM-DD");
          const days = moment(moment(value).add(1, 'days')).diff(startDate, 'days');
          let payDays = getAllDaysInWeek([], new Date(startDate + ' 00:00:00'), days, dayNames[auxForm.transferDay]);
          if (moment(value).isBefore(moment(startDate)) || payDays.length < 5 || moment(value).isAfter(moment(endDate))) {
            errors[label] = 'First date must be 5 ' + auxForm.transferDay + ' after the start date and less than ' + auxForm.endDate;
          }
          if (!moment(value).isBefore(moment(startDate)) && payDays.length >= 5 && moment(startDate).isSameOrAfter(today)) {
            errors.startDate = null;
          }
        }
        break;
      case 'lender_id':
        errors[label] = !value || isNaN(value) ? "The field must be required" : null;
        break;
      case 'transferDay':
        if (type === 'customer') {
          errors[label] = !value ? "The field must be required" : null;
          const days = moment(moment(firstDate).add(1, 'days')).diff(startDate, 'days');
          let payDays = getAllDaysInWeek([], new Date(startDate + ' 00:00:00'), days, dayNames[auxForm.transferDay]);
          if (payDays.length < 5) {
            errors.firstDate = 'First date must be 5 ' + value + ' after the start date and less than ' + auxForm.endDate;
          }
        }
        break;
      case 'payment':
        errors[label] = value == null ? "Select weekly payment or weekly payment plus" : null;
        break;
      case 'agree':
      case 'amount':
        if (type === 'customer') {
          errors[label] = !value ? "Please, accept the terms and conditions" : null;
        }
        break;
      default:
        break;
    }
    return errors;
  }

  // ------------------------------------- update plan props --------
  const updatePlan = (plan, flag) => {
    let auxForm = plan;
    setDisabledForm(flag);
    setForm({ ...auxForm });
    if (detail || create) {
      updatePaymentPlan(auxForm, create);
    }
  }

  // ------------------------------------ open modal ----------------
  const openModal = () => {
    setPayments([]);
    setStep(0);
    setIsLoading(true);
    setDisabledForm(false);
    let params = {
      id: id,
      customer_id: customer.id,
    }
    // ------------------------------------ get paymen plan
    getPaymentPlan(params).then(response => {
      setIsLoading(false);
      let data = { ...response.data };
      let auxForm = { ...data.paymentPlan }, auxLenders = data.lenders;
      setLendersBackup(data.lenders);
      if (auxForm.status !== 0) {
        setDisabledForm(true);
      }
      if ((auxForm.status === 3 || auxForm.status === 4) && auxForm.events?.length > 0) {
        setShowErros(true);
      }
      if (auxForm.lender && auxForm.lender.id && ((auxForm.lender.deleted_at != null && (auxForm.status === 0 || auxForm.status === 3)) || (auxForm.status === 0 && auxForm.lender.payment_plan && auxForm.lender.payment_plan.status === 1))) {
        auxForm.lender = null;
        auxForm.lender_id = null;
      }
      auxLenders = auxLenders.map(item => {
        return { text: item.name + " - #" + item.routingNumber, value: item.id }
      });
      auxForm.transferDay = !auxForm.transferDay ? 'Monday' : auxForm.transferDay;
      auxForm.agree = auxForm.terms_accepted ? true : false;
      auxForm.firstDate = moment(auxForm.firstDate == null ? moment(auxForm.startDate).add(30, 'days') : auxForm.firstDate, 'YYYY-MM-DD').format('MM-DD-YYYY');
      auxForm.endDate = moment(auxForm.endDate == null ? moment(auxForm.startDate).add(12, 'months') : auxForm.endDate, 'YYYY-MM-DD').format('MM-DD-YYYY');
      auxForm.startDate = moment(auxForm.startDate).format('MM-DD-YYYY');
      openCleared(auxForm, true);
      if (auxForm.status === 0) {
        validation(auxForm, Object.assign({}, defaultErrors));
      }
      setLenders(auxLenders);
      if (detail) {
        updatePaymentPlan(auxForm, false);
      }
    }).catch(error => {
      console.log(error);
      setIsLoading(false);
      if (error.response) {
        if (error.response.status === 404) { // if ther is not payment plan crated, we only get the lenders list
          openCleared(defaultForm, true);
          setLendersBackup(error.response.data);
          let auxLenders = error.response.data.map(item => {
            return { text: item.name + " - #" + item.routingNumber, value: item.id }
          });
          setLenders(auxLenders);
        } else {
          console.log(error.response);
          setModal({ status: 500, message: "Sorry. An error occurred, try again later" });
        }
      }
    });
  }

  const openCleared = (form, disabled) => {
    setForm(form);
    if (disabled) {
      setErrors(defaultErrors);
    }
    setOpen(true);
  }

  const nextButton = () => {
    let auxStep = step + 1;
    setStep(auxStep);
    validation({ ...form }, { ...errors }, auxStep);
    setTimeout(() => {
      let dates = document.querySelectorAll('.readonly');
      dates.forEach(el => el.querySelector('input').setAttribute("readonly", "true"));
      console.log(dates);
    }, 100);
  }

  // ------------------------------------ close alert modal --------------------
  const closeAlertModal = () => {
    setModal({});
  }

  return (
    <Grid>
      {
        detail ?
          <span className={'detailsQuote'} onClick={openModal}>
            See more...
          </span>
          :
          <Button secondary onClick={openModal}>
            {
              type === 'customer' ?
                (create ? 'Create ' : '') + 'Payment Plan'
                :
                'Loan Details'
            }
          </Button>
      }
      <Modal size="large" open={open} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>
          {
            type === 'customer' ?
              <div>
                Payment Plan
                <Popup wide position={"bottom center"}
                  content="The payment plan can only be stopped twice a year for insufficient funds or errors on the lender's card, otherwise it will be canceled"
                  trigger={<Icon color='blue' link name="info circle" />}
                />
              </div>
              :
              'Loan Details'
          }
        </Modal.Header>
        <Modal.Content className='loanDetails' scrolling>
          {
            (form.status > 0) && (
              <Header as={"h2"} className={form.status >= 3 ? 'stoped' : 'activated'}>
                Payment Plan is {form.status === 1 ? 'activated' : form.status === 3 ? 'stopped' : form.status === 2 ? 'finished' : 'cancelled'}
                {
                  form.id && (
                    <div>
                      {
                        (form.status >= 3) && (
                          <StopErrors plan={form} show={showErros} />
                        )
                      }
                      <Payments id={form.id} customer={customer} type={'customer'} />
                    </div>
                  )
                }
              </Header>
            )
          }
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <LoanHeader step={step} />
              <Form>
                {
                  step === 0 && (
                    <div>
                      <Header className='ui-mobile'>Loan Amount</Header>
                      <Form.Group widths='equal'>
                        <Form.Input
                          label={<label>Loan Amount ($):<span className="required-inut"> *</span></label>}
                          onChange={(e) => onEditInput(e.target.value, "loanAmount")}
                          placeholder={'Loan Amount'}
                          width={8}
                          value={form.loanAmount}
                          error={form.loanAmount.length > 0 || errors.loanAmount ? errors.loanAmount : null}
                          disabled={disabledForm}
                        />
                        <Form.Input
                          label={<label>Loan Monthly Payment ($):<span className="required-inut"> *</span></label>}
                          onChange={(e) => onEditInput(e.target.value, "loanMonthlyPayment")}
                          placeholder={'Loan Monthly Payment'}
                          width={8}
                          value={form.loanMonthlyPayment}
                          error={form.loanMonthlyPayment.length > 0 || errors.loanMonthlyPayment ? errors.loanMonthlyPayment : null}
                          disabled={disabledForm}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                          label={<label>Rate (%):<span className="required-inut"> *</span></label>}
                          onChange={(e) => onEditInput(e.target.value, "rate")}
                          placeholder={'Rate'}
                          width={4}
                          value={form.rate}
                          error={form.rate || errors.rate ? errors.rate : null}
                          disabled={disabledForm}
                        />
                        <Form.Input
                          label={<label>Principal ($):<span className="required-inut"> *</span></label>}
                          onChange={(e) => onEditInput(e.target.value, "principal")}
                          placeholder={'Principal'}
                          width={6}
                          value={form.principal}
                          error={form.principal || errors.principal ? errors.principal : null}
                          disabled={disabledForm}
                        />
                        <Form.Input
                          label={<label>Monthly Escrow(Real-Estate Only) ($):</label>}
                          onChange={(e) => onEditInput(e.target.value, "monthlytEscrow")}
                          placeholder={'Monthly Escrow(Real-Estate Only)'}
                          width={6}
                          value={form.monthlytEscrow}
                          error={form.monthlytEscrow || errors.monthlytEscrow ? errors.monthlytEscrow : null}
                          disabled={disabledForm}
                        />
                      </Form.Group>
                    </div>
                  )
                }
                {
                  step === 1 && (
                    <div>
                      <Header className='ui-mobile'>Loan Date</Header>
                      <Form.Group widths='equal'>
                        <Form.Field width={8}>
                          <label>
                            <Popup
                              trigger={<Icon name='info circle' color='blue' size='large' />}
                              content={"Start date must be 5 " + form.transferDay + " before first date and after today date"}
                              position='top center'
                            />
                            Loan Start Date: <span className="required-inut">*</span>
                          </label>
                          <DateInput
                            className="readonly"
                            dateFormat="MM-DD-YYYY"
                            placeholder="Date"
                            value={form.startDate}
                            iconPosition="left"
                            onChange={(event, { name, value }) => {
                              onEditInput(value, 'startDate');
                            }}
                            error={form.startDate || errors.startDate ? errors.startDate : null}
                            disabled={disabledForm}
                            animation='none'
                          />
                        </Form.Field>
                        {
                          type === 'customer' && (
                            <Form.Field width={8}>
                              <label>
                                <Popup
                                  trigger={<Icon name='info circle' color='blue' size='large' />}
                                  content={"First date must be 5 " + form.transferDay + " after the start date and less than End date"}
                                  position='top center'
                                />
                                First Payment Date: <span className="required-inut">*</span>
                              </label>
                              <DateInput
                                className="readonly"
                                dateFormat="MM-DD-YYYY"
                                placeholder="Date"
                                value={form.firstDate}
                                iconPosition="left"
                                onChange={(event, { name, value }) => {
                                  onEditInput(value, 'firstDate');
                                }}
                                error={form.firstDate || errors.firstDate ? errors.firstDate : null}
                                disabled={disabledForm}
                                animation='none'
                              />
                            </Form.Field>
                          )
                        }
                      </Form.Group>
                      {
                        type === 'customer' && (
                          <Form.Group>
                            <Form.Input
                              label={<label>Loan Term (months):<span className="required-inut"> *</span><small>End date: {form.endDate}</small></label>}
                              onChange={(e) => onEditInput(e.target.value, "loanTerm")}
                              placeholder={'Loan Term'}
                              width={8}
                              value={form.loanTerm}
                              error={form.loanTerm.length > 0 || errors.loanTerm ? errors.loanTerm : null}
                              disabled={disabledForm}
                            />
                            <Form.Select width={8}
                              value={form.transferDay}
                              label={<label>Transfer Day:<span className="required-inut"> *</span></label>}
                              onChange={(e, { value }) => onEditInput(value, "transferDay")}
                              options={dayOptions}
                              disabled={disabledForm}
                            />
                          </Form.Group>
                        )
                      }
                    </div>
                  )
                }
                {
                  step === 2 && (
                    <div>
                      <Header className='ui-mobile'>Lender Account</Header>
                      <Form.Group>
                        {
                          form.id && form.status !== 0 ?
                            <Form.Field width={8}>
                              <label>Lender Account: <span className="required-inut">*</span></label>
                              <span>{form.lender ? form.lender.name + " - #" + form.lender.routingNumber : 'Lender account was deleted'}</span>
                            </Form.Field>
                            :
                            <Form.Select width={16}
                              fluid
                              label={
                                <label>
                                  <Popup
                                    trigger={<Icon name='info circle' color='blue' size='large' />}
                                    content="You can only see the lender acconunts that don't belong to an activated payment plan"
                                    position='top center'
                                  />
                                  Lender Account:<span className="required-inut"> *</span>
                                </label>
                              }
                              placeholder='Lender Account'
                              options={lenders}
                              value={form.lender_id}
                              onChange={(e, { value }) => onEditInput(value, "lender_id")}
                              disabled={disabledForm}
                              clearable
                            >
                            </Form.Select>
                        }
                      </Form.Group>
                    </div>
                  )
                }
                {
                  (type === 'customer' && !form.status) && (
                    <Form.Group>
                      <Form.Checkbox
                        label={
                          <label>I agree to the terms and conditions. Please <TermsConditions show={true} /> of Use.
                            As well as our Vendor
                            <a className="dwolla-link" href="https://www.dwolla.com/legal/tos/" rel="noopener noreferrer" target="_blank"> Dwolla's Terms of Service </a>
                            and
                            <a className="dwolla-link" href="https://www.dwolla.com/legal/privacy/" rel="noopener noreferrer" target="_blank"> Privacy Policy</a>
                          </label>
                        }
                        onChange={(e, value) => onEditInput(value.checked, "agree")}
                        width={16}
                        checked={form.agree}
                        error={errors.agree ? errors.agree : null}
                      />
                    </Form.Group>
                  )
                }
              </Form>
              <br />
              <Progress color='blue' className='ui-mobile' percent={((step + 1) * 33.33333)} />
              {
                step > 0 && (
                  <Button primary onClick={() => setStep(step => step - 1)}>Previous</Button>
                )
              }
              {
                step < 2 && (
                  <Button primary onClick={nextButton} >Next</Button>
                )
              }
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <PaymentsTable data={form} errors={errors} getAmortization={(value) => setAmortization(value)} payment={form.payment}
                onChangeForm={(value, label) => getValuesFromTable(value, label)} disabled={disabledForm} fee={fee} getPaymentList={(value) => setPayments(value)} />
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => setOpen(false)} >
            Close
          </Button>
          {
            type === 'customer' ?
              !form.status || form.status === 0 ?
                <div style={{ display: 'inline-block' }}>
                  <EditButton userType={"customer"} disabled={disabled} quote={quote} retailer={retailer}
                    customer={customer} form={form} amortization={amortization} updatePlan={updatePlan} status={0} errors={errorsButton} />
                  {
                    customer.customer.account === null ?
                      <Popup content="You are not able to start the plan. You don't have a funding account" trigger={
                        <div style={{ display: "inline-block" }}>
                          <Button color='green' disabled={true}>
                            Start
                          </Button>
                        </div>
                      } />
                      :
                      <EditButton userType={"customer"} disabled={disabled} quote={quote} retailer={retailer} payments={payments} fee={fee}
                        customer={customer} form={form} amortization={amortization} updatePlan={updatePlan} status={1} />
                  }
                </div>
                :
                form.status === 1 ?
                  <StopButton form={form} updatePlan={updatePlan} />
                  :
                  form.status === 3 ?
                    customer.customer.account === null ?
                      <Popup content="You are not able to start the plan. You don't have a funding account" trigger={
                        <div style={{ display: "inline-block" }}>
                          <Button color='green' disabled={true}>
                            Restart Payment plan
                          </Button>
                        </div>
                      } />
                      :
                      <RestartButton form={form} updatePlan={updatePlan} />
                    : null
              :
              !form.status || form.status === 0 ?
                <EditButton userType={"retailer"} disabled={disabled} quote={quote} retailer={retailer}
                  customer={customer} form={form} amortization={amortization} updatePlan={updatePlan} status={0} />
                :
                <Button color={form.status === 3 ? 'red' : 'green'} disabled={true}>
                  {form.status === 1 ? "Payment plan is activated" : form.status === 2 ? "Payment plan finished" : "Payment plan was stopped"}
                </Button>
          }
          {
            (type === 'customer' && form.status > 0) && (
              <Button role="a" href={`http://mypaymentyplan.s3-website-us-east-1.amazonaws.com/documents/plans/${customer.id}/${form.id}.pdf`} target="_blank" color='green'>
                <Icon name='file pdf outline' /> Contract
              </Button>
            )
          }
        </Modal.Actions>

      </Modal>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={closeAlertModal} />
      }
    </Grid>
  );
};