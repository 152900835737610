import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Dimmer, Form, Grid, Header, Icon, Loader, Pagination, Table } from 'semantic-ui-react';
import { FormatNumberToPrice } from '../../helpers/general';
import ModalComponent from '../alertModal/modal';
import { getInventory, getRetailersList } from '../requests';
import { EditCarButton } from './editCarButton/EditCarButton';
import { InventoryHeadTable } from './InventoryHeadTable';
import { FilterSection } from './FilterSection';
import { Polls } from './pollingManager/Polls';
import _ from "lodash";

// ------------- styles
import './Inventory.sass'

const condition = [
  { value: '', text: "Show All" },
  { value: 'used', text: "Pre-owned" },
  { value: 'new', text: "New" },
]

export const Inventory = (props) => {
  const { userType } = props;
  const sizePagination = 20;
  const [inventory, setInventory] = useState([]);
  const [inventoryBackUp, setInventoryBackUp] = useState([]);
  const [beforeSortInventory, setBeforeSortInventory] = useState([]);
  const [pagination, setPagination] = useState({ totalPages: 0, currentPage: 1, startPage: 0, endPage: sizePagination })
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState({ make: [], model: [], condition: condition });
  const [retailers, setRetailers] = useState([])
  const [retailer, setRetailer] = useState("")
  const [sortSelected, setSortSelected] = useState("");
  const [direction, setDirection] = useState("");
  const [form, setForm] = useState({ year: "", make: "", status: "", condition: "", stock_date_1: "", stock_date_2: "", keyword: "" })

  // ----------------------------- click in pagination elements ---------------
  const paginationClick = (currentPage, totalPages = pagination.totalPages) => {
    let startPage = (currentPage - 1) * sizePagination;
    let endPage = startPage + sizePagination;
    let auxPagination = { ...pagination };
    auxPagination.currentPage = currentPage;
    auxPagination.startPage = startPage;
    auxPagination.endPage = endPage;
    auxPagination.totalPages = totalPages;
    setPagination({ ...auxPagination });
  }

  const calculatePages = (inventory) => {
    let totalPages = Math.ceil(inventory.length / sizePagination);
    paginationClick(1, totalPages);
  }

  // ------------------------------- search method --------------------------
  const search = (form, auxInventoryBackUp = inventoryBackUp) => {
    setForm(form);
    let auxInventory = [];
    if (form.year || form.make || form.model || form.condition || (form.stock_date_1 && form.stock_date_2) || form.keyword) {
      for (const item of auxInventoryBackUp) {
        let flag = { make: true, year: true, model: true, condition: true, stock_date: true, keyword: true };
        flag.year = !form.year ? true : item.year == form.year ? true : false;
        flag.make = !form.make ? true : item.make === form.make ? true : false;
        flag.model = !form.model ? true : item.model === form.model ? true : false;
        flag.condition = !form.condition ? true : item.condition === form.condition ? true : false;
        if (form.stock_date_1 && form.stock_date_2) {
          let startDate = moment(form.stock_date_1, "MM-DD-YYYY");
          let endDate = moment(form.stock_date_2, "MM-DD-YYYY");
          let date = moment(item.stock_date, "YYYY-MM-DD");
          flag.stock_date = date.isBetween(startDate, endDate)
        }
        if (form.keyword) {
          let key = form.keyword.toLowerCase();
          if (item.make.toLowerCase().includes(key) || item.stock_number.toLowerCase().includes(key) || item.model.toLowerCase().includes(key)
            || item.trim.toLowerCase().includes(key) || item.exterior_color.toLowerCase().includes(key) || item.interior_color.toLowerCase().includes(key)
            || item.stock_number.toLowerCase().includes(key)) {
            flag.keyword = true;
          } else {
            flag.keyword = false;
          }
        }
        if (flag.make && flag.year && flag.model && flag.condition && flag.stock_date && flag.keyword) {
          auxInventory.push({ ...item });
        }
      }
    } else {
      auxInventory = [...auxInventoryBackUp]
    }
    setBeforeSortInventory(auxInventory);
    updateIventoryList(auxInventory, true);
    // ------------------- check sort status --------
    if (sortSelected !== '') {
      handleSort({ option: sortSelected, sort: direction }, auxInventory, true);
    }
  }

  const handleSortUpdate = (direction, itemSorted) => {
    handleSort({ option: itemSorted, sort: direction });
  }

  const handleSort = (column, array = inventory, isSearch = false) => {
    setSortSelected(column.option);
    if (column.option !== '') {
      let auxArray = [...array];
      setDirection(column.sort);
      if (column.sort === 'ascending') {
        auxArray = sortAlgoritm(auxArray, column);
      } else {
        auxArray = auxArray.reverse();
      }
      setInventory(auxArray);
    } else {
      // ----------------------- validate if the event is from searching o sorting ---------
      let auxArray = [...(isSearch ? array : beforeSortInventory)];
      setInventory(auxArray);
      setDirection('');
    }
  }

  const sortAlgoritm = (array, column) => {
    if (column.option !== 'stock_date') {
      array = _.sortBy(array, [column.option])
    } else {
      array = array.sort((a, b) => { return new Date(a.stock_date) - new Date(b.stock_date); })
    }
    return array;
  }

  // ------------------------------ change retailer -------------------------
  const changeRetailer = (dealer_id) => {
    setRetailer(dealer_id);
    getCarInventory(1, dealer_id);
  }

  // ------------------------------- search method --------------------------
  const updateIventoryList = (array, resetPagesFlag) => {
    setInventory(array);
    if (resetPagesFlag) {
      calculatePages(array);
    }
  }

  // ----------------------------- update list after edit a car -------------
  const updateIventoryListEdit = (array, arrayBakUp) => {
    setInventoryBackUp(arrayBakUp);
    setInventory(array);
    if (sortSelected !== '') {
      handleSort({ option: sortSelected, sort: direction }, array);
    }
  }


  // -------------------------------- get lis of inventory
  const getCarInventory = (page = 1, dealer_id, flagFromPoll) => {
    setIsLoading(true);
    getInventory({ page, dealer_id }).then(response => {
      setIsLoading(false);
      console.log(response);
      setInventory(response.data);
      setInventoryBackUp(response.data);
      setBeforeSortInventory(response.data);
      calculatePages(response.data);
      let auxOptions = { ...options };
      auxOptions.make = groupBy(response.data, 'make');
      auxOptions.model = groupBy(response.data, 'model');
      setOptions({ ...auxOptions });
      if (flagFromPoll) {
        setModal({ status: 200, message: "Inventory was updated" });
      }
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        setModal({ status: 500, message: "Sorry. An error occurred, please verify your information or try again later. If This Issue Continues, Contact Support." });
      }
    });
  }

  // --------------- ordering make and model object structure
  const groupBy = (arr, criteria) => {
    const newObj = arr.reduce(function (acc, currentValue) {
      if (!acc[currentValue[criteria]]) {
        acc[currentValue[criteria]] = [];
      }
      acc[currentValue[criteria]].push(currentValue);
      return acc;
    }, {});
    let items = [{ value: "", text: "Show All" }];
    for (const key in newObj) {
      if (newObj.hasOwnProperty.call(newObj, key)) {
        items.push({ value: key, text: key });
      }
    }
    return items;
  }

  useEffect(() => {
    if (userType === 'admin') {
      setIsLoading(true);
      getRetailersList().then(response => {
        setIsLoading(false);
        console.log(response);
        let auxRetailers = response.data.map(item => {
          return {
            value: item.dealer_id,
            text: item.name,
            key: item.id
          }
        });;
        setRetailers(auxRetailers);
        if (auxRetailers.length > 0) {
          setRetailer(auxRetailers[0].value);
          getCarInventory(1, auxRetailers[0].value);
        }
      }).catch(error => {
        setIsLoading(false);
        if (error.response) {
          setModal({ status: 500, message: "Sorry. An error occurred, please verify your information or try again later. If This Issue Continues, Contact Support." });
        }
      });
    } else {
      getCarInventory();
    }
  }, [])

  return (
    <div className={'body inventory'}>
      <Grid stackable>
        <Breadcrumb size={'large'}>
          <Breadcrumb.Section active>Inventory</Breadcrumb.Section>
        </Breadcrumb>
      </Grid>
      {
        userType === 'admin' && (
          <div className='search-form'>
            <Form.Field className='retailer-select'>
              <Form.Select
                label={<label>Retailer:</label>}
                onChange={(e, { value }) => changeRetailer(value)}
                placeholder="Rooftop"
                value={retailer}
                options={retailers}
              />
            </Form.Field>
            <Polls retailers={retailers} inventoryRetailer={retailer} updateInventoryCallBack={() => getCarInventory(1, retailer, true)}/>
          </div>
        )
      }
      <FilterSection options={options} search={search} />
      <div className='search-form'>
        <Header size='medium'>{inventory.length + " vehicles listed"}</Header>
      </div>
      <div className='inventory-table'>
        <Table sortable celled unstackable>
          <InventoryHeadTable handleSortUpdate={handleSortUpdate} />
          <Table.Body>
            {
              inventory.slice(pagination.startPage, pagination.endPage).map(item => (
                !item.hide &&
                <Table.Row>
                  <Table.Cell>{item.stock_number}</Table.Cell>
                  <Table.Cell>{moment(item.stock_date).format("LL")}</Table.Cell>
                  <Table.Cell>{item.year}</Table.Cell>
                  <Table.Cell>{item.make}</Table.Cell>
                  <Table.Cell>{item.model}</Table.Cell>
                  <Table.Cell>{item.trim}</Table.Cell>
                  <Table.Cell>{item.exterior_color}</Table.Cell>
                  <Table.Cell>{item.interior_color}</Table.Cell>
                  <Table.Cell>{item.miles}</Table.Cell>
                  <Table.Cell>{FormatNumberToPrice(item.price)}</Table.Cell>
                  <Table.Cell>
                    <EditCarButton car={item} updateIventoryList={updateIventoryListEdit} inventory={inventory} inventoryBackUp={inventoryBackUp} />
                    <br />
                    <Button color="brown" icon>
                      <Icon name='file pdf' />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
      </div>
      {
        inventory.length > 0 && (
          <div className='invetory-pagination'>
            <Pagination activePage={pagination.currentPage} defaultActivePage={1} totalPages={pagination.totalPages}
              onPageChange={(e, data) => paginationClick(data.activePage)} />
          </div>
        )
      }
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
    </div >
  );
};