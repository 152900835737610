import React, { Component } from 'react';
import { Button, Dimmer, Loader, Message} from "semantic-ui-react";
import {validEmail, validPhoneNumber} from "../../helpers/general";
import { signUp } from "../../utilities/requests";

// IMPORT COMPONENTS
import AlertModal from "../../utilities/alertModal/modal";
import CodeModal from "../../utilities/codeModal/codeModal";
import Contact from "../../utilities/contact/contact";

// IMPORT CSS STYLES
import './register.sass';
import moment from 'moment';

class Register extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: '',
            phone_number: '',
            password: '',
            confirmPassword: '',
            code: '',
            firstName:          this.props.preQualified.firstName ? this.props.preQualified.firstName : "",
            lastName:           this.props.preQualified.lastName ? this.props.preQualified.lastName : "",
            birthDate:            moment().subtract(18, 'years').format("MM-DD-YYYY"),
            contactNumber:      '',
            extension:          '',
            workContactNumber:      '',
            workExtension:          '',
            // email:              '',
            phoneType:          null,
            bestTimeCall:       null,
            address:            this.props.preQualified.address ? this.props.preQualified.address : "",
            city:              this.props.preQualified.city ? this.props.preQualified.city : "",
            state:               this.props.preQualified.state ? this.props.preQualified.state : "",
            zip_code:            this.props.preQualified.postalCode ? this.props.preQualified.postalCode : "",
            ssn:            '',
            contactMethod:      1,
            agreement:          false,
            completeForm:       false,
            msnErrors: {
                email: '',
                phone_number: '',
                password: '',
                confirmPassword: '',
                code: '',
            },
            user: {},
            validCode: false,
            error: {},
            modal: {}
        };
        this.onEditInput = this.onEditInput.bind(this);
        this.register = this.register.bind(this);
    }
    onEditInput(label,value){
        let msnErrors = this.state.msnErrors;
        msnErrors = this.switchLabel(label, value, msnErrors)
        if (label === 'fullAddress') {
            this.setState({address: value.address, state: value.state, city: value.city, zip_code: value.zip_code});
        } else {
            this.setState({[label]: value, msnErrors});
        }
    }

    // -------------------------- hide modal alert in quotation proccess ----------
    hideModal = () => {
        if (this.state.modal.status == 200) { // validate when formulas were changed
            this.props.getUser(this.state.user, 200);
        }
        this.setState({modal: {}});
    }

    // ------------------------------- validate inputs ---------------------------------
    switchLabel(label, value, msnErrors) {
        switch (label) {
            case 'password': 
                msnErrors.password = value.length < 8 ? 'Password must be at least 8 characters long' : '';
                break;
            case 'confirmPassword': 
                msnErrors.confirmPassword = value != this.state.password ? 'Passwords do not match' : '';
                break;
            case 'email': 
                msnErrors.email = validEmail(value) ? '' : 'Email is not valid';
                break;
            case 'phone_number':
                console.log(value, validPhoneNumber(value));
                msnErrors.phone_number = validPhoneNumber(value) ? '' : 'Phone number must be 10 numbers';
                break;
            default:
                break;
        }
        return msnErrors;
    }

    validateForm = () => {
        let valid = true;
        let msnErrors = this.state.msnErrors;
        let array = ['email', 'password', 'confirmPassword', 'phone_number'];
        for (const iterator of array) {
            msnErrors = this.switchLabel(iterator, this.state[iterator], msnErrors);
        }
        this.setState({msnErrors});
        Object.values(msnErrors).forEach(val => (val.length > 0) && (valid = false));
        return valid;
    }

    // ------------------------------------------ register user -----------------------------------
    register(e){
        console.log(this.validateForm());
        let data = {};
        if (!this.state.validCode) {
            if(!this.state.completeForm) {
                return;
            }
            data = {
                email: this.state.email,
                password: this.state.password,
                password_confirmation: this.state.confirmPassword,
                phone_number: this.state.phone_number,
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                contact_number: this.state.contactNumber,
                extension: this.state.extension,
                work_contact_number: this.state.workContactNumber,
                work_extension: this.state.workExtension,
                time_call: this.state.bestTimeCall,
                zip_code: this.state.zip_code,
                address: this.state.address,
                state: this.state.state,
                city: this.state.city,
                ssn: this.state.ssn,
                contact_method: this.state.contactMethod,
                birthDate: moment(this.state.birthDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
                dwolla: this.props.preQualified.type === 'plan' ? true : false,
            }
        }
        if (this.state.validCode) {
            let msnErrors = this.state.msnErrors;
            if (this.state.code == '') {
                msnErrors.code = 'The code is required';
                return;
            }
            msnErrors.code = '';
            data = {};
            data['id'] = this.state.user.id;
            data['code'] = this.state.code;
            data["formula_id"] = this.props.formula.id;
            data["retailer_id"] = this.props.retailer.id;
            data["car_id"] = this.props.car.id;
            data["monthly_payment"] = this.props.monthly_payment;
            data["loan_length"] = this.props.loan_length;
            data["down_payment"] = this.props.down_payment;
            data["credit_score_range"] = this.props.credit_score;
            data["insurance_protection"] = this.props.insurance_protection;
            data["trade_in"] = this.props.trade_in == null || this.props.trade_in == '' ? 0 : this.props.trade_in;
            data["money_owed"] = this.props.money_owed == null || this.props.money_owed == '' ? 0 : this.props.money_owed;
            data["type"] = this.props.paymentType;
            this.setState({msnErrors})
        }
        this.setState({loading: true})
        signUp(data).then((response)=>{
            console.log(response);
            this.setState({loading: false})
            if (response.status === 202) { // send code to phone number
                this.setState({validCode: true, user: response.data});
            } else if (response.status === 200) { // formula was changed
                this.setState({
                    validCode: false,
                    loading: false,
                    user: Object.assign({}, response.data),
                    modal: {
                        status: 200,
                        message: "The interest percentages have changed, it is necessary to start the process from the beginning"
                    }
                });
                response.data['formula'] = null;
                response.data['quot'] = null;
                localStorage.setItem("currentUser", JSON.stringify(response.data));
            } else if (response.status === 201) { // success register
                this.setState({validCode: false});
                this.props.getUser(response.data, 201, 5);
                response.data['quot'] = null;
                localStorage.setItem("currentUser", JSON.stringify(response.data));
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status == 422) {
                    this.setState({loading: false, error: { message: "Sorry. Fields are missing to complete the registration" }})
                } else if (error.response.status == 406) {
                    this.setState({loading: false, error: { message: error.response.data.message }})
                } else if (error.response.status == 400) {
                    this.setState({loading: false, modal: { message: 'The code is invalid', status: 400 }})
                } else {
                    this.setState({loading: false, validCode: false, error: {
                        message: "Sorry. An error occurred, please try again later"
                    }})
                }
            } else {
                this.setState({loading: false, validCode: false, error: {
                    message: "Sorry. An error occurred, please try again later"
                }})
            }
            console.log(error.response);
        });
    }

    render(){
        return(
            <div className="register">
                <div className="register-content">
                    <div className="register-body">
                        <h2 className="title">Get Pre-Approved & Create My Vault</h2>
                        <Contact user={{prequalified: true, ...this.props.preQualified}} continue={this.saveContact} updateUser={this.updateUser} 
                        validateRegister={this.onEditInput} register={true} acceptedPlan={this.props.preQualified.type === 'plan' ? true : false} quotationProccess={true}/>
                    </div>
                    {
                        this.state.error.message && (
                            <Message error header='Register Failed' content={this.state.error.message} />
                        )
                    }
                    <Button className="btn-continue" disabled={!this.state.completeForm} onClick={this.register}><h3>Get Pre-Approved & Create My Vault</h3></Button>
                    <h2 classzName="title-or">OR</h2>
                    <h2 className="title" onClick={this.props.goToLogin}><p>Log-in with your</p> <p>Current MyPaymentPower Profile</p></h2>
                </div>
                {
                    this.state.modal.message ?
                        <AlertModal hideModal={this.hideModal} modal={this.state.modal}/>
                    :null
                }
                {
                    this.state.validCode ?
                        <CodeModal register={true} email={this.state.email} password={this.state.password} updateCode={this.onEditInput} action={this.register} hideModalCode={() => this.setState({validCode: false})}/>
                    :null
                }
                {
                    this.state.loading ?
                        <Dimmer className="loading" active>
                            <Loader />
                        </Dimmer>
                    :null
                }
            </div>
        );
    }
}

export default Register;