import React, { useEffect, useState } from 'react';
import { Form, Grid, Label } from 'semantic-ui-react';
import { states } from '../../helpers/usaStates';
import { GoogleAutocomplete } from '../googleAutocomplete/GoogleAutocomplete';

const usaState = states().map(item => {
  return {
    key: item.isoCode, text: item.name, value: item.isoCode
  }
});
const Address = (props) => {
  const [form, setForm] = useState(props.address ? props.address : { address: "", city: "AL", zip_code: "", state: "" });

  // ----------------------- get form values
  const onChangeState = (value, flag) => {
    let auxForm = { ...form };
    if (!flag) {
      console.log(value.address);
      auxForm.address = value.address;
      auxForm.city = value.locationCity;
      auxForm.zip_code = value.postalCode;
      let state = '';
      if (value.locationState) {
        for (const item of usaState) {
          if (value.locationState === item.text) {
            state = item.value;
            break;
          }
        }
      }
      auxForm.state = state;
      props.onChangeState("fullAddress", auxForm);
    } else {
      auxForm['address'] = value;
      props.onChangeState("address", auxForm.address);
    }
    setForm(auxForm);
  }

  // ------------------------------------ change input values --------------------
  const onEditInput = (label, value) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    setForm(auxForm);
    props.onChangeState(label, value);
  }

  useEffect(() => {
    setForm(props.address ? props.address : { address: "", city: "AL", zip_code: "", state: "" });
  }, [props])

  return (
    <Grid>
      <Grid.Column mobile={16} tablet={16} computer={16} style={props.noPadding ? { padding: "0" } : {}}>
        <Form.Group>
          <Form.Field width={16}>
            <GoogleAutocomplete value={form.address} onChangeSelect={(value, flag) => onChangeState(value, flag)} disabled={props.flagEdit} validateTyping />
            {
              (form.address && props.msnErrors.address) &&
              <Label basic color='red' pointing>
                {props.msnErrors.address}
              </Label>
            }

          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={6}>
            <Form.Dropdown
              label={<label>State <span className="required-inut">*</span></label>}
              id={'state'}
              placeholder='State'
              search
              options={usaState}
              value={form.state}
              onChange={(e, { value }) => onEditInput('state', value)}
              error={form.state || props.msnErrors.state ? props.msnErrors.state : null}
              disabled={props.flagEdit}
              selection
            />
          </Form.Field>
          <Form.Input
            label={<label>City <span className="required-inut">*</span></label>}
            width={6}
            id={'city'}
            placeholder='City'
            fluid
            value={form.city}
            onChange={(e) => onEditInput('city', e.target.value)}
            error={form.city || props.msnErrors.city ? props.msnErrors.city : null}
            disabled={props.flagEdit}
          />
          <Form.Input
            value={form.zip_code}
            label={<label>Zip Code <span className="required-inut">*</span></label>}
            placeholder={'Zip Code'}
            onChange={(e) => onEditInput('zip_code', e.target.value)}
            width={4}
            fluid
            error={form.zip_code || props.msnErrors.zip_code ? props.msnErrors.zip_code : null}
            maxLength="50" disabled={props.flagEdit}
          />
        </Form.Group>
      </Grid.Column>
    </Grid>

  );
}

export default Address;