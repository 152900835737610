import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import { FormatNumberToPrice, returnInfoPayments, } from "../../../helpers/general";
import Buttons from "./Buttons";

const PaymentMobile = (props) => (
    <div className="paymentMobile">
        <div className="left">
            <span>
                <Popup
                    content={returnInfoPayments(props.payment)}
                    trigger={<Icon link name="info circle" />}
                />
                {
                    props.payment === 'monthly' ?
                        "Traditional Monthly Payments"
                        :
                        props.payment === 'weekly' ?
                            "Weekly Power Payments"
                            :
                            "Weekly Power Payments +"
                }
            </span>
            <span>
                {FormatNumberToPrice(props.quots[props.payment].payment)}
                {
                    props.payment != 'monthly' && (
                        <Popup wide position={"top center"}
                            content={FormatNumberToPrice(props.quots[props.payment].payment) + " = " + FormatNumberToPrice(props.quots[props.payment].paymentNoFeeWeek) + " (Loan)" + " + " + FormatNumberToPrice(props.quots[props.payment].debitFeeWeek) + " (Program Fee)"}
                            trigger={<Icon link name="info circle" />}
                        />
                    )
                }
            </span>
            <span>Contracted term <label className={props.payment === 'monthly' ? "red" : "green"}>{props.quots[props.payment].contracted_term + " Months"}</label></span>
            <span>Effective term <label className={props.payment === 'monthly' ? "red" : "green"}>{props.quots[props.payment].effective_term + " Months"}</label></span>
        </div>
        <div className="right">
            <div className="icons">
                <img className="img-video" src={require("../../../../images/vault.svg")} />
                <Icon name="arrow up" />
            </div>
            <span onClick={(e) => props.selectedPayment(props.payment, props.payment !== 'monthly' ? 'plan' : false)}>Repayment Plan</span>
        </div>
        <div className="paymentOptions paymentsBorderMobile">
            <Buttons {...props}/>
        </div>
    </div>
)

export default PaymentMobile;