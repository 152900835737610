import React, { useEffect } from 'react';
import { Form, Grid } from 'semantic-ui-react';

export const WorkContactInformation = (props) => {
  const { form, msnErrors, flagEdit, noPadding } = props;

  useEffect(() => {
  }, [form, msnErrors, flagEdit]);

  return (
    <Grid>
      <Grid.Column mobile={16} tablet={16} computer={16} style={noPadding ? { padding: "0" } : {}}>
        <Form.Group>
          <Form.Input
            value={form.workContactNumber}
            label={<label>Work Contact Number </label>}
            placeholder={'Work Contact Number'}
            onChange={(e) => props.onEditInput('workContactNumber', e.target.value)}
            width={8}
            fluid
            error={msnErrors.contactNumber.length > 0 ? msnErrors.contactNumber : null}
            maxLength="50" disabled={flagEdit}
          />
          <Form.Input
            value={form.workExtension}
            label={'Work Extension'}
            placeholder={'Work Extension'}
            width={8}
            onChange={(e) => props.onEditInput('workExtension', e.target.value)}
            fluid
            maxLength="50" disabled={flagEdit}
          />
        </Form.Group>
      </Grid.Column>
    </Grid>
  );
};