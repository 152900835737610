import React from "react";
import { Header, Icon } from "semantic-ui-react";

const CarsHeader = (props) => (
    <div className={props.carType == props.type ? 'item-payment active' : 'item-payment'}>
        <div className={props.carType == props.type ? 'content-item car-active' : 'content-item'} onClick={(e) => props.efectCars(props.type)}>
            <div className="ui-computer">
                <Header as='h3'>{props.type == 'weekly' ? 'Pay Weekly' : props.type == 'monthly' ? 'Pay Monthly' : 'Pay Weekly+'}</Header>
                <span>Inventory Stock: {props.carsPayment ? props.carsPayment[props.type].total : 0} cars</span>
            </div>
            <div className="ui-mobile">
                <Header as='h3' style={{ marginBottom: 0 }}>Pay</Header>
                <Header as='h3' style={{ marginBottom: 0, marginTop: 0 }}>Weekly</Header>
                <span>{props.carsPayment ? props.carsPayment[props.type].total : 0} cars</span>
            </div>
        </div>
        <div className="ui-computer">
            <Icon name="angle down" />
        </div>
    </div>
);

export default CarsHeader;