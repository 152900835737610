import moment from 'moment';
import React, { useState } from 'react';
import { Button, Dimmer, Loader, Message, Modal, Pagination, Table } from 'semantic-ui-react';
import ModalComponent from '../../alertModal/modal';
import { getEvents } from '../../requests';
import { EventsFilter } from './EventsFilter';

const filter = { option: "", status: '', type: '' };

export const Events = ({ type, id }) => {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState({ data: [], last_page: 0, total: 0 });
  const [defaultFilter, setDefaultFilter] = useState(filter)
  const [page, setPage] = useState(1);

  const geEventsList = (page = 1, filter = defaultFilter) => {
    setDefaultFilter(filter);
    setIsLoading(true);
    const params = {
      page,
      type: filter.type,
      ...(filter.option !== 'range' ?
        { date: filter.option }
        : filter.startDate && filter.startDate && {
          startDate: moment(filter.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
          endDate: moment(filter.endDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
        }
      ),
      ...((type !== 'global') && { plan_id: id }),
    }
    getEvents(params, id, type).then(response => {
      setIsLoading(false);
      if (response.data) {
        setEvents(response.data);
        setPage(page);
      }
      setOpen(true);
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
      }
    });
  }

  const openModal = () => {
    geEventsList(1, filter);
  }

  return (
    <div style={{display: 'inline-block'}}>
      <Modal size={type === 'admin' ? "large" : "small"} open={open} closeOnEscape={false} closeOnDimmerClick={false}
        trigger={
          <Button primary onClick={openModal}>Events</Button>
        }>
        <Modal.Header>
          Payments
        </Modal.Header>
        <Modal.Content className='payment-plans' scrolling>
          <EventsFilter filter={(form) => geEventsList(1, form)} />
          {
            events.data.length > 0 ?
              <div className='table-wrapper'>
                <Table className={'customTable'} celled unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign='center'>ID</Table.HeaderCell>
                      {
                        type === 'global' && (
                          <Table.HeaderCell textAlign='center'>Payment Plan ID</Table.HeaderCell>
                        )
                      }
                      <Table.HeaderCell textAlign='center'>Type</Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'>Description</Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'>Creation Date</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      events.data.map(item => (
                        <Table.Row>
                          <Table.Cell textAlign='center'> {item.id}</Table.Cell>
                          {
                            type === 'global' && (
                              <Table.Cell textAlign='center'>{item.payment_plan_id}</Table.Cell>
                            )
                          }
                          <Table.Cell textAlign='center'>{item.type}</Table.Cell>
                          <Table.Cell textAlign='center'>{item.description}</Table.Cell>
                          <Table.Cell textAlign='center'>{moment(item.created_at).format('LLLL')}</Table.Cell>
                        </Table.Row>
                      ))
                    }
                  </Table.Body>
                </Table>
              </div>
              :
              <Message style={{ textAlign: 'center', margin: "1em 0" }}>
                <Message.Header style={{ textAlign: "center" }}>There are not events</Message.Header>
              </Message>
          }
          {
            events.data.length > 0 && (
              <div style={{ textAlign: 'center', margin: "1em 0" }}>
                <Pagination defaultActivePage={1} activePage={page} totalPages={events.last_page} onPageChange={(e, { activePage }) => geEventsList(activePage)} />
              </div>
            )
          }
          {
            isLoading &&
            <Dimmer className="loading" active>
              <Loader />
            </Dimmer>
          }
          {
            modal.message &&
            <ModalComponent modal={modal} hideModal={() => setModal({})} />
          }
        </Modal.Content>
        <Modal.Actions>
          <Button secondary className='close' onClick={() => setOpen(false)} ><h3>Close</h3></Button>
        </Modal.Actions>
      </Modal>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
    </div>
  );
};