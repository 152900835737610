import React, { useState } from "react";
import { Form, Header, Icon, Popup } from "semantic-ui-react";
import { FormatCashDownToPrice, FormatNumberToPrice } from "../../helpers/general";

const CarPrice = (props) => {
    const { formula } = props;
    const [incentives, setIncentives] = useState(false);

    return (
        <div style={{ padding: "0 10px" }}>
            {
                props.car.msrp > 0 && props.car.condition === 'new' ?
                    <div className="ui-computer"><span className="prices">
                        <span>MSRP: </span><span className="value">{FormatNumberToPrice(props.car.msrp)}</span> </span>
                    </div>
                    : null
            }
            {
                props.car.condition === 'new' && (
                    <div className="ui-computer">
                        <span className="prices">
                            <span>
                                {
                                    (formula?.incentives?.totalDealerCash > 0 || formula?.incentives?.totalRebate > 0) && (
                                        <Icon link color="black" onClick={() => setIncentives((incentives) => !incentives)} name={!incentives ? "chevron down" : "chevron up"} />
                                    )
                                }
                                REBATES AND INCENTIVES:
                            </span>
                            <span className="value">{FormatNumberToPrice((formula?.incentives?.totalDealerCash > 0 ? formula?.incentives?.totalDealerCash : 0) + (formula?.incentives?.totalRebate > 0 ? formula?.incentives?.totalRebate : 0))}</span>
                        </span>
                        {
                            incentives && (
                                <div className="rebates">
                                    {
                                        formula?.incentives?.totalDealerCash > 0 && (
                                            <span className="prices">
                                                Total Dealer Cash
                                                <span className="value">{FormatNumberToPrice(formula?.incentives?.totalDealerCash)}</span>
                                            </span>
                                        )
                                    }
                                    {
                                        formula?.incentives?.totalRebate > 0 && (
                                            <span className="prices">
                                                Total Rebate
                                                <span className="value">{FormatNumberToPrice(formula?.incentives?.totalRebate)}</span>
                                            </span>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                )
            }
            <div className="ui-computer"><span className="prices"><span>SALE PRICE: </span><span className="value">{FormatNumberToPrice(props.car.price)}</span></span></div>
            {
                props.car.cost > 0 ?
                    <span className="prices"><span>COST:</span> <span className="value">{FormatNumberToPrice(props.car.cost)}</span></span>
                    : null
            }
            <span className="prices">
                <span>TRADE EQUITY: </span>
                <span className="value" style={{ color: (props.trade_in - props.money_owed < 0 ? "red" : "#00D367") }}>{
                    props.trade_in - props.money_owed < 0 ?
                        "(" + FormatNumberToPrice(-(props.trade_in - props.money_owed)) + ")"
                        :
                        FormatNumberToPrice(props.trade_in - props.money_owed)
                }</span>
            </span>
            {
                props.car.adjusted_price > 0 ?
                    <span className="prices"><span>ADJUSTED PRICE: </span><span className="value">{FormatNumberToPrice(props.car.adjusted_price)}</span></span>
                    : null
            }
            <span className="prices"><span>TAX, TITLE, & REG.: </span><span className="value">{FormatNumberToPrice(props.quots.monthly.tax)}</span></span>
            <span className="prices"><span>DEALER FEES: </span><span className="value">{FormatNumberToPrice(parseInt(props.quots.monthly.dealer_fees))}</span></span>
            <span className="prices"><span>CASH DOWN: </span><span className="value">{FormatCashDownToPrice(parseInt(props.quots.monthly.down_payment))}</span></span>
            <span className="prices"><span>APR: </span><span className="value">{props.interest}%</span></span>
            {
                (formula?.incentives?.totalDealerCash > 0 || formula?.incentives?.totalRebate > 0) && (
                    <span className="prices"><span>AMOUNT FINANCED BEFORE INCENTIVES: </span><span className="value">{FormatNumberToPrice(props.quots.monthly.amountNoIncentives)}</span></span>
                )
            }
            <span className="prices"><span>ESTIMATED AMOUNT FINANCED: </span><span className="value">{FormatNumberToPrice(props.quots.monthly.amount)}</span></span>
            {
                (props.type === 'selectedCar' || props.type === 'firstCar' || props.type === 'selectedCarLanding') && props.retailer.credit_range.insurance_protection ?
                    <div className="insurance-protection">
                        <Form.Checkbox className="agree-check"
                            checked={props.insurance_protection}
                            label={'Insurance and protection'}
                            onChange={(e, value) => props.insuranceChange(value.checked, 'insurance_protection')}
                        />
                        <Popup
                            content={props.retailer.credit_range.text_insurance_protection}
                            trigger={<Icon link name="info circle" />}
                        />
                    </div>
                    : null
            }
            {
                props.type === 'finishedCar' && props.retailer.credit_range.insurance_protection && (
                    <Header className='monthlyPayment insurance-text' as='h4'>
                        <span>Insurance and protection</span>
                        {
                            props.insurance_protection ?
                                <Icon name="check" />
                                :
                                <Icon name="close" />
                        }
                    </Header>
                )
            }
        </div>
    );
}

export default CarPrice;