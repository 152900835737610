import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { Form } from 'semantic-ui-react';
import { getCity, getCountry, getNeighborood, getNumber, getPostalCode, getState, getStreet, getUbication } from '../../helpers/googleMethods';

// IMPORT CSS STYLE
import './GoogleAutocomplete.sass'

export const GoogleAutocomplete = ({ value = "", onChangeSelect, disabled, validateTyping = false}) => {
  const [ubication, setUbication] = useState(value);

  // -------------------- save selected option ubication -----------------
  const onPlaceSelected = (place) => {
    console.log(place, place.address_components);
    const location = {
      locationCountry: "",
      locationState: "",
      locationCity: "",
    };
    if (place.address_components) {
      const address = place.formatted_address,
        addressArray = place.address_components,
        city = getCity(addressArray),
        area = getCountry(addressArray),
        postalCode = getPostalCode(addressArray),
        state = getState(addressArray);
      location.address = getNumber(addressArray) + " " + getStreet(addressArray);
      location.locationCountry = area ? area : '';
      location.locationState = state ? state : '';
      location.locationCity = city ? city : '';
      location.postalCode = postalCode ? postalCode : '';
    } else {
      location.address = '';
      location.locationCountry = '';
      location.locationState = '';
      location.locationCity = '';
      location.postalCode = '';
    }
    setUbication(location.address);
    onChangeSelect(location);
  }

  const onKey = (value) => {
    const location = {
      locationCountry: "",
      locationState: "",
      locationCity: "",
    };
    setUbication(value)
    if (validateTyping) {
      onChangeSelect(value, true);
    }
  }

  useEffect(() => {
    setUbication(value);
  }, [value])

  return (
    <Form.Field style={{ width: "100%" }} className={disabled ? "googleDisabled" : ""}>
      <label>Address <span className="required-inut">*</span></label>
      <Autocomplete apiKey="AIzaSyAbLPPXKcfqGs4Ku1gdEZLTCwc1ca2VZa0"
        onChange={(e) => onKey(e.target.value)} onPlaceSelected={onPlaceSelected}
        value={ubication}
        types={['address']}
        componentRestrictions={{ country: "us" }}
      />
    </Form.Field>
  )
};