import React, { useEffect } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { callOptions, contactOptions } from '../../helpers/general';

export const ContactInformation = (props) => {
  const { form, msnErrors, flagEdit, noPadding } = props;

  useEffect(() => {
  }, [form, msnErrors, flagEdit]);

  return (
    <Grid>
      <Grid.Column mobile={16} tablet={16} computer={16} style={noPadding ? { padding: "0" } : {}}>
        <Form.Group>
          <Form.Input
            id={'contactNumber'}
            value={form.contactNumber}
            label={<label>Contact Number </label>}
            placeholder={'Contact Number'}
            onChange={(e) => props.onEditInput('contactNumber', e.target.value)}
            width={8}
            fluid
            error={msnErrors.contactNumber.length > 0 ? msnErrors.contactNumber : null}
            maxLength="50" disabled={flagEdit}
          />
          <Form.Input
            id={'extension'}
            value={form.extension}
            label={'Extension'}
            placeholder={'Extension'}
            width={8}
            onChange={(e) => props.onEditInput('extension', e.target.value)}
            fluid
            maxLength="50" disabled={flagEdit}
          />
        </Form.Group>
        <Form.Group>
          <Form.Select
            id={'bestTimeCall'}
            value={form.bestTimeCall}
            label={'Best time to connect'}
            placeholder={'Choose one'}
            options={callOptions}
            onChange={(e, { value }) => props.onEditInput('bestTimeCall', value)}
            width={8}
            fluid disabled={flagEdit}
          // error={!msnErrors.bestTimeCall ? 'Select a option' : null}
          />
          <Form.Select
            id={'contactMethod'}
            value={form.contactMethod}
            label={<label>Preferred contact method <span className="required-inut">*</span></label>}
            placeholder={'choose one'}
            options={contactOptions}
            onChange={(e, { value }) => props.onEditInput('contactMethod', value)}
            width={8}
            fluid disabled={flagEdit}
            error={!msnErrors.contactMethod ? 'Select a option' : null}
          />
        </Form.Group>
      </Grid.Column>
    </Grid>
  );
};