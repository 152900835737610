import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon, Input, Modal, Popup } from 'semantic-ui-react';
import { FormatCashDownToPrice } from '../../helpers/general';

// styles
import "./TradeModal.sass";

export const TradeModal = (props) => {
  const { trade_in, money_owed, disabledTrade, detail } = props;
  const [form, setForm] = useState({ trade_in: 0, money_owed: 0 })
  const [tradeText, setTradeText] = useState(false);
  const [open, setOpen] = useState(false);

  // ----------------------- change trade in value
  const changeTrade = (label, value, flag) => {
    let auxForm = { ...form };
    if (flag) {
      // let car_price = this.state.car.price * (this.state.formula.tax_rate / 100) + this.state.car.price;
      // if (this.state.formula.dealer_fees) {
      //     car_price += this.state.formula.dealer_fees;
      // }
      // let max_down_payment = this.state.max_down_payment;
      // if (this.props.retailer.credit_range.max_down_payment != null) {
      //     max_down_payment = (this.props.retailer.credit_range.max_down_payment / 100) * car_price;
      // } else {
      //     max_down_payment = car_price;
      // }
      // let down_payment = this.state.quots.monthly.down_payment;
      // if (((+value) + down_payment) > max_down_payment) {
      //     value = parseInt(max_down_payment - down_payment);
      // }
      if (value === '' || value.split(' ').join('') === '' || isNaN(value) || +value < 0) {
        value = 0;
      }
      props.tradeChange(value, label);
    }
    auxForm[label] = value;
    setForm(auxForm);
  }

  const parseValue = (value) => {
    if (isNaN(value) || value === '') {
      return '';
    }
    return parseInt(value);
  }

  useEffect(() => {
    setForm({ trade_in: +trade_in === 0 ? '' : +trade_in, money_owed: +money_owed === 0 ? '' : +money_owed});
  }, [props])

  return (
    <Modal size="small" className="trade-modal" open={open} closeOnEscape={false} closeOnDimmerClick={false}
      trigger={
        <Grid centered columns={1} className='trade-wrapper'>
          {
            (disabledTrade && !detail) && (
              <Grid.Column mobile={16} tablet={8} computer={8} style={{padding: 0}}></Grid.Column>
            )
          }
          <Grid.Column mobile={16} tablet={8} computer={8}>
            {
              !disabledTrade ?
                <span className="prices border-t-b">
                  <span>TRADE-IN CREDIT
                    <Popup
                      content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book"
                      trigger={<Icon link name="info circle" />}
                    />
                  </span>
                  <span className="trande-in" onClick={() => setOpen(true)}>Add a Trade-In</span>
                </span>
                : null
            }
            <span className="prices only-text" style={{padding: !disabledTrade ? 0 : "0 10px"}}><span>TRADE-IN ADDED: </span><span className="value">{FormatCashDownToPrice(+trade_in - +money_owed < 0 ? 0 : +trade_in - +money_owed)}</span></span>
          </Grid.Column>
        </Grid>
      }>
      <Modal.Content style={{ padding: "0" }} open={open}>
        <Grid style={{ padding: "1rem" }}>
          <Grid.Column mobile={16} tablet={7} computer={7} >
            <h4 className="title-info">Consider My Trade/Sell Value</h4>
            <Input
              value={parseValue(form.trade_in)} type="number"
              onChange={(e) => changeTrade('trade_in', e.target.value)}
              onBlur={(e) => changeTrade('trade_in', e.target.value, true)}
              label={{ basic: true, content: '$' }}
              labelPosition='left'
              maxLength="50"
            />
            <small style={{ display: "block" }}>
              {
                !tradeText ?
                  'Enter any verified trade-in offer amount'
                  :
                  tradeText === 'tradeByMe' ?
                    'Use my preferred trade-in value'
                    :
                    <span>3<sup>rd</sup> Party Instant wholesale offer</span>

              }

            </small>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={9} computer={9} verticalAlign='middle'>
            <h4 className="title-info">Money Still Owed On Trade/Sell Vehicle</h4>
            <Input
              value={parseValue(form.money_owed)} type="number"
              onChange={(e) => changeTrade('money_owed', e.target.value)}
              onBlur={(e) => changeTrade('money_owed', e.target.value, true)}
              label={{ basic: true, content: '$' }}
              labelPosition='left'
              maxLength="50"
            />
            <small style={{ display: "block" }}>
              Enter amount you still owe (Payoff $$)
            </small>
          </Grid.Column>
        </Grid>
        <Button className="btn-detail" onClick={() => setOpen(false)}>
          <h3>Update</h3>
        </Button>
        <hr style={{ borderTop: "2px solid #bbb" }} />
        <Grid style={{ padding: "1rem" }}>
          <Grid.Column mobile={16} tablet={8} computer={8} textAlign='center'>
            {/* <div className="button-black" onClick={() => setTradeText('tradeByMe')}>Use Trade Value Provided By Me</div> */}
            <div className="button-green" >Get An Instant <strong style={{ display: "block" }}>*Wholesale Offer</strong></div>
            <small>
              *Similar to less considerate vehicle offers from systems like KBB, Black Book, Edmunds or other more
              “auction value-based” trade/sell value systems
            </small>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8} >
            <span className="or">
              Or... <img src={require("../../../images/max-allowance.png")} alt="" className="logo" />
            </span>
            <span>Where vehicle photos & video from your driveway reveal today's highest local <strong>trade or sell</strong> dealer offer near you</span>
            <a className="button-green-outline" target="_blank" rel="noopener noreferrer" href='https://c.maxallowance.com/d/ewaldauto'><h3>GET ME THE MAX**</h3></a>
            <small>**A transparency focused driveway-to-dealer trade or sell your car experience. A better than CarMax,
              trust building service to bridge the gaps between local shoppers & local dealers.
            </small>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal >
  );
};