import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Icon, Loader, Popup } from 'semantic-ui-react';
import ModalComponent from '../alertModal/modal';
import { ConfirmModal } from '../confirmModal/ConfirmModal';
import { stopPaymentPlan } from '../requests';

export const StopButton = (props) => {
  const { form } = props;
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState({});
  const [disabled, setDisabled] = useState(true)

  // ------------------------------ confirm modal
  const confirmStop = (flag) => {
    setConfirmModal(false);
    if (flag) {
      disabledPaymentPlan();
    }
  }

  // ----------------------------------- disabled payment plan
  const disabledPaymentPlan = () => {
    setIsLoading(true);
    stopPaymentPlan(form.id).then(response => {
      setIsLoading(false);
      setModal({ status: 201, message: "Payment plan was cancelled " + (response.status === 202 ? 'and we made the refund' : "but we could not make the refund because the last payments are pending. We will make the refund in the next transfer day"), response: response.data });
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else if (error.response.status === 404) {
          setModal({ status: 406, message: "Sorry. Element was not found" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, try again later" });
        }
      }
    });
  }

  // ------------------------------------ close alert modal --------------------
  const closeAlertModal = () => {
    if (modal.status === 201) {
      let auxForm = { ...form }
      auxForm.status = modal.response.status;
      auxForm.comments = modal.response.comments;
      props.updatePlan(auxForm, true);
    }
    setModal({});
  }

  useEffect(() => {
    let day = moment().format('dddd'), dt = moment().format('YYYY-MM-DD');
    let text = false;
    if (day === form.transferDay && (form.paymentDate == null || form.paymentDate === dt)) {
      text = "You can't cancell the plan in the transfer day"
    }
    if (form.payments?.length > 0 && form.payments[form.payments.length - 1].final) {
      console.log(form.payments[form.payments.length - 1].final, 'entree lol');
      let final = 'because you have already made your last payment'
      text = text ? text + " and " + final : "You can't cancell the plan " + final;
    }
    setDisabled(text);
  }, [props])


  return (
    <div style={{ display: "inline-block" }}>
      {
        disabled ?
          <Popup content={disabled} trigger={
            <div>
              <Button color='red' disabled>
                Cancell Payment plan
              </Button>
            </div>
          } />
          :
          <Button color='red'
            onClick={() => setConfirmModal({
              title: "Cancell Payment plan",
              message: "We will make a refund of your payments that have not been transferred to the lender. Are you sure you want to cancell the payment plan?"
            })}>
            Cancell Payment plan
          </Button>
      }


      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={closeAlertModal} />
      }
      {
        confirmModal.message &&
        <ConfirmModal modal={confirmModal} afterEvent={confirmStop} />
      }
    </div>
  );
};