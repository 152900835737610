import React, { useState } from 'react';
import { Button, Header, Progress } from 'semantic-ui-react';
import { AddressInformation } from '../address/AddressInformation';
import { CurrentEmployment } from '../currentEmployment/CurrentEmployment';
import { DriverLicenceInformation } from './DriverLicenceInformation';
import { MaritalStatus } from './MaritalStatus';
import { StepsPersonalInformation } from './StepsPersonalInformation';

export const PersonalInformation = (props) => {
  const { formDisabled, errors, form } = props;
  const [step, setStep] = useState(0);

  const nextButton = () => {
    if (step === 2) {
      props.nextButton();
    } else {
      setStep(step => step + 1);
    }
  }

  const prevButton = () => {
    setStep(step => step - 1);
  }

  return (
    <div>
      <div className='ui-computer'>
        <StepsPersonalInformation step={step} />
      </div>
      {
        step === 0 && (
          <div className='padding-top'>
            <Header size="medium">What is your current job?</Header>
            <CurrentEmployment form={form} errors={errors} onEditInput={props.onEditInput} flagEdit={formDisabled} />
          </div>

        )
      }
      {
        step === 1 && (
          <div className='padding-top'>
            <Header size="medium">Tell us about your current address</Header>
            <AddressInformation form={form} errors={errors} onEditInput={props.onEditInput} flagEdit={formDisabled} />
            <Header size="medium">What is your driver license?</Header>
            <DriverLicenceInformation form={form} errors={errors} onEditInput={props.onEditInput} flagEdit={formDisabled}/>
          </div>
        )
      }
      {
        step === 2 && (
          <div className='padding-top'>
            <Header size="medium">What is your marital status? <span className="required-inut">*</span></Header>
            <MaritalStatus form={form} errors={errors} onEditInput={props.onEditInput} flagEdit={formDisabled} loanProcess={true}/>
          </div>
        )
      }
      <Progress color='blue' className='ui-mobile' percent={((step) * 50)} />
      {
        step > 0 && (
          <Button primary onClick={prevButton} type="button">Previous</Button>
        )
      }
      <Button primary onClick={nextButton} type="button">Next</Button>
    </div>
  );
};