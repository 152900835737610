import moment from 'moment';
import React, { useEffect } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Form, Grid, Message } from 'semantic-ui-react';

export const UniqueIdentifiers = (props) => {
  const { form, msnErrors, flagEdit, noPadding } = props;

  useEffect(() => {
    if (!flagEdit) {
      document.querySelector('.readonly').querySelector('input').setAttribute("readonly", "true");
    }
  }, [form, msnErrors, flagEdit])

  return (
    <Grid>
      <Grid.Column mobile={16} tablet={16} computer={16} style={noPadding ? { padding: "0" } : {}}>
        <Form.Group>
          {
            flagEdit ?
              <Form.Input
                label={<label>Birthday <span className="required-inut">*</span></label>}
                value={form.birthDate}
                width={8} disabled fluid
              />

              :
              <Form.Field width={8}>
                <label>Birthday <span className="required-inut">*</span></label>
                <DateInput
                  dateFormat="MM-DD-YYYY"
                  maxDate={moment().subtract(18, 'years').format("MM-DD-YYYY")}
                  minDate={moment().subtract(100, 'years').format("MM-DD-YYYY")}
                  name="date"
                  className="readonly"
                  placeholder="Date"
                  value={form.birthDate}
                  iconPosition="left"
                  onChange={(event, { name, value }) => {
                    props.onEditInput('birthDate', value);
                  }}
                  error={msnErrors.birthDate.length > 0 ? msnErrors.birthDate : null}
                  disable={true} animation='none'
                />
              </Form.Field>
          }
          <Form.Input
            id={'ssn'}
            value={form.ssn}
            label={<label>SSN <span className="required-inut">*</span></label>}
            placeholder={'SSN'}
            onChange={(e) => props.onEditInput('ssn', e.target.value)}
            width={8}
            fluid
            error={msnErrors.ssn.length > 0 ? msnErrors.ssn : null}
            maxLength="100" disabled={flagEdit}
          />
        </Form.Group>
        {
          (form.dwolla) && (
            <Message hidden={flagEdit} info>
              <Message.Header>Update SSN</Message.Header>
              <p>If you want to update the ssn, you must enter it again. This is done for the purpose of protecting your data.</p>
            </Message>
          )
        }
      </Grid.Column>
    </Grid>
  );
};