import React from 'react';
import { Header } from 'semantic-ui-react';

export const Text3 = () => {
  return (
    <div>
      <p>
        Notwithstanding the foregoing, USEA shall not incur any liability for any Error resulting from any of the following circumstances: (i) there are
        insufficient funds in the Payment Account; (ii) the ACH Network or other payment processing center is not working properly; or (iii) the Payment
        Account information or the Payment Instructions are inaccurate, incomplete or out of date due to Customer or Dealer error.
      </p>
      <p className='block'>
        If you believe you are entitled to payment under this Section, you must notify USEA as soon as you learn of the Error. You must cooperate fully
        with USEA remediation efforts and take all other actions reasonably requested to correct or mitigate the Error.
        <span>THE FOREGOING SETS FORTH THE SOLE AND EXCLUSIVE REMEDY AND USEA'S FULL AND EXCLUSIVE
          OBLIGATION WITH RESPECT TO ANY BREACH OF THIS LIMITED WARRANTY.</span>
      </p>
      <p>
        <span className='underline bold'>Event of Force Majeure or Change of Law.</span> In the case of an event beyond the control of USEA and Customer, which prevents either from
        complying with any or all of its obligations under this Agreement, including but not limited to acts of God (such as, but not limited to, fires,
        explosions, earthquakes, drought, tidal waves and floods); war, hostilities, acts of threats or terrorism, strikes, lock outs or disorder and
        specifically though not traditionally deemed to include changes in State or Federal law that prohibit the continuation of the Service, neither USEA
        nor the Customer shall be considered in breach of this Agreement to the extent that performance of their respective obligations is prevented by
        said Event of Force Majeure that arises after the date that USEA accepts the contract. In the case of a change in law, USEA shall have the
        opportunity to adhere to the change in law and/or substitute services of not less than equal value without being considered in breach.
      </p>
      <p>
        <span className='underline bold'>Event of Force Majeure or Change of Law.</span> In the case of an event beyond the control of USEA and Customer, which prevents either from
        complying with any or all of its obligations under this Agreement, including but not limited to acts of God (such as, but not limited to, fires,
        explosions, earthquakes, drought, tidal waves and floods); war, hostilities, acts of threats or terrorism, strikes, lock outs or disorder and
        specifically though not traditionally deemed to include changes in State or Federal law that prohibit the continuation of the Service, neither USEA
        nor the Customer shall be considered in breach of this Agreement to the extent that performance of their respective obligations is prevented by
        said Event of Force Majeure that arises after the date that USEA accepts the contract. In the case of a change in law, USEA shall have the
        opportunity to adhere to the change in law and/or substitute services of not less than equal value without being considered in breach.
      </p>
      <Header as="h3" className='underline'>Warranty Disclaimer.</Header>
      <span>
        EXCEPT AS EXPRESSLY PROVIDED HEREIN, THE SERVICE IS PROVIDED "AS-IS" WITHOUT ANY WARRANTIES, EXPRESS
        OR IMPLIED, INCLUDING WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, TITLE, OR NON- INFRINGEMENT. YOU ASSUME ALL RISK AS TO THE RESULTS AND PERFORMANCE OF THE
        SERVICE. USEA DOES NOT WARRANT THAT THE SERVICE IS COMPLETELY SECURE OR IS FREE FROM BUGS,
        INTERRUPTIONS, ERRORS, OR OTHER PROGRAM LIMITATIONS, OR THAT ALL ERRORS WILL BE CORRECTED. USEA
        FURTHER DISCLAIMS ANY AND ALL WARRANTIES ARISING FROM COURSE OF DEALING OR USAGE OF TRADE. NO
        ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM USEA OR ELSEWHERE SHALL CREATE
        ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT. SOME STATES DO NOT ALLOW THE EXCLUSION OF
        IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
      </span>
      <p>
        <span className='underline bold'>Limitation of Liability.</span> IN NO EVENT SHALL USEA BE LIABLE TO YOU FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR
        CONSEQUENTIAL DAMAGES, INCLUDING LOSS OF INCOME, DAMAGE TO CREDIT, PROFITS, REVENUE OR BUSINESS
        INTERRUPTION, OR COST OF
        SUBSTITUTE SERVICES, OR OTHER ECONOMIC LOSS, WHETHER OR NOT USEA HAS BEEN ADVISED OF THE POSSIBILITY
        OF SUCH DAMAGES, AND WHETHER ANY CLAIM FOR RECOVERY IS BASED ON THEORIES OF CONTRACT, WARRANTY,
        TORT (INCLUDING NEGLIGENCE
        AND STRICT LIABILITY) OR OTHERWISE. USEA WILL NOT BE LIABLE FOR ATTORNEYS' FEES, EXCEPT AS REQUIRED BY
        LAW.
      </p>
      <p>
        <span className='underline bold'>Acknowledgment.</span> The parties acknowledge that the limitations and exclusions contained in this Agreement represent the parties' agreement
        based upon the perceived level of risk associated with their respective obligations under this Agreement and the service fees paid. Without
        limiting the generality of the foregoing, the parties acknowledge and agree that (a) the provisions hereof that limit liability, disclaim warranties
        or exclude consequential damages or other damages or remedies shall be severable and independent of any other provisions and shall be
        enforced as such, regardless of any breach hereunder, and (b) all limitations of liability, disclaimers of warranties, and exclusions of
        consequential damages or other damages or remedies shall remain fully valid, effective and enforceable in accordance with their respective terms,
        even under circumstances that cause an exclusive remedy to fail of its essential purpose. SOME JURISDICTIONS DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF DAMAGES, LIABILITY OR CERTAIN WARRANTIES IN CERTAIN CIRCUMSTANCES.
        ACCORDINGLY, SOME OF THESE EXCLUSIONS OR
        LIMITATIONS MAY NOT APPLY.
      </p>
      <p>
        <span className='underline bold'>Consent to Electronic Disclosures and Automated Dialers.</span> You hereby agree to receive information electronically through the AutoPayPlus
        portal and at the email address you provide. This information may include information regarding the Agreement, including legal disclosures,
        future changes to the Agreement, and other notices, legal communications or disclosures. You agree to notify us promptly if your email address
        changes. You confirm that the computer and browser you are using enable you to receive, access, and retain this information, and that you can
        print copies for your records. In order to contact you more efficiently, we may at times contact you using auto-dialed or prerecorded message calls
        or text messages at the telephone number(s) you have provided us. We may place such calls or texts to (i) provide notices regarding your Account
        or Account activity (ii) investigate or prevent fraud, or (iii) collect a debt owed to us. You agree that we and our service providers may contact
        you using auto-dialed or prerecorded message calls and text messages to carry out the purposes we have identified above. We may share your
        phone number(s) with service providers with whom we contract to assist us in pursuing these interests but will not share your phone number(s)
        with third parties for their own purposes without your consent. Standard telephone minute and text charges may apply. We and our service
        providers will not use auto-dialed or prerecorded message calls or texts to contact you for marketing purposes at the telephone number(s) you
        designate unless we receive your prior express written consent.
        <span>
          You do not have to consent to receive auto-dialed or prerecorded message calls or texts in order to use and enjoy USEA's products and services.
          Where USEA is required to obtain your consent for such communications, you may choose to revoke your consent by contacting Customer
          Care at (800) 894-5000 and informing us of your preferences.
        </span>
      </p>
      <p>
        <span className='underline bold'>Consent to Electronic Signatures and Documents.</span> You hereby consent to the use of electronic documents and signatures. “Electronic
        documents” include documents you may complete via web page and save on your computer or attach to email. They can typically be printed out
        but exist independently in an electronic form on a server or on your computer. An “electronic signature” includes any mark, symbol, sound or
        process that is written, stamped, engraved, attached to or logically associated with an electronic document and executed by a person with the
        intent to sign. You understand that USEA may employ a digital signature technology. This technology combines your authenticated user session
        with your device's IP address and/or cellular phone number and current date/time stamp to ensure you are the owner of the signature. By using
        this electronic method of enrollment, you hereby consent and agree to the use of this digital signature method and agree that it carries the same
        validity and legal authority as your written signature. If you do not wish to “sign” this way, you may print out the document(s), sign by hand, and
        forward to the Company.
      </p>
      <p>
        <span className='underline bold'>General Legal..</span> You may not assign this Agreement to any other party. USEA may assign this Agreement to any third party, directly or indirectly
        affiliated with USEA. USEA may also assign or delegate certain of its rights and responsibilities under this Agreement to independent contractors
        or other third parties. You agree to indemnify and hold USEA and its respective officers, directors, employees and representatives harmless
        (including payment of reasonable attorney's fees) against any and all liability to third parties arising out of, or in connection with your use of the
        Service, any actions taken by USEA pursuant to your instructions, or your breach of this Agreement. USEA shall not be deemed to have waived
        any of its rights or remedies under this Agreement unless such waiver is in writing and signed by USEA. No delay or omission on the part of
        USEA in exercising any rights or remedies shall operate as a waiver of such rights or remedies or any other rights or remedies. A waiver on any
        one occasion shall not be construed as a bar or waiver of any rights or remedies on future occasions. This Agreement and all matters arising out of
        or relating to this Agreement shall be governed by the laws of the State of Florida, without regard to its conflict of law provisions. Any legal
        action or proceeding relating to the Agreement shall be brought exclusively in the state or federal courts located in Orange County Florida. You
        hereby agree to submit to the exclusive jurisdiction of, and agree that venue is proper in, those courts in any such legal action or proceeding. You
        hereby waive any right you may have to a jury trial or to form and/or join a class action.
      </p>
    </div>
  );
};