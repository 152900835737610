import React, { Component } from 'react';
import { Form, Button, Modal, Icon, Dimmer, Loader, Input, Label, } from "semantic-ui-react"
import { resendSMS, resendSMSAuth } from "../requests";

// IMPORT COMPONENTS
import AlertModal from "../alertModal/modal";

// IMPORT STYLES
import './codeModal.sass'
import { validCodeNumber, validPhoneNumber } from '../../helpers/general';

class CodeModal extends Component{
    constructor(props){
        super(props);

        this.state = {
            phone_number: props.phone,
            changePhone: props.register,
            loading:            false,
            completeForm:       false,
            code:               '',
            modal:              {},
            msnErrors: {
                code: '',
                phone_number: ''
            },
            time: false,
            seconds: 60,
        };
        this.onEditInput = this.onEditInput.bind(this);
    }

    componentWillUnmount () {
        clearInterval(this.interval);
    }

    switchLabel(label, value, msnErrors) {
        switch (label) {
            case 'code': 
                msnErrors.code = value.length < 6 || value === '' ? 'Code is required' : '';
                break;
            case 'phone_number':
                msnErrors.phone_number = !validPhoneNumber(value) ? 'Phone number must be 10 digits' : !validCodeNumber(value) ? 'Invalid phone number' : '';
                break;
            default:
              break;
        }
        return msnErrors;
    }

    onEditInput(label, value){
        let msnErrors = this.state.msnErrors;
        msnErrors = this.switchLabel(label, value, msnErrors);
        if (label !== 'phone_number') {
            this.props.updateCode(label,value)
        }
        this.setState({[label]: value, msnErrors}, () => this.validateForm());
    }

    validateForm = () => {
        let completeForm = true;
        if (this.state.code.length < 6 || this.state.code == '') {
            completeForm = false;
        }
        this.setState({completeForm});
    }

    sms = () => {
        if (this.state.loading) return;
        this.setState({loading: true})
        const success = (response) => {
            console.log(response);
            this.setState({
                loading: false,
                modal: {
                    status: 200,
                    message: "Code was sent" + (((this.props.register || this.props.phone_verified_at == null) && !this.props.logged && !this.props.reset && response.data.number) ? ' and your phone number was changed' : '')
                }
            });
            if ((this.props.register || this.props.phone_verified_at == null) && !this.props.logged && !this.props.reset && response.data.number && !isNaN(this.props.email)) {
                this.props.updateCode('email', this.state.phone_number)
            }
            this.setState({time: true});
            this.myInterval();
        }
        const errorResponse = (error) => {
            this.setState({loading: false});
            if (error.response) {
                if (error.response.status === 422) {
                    this.setState({ modal: { message: "Phone number is required" } });
                } else if (error.response.status === 406) {
                    this.setState({ modal: { message: error.response.data.message } });
                } else {
                    this.setState({ modal: { message: "Sorry. An error occurred, please try again later" } });
                }
                console.log(error.response);
            }
        }
        if (this.props.logged) {
            resendSMS().then((response)=>{
                success(response);
            }).catch((error) => {
                errorResponse(error);
            });
        } else {
            let data = {
                email: this.props.email,
                password: this.props.password,
                phone_number: this.state.phone_number,
            }
            resendSMSAuth(data).then((response)=>{
                success(response);
            }).catch((error) => {
                errorResponse(error);
            });
        }
    }

    myInterval = () => {
        this.interval = setInterval(() => {
            if (this.state.seconds > 0) {
                this.setState({seconds: this.state.seconds - 1});
            }
            if (this.state.seconds === 0) {
                this.setState({time: false, seconds: 60});
                clearInterval(this.interval);
            } 
        }, 1000)
    }

    render() {
        return(
            <div>
                <Modal size="mini" className="codeModal" open={true} closeOnEscape={false} closeOnDimmerClick={false}>
                    <Modal.Content>
                        <h2>Phone Number Verification</h2>
                        <p>As an additional security measure, enter the 6-digit code that we send to your phone number:
                            {
                                ((!this.props.register && this.props.phone_verified_at != null) || this.props.logged || this.props.reset) && (
                                    <strong> +1 {this.props.phone}</strong>
                                )
                            }
                        </p>
                        <div style={{ width: '100%', textAlign: "center" }}>
                            <Form>
                                {
                                    ((this.props.register || this.props.phone_verified_at == null) && !this.props.logged && !this.props.reset) && (
                                        <Form.Field width={16} >
                                            <div style={{ width: "100%", fontWeight: "bold" }}>
                                                <Input
                                                    label='+1'
                                                    value={this.state.phone_number}
                                                    placeholder='Enter Phone Number'
                                                    onChange={(e) => this.onEditInput('phone_number', e.target.value)}
                                                    maxLength="100"
                                                    min="12"
                                                    width={16}
                                                />
                                                {
                                                    this.state.msnErrors.phone_number.length > 0 ?
                                                        <Label basic color='red' pointing>
                                                            {this.state.msnErrors.phone_number}
                                                        </Label>
                                                        : null
                                                }
                                            </div>
                                            <Button as={'a'} className="resend-button" onClick={this.sms} disabled={this.state.msnErrors.phone_number.length > 0 || this.state.time}>
                                                Resend code and update phone number
                                                {
                                                    this.state.time && (
                                                        <strong> in {this.state.seconds}s</strong>
                                                    )
                                                }
                                            </Button>
                                        </Form.Field>
                                    )
                                }
                                <Form.Input className="form-register"
                                    type={'string'}
                                    placeholder={'Enter code'}
                                    onChange={(e) => this.onEditInput('code', e.target.value)}
                                    error={this.state.msnErrors.code.length > 0 ? this.state.msnErrors.code : null}
                                    maxLength="6"
                                />
                            </Form>
                            {
                                ((!this.props.register && this.props.phone_verified_at != null) || this.props.logged || this.props.reset) && (
                                    <Button as={'a'} className="resend-button" onClick={this.sms} disabled={this.state.time}>
                                        Resend code 
                                        {
                                            this.state.time && (
                                                <strong> in {this.state.seconds}s</strong>
                                            )
                                        }
                                    </Button>
                                )
                            }
                        </div>
                        {
                            this.state.modal.message ?
                                <AlertModal hideModal={() => this.setState({modal: {}})} modal={this.state.modal}/>
                            :null
                        }
                        {
                            this.state.loading ?
                                <Dimmer className="loading" active>
                                    <Loader />
                                </Dimmer>
                            :null
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        {
                            !this.props.register ?
                                <Button color="violet" onClick={this.props.hideModalCode} >
                                    Close
                                </Button>
                            :null
                        }
                        <Button primary disabled={!this.state.completeForm || this.props.disabled} onClick={this.props.action} >
                            Accept
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

export default CodeModal;