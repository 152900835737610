import React from 'react';
import { Button, Icon, Segment } from 'semantic-ui-react';

export const Contact = ({ step, section }) => {
  return (
    <div className={section}>
      {/* <Icon name="arrow left" /> */}
      <div className='text'>
        <Segment>
          <h4>In this section you will able to edit your information and your password.</h4>
        </Segment>
        <Button onClick={() => step('loan')}>Previous</Button>
        <Button onClick={() => step('account')}>Next</Button>
      </div>
    </div>
  );
};