import moment from 'moment';
import React, { useState } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Form, Grid } from 'semantic-ui-react';

const options = [
  { value: "today", text: "Today" },
  { value: "month", text: "This month" },
  { value: "last_month", text: "Last month" },
  { value: "range", text: "Date range" },
];

const types = [
  { value: "stop", text: "stop" },
  { value: "start", text: "start" },
  { value: "cancell", text: "cancell" },
  { value: "transfer", text: "transfer" },
];

export const EventsFilter = ({filter}) => {
  const [form, setForm] = useState({ option: "", status: '', type: '' });

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    setForm({ ...auxForm });
  }
  return (
    <Form className='search-form'>
      <Form.Group>
        <Form.Select
          label={<label>Filter Date by:</label>}
          onChange={(e, { value }) => onEditInput(value, "option")}
          placeholder={'Filter by'}
          options={options}
          value={form.option}
          width={6}
          clearable
        />
        {
          form.option === 'range' && (
            <Form.Field width={10}>
              <label>Date range:</label>
              <Grid style={{ display: "contents" }}>
                <Grid.Column width={8} style={{ padding: 0 }}>
                  <DateInput
                    dateFormat="MM-DD-YYYY"
                    onChange={(event, { name, value }) => {
                      onEditInput(value, 'startDate');
                    }}
                    value={form.startDate}
                    popupPosition={"right center"}
                    animation='none'
                    clearable closable
                    timeFormat="AMPM"
                  />
                </Grid.Column>
                <Grid.Column width={8} style={{ padding: 0 }}>
                  <DateInput
                    dateFormat="MM-DD-YYYY"
                    onChange={(event, { name, value }) => {
                      onEditInput(value, 'endDate');
                    }}
                    value={form.endDate}
                    popupPosition={"right center"}
                    animation='none'
                    clearable closable
                    maxDate={moment()}
                    timeFormat="AMPM"
                  />
                </Grid.Column>
              </Grid>
            </Form.Field>
          )
        }

      </Form.Group>
      <Form.Group>
        <Form.Select
          label={<label>Filter Type by:</label>}
          onChange={(e, { value }) => onEditInput(value, "type")}
          placeholder={'Filter Type by'}
          options={types}
          value={form.type}
          width={7}
          clearable
        />
        <Form.Field width={3} style={{display: "flex", alignItems: 'end', marginTop: "1rem"}}>
          <Button primary onClick={() => filter(form)} style={{width: "100%"}}
            disabled={form.option === 'range' && (!moment(form.startDate, 'MM-DD-YYYY', true).isValid() || !moment(form.endDate, 'MM-DD-YYYY', true).isValid())}>
            Filter
          </Button>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};