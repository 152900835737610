import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import moment from 'moment';

export const Message = ({ message, role }) => {
  // ------------------------- format of date ---------------------
  const groupByDate = (date) => {
    let today = new Date();
    let todayWeek = new Date();
    let yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    let validate = new Date(date);
    today = notHour(today)
    yesterday = notHour(yesterday)
    validate = notHour(validate)
    let firstday = new Date(todayWeek.setDate(todayWeek.getDate() - (todayWeek.getDay() - 1)));
    firstday = notHour(firstday);
    if (today.getTime() == validate.getTime() || yesterday.getTime() == validate.getTime() || firstday.getTime() <= validate.getTime()) {
      return 0;
    } else {
      return 1;
    }
  }

  const notHour = (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0, 0);
    return date;
  }

  return (
    <div className='message'>
      {
        message.readFlag && (
          <span className='alert' id={"no-read-" + role}>Unread messages</span>
        )
      }
      <div className='message-content'>
        <div className='grapperBox'>
          <div className={(role === 'retailer' && message.type === 1) || (role === 'customer' && message.type === 2) ? 'contentBoxSent' : 'contentBoxReceived'}>
            <div className={(role === 'retailer' && message.type === 1) || (role === 'customer' && message.type === 2) ? 'text sent' : 'text received'}>
              {message.message}
            </div>
          </div>
        </div>
        {
          ((role === 'retailer' && message.type === 1) || (role === 'customer' && message.type === 2)) && (
            <div className="iconsMsn">
              {
                message.status === 0 ?
                  <Popup content='Sent' trigger={<Icon name='check square outline' />} />
                  :
                  <Popup content='Read' trigger={<Icon name='check square' />} />

              }
            </div>
          )
        }
      </div>
      <label className="date" style={{ textAlign: (role === 'retailer' && message.type === 1) || (role === 'customer' && message.type === 2) ? 'right' : 'left' }}>
        {
          groupByDate(message.created_at) === 0 ?
            moment(message.created_at).calendar()
            :
            moment(message.created_at).format('llll')
        }
      </label>
    </div>
  );
};