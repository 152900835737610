import React, { useEffect, useState } from 'react';
import { Card, Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import ModalComponent from '../alertModal/modal';
import { getVideos } from '../requests';
import { Section } from './Section';

// IMPORT CSS STYLES
import './Videos.sass';

const defaultForm = {
  name: "", description: "", video: null
};

const intialSection = {
  prequalified: defaultForm, plan: defaultForm, approval: defaultForm, retailer: defaultForm, search: defaultForm
}

export const Videos = (props) => {
  const { retailersList } = props;
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [defaultSection, setDefaultSection] = useState(intialSection)
  const [section, setSection] = useState(intialSection)
  const [sections, setSections] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [retailer, setRetailer] = useState("")

  // --------------------------- update videos after backendp response
  const updateVideo = (result, label, action) => {
    let flagExist = false;
    let auxRetailers = [...retailers].map((item) => {
      let auxItem = { ...item }
      if (auxItem.value === retailer) {
        let auxVideos = [...auxItem.videos];
        auxVideos = auxVideos.map((video) => {
          if (video.section.section === label) {
            flagExist = true;
            return result;
          } else {
            return video;
          }
        });
        if (!flagExist) {
          auxVideos.push(result);
        }
        auxItem.videos = auxVideos;
      }
      return auxItem
    });
    selectRetailer(retailer, auxRetailers);
    setRetailers(auxRetailers);
  }

  const selectRetailer = (value, auxRetailers = retailers) => {
    setRetailer(value);
    let auxSection = { ...defaultSection };
    if (value) {
      let foundRetailer = auxRetailers.find(item => item.value === value);
      if (foundRetailer.videos.length > 0) {
        for (const item of foundRetailer.videos) {
          auxSection[item.section.section] = item;
        }
      }
    }
    setSection({ ...auxSection });
  }

  const getDifference = (array1, array2) => {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.value === object2.value;
      });
    });
  }

  useEffect(() => {
    if (retailers.length > 0 && retailersList.length !== retailer.length) {
      let auxRetailers = retailers;
      let auxRetailerList = retailersList.map(item => {
        return {
          videos: [], text: item.name, value: item.id
        }
      });
      let auxRetailer = getDifference(auxRetailerList, retailers);
      for (const item of auxRetailer) {
        auxRetailers.push(item);
      }
    } else {
      setIsLoading(true);
      getVideos().then(response => {
        setIsLoading(false);
        let auxRetailers = response.data.retailer.map(item => {
          return {
            videos: item.all_videos, text: item.name, value: item.id
          }
        });
        setRetailers(auxRetailers);
        setSections(response.data.sections);
        let auxDefaultSection = { ...defaultSection };
        for (const item of response.data.sections) {
          auxDefaultSection[item.section] = defaultForm;
        }
        setDefaultSection({ ...auxDefaultSection });
        if (auxRetailers.length > 0) {
          selectRetailer(auxRetailers[0].value, auxRetailers);
        }
      }).catch(error => {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response);
          setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
        }
      });
    }
  }, [props])

  return (
    <div className={'body videos'}>
      <Grid>
        <Form>
          <Form.Dropdown
            label="Retailer"
            placeholder='Retailer'
            search
            options={retailers}
            value={retailer}
            onChange={(e, { value }) => selectRetailer(value)}
            selection
          />
        </Form>
      </Grid>
      {
        retailer ?
          sections.map(item => (
            <Section form={section[item.section]} section={item} updateVideo={updateVideo} retailer={retailer} />
          ))

          :
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Card style={{ width: "100%", padding: "2rem", textAlign: "center" }} ><Card.Header>Select a retailer</Card.Header></Card>
            </Grid.Column>
          </Grid>
      }
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
    </div>
  );
};