import React, { useEffect } from 'react';
import { Form, Grid } from 'semantic-ui-react';

export const BasicInformation = (props) => {
  const { form, msnErrors, flagEdit, noPadding } = props;

  useEffect(() => {
  }, [form, msnErrors, flagEdit])

  return (
    <Grid>
      <Grid.Column mobile={16} tablet={16} computer={16} style={noPadding ? { padding: "0" } : {}}>
        <Form.Group>
          <Form.Input
            id={'firstName'}
            value={form.firstName}
            label={<label>First Name <span className="required-inut">*</span></label>}
            placeholder={'First Name'}
            onChange={(e) => props.onEditInput('firstName', e.target.value)}
            width={8}
            fluid
            error={msnErrors.firstName.length > 0 ? msnErrors.firstName : null}
            maxLength="50" disabled={flagEdit}
          />
          <Form.Input
            id={'lastName'}
            value={form.lastName}
            label={<label>Last Name <span className="required-inut">*</span></label>}
            placeholder={'Last Name'}
            onChange={(e) => props.onEditInput('lastName', e.target.value)}
            width={8}
            fluid
            error={msnErrors.lastName.length > 0 ? msnErrors.lastName : null}
            maxLength="50" disabled={flagEdit}
          />
        </Form.Group>
      </Grid.Column>
    </Grid>
  );
};