// @flow 
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Dimmer, Loader, Message, Modal, Pagination, Table } from 'semantic-ui-react';
import { FormatNumberToPrice } from '../../../helpers/general';
import ModalComponent from '../../alertModal/modal';
import { getPayments } from '../../requests';
import { PaymentsFilter } from './PaymentsFilter';

const filter = { option: "", status: '', type: '' };

export const Payments = ({ customer, id, type, global }) => {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState({ data: [], last_page: 0, total: 0 });
  const [defaultFilter, setDefaultFilter] = useState(filter)
  const [page, setPage] = useState(1)

  const getPaymentsList = (page = 1, filter = defaultFilter) => {
    setDefaultFilter(filter);
    setIsLoading(true);
    const params = {
      page,
      status: filter.status,
      type: filter.type,
      ...(filter.option !== 'range' ?
        { date: filter.option }
        : filter.startDate && filter.startDate && {
          startDate: moment(filter.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
          endDate: moment(filter.endDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
        }
      ),
      ...((type === 'admin' && !global) && { customer_id: customer.id }),
      global: global ? 1 : 0
    }
    getPayments(params, id, type).then(response => {
      setIsLoading(false);
      setPayments(response.data);
      setPage(page);
      setOpen(true);
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
      }
    });
  }

  const openModal = () => {
    console.log(type);
    getPaymentsList(1, filter);
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <Modal size={type === 'admin' ? "large" : "small"} open={open} closeOnEscape={false} closeOnDimmerClick={false}
        trigger={
          <Button primary onClick={openModal} >Payments</Button>
        }>
        <Modal.Header>
          Payments
        </Modal.Header>
        <Modal.Content className='payment-plans' scrolling>
          <PaymentsFilter filter={(form) => getPaymentsList(1, form)} />
          {
            payments.data.length > 0 ?
              <div className='table-wrapper'>
                <Table className={'customTable'} celled unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign='center'>Status</Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'>Amount</Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'>Creation Date</Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'>Update Date</Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'>Type</Table.HeaderCell>
                      {
                        global && (
                          <Table.HeaderCell textAlign='center'>Customer Name</Table.HeaderCell>
                        )
                      }
                      {
                        global && (
                          <Table.HeaderCell textAlign='center'>Payment Plan ID</Table.HeaderCell>
                        )
                      }
                      {
                        type === 'admin' && (
                          <Table.HeaderCell textAlign='center'>Transfer ID</Table.HeaderCell>
                        )
                      }
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      payments.data.map(item => (
                        <Table.Row>
                          <Table.Cell textAlign='center'>
                            {
                              item.status === 'pending' ?
                                <Button className='plan-status' color='grey'>Pending</Button>
                                :
                                item.status === 'processed' ?
                                  <Button className='plan-status' color='green'>Processed</Button>
                                  : null
                            }
                          </Table.Cell>
                          <Table.Cell textAlign='center'>{FormatNumberToPrice(item.payment)}</Table.Cell>
                          <Table.Cell textAlign='center'>{moment(item.created_at).format('dddd, MMMM D, YYYY')}</Table.Cell>
                          <Table.Cell textAlign='center'>{moment(item.updated_at).format('dddd, MMMM D, YYYY')}</Table.Cell>
                          <Table.Cell textAlign='center'>{item.type === 'lender' ? 'Balance to Lender' : item.type === 'balance' ? 'Account to Balance' : item.type === 'refund' ? 'Refund to your account' : 'Balance to Dwolla'}</Table.Cell>
                          {
                            global && (
                              <Table.Cell textAlign='center'>{item.customer.name + " " + item.customer.second_name}</Table.Cell>
                            )
                          }
                          {
                            global && (
                              <Table.Cell textAlign='center'>{item.payment_plan_id}</Table.Cell>
                            )
                          }
                          {
                            type === 'admin' && (
                              <Table.Cell >{item.transfer_id}</Table.Cell>
                            )
                          }
                        </Table.Row>
                      ))
                    }
                  </Table.Body>
                </Table>
              </div>
              :
              <Message style={{ textAlign: 'center', margin: "1em 0" }}>
                <Message.Header style={{ textAlign: "center" }}>There are not payments</Message.Header>
              </Message>
          }
          {
            payments.data.length > 0 && (
              <div style={{ textAlign: 'center', margin: "1em 0" }}>
                <Pagination defaultActivePage={1} activePage={page} totalPages={payments.last_page} onPageChange={(e, { activePage }) => getPaymentsList(activePage)} />
              </div>
            )
          }
          {
            isLoading &&
            <Dimmer className="loading" active>
              <Loader />
            </Dimmer>
          }
          {
            modal.message &&
            <ModalComponent modal={modal} hideModal={() => setModal({})} />
          }
        </Modal.Content>
        <Modal.Actions>
          <Button secondary className='close' onClick={() => setOpen(false)} ><h3>Close</h3></Button>
        </Modal.Actions>
      </Modal>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
    </div>
  );
};