// @flow 
import React, { useEffect, useState } from 'react';
import { Form, Grid, Input, Label } from 'semantic-ui-react';

const occupationStatusOptions = [
  { value: "Employed", text: "Employed" },
  { value: "Unemployed", text: "Unemployed" },
  { value: "Retired", text: "Retired" },
  { value: "ActiveMilitary", text: "Active Military" },
  { value: "RetiredMilitary", text: "Retired Military" },
  { value: "SelfEmployed", text: "SelfEmployed" },
  { value: "Student", text: "Student" },
  { value: "Other", text: "Other" },
]

const incomeFrequencyOptions = [
  { value: "Weekly", text: "Weekly" },
  { value: "Biweekly", text: "Biweekly" },
  { value: "Monthly", text: "Monthly" },
  { value: "Annually", text: "Annually" },
]

export const CurrentEmployment = (props) => {
  const [form, setForm] = useState({});

  // ------------------------------------ change input values --------------------
  const onEditInput = (label, value) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    setForm(auxForm);
    props.onEditInput(label, value);
  }

  useEffect(() => {
    setForm(props.form ? props.form : {})
  }, [props])

  return (
    <Grid>
      <Grid.Column width={16} style={props.noPadding ? { padding: "0" } : {}}>
        <Form.Input
          label={<label>Employer Name <span className="required-inut">*</span></label>}
          width={16}
          placeholder='Employer Name'
          fluid
          value={form.employerName}
          maxLength={30}
          onChange={(e) => onEditInput('employerName', e.target.value)}
          error={form.employerName || props.errors.employerName ? props.errors.employerName : null}
          disabled={props.flagEdit}
        />
        <Form.Input
          label={<label>Occupation <span className="required-inut">*</span></label>}
          width={16}
          placeholder='Occupation'
          fluid
          value={form.occupation}
          maxLength={26}
          onChange={(e) => onEditInput('occupation', e.target.value)}
          error={form.occupation || props.errors.occupation ? props.errors.occupation : null}
          disabled={props.flagEdit}
        />
        <Form.Group>
          <Form.Select
            label={<label>Status <span className="required-inut">*</span></label>}
            fluid width={6}
            options={occupationStatusOptions}
            value={form.occupationStatus}
            onChange={(e, { value }) => onEditInput('occupationStatus', value)}
            error={form.occupationStatus || props.errors.occupationStatus ? props.errors.occupationStatus : null}
            disabled={props.flagEdit}
          />
          <Form.Input
            label={<label>Income ($)<span className="required-inut">*</span></label>}
            placeholder='Income'
            fluid width={6}
            value={form.income}
            onChange={(e) => onEditInput('income', e.target.value)}
            error={form.income || props.errors.income ? props.errors.income : null}
            disabled={props.flagEdit}
          />
          <Form.Select
            label={<label>Income Frequency <span className="required-inut">*</span></label>}
            fluid width={6}
            options={incomeFrequencyOptions}
            value={form.incomeFrequency}
            onChange={(e, { value }) => onEditInput('incomeFrequency', value)}
            error={form.incomeFrequency || props.errors.incomeFrequency ? props.errors.incomeFrequency : null}
            disabled={props.flagEdit}
          />
        </Form.Group>
        {
          form.occupationStatus !== 'Unemployed' && (
            <Form.Group>
              <Form.Field width={8}>
                <label>Phone Number <span className="required-inut">*</span></label>
                <Input
                  label='+1'
                  value={form.workPhone}
                  placeholder='Phone Number'
                  onChange={(e) => onEditInput('workPhone', e.target.value)}
                />
                {
                  ((form.workPhone && props.errors.workPhone) || props.errors.workPhone) &&
                  <Label basic color='red' pointing>
                    {props.errors.workPhone}
                  </Label>
                }
              </Form.Field>
              <Form.Input
                label={<label>Total Months Employed  <span className="required-inut">*</span></label>}
                placeholder='totalMonthsEmployed'
                fluid width={8}
                value={form.totalMonthsEmployed}
                onChange={(e) => onEditInput('totalMonthsEmployed', e.target.value)}
                error={form.totalMonthsEmployed || props.errors.totalMonthsEmployed ? props.errors.totalMonthsEmployed : null}
                disabled={props.flagEdit}
              />
            </Form.Group>
          )
        }
      </Grid.Column>
    </Grid>
  );
};