// IMPORT OBJECTS/LIBRARIES
import React, { Component } from "react";
import { Button, Grid, Header, Icon } from "semantic-ui-react";
import CustomCarousel from "../carousel/carousel";
import { FormatNumberToPrice } from "../../helpers/general";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// IMPORT COMPONENTS
import PaymentMobile from "../../customer/quotation/payments/PaymentMobile";
import CarPrice from "./CarPrice";
import WeeklyHead from "./WeeklyHead";

// IMPORT CSS STYLE
import "./Car.sass"
import { TradeModal } from "./TradeModal";

class Car extends Component {
    constructor(props) {
        super(props);
        this.state = {
            car: props.car,
            quots: props.quots,
            formula: props.formula,
            insurance_protection: props.insurance_protection,
            max_down_payment: props.max_down_payment,
            trade_in: props.trade_in,
            money_owed: props.money_owed,
            isDetail: false,
            tradeModal: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.car.id != prevProps.car.id || (this.props.quots && this.props.quots.monthly.id != prevProps.quots.monthly.id) || (this.props.trade_in != prevProps.trade_in) 
        || (this.props.money_owed != prevProps.money_owed)) {
            this.setState({
                car: this.props.car,
                quots: this.props.quots,
                formula: this.props.formula,
                interest: this.props.interest,
                trade_in: this.props.trade_in,
                money_owed: this.props.money_owed,
            });
        }
    }

    insuranceChange = (value, label) => {
        this.props.insuranceChange(value, label);
        this.setState({ [label]: value });
    }

    renderHeaderCar() {
        return (
            <Grid.Column mobile={16} tablet={16} computer={16}>
                <Header className={'title'} as='h2'><span>{this.state.car.year + " " + this.state.car.make + " " + this.state.car.model}</span><div className="stk ui-mobile">STK #{this.state.car.stock_number}</div></Header>
                <div className="stk ui-computer">{this.state.car.miles.toLocaleString()} Miles</div>
                <div className="stk ui-computer">STK #{this.state.car.stock_number}</div>
            </Grid.Column>
        );
    }

    renderPrice() {
        return (
            <CarPrice car={this.state.car} quots={this.state.quots} trade_in={this.state.trade_in} money_owed={this.state.money_owed} interest={this.props.interest}
                disabledTrade={this.props.disabledTrade} type={this.props.type} retailer={this.props.retailer} insurance_protection={this.state.insurance_protection}
                insuranceChange={(value, label) => this.insuranceChange(value, label)} openTredModal={() => this.setState({ tradeModal: true })}
                formula={this.props.formula}
            />
        );
    }

    renderWeekly = () => {
        return (
            <WeeklyHead quots={this.state.quots} interest={this.props.interest} isDetail={this.state.isDetail} nextStep={() => this.props.nextStep ? this.props.nextStep() : undefined} abledNext={this.props.nextStep ? true : false} />
        );
    }

    renderSalePrice = (ui) => {
        return (
            <div className={ui + " prices-car"}>
                {
                    this.state.car.msrp > 0 ?
                        <span className="prices">ORIGINAL PRICE: {FormatNumberToPrice(this.state.car.msrp)}</span>
                        : null
                }
                <span className="prices">SALE PRICE: {FormatNumberToPrice(this.state.car.price)}</span>
            </div>
        );
    }

    render() {
        return (
            <div className={'carComponent'}>
                {
                    this.props.type == 'firstCar' ?
                        <Grid style={{ display: "flex", justifyContent: "center" }}>
                            <Grid.Column mobile={16} tablet={8} computer={8} className="columnGalery">
                                <CustomCarousel images={this.state.car.images} carId={this.state.car.id} />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                {this.renderHeaderCar()}
                                {this.renderSalePrice('ui-mobile')}
                                {this.renderWeekly()}
                                <div className="carDetails">
                                    {this.renderPrice()}
                                </div>
                                <div className="ui-mobile">
                                    <div className={'acceptButton'}>
                                        <Button className={!this.state.isDetail ? 'btn-detail' : 'btn-detail btn-detail-click'} onClick={(e) => this.setState({ isDetail: !this.state.isDetail })}>
                                            Price Details{!this.state.isDetail ? '' : <Icon name='close' />}
                                        </Button>
                                    </div>
                                    {
                                        !this.state.isDetail ?
                                            null
                                            :
                                            <div style={{ textAlign: "center", padding: 5 }}>
                                                {this.renderPrice()}
                                            </div>
                                    }
                                </div>
                            </Grid.Column>
                        </Grid>
                        :
                        this.props.type == 'car' || this.props.type == 'selectedCar' || this.props.type === 'selectedCarLanding' ?
                            <Grid>
                                <Grid.Column mobile={16} tablet={8} computer={this.props.type == 'selectedCar' ? 6 : 8} className="columnGalery">
                                    <CustomCarousel images={this.state.car.images} carId={this.state.car.id} />
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={this.props.type == 'selectedCar' ? 10 : 8}>
                                    {this.renderHeaderCar()}
                                    {this.renderSalePrice('ui-mobile')}
                                    {this.renderWeekly()}
                                    {
                                        this.props.type === 'car' ?
                                            <div>
                                                <div className="carDetails">
                                                    {this.renderPrice()}
                                                </div>
                                                <div className="ui-mobile">
                                                    <div className={'acceptButton'}>
                                                        <Button className={!this.state.isDetail ? 'btn-detail' : 'btn-detail btn-detail-click'} onClick={(e) => this.setState({ isDetail: !this.state.isDetail })}>
                                                            Price Details{!this.state.isDetail ? '' : <Icon name='close' />}
                                                        </Button>
                                                    </div>
                                                    {
                                                        !this.state.isDetail ?
                                                            null
                                                            :
                                                            <div style={{ textAlign: "center", padding: 5 }}>
                                                                {this.renderPrice()}
                                                            </div>
                                                    }
                                                </div>
                                                <div className={'acceptButton'}>
                                                    <Button className="btn-continue" onClick={(e) => this.props.carContinue(this.state.car)}>
                                                        <div className="ui-computer"><h3>Finance & Payment Options</h3></div>
                                                        <div className="ui-mobile"><h4>Finance & Payment Options</h4></div>
                                                    </Button>
                                                </div>
                                            </div>
                                            : null
                                    }
                                    {
                                        this.props.type == 'selectedCar' || this.props.type === 'selectedCarLanding' ?
                                            <div>
                                                {
                                                    this.props.type === 'selectedCarLanding' &&
                                                    <div className="carDetails">
                                                        {this.renderPrice()}
                                                    </div>
                                                }
                                                <div className="ui-mobile">
                                                    <div className={'acceptButton'}>
                                                        <Button className={!this.state.isDetail ? 'btn-detail' : 'btn-detail btn-detail-click'} onClick={(e) => this.setState({ isDetail: !this.state.isDetail })}>
                                                            Price Details{!this.state.isDetail ? '' : <Icon name='close' />}
                                                        </Button>
                                                    </div>
                                                    {
                                                        this.state.isDetail && (
                                                            this.renderPrice()
                                                        )
                                                    }
                                                </div>

                                            </div>
                                            : null
                                    }
                                </Grid.Column>
                                {
                                    this.props.type === 'selectedCar' || this.props.type === 'selectedCarLanding' ?
                                        !this.state.isDetail && (
                                            <Grid.Column mobile={16} tablet={14} className="ui-mobile">
                                                <div className="grapper-methods">
                                                    <div className="paymentHeader">
                                                        <Icon size="large" name="long arrow alternate left"/>COMPARE PAYMENT OPTIONS<Icon size="large" name="long arrow alternate right"/>
                                                    </div>
                                                    <Carousel useKeyboardArrows autoPlay={false} infiniteLoop={true} showIndicators={false} showThumbs={false} swipeable={true} className={'generalCarousel'} showStatus={false}  >
                                                        <div className="slide-holder">
                                                            <PaymentMobile payment={'monthly'} quots={this.state.quots} selectedPayment={this.props.selectedPayment} />
                                                        </div>
                                                        <div className="slide-holder">
                                                            <PaymentMobile payment={'weekly'} quots={this.state.quots} selectedPayment={this.props.selectedPayment} />
                                                        </div>
                                                        <div className="slide-holder">
                                                            <PaymentMobile payment={'weeklyPlus'} quots={this.state.quots} selectedPayment={this.props.selectedPayment} />
                                                        </div>
                                                    </Carousel>
                                                </div>
                                            </Grid.Column>
                                        )
                                    :null
                                }
                            </Grid>
                            :
                            this.props.type == 'good_car' || this.props.type == 'better_car' || this.props.type == 'best_car' ?
                                <Grid>
                                    <Grid.Column tablet={16} computer={16}>
                                        <CustomCarousel images={this.state.car.images} carId={this.state.car.id} />
                                        <div style={{ width: "100%", textAlign: "center" }}>
                                            {this.renderHeaderCar()}
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                :
                                this.props.type == 'finishedCar' || this.props.type == 'detailQuot' ?
                                    <Grid style={{ display: "flex", justifyContent: "center" }}>
                                        <Grid.Column mobile={16} tablet={8} computer={8} className="columnGalery">
                                            <CustomCarousel images={this.state.car.images} carId={this.state.car.id} />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            {this.renderHeaderCar()}
                                            {this.renderSalePrice('ui-mobile')}
                                            {this.renderWeekly()}
                                            <div className="carDetails">
                                                {this.renderPrice()}
                                            </div>
                                            <div className="ui-mobile">
                                                <div className={'acceptButton'}>
                                                    <Button className={!this.state.isDetail ? 'btn-detail' : 'btn-detail btn-detail-click'} onClick={(e) => this.setState({ isDetail: !this.state.isDetail })}>
                                                        Price Details{!this.state.isDetail ? '' : <Icon name='close' />}
                                                    </Button>
                                                </div>
                                                {
                                                    !this.state.isDetail ?
                                                        null
                                                        :
                                                        <div style={{ textAlign: "center", padding: 5 }}>
                                                            {this.renderPrice()}
                                                        </div>
                                                }
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                    :
                                    this.props.type == 'cars' ?
                                        <Grid>
                                            <Grid.Column mobile={16} tablet={4} computer={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CustomCarousel showThumbs images={this.state.car.images} carId={this.state.car.id} />
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={12} computer={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div style={{ width: "100%", textAlign: "center" }}>
                                                    <Header className={'title-car'} as='h3'><span className="color-blue-ligth">{FormatNumberToPrice(this.state.quots.weekly.payment) + " Weekly - "}</span>{this.state.car.year + " " + this.state.car.make + " " + this.state.car.model}</Header>
                                                    <div className="ui-computer">
                                                        {this.renderSalePrice('computer-prices')}
                                                        <h3 className="color-blue-ligth">{FormatNumberToPrice(this.state.quots.monthly.payment) + " Monthly"}</h3>
                                                    </div>
                                                    <div className="ui-mobile">
                                                        {this.renderSalePrice('')}
                                                        <span className="color-blue-ligth">{FormatNumberToPrice(this.state.quots.monthly.payment) + " Monthly"}</span>
                                                    </div>
                                                    {/* <Header className={'terms'} as='h3'>Effective Term {this.state.quots.weekly.effective_term}</Header> */}
                                                    {/* <Header className={'terms'} as='h3'>contracted Term {this.state.quots.weekly.contracted_term}</Header> */}
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                        :
                                        this.props.type == 'onlyDetailInfo' ?
                                            <Grid>
                                                <Grid.Column mobile={16} tablet={8} computer={8} >
                                                    {this.renderHeaderCar()}
                                                    <div className="stk ui-computer">VIN {this.state.car.vin}</div>
                                                    {this.renderSalePrice('ui-mobile')}
                                                    {this.renderWeekly()}
                                                    <div className="carDetails">
                                                        {this.renderPrice()}
                                                    </div>
                                                    <div className="ui-mobile">
                                                        <div className={'acceptButton'}>
                                                            <Button className={!this.state.isDetail ? 'btn-detail' : 'btn-detail btn-detail-click'} onClick={(e) => this.setState({ isDetail: !this.state.isDetail })}>
                                                                Price Details{!this.state.isDetail ? '' : <Icon name='close' />}
                                                            </Button>
                                                        </div>
                                                        {
                                                            !this.state.isDetail ?
                                                                null
                                                                :
                                                                <div style={{ textAlign: "center", padding: 5 }}>
                                                                    {this.renderPrice()}
                                                                </div>
                                                        }
                                                    </div>
                                                </Grid.Column>
                                            </Grid>
                                            : null
                }
                {
                    this.state.tradeModal ?
                        <TradeModal trade_in={this.state.trade_in} money_owed={this.state.money_owed} changeTrade={this.changeTrade} tradeText={this.props.tradeText}
                            closeTrade={(tradeText) => {this.setState({ tradeModal: false }); this.props.insuranceChange(tradeText, "tradeText");}} />
                        : null
                }
            </div>
        )
    }
}
export default Car;