// @flow 
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Dimmer, Header, Loader, Message, Pagination, Table } from 'semantic-ui-react';
import ModalComponent from '../alertModal/modal';
import { Events } from '../paymentPlans/events/Events';
import { PaymentPlansModal } from '../paymentPlans/PaymentPlansModal';
import { Payments } from '../paymentPlans/payments/Payments';
import { getAccounts } from '../requests';
import { TermsConditions } from '../termsConditions/TermsConditions';

import "./Accounts.sass";
import { AccountsFilter } from './AccountsFilter';

const filter = { option: "", key: '' };

export const Accounts = () => {
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState({ data: [], last_page: 0, total: 0 });
  const [defaultFilter, setDefaultFilter] = useState(filter)
  const [page, setPage] = useState(1);

  const getAccountsList = (page = 1, filter = defaultFilter, isMounted = true) => {
    setDefaultFilter(filter);
    setIsLoading(true);
    const params = {
      page,
      ...(filter.option !== 'range' ?
        { date: filter.option }
        : filter.startDate && filter.startDate && {
          startDate: moment(filter.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
          endDate: moment(filter.endDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
        }
      ),
      ...(filter.keyword && { keyword: filter.keyword })
    }
    getAccounts(params).then(response => {
      setIsLoading(false);
      if (isMounted) {
        setAccounts(response.data);
        setPage(page);
      }
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
      }
    });
  }

  useEffect(() => {
    let isMounted = true;
    getAccountsList(1, filter, isMounted);
    return () => { isMounted = false };
  }, []);

  return (
    <div className='body accounts' style={{ display: accounts.data.length > 3 ? 'grid' : '' }}>
      <Header size='huge'>Accounts</Header>
      <div>
        <div style={{ display: "inline-block", marginBottom: "1rem" }}>
          <TermsConditions show={false} />
        </div>
        <div style={{ display: "inline-block", marginBottom: "1rem" }}>
          <Payments global type={'admin'} />
        </div>
        <div style={{ display: "inline-block", marginBottom: "1rem" }}>
          <Events type={'global'} />
        </div>
      </div>
      <AccountsFilter filter={(form) => getAccountsList(1, form)} />
      {
        accounts.data.length > 0 ?
          <div className='table-wrapper'>
            <Table className={'customTable'} celled unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Phone Number</Table.HeaderCell>
                  <Table.HeaderCell>Creation Date</Table.HeaderCell>
                  <Table.HeaderCell>Payment Plans</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  accounts.data.map(item => (
                    <Table.Row>
                      <Table.Cell>{item.name + " " + item.second_name}</Table.Cell>
                      <Table.Cell>{item.user.email}</Table.Cell>
                      <Table.Cell>{item.user.phone_number}</Table.Cell>
                      <Table.Cell>{item.dwolla_created_at}</Table.Cell>
                      <Table.Cell><PaymentPlansModal item={item} /></Table.Cell>
                    </Table.Row>
                  ))
                }
              </Table.Body>
            </Table>
          </div>
          :
          <Message style={{ textAlign: 'center', margin: "1em 0" }}>
            <Message.Header style={{ textAlign: "center" }}>There are not Dwolla Accounts Created</Message.Header>
          </Message>
      }
      {
        accounts.data.length > 0 && (
          <div style={{ textAlign: 'center', margin: "1em 0" }}>
            <Pagination efaultActivePage={1} activePage={page} totalPages={accounts.last_page} onPageChange={(e, { activePage }) => getAccountsList(activePage)} />
          </div>
        )
      }
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
    </div>
  );
};