import moment from 'moment';
import React, { useState } from 'react';
import { Button, Dimmer, Icon, Loader } from 'semantic-ui-react';
import ModalComponent from '../alertModal/modal';
import { ConfirmModal } from '../confirmModal/ConfirmModal';
import { restartPaymentPlan } from '../requests';

export const RestartButton = ({form, updatePlan}) => {
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState({});

  // ------------------------------ confirm modal
  const confirmRestart = () => {
    setConfirmModal({
      title: "Restart Payment Plan",
      message: "We will restart the plan on " + moment(form.paymentDate).format('dddd, MMMM D, YYYY') + " . Are you sure you want to restart the payment plan?"
    })
  }
  const confirm = (flag) => {
    setConfirmModal(false);
    if (flag) {
      paymentPlan();
    }
  }

  // ----------------------------------- disabled payment plan
  const paymentPlan = () => {
    setIsLoading(true);
    restartPaymentPlan(form.id).then(response => {
      setIsLoading(false);
      setModal({ status: 201, message: "Payment plan was started", response: response.data });
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else if (error.response.status === 404) {
          setModal({ status: 406, message: "Sorry. Element was not found" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, try again later" });
        }
      }
    });
  }

  // ------------------------------------ close alert modal --------------------
  const closeAlertModal = () => {
    if (modal.status === 201) {
      let auxForm = { ...form }
      auxForm.status = 1;
      auxForm.comments = '';
      updatePlan(auxForm, true);
    }
    setModal({});
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Button color='green'
        onClick={confirmRestart}>
        <Icon name='checkmark' />Restart Payment plan
      </Button>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={closeAlertModal} />
      }
      {
        confirmModal.message &&
        <ConfirmModal modal={confirmModal} afterEvent={confirm} />
      }
    </div>
  );
};