import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Dimmer, Grid, Loader, Pagination, Table } from 'semantic-ui-react';
import { FormatDate } from '../../helpers/general';
import ModalComponent from '../alertModal/modal';
import { CoApplicant } from '../coApplicant/CoApplicant';
import { searchQuotesCustomer } from '../requests';
import { QuoteApproval } from './QuoteApproval';

import "./QuotesApproval.sass"

export const QuotesApproval = (props) => {
  const { isActive, user } = props;
  const [quotes, setQuotes] = useState({ data: [], last_page: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState({});

  // ------------------------------------ change page
  const changePage = (page = 1) => {
    let params = { page };
    setIsLoading(true);
    searchQuotesCustomer(params).then(response => {
      setIsLoading(false);
      setCurrentPage(page);
      setQuotes(response.data);
    }).catch(error => {
      setIsLoading(false);
    });
  }

  // ---------------------------------- update quotes list after lona creation
  const updateQuote = (loan) => {
    let auxQuotes = { ...quotes };
    for (const item of auxQuotes.data) {
      if (item.id === loan.quot_id) {
        item.loan = loan;
        let auxCustomer = { ...user.customer };
        auxCustomer.customerData = auxCustomer.customerData == null ? {} : auxCustomer.customerData;
        auxCustomer.customerData.employerName = loan.employerName;
        auxCustomer.customerData.occupation = loan.occupation;
        auxCustomer.customerData.occupationStatus = loan.occupationStatus;
        auxCustomer.customerData.income = loan.income;
        auxCustomer.customerData.incomeFrequency = loan.incomeFrequency;
        auxCustomer.customerData.monthsAtCurrentAddress = loan.monthsAtCurrentAddress;
        auxCustomer.customerData.housingStatus = loan.housingStatus;
        auxCustomer.customerData.driverLicenseState = loan.driverLicenseState;
        auxCustomer.customerData.driverLicense = loan.driverLicense;
        auxCustomer.customerData.maritalStatus = loan.maritalStatus;
        auxCustomer.spouse = loan.spouse;
        props.updateCustomerData(auxCustomer, 'approval');
        break;
      }
    }
    setQuotes(auxQuotes);
  }

  useEffect(() => {
    setQuotes(props.quotes);
  }, [props])

  return (
    <div className={'body'} style={{ display: isActive ? 'block' : 'none' }}>
      <Grid stackable>
        <Grid.Column width={16}>
          <Breadcrumb size={'large'}>
            <Breadcrumb.Section active>Deals list</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
      </Grid>
      <CoApplicant {...user.customer} type={'coApplicant'} />
      {/* <CoApplicant {...user.customer} type={'guarantor'} /> */}
      {/* <CoApplicant {...user.customer} type={'driver'} /> */}
      <Grid stackable className='quote-table'>
        <Grid.Column width={16}>
          <Table className={'customTable'} unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Retailer Name
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Stock #/Make/Model/Year
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Quotation Date
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Loan Approval Status
                </Table.HeaderCell>
                <Table.HeaderCell>
                  View Approved Offers
                </Table.HeaderCell>
                <Table.HeaderCell>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                quotes.data.map((item, key) => (
                  <Table.Row key={key}>
                    <Table.Cell>
                      {item.retailer.name}
                    </Table.Cell>
                    <Table.Cell>
                      {`#${item.car.stock_number} ${item.car.make} ${item.car.model} ${item.car.year}`}
                    </Table.Cell>
                    <Table.Cell>
                      {FormatDate(item.created_at)}
                    </Table.Cell>
                    <Table.Cell>
                      {
                        item.status === 0 ? ' Pending' : item.status === 1 ? ' Created' :
                          item.status === 2 ? ' Accepted' : item.status === 3 ? ' Cancelled' :
                            item.status === 4 ? ' Expired' : null
                      }
                    </Table.Cell>
                    <Table.Cell>
                      {
                        !item.loan ?
                          <Button className='loanStatus' basic>No loan application</Button>
                          :
                          item.loan.status === 0 ?
                            <Button className='loanStatus' color='grey'>Not completed</Button>
                            :
                            item.loan.status === 1 ?
                              <Button className='loanStatus' color='primary'>Pending Approval</Button>
                              :
                              item.loan.status === 2 ?
                                <Button className='loanStatus' color='green'>Approved</Button>
                                :
                                <Button className='loanStatus' color='red'>Rejected</Button>
                      }
                    </Table.Cell>
                    <Table.Cell>
                    </Table.Cell>
                    <Table.Cell>
                      <QuoteApproval quote={item} user={user} updateQuote={updateQuote} />
                    </Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
      {
        quotes.data.length > 0 &&
        <div style={{ textAlign: 'Center', width: "100%", marginTop: "1rem" }}>
          <Pagination
            defaultActivePage={1}
            currentPage={currentPage}
            totalPages={quotes.last_page}
            onPageChange={(e, { activePage }) => changePage(activePage)} />
        </div>
      }
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
    </div>
  );
};