import React from 'react';
import { Divider, Grid, Header, Table } from 'semantic-ui-react';
import { FormatNumberToPrice } from '../../../../helpers/general';

export const Summary = ({ plan, amortization, fee }) => {
  return (
    <div>
      <Grid columns={2} stackable>
        <Grid.Column>
          <Table basic='very' unstackable>
            <Table.Body>
              <Table.Row >
                <Table.Cell textAlign='right' className='bold'>
                  Amount Financed:
                </Table.Cell>
                <Table.Cell textAlign='left'>
                  {FormatNumberToPrice(amortization?.total?.sum_of_installments || 0)}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell textAlign='right' className='bold'>
                  Term:
                </Table.Cell>
                <Table.Cell textAlign='left'>
                  {plan.loanTerm}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell textAlign='right' className='bold'>
                  Interest Rate:
                </Table.Cell>
                <Table.Cell textAlign='left'>
                  {plan.rate}%
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell textAlign='right' className='bold'>
                  1st Payment Due Date:
                </Table.Cell>
                <Table.Cell textAlign='left'>
                  {plan.firstDate}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell textAlign='right' className='bold'>
                  Total Monthly Payment:
                </Table.Cell>
                <Table.Cell textAlign='left'>
                  {plan.loanMonthlyPayment}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column>
          <fieldset className='fieldset'>
            <legend className='legend'><Header as="h2">WHAT CAN I EXPECT?</Header></legend>
            <Table basic='very' unstackable>
              <Table.Body>
                <Table.Row >
                  <Table.Cell textAlign='right' className='bold'>
                    Account Verification Fee:
                  </Table.Cell>
                  <Table.Cell textAlign='left' className='bold'>
                    Upon Receipt
                  </Table.Cell>
                  <Table.Cell textAlign='left'>
                    {FormatNumberToPrice(fee)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row >
                  <Table.Cell textAlign='right' className='bold'>
                    Date of 1st Debit:
                  </Table.Cell>
                  <Table.Cell textAlign='left' className='bold'>
                  </Table.Cell>
                  <Table.Cell textAlign='left'>
                  </Table.Cell>
                </Table.Row>
                <Table.Row >
                  <Table.Cell textAlign='right' className='bold'>
                    Debit Frequency:
                  </Table.Cell>
                  <Table.Cell textAlign='left'>
                    Weekly
                  </Table.Cell>
                </Table.Row>
                <Table.Row >
                  <Table.Cell textAlign='right' className='bold'>
                    Date of 1st Recurring Debit:
                  </Table.Cell>
                  <Table.Cell textAlign='left'>
                    {plan.firstDate}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </fieldset>
        </Grid.Column>
      </Grid>
      <Divider />
      <Header as="h2" color="green">Explanation of benefits for {FormatNumberToPrice(amortization?.total?.sum_of_installments || 0)} loan amount at {plan.rate}%</Header>
      <Grid columns={3} stackable>
        <Grid.Column>
          <Header as="h3" textAlign='center'>TERM REDUCTION</Header>
          <Table basic='very' unstackable>
            <Table.Body>
              <Table.Row >
                <Table.Cell className='bold'>
                  Standard Loan Term
                </Table.Cell>
                <Table.Cell className='bold'>
                  {plan.loanTerm}
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell className='bold'>
                  AutoPayPlus
                </Table.Cell>
                <Table.Cell className='bold'>
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell className='bold blue'>
                  Payoff Early
                </Table.Cell>
                <Table.Cell className='bold'>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column>
          <Header as="h3" textAlign='center'>IMPROVED EQUITY</Header>
          <Table basic='very' unstackable>
            <Table.Body>
              <Table.Row >
                <Table.Cell className='bold'>
                  At 3 1
                  /2 year trade-in
                  mark, standard loan
                  balance
                </Table.Cell>
                <Table.Cell className='bold'>
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell className='bold'>
                  AutoPayPlus
                  Loan balance
                </Table.Cell>
                <Table.Cell className='bold'>
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell className='bold blue'>
                  Equity Benefit
                </Table.Cell>
                <Table.Cell className='bold'>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column>
          <Header as="h3" textAlign='center'>INTEREST SAVINGS & SERVICE EXPENSE</Header>
          <Table basic='very' unstackable>
            <Table.Body>
              <Table.Row >
                <Table.Cell className='bold'>
                  Standard loan interest
                </Table.Cell>
                <Table.Cell className='bold'>
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell className='bold'>
                  AutoPayPlus Interest
                </Table.Cell>
                <Table.Cell className='bold'>
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell className='bold'>
                  Interest Savings
                </Table.Cell>
                <Table.Cell className='bold'>
                </Table.Cell>
              </Table.Row>
              <Table.Row >
                <Table.Cell className='bold'>
                  *Service Expense
                </Table.Cell>
                <Table.Cell className='bold'>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
      <p>
        *There is an Enrollment Fee of up to $399 and debit fees of up to $2.45 per debit for the AutoPayPlus service not included in the calculations.
        Ask your dealer for details. For a full breakdown of all costs and benefits please see www.autopayplus.com/pricing. All numbers shown are
        estimates only.
      </p>
    </div>
  );
};