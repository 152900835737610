//Formater for Price
import moment from "moment";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import isInt from 'validator/lib/isInt';
import isLength from 'validator/lib/isLength';
import { isValidPhoneNumber } from 'react-phone-number-input'
import { zipCodesStates } from "./usaStates";

export const formatterPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: 'USD',
    minimumFractionDigits: 2
    // the default value for minimumFractionDigits depends on the currency
    // and is usually already 2
});

//Format a Number to Price
export const FormatNumberToPrice = number => {
    return formatterPrice.format(number).toString();
};

//Format a CASH DOWN to Price
export const formatterCash = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: 'USD',
    minimumFractionDigits: 0
    // the default value for minimumFractionDigits depends on the currency
    // and is usually already 2
});
export const FormatCashDownToPrice = number => {
    return formatterCash.format(number).toString();
};

export const FormatDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
}

export const dateToLabel = (month, year) => {
    let months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    return `${months[month - 1]} ${year}`;
}

// -------------------------- calculate payments ----------------------------
export const calculatePayments = (car, loan_length, down_payment, formula, annual_interest_rate, insurance_protection, trade_in, money_owed) => {
    let car_price = car.price, coxPrice = car.price, carNoIncentives = +car.price;
    if (insurance_protection) {
        let price = car_price * (formula.insurance_protection / 100) + car_price;
        car_price = price;
        coxPrice = price;
        carNoIncentives = price;
    }
    if (formula.incentives) {
        car_price -= (formula.incentives.totalRebate > 0 ? formula.incentives.totalRebate : 0) + (formula.incentives.totalDealerCash > 0 ? formula.incentives.totalDealerCash : 0);
    }
    let tax = car_price * (formula.tax_rate / 100);
    let tax2 = carNoIncentives * (formula.tax_rate / 100);
    if (formula.tax_rate && formula.tax_rate != 0) {
        car_price += tax;
        carNoIncentives += tax2;
    }
    if (formula.dealer_fees) {
        car_price += formula.dealer_fees;
        carNoIncentives += formula.dealer_fees;
    }
    let quots = {
        monthly: {},
        weekly: {},
        weeklyPlus: {}
    }, interest = (annual_interest_rate / 100) / 12, payment = 0, weekly_payment = 0, amount = 0;
    if (isNaN(trade_in) || trade_in == '') {
        trade_in = 0;
    } else {
        trade_in = parseFloat(trade_in)
    }
    let new_trade_in = +trade_in - +money_owed, finish_down = down_payment;
    if (new_trade_in < 0) {
        // ----------------- add diference trade in to cr price
        car_price = car_price - new_trade_in;
        carNoIncentives = carNoIncentives - new_trade_in;
    } else {
        finish_down = finish_down + new_trade_in
    }
    amount = car_price - finish_down
    carNoIncentives = carNoIncentives - finish_down
    payment = (car_price - finish_down) *
        (((interest) * Math.pow((1 + interest), loan_length)) / (Math.pow((1 + interest), loan_length) - 1));
    if (/*car_price <= down_payment || */car_price == 0 || payment < 0) {
        payment = 0;
    }
    amount = amount < 0 ? 0 : amount;
    // -------------------------- payments -------------------------------
    quots = calculatePaymentsWeekly(quots, payment, formula, loan_length, down_payment, amount, tax, car_price, formula.dealer_fees, car.id, annual_interest_rate, coxPrice, carNoIncentives);
    return quots;
}

export const calculateDownPayment = (car, loan_length, formula, annual_interest_rate,insurance_protection, payment, trade_in, money_owed) => {
    let car_price = +car.price;
    if (insurance_protection) {
        car_price = (car_price * (formula.insurance_protection / 100)) + car_price;
    }
    if (formula.incentives) {
        car_price -= (formula.incentives.totalRebateFlag ? formula.incentives.totalRebate : 0) + (formula.incentives.totalDealerCashFlag ? formula.incentives.totalDealerCash : 0);
    }
    if (formula.tax_rate && formula.tax_rate != 0) {
        car_price = car_price * (formula.tax_rate / 100) + car_price;
    }
    if (formula.dealer_fees) {
        car_price += formula.dealer_fees;
    }
    let new_trade_in = +trade_in - +money_owed;
    if (new_trade_in < 0) {
        car_price -= new_trade_in;
    }
    let  interest = (annual_interest_rate / 100) / 12;
    let down_payment = -(payment / ((((interest) * Math.pow((1 + interest), loan_length)) / (Math.pow((1 + interest), loan_length) - 1))) - car_price)
    return down_payment;
}

const defaultQuotes = {
    monthly: {},
    weekly: {},
    weeklyPlus: {}
}

export const calculatePaymentsWeekly = (quots = defaultQuotes, payment, formula, loan_length, down_payment, amount, tax, car_price, dealer_fees, carId, annual_interest_rate, coxPrice, carNoIncentives) => {
    // -------------------------- monthly payment -------------------------------
    if (quots == null) {
        quots = defaultQuotes;
    }
    quots['monthly']['id'] = (new Date()).getTime();
    quots['monthly']['carid'] = carId;
    quots['monthly']['payment'] = payment;
    quots['monthly']['formatPayment'] = parseFloat(payment).toFixed(2);
    quots['monthly']['purchasing_power'] = payment * loan_length;
    quots['monthly']['term_reduction'] = 0;
    quots['monthly']['contracted_term'] = loan_length;
    quots['monthly']['effective_term'] = loan_length;
    quots['monthly']['payment_benefit'] = 0;
    quots['monthly']['interest_charges'] = 0;
    quots['monthly']['down_payment'] = down_payment;
    quots['monthly']['amount'] = amount;
    quots['monthly']['amountNoIncentives'] = carNoIncentives;
    quots['monthly']['tax'] = tax;
    quots['monthly']['final_price'] = car_price;
    quots['monthly']['dealer_fees'] = dealer_fees ? dealer_fees : 0;
    quots['monthly']['debit_fee_week'] = formula.debit_fee_week;
    quots['monthly']['annual_interest_rate'] = annual_interest_rate;
    quots['monthly']['coxPrice'] = coxPrice;
    // -------------------------- weekly payment --------------------------------
    let weekly_payment = payment * (13 / 52);
    let weekly_payment_first = weekly_payment;
    if (!isNaN(formula.debit_fee_week) && payment != 0) {
        weekly_payment += formula.debit_fee_week;
    }
    let term_reduction_first = loan_length - (((payment * loan_length) / weekly_payment_first) / 52 * 12);
    let weeks = payment != 0 ? Math.ceil(((payment * loan_length) / weekly_payment) / 52 * 12) : 0;
    quots['weekly']['payment'] = weekly_payment;
    quots['weekly']['debitFeeWeek'] = formula.debit_fee_week ? formula.debit_fee_week : 0;
    quots['weekly']['paymentNoFeeWeek'] = weekly_payment_first;
    quots['weekly']['purchasing_power'] = weekly_payment * (loan_length / 12 * 52);
    quots['weekly']['term_reduction'] = loan_length - weeks;
    quots['weekly']['contracted_term'] = loan_length;
    quots['weekly']['effective_term'] = weeks;
    quots['weekly']['payment_benefit'] = quots['weekly']['purchasing_power'] - quots.monthly.purchasing_power;
    // quots['weekly']['interest_charges'] = quots['weekly']['term_reduction'] * weekly_payment;
    if (annual_interest_rate) {
        quots['weekly']['interest_charges'] = weekly_payment_first > 0 ? weekly_payment_first * (annual_interest_rate / 100) * (term_reduction_first / 12 * 52) : 0;
    }
    // -------------------------- weekly plus payment --------------------------------
    weekly_payment = payment * (14 / 52);
    weekly_payment_first = weekly_payment;
    if (!isNaN(formula.debit_fee_week) && payment != 0) {
        weekly_payment += formula.debit_fee_week;
    }
    // weekly_payment = weekly_payment + (payment / 52);
    term_reduction_first = loan_length - (((payment * loan_length) / weekly_payment_first) / 52 * 12);
    weeks = payment != 0 ? Math.ceil(((payment * loan_length) / weekly_payment) / 52 * 12) : 0;
    quots['weeklyPlus']['payment'] = weekly_payment;
    quots['weeklyPlus']['debitFeeWeek'] = formula.debit_fee_week ? formula.debit_fee_week : 0;
    quots['weeklyPlus']['paymentNoFeeWeek'] = weekly_payment_first;
    quots['weeklyPlus']['purchasing_power'] = weekly_payment * (loan_length / 12 * 52);
    quots['weeklyPlus']['term_reduction'] = loan_length - weeks;
    quots['weeklyPlus']['contracted_term'] = loan_length;
    quots['weeklyPlus']['effective_term'] = weeks;
    quots['weeklyPlus']['payment_benefit'] = quots['weeklyPlus']['purchasing_power'] - quots.monthly.purchasing_power;
    // quots['weeklyPlus']['interest_charges'] = quots['weeklyPlus']['term_reduction'] * weekly_payment;
    if (annual_interest_rate) {
        quots['weeklyPlus']['interest_charges'] = weekly_payment_first > 0 ? weekly_payment_first * (annual_interest_rate / 100) * (term_reduction_first / 12 * 52) : 0;
    }
    return quots;
}

// -------------------------------------- change monthly payment ------------------
export const onchangeMonthly = (payment) => {
    let max_monthly_payment = payment;
    let steps_monthly_payment = {
        0: "$0",
        // [max_monthly_payment / 2]: FormatNumberToPrice(max_monthly_payment / 2),
        [max_monthly_payment]: FormatNumberToPrice(max_monthly_payment),
    }
    let monthly_payment =  payment;
    return {max_monthly_payment, steps_monthly_payment, monthly_payment}
}


export const validEmail = (string) => {
    let regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(string);
}

export const validZipCode = (string) => {
    let regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return regex.test(string);
}

export const validateSSN = (string = "") => {
    if (string == null) {
        string = '';
    }
    let flag = true;
    flag = isInt(string);
    if (!flag) {
        return flag;
    }
    flag = isLength(string, { min: 9, max: 9 });
    // if (!flag) {
    //     flag = isLength(string, { min: 9, max: 9 });
    // }
    return flag;
}

export const validateRoutingNumber = (string = "") => {
    if (string == null) {
        string = '';
    }
    let flag = true;
    flag = isInt(string);
    if (!flag) {
        return flag;
    }
    flag = isLength(string, { min: 9, max: 9 });
    return flag;
}

export const validateBankNumber = (string = "") => {
    if (string == null) {
        string = '';
    }
    let flag = true;
    flag = isInt(string);
    if (!flag) {
        return flag;
    }
    flag = isLength(string, { min: 4, max: 17 });
    return flag;
}

export const CustomDatePicker = ({ value, onClick}) => (
    <Button className={'customDatePicker'} onClick={onClick}>
        {value}&nbsp;
        <Icon name='angle down' size={'big'} />
    </Button>
);

// ----------------- validate phone numbers
export const validPhoneNumber = (string) => {
    if (!string || isNaN(string) || string.length !== 10 ) {
        return false;
    }
    return true;
}



export const validCodeNumber = (string) => {
    var strFirstThree = string.substring(0,3);
    if (strFirstThree === '800' || strFirstThree === '866' || strFirstThree === '888' || strFirstThree === '877') {
        return false
    }
    if (!isValidPhoneNumber('+1' + string)) {
        return false;
    }
    return true;
}

// -------------------- validate address
export const validAddress = (value) => {
    if (value === null || value === '' || value.split(" ").join("") === "") {
        return 'Address is required';
    }
    if (!checkFirstLetterNumber(value)) {
        return 'Address must be number + street name';
    }
    return null;
}

const checkFirstLetterNumber = (string) => {
    return string.match(new RegExp(/^\d/)) !== null;    
}


export const getMonths = (age) => {
    var startDate = moment(age, 'YYYY-MM-DD');
    var endDate = moment(moment(), 'YYYY-MM-DD');
    var monthDiff = endDate.diff(startDate, 'months');
    return monthDiff;
}

export const validateTotalMonthsEmployed = (value, occupationStatus, birthDate) => {
    let text = null;
    if (occupationStatus !== 'Unemployed') {
      text = null;
      if (!value || isNaN(value) || +value < 0 || +value > 1199) {
        text = 'The field must be a positive number maximum of 1199';
      } else {
        text = getMonths(birthDate) < +value ? 'The field must be under the age' : null;
      }
    }
    return text;
}

export const validateMonthsAtCurrentAddress = (value, birthDate) => {
    let text = null;
    if (!value || isNaN(value) || +value < 1 || +value > 1199) {
        text = 'The field must be a integer minimum 1 and a maximum of 1199';
    } else {
        text = getMonths(birthDate) < +value ? 'The field must be under the age' : null;
    }
    return text;
}

// ----------------------------------- valid empty string for spouse
export const validateSpouse = (spouse) => {
    if (!spouse || !validateEmpty(spouse.firstName, 'i') || !validateEmpty(spouse.lastName, 'i')
        || !validateEmpty(spouse.address, 'i') || !validateEmpty(spouse.city, 'i') || !validateEmpty(spouse.state, 's')
        || !validateEmpty(spouse.zip_code, 'z', spouse.state) ) {
        return false;
    }
    return true;
}

export const validateEmpty = (string, type, state) => {
    if (type === 'i') {
        if (!string || string.split(" ").join("") === "") {
            return false;
        }
    } else if (type === 'z') {
        if (!string || string.split(" ").join("") === "" || !validZipCode(string) || zipCodesStates(state, string)) {
            return false;
        }
    } else {
        if ((string === '' || !string)) {
            return false;
        }
    }
    return true;
}

// ------------------------------------ contact option ------------------------
export const phoneOptions = [
    {
        key: 1,
        text: 'Mobile',
        value: 1
    },
    {
        key: 2,
        text: 'Home',
        value: 2
    },
    {
        key: 3,
        text: 'Work',
        value: 3
    }
];

export const callOptions = [
    {
        key: 1,
        text: 'Morning',
        value: 1
    },
    {
        key: 2,
        text: 'Afternoon',
        value: 2
    },
    {
        key: 3,
        text: 'Evening',
        value: 3
    }
];

export const inspectionDistanceOptions = [
    {
        key: 1,
        text: '10 miles',
        value: 1
    },
    {
        key: 2,
        text: '30 miles',
        value: 2
    },
    {
        key: 3,
        text: '50 miles',
        value: 3
    }
]

export const contactOptions = [
    {
        key: 1,
        text: 'SMS',
        value: 1
    },
    {
        key: 2,
        text: 'Email',
        value: 2
    },
    {
        key: 3,
        text: 'No Communication',
        value: 3
    }
]

// ------------------------------------ Request data processed for bar chart ------------------------

export const FormatChartData = (data)=>{
    let procesed = data.reduce((result, element)=>{
        const ix = result.findIndex(e => e['Month'] === element['Month'] && e['Year'] === element['Year']);
        if(ix === -1){
            result.push(
                {
                    'Month':         element['Month'],
                    'Year':          element['Year'],
                    'authorized':    element['status'] === 2 ? element['count_status'] : 0,
                    'pending':       element['status'] === 0 || element['status'] === 1 ? element['count_status'] : 0,
                    'cancelled':     element['status'] === 3 || element['status'] === 4 ? element['count_status'] : 0
                }
            );
            return result;
        }else{
            result[ix]['authorized'] += (element['status'] === 2 ? element['count_status'] : 0);
            result[ix]['pending'] += (element['status'] === 0 || element['status'] === 1 ? element['count_status'] : 0);
            result[ix]['cancelled'] += (element['status'] === 3 || element['status'] === 4 ? element['count_status'] : 0);
            return result
        }
    }, []);

    return procesed.reduce((result, itm)=>{
        result['labels'].push(dateToLabel(itm['Month'], itm['Year']));
        result['datasets'][0]['data'].push(itm['authorized']);
        result['datasets'][1]['data'].push((itm['pending'] + itm['cancelled']));
        return result;
    }, {
        labels: [],
        datasets: [
            {
                label: 'Closed',
                backgroundColor: '#00d367',
                data: []
            },
            {
                label: 'Pending',
                backgroundColor: '#cecece',
                data: []
            }
        ]
    });
}

// --------------------------------------- return info inside popup payments ------------------------------
export const returnInfoPayments = (payment) => {
    if (payment == 'monthly') {
        return (<span>
            <span style={{textAlign: "justify"}}>Conventional and lender-preferred monthly loan/lease repayment plans can keep you from driving the vehicle you want with the options and protection you need. Monthly payments work for the bank but can eliminate important options for you.  Consider one of our weekly bank account drafting repayment plans below to:</span>
            <ul>
                <li>Afford more vehicle and/or options</li>
                <li>Pay your loan off faster</li>
                <li>Reduce or reverse negative equity</li>
                <li>Improve monthly cash flow</li>
            </ul>
        </span>)
        
    } else if(payment == 'weekly') {
        return (<span>
            <span style={{textAlign: "justify"}}>Weekly bank account drafting puts your interests ahead of the car finance companies.  Consider one of our weekly bank account drafting repayment plans below to:</span>
            <ul>
                <li>Afford more vehicle and/or options</li>
                <li>Pay your loan off faster</li>
                <li>Reduce or reverse negative equity</li>
                <li>Improve monthly cashflow</li>
            </ul>
        </span>)
    } else {
        return (<span>
            <span style={{textAlign: "justify"}}>Are you really upside down on your trade-in or know you'll want to trade in your vehicle well before the loan payoff? 
            By adding just 8% of your regular weekly payment on top of the weekly amount we draft from you bank account weekly, Power Payments Plus will:</span>
            <ul>
                <li>Help you make 14 full monthly payments a year against your vehicle loan</li>
                <li>Help you afford the vehicle and options you want or need</li>
                <li>Pay your loan up to 14 months faster</li>
                <li>Reduce, reverse or even eliminate negative equity by the end of this next loan</li>
                <li>Still Improves monthly cash flow while getting you into our best repayment program (for less per day than a cup of coffee)</li>
            </ul>
        </span>)
    }
}

export const ScheduleText = () => {
    return "lorem";
}

export const getAPPROVED = () => {
    return "lorem";
}

// -------------------------------------------- return quote summary in car info --------------------
export const returnQuoteSummary = (quote) => {
    // let moreWeekly = quote.weeklyPlus.payment - (quote.monthly.payment / 4)
    let moreWeekly = ((quote.weekly.purchasing_power  - quote.monthly.purchasing_power) / quote.monthly.contracted_term) / 4;
    return "Weekly: For a small $" + quote.monthly.debit_fee_week + " per transaction convenience fee," + 
    "The Advantage2U plan is adding a 13th payment to your annual number of payments, and splitting your monthly into 52 " +
    "(easier to budget) weekly payments annually. You'll eliminate late fees & be paying an extra " + FormatNumberToPrice(moreWeekly) + " every week to pay off your loan in " + quote.weekly.effective_term + " months instead of the typical " + quote.weekly.contracted_term + " months. " +
    "This also saves approximately " + FormatNumberToPrice(quote.weekly.interest_charges) + " in loan interest in the process!"
}


export const returnMonthlySummary = (quote, interest) => {
    let auxmonthtly = quote.monthly.payment / (quote.monthly.amount / 1000);
    return 'Monthly: ' + FormatNumberToPrice(quote.monthly.down_payment) + ' down. ' + interest + '% for ' + quote.monthly.contracted_term + 
    ' months ' + FormatNumberToPrice(quote.monthly.amount) + ' total amount financed. Includes '
    + FormatNumberToPrice(quote.monthly.dealer_fees) + ' dealer doc fee ' + quote.monthly.contracted_term + ' monthly payments of ' + FormatNumberToPrice(auxmonthtly) + ' for each'+
    ' $1,000 borrowed. Plus goverment fees and taxes, any finance charges , any dealer document processing charges, any electronic filing charges,'+
    ' and any emission testing charges. APR available from select lenders as of today. Not all buyers will qualify. Please contact us for complete details.'
}


// --------------------------------------------- text in Payment Benefit Accelerated by -----------
export const infoPaymentBenefit = () => {
    return (
        <span>Your accelerated payment benefit can help improve your equity position through term reduction and interest charge reduction
            <p>-OR-</p>
            You can use your accelerated payment benefit to afford more vehicle (protection, accessories & equipment options). 
        </span>
    );
}

export const infoWeeklyPayment = () => {
    return "Sends Your Finance Company/Bank the equivalent of 13 Monthly Payments/Yr";
}
export const infoWeeklyPlusPayment = () => {
    return "Sends Your Finance Company/Bank the equivalent of 14 Monthly Payments/Yr";
}

export const purchasingPowerInfo = (quote, payment) => {
    if (payment === 'weekly') {
        return "Paying off this loan in " + quote[payment].effective_term + " months instead of " + quote[payment].contracted_term + 
        " months would result in " + (quote[payment].contracted_term - quote[payment].effective_term) + " months of " + FormatNumberToPrice(quote.monthly.payment) + " payments you'll " +
        "never need to make. You could elect to simply enjoy that early pay off and keep driving with no more payments. " +
        "Another way to work our weekly payments system is to resign to contract a <span class='dobleLine'>longer term</span> loan (on a vehicle with " +
        "more safety and performance options). For example, with Advantage2Us weekly payments, an <span class='dobleLine'>84 month</span> new car term loan " +
        "can be set up (with weekly payments) for pay off in just over 72 months. Providing you qualify for the higher loan amount, "+
        "you'll be able to drive a nicer vehicle and or add more protection options like ding and dent, tire and wheel coverage " +
        "or even paint protection without busting the monthly budget."
    } else {
        return "Paying off this loan in " + quote[payment].effective_term + " months instead of " + quote[payment].contracted_term + 
        " months would result in " + (quote[payment].contracted_term - quote[payment].effective_term) + " months of " + FormatNumberToPrice(quote.monthly.payment) + " payments you'll never need to make. " +
        "You could elect to simply enjoy that early pay off and keep driving with no more payments. Another way to work our weekly payments " +
        "system is to resign to contract a <span class='dobleLine'>longer term</span> loan (on a vehicle with more safety and performance options) knowing you'll barely " +
        "notice the few additional dollars we pull from your checking account weekly. Power Payments Plus is also a great option if you are " +
        "rolling negative equity from a previous car loan into a loan for this vehicle. Imagine getting yourself right side up by the end of " +
        "your " + quote[payment].effective_term + "th monthly loan payment on this vehicle."
    }

}

// ------------------------ build score range values
export const interestRageBuild = (formula) => {
    return {
        [0]: formula.annual_interest_rate_poor,
        [1]: formula.annual_interest_rate_fair,
        [2]: formula.annual_interest_rate_good,
        [3]: formula.annual_interest_rate_very_good,
        [4]: formula.annual_interest_rate_exceptional,
    }
}

export const getCarPrice = (car, formula, insurance) => {
    let car_price = car.price;
    if (insurance) {
        car_price = car_price * (formula.insurance_protection / 100) + car_price;
    }
    if (formula.tax_rate && formula.tax_rate != 0) {
        car_price = car_price * (formula.tax_rate / 100) + car_price;
    }
    if (formula.dealer_fees) {
        car_price += formula.dealer_fees;
    }
    return car_price;
}

export const calculateMinDownPayment = (carPrice, retailer_credit_range) => {
    let min_down_payment = 0;
    if (retailer_credit_range.min_down_payment != null && retailer_credit_range.min_down_payment >= 0) {
        min_down_payment = (retailer_credit_range.min_down_payment / 100) * carPrice;
    }
    return min_down_payment;
}

export const calculateMaxDownPayment = (carPrice, retailer_credit_range) => {
    let max_down_payment = carPrice;
    if (retailer_credit_range.max_down_payment != null && retailer_credit_range.max_down_payment > 0) {
        max_down_payment = (retailer_credit_range.max_down_payment / 100) * carPrice;
    }
    return max_down_payment;
}

// get pay day friday or monday
export const getAllDaysInWeek = (f, starDate, days, day) => {
    if (days > 0) {
        if (starDate.getDay() === day) {
            f.push(new Date(starDate));
        }
        starDate.setDate(starDate.getDate() + 1);
        f = getAllDaysInWeek(f, starDate, days - 1, day);
    }
    return f;
}

// -------------- get next first payment date
export const dayNames= {Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5,};
export const paymentDate = (data) => {
    const startDate = moment(data.startDate, "MM-DD-YYYY").format("YYYY-MM-DD");
    const firstDate = moment(data.firstDate, "MM-DD-YYYY").format("YYYY-MM-DD");
    const days = moment(moment(firstDate).add(1, 'days')).diff(moment(startDate), 'days');
    let auxPayDay = getAllDaysInWeek([], new Date(startDate + ' 00:00:00'), days, dayNames[data.transferDay]);
    auxPayDay = [{ date: auxPayDay[auxPayDay.length - 5], type: 'newBalance', number: 1 }];
    return auxPayDay;
}