import React, { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { returnMonthlySummary, returnQuoteSummary } from '../../helpers/general';

export const MonthlyTooltip = ({ finalQuots, annual_interest_rate }) => {
  const [seeMore, setSeeMore] = useState(false);

  return (
    <Popup
      hoverable size="small"
      onClose={() => setSeeMore(false)}
      content={
        <div>
          {returnMonthlySummary(finalQuots, annual_interest_rate)}
          {
            !seeMore ?
              <span className='tooltip-button' onClick={() => setSeeMore(true)}>See more...</span>
              :
              <div>
                {returnQuoteSummary(finalQuots)}
                <span className='tooltip-button' onClick={() => setSeeMore(false)}>See less...</span>
              </div>
          }
        </div>
      }
      trigger={<Icon className="infoMIcon" link name="info circle" />}
    />
  );
};