import React, { Component } from "react";
import _ from 'lodash';
import { Button, Form, Modal, Grid, Input } from 'semantic-ui-react'
import {phoneOptions, callOptions, inspectionDistanceOptions, contactOptions} from "../../helpers/general";


// IMPORT CSS STYLES
import './buyerInformation.sass';

class BuyerInformation extends Component {
  

    constructor(props) {
        super(props);
        this.state = {
            customer: props.customer
        };
    }

    render() {
        return (
            <Modal size="small" className="buyer-modal" open={true} closeOnEscape={false} closeOnDimmerClick={false}>
                <Modal.Content>
                    <h2 className="title-info">Buyer Contact Information</h2>
                    <Form className={'contactForm customForm'}>
                        <Form.Group>
                            <Form.Input
                                value={this.state.customer.name}
                                label={<label>First Name</label>}
                                width={4}
                                fluid
                                disabled
                            />
                            <Form.Input
                                value={this.state.customer.second_name}
                                label={<label>Last Name</label>}
                                width={4}
                                fluid
                                disabled
                            />
                            <Form.Input
                                value={this.state.customer.contact_number}
                                label={<label>Contact Number</label>}
                                width={4}
                                fluid
                                disabled
                            />
                            <Form.Input
                                value={this.state.customer.extension}
                                label={'Extension'}
                                width={4}
                                fluid
                                disabled
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                value={this.state.customer.user.email}
                                label={<label>E-mail Address</label>}
                                width={8}
                                fluid
                                disabled
                            />
                            <Form.Select
                                value={this.state.customer.time_call}
                                label={'Best time to connect'}
                                options={callOptions}
                                width={8}
                                fluid disabled
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                value={this.state.customer.zip_code}
                                label={<label>Zip Code</label>}
                                width={2}
                                fluid disabled
                            />
                            <Form.Select
                                value={this.state.customer.contact_method}
                                label={<label>Preferred contact method</label>}
                                options={contactOptions}
                                width={8}
                                fluid disabled
                            />
                        </Form.Group>
                        <Grid>
                            <Grid.Column mobile={16} tablet={6} computer={6} style={{paddingTop: 0}}>
                                <div className="form-register" style={{ width: "100%", textAlign: "left", fontWeight: "bold" }}>
                                    <label style={{display: "block", textAlign: "left"}}>Phone Number</label>
                                    <Input style={{ width: "85%" }}
                                        label='+1'
                                        value={this.state.customer.user.phone_number}
                                        disabled
                                    />
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={() => this.props.close()}>
                        <h3>Close</h3>
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default BuyerInformation;