import React from 'react';
import { Header } from 'semantic-ui-react';

import "./QuickStartIncentivizeProgram.sass";

export const QuickStartIncentivizeProgram = ({ plan }) => {
  return (
    <div className='incentive-program' id="page1">
      <Header as="h2" className='title'><span>QuickStart</span> Incentive Program</Header>
      <Header as="h3" className='sub-title'>
        To help you through the initial lag in commissions paid for those Finance
        Managers new to AutoPayPlus, we developed a short-term incentive,
        QuickStart.
      </Header>
      <Header as="h3">How It Works:</Header>
      <p>
        For 8 weeks, QuickStart delivers regular weekly cash payouts.For everytwodeals per
        week submitted, the finance manager gets paid an extra $25 on top of their standard
        commission and any quarterly reward they may receive. <strong>There is no maximum limit!</strong>
      </p>
      <p>
        When QuickStart ends, it's just the beginning of more opportunities to earn greater
        incentives throughout the year. At the end of the 8 weeks, the average monthly
        enrollment over the QuickStart period is calculated and that determines which tier
        finance managers will start in for the Quarterly Rewards program.
      </p>
      <Header as="h3">Payout:</Header>
      <p>
        For every two (2) deals submitted to US Equity Advantage during a weekly period
        of Thursday to Wednesday, the finance manager is paid a $25 bonus on Friday.
      </p>
      <p><strong>Duration:</strong> 8 weeks (56 days)</p>
      <p><strong>Payout:</strong> Each Friday, loaded on the USEA Visa card.</p>
      <strong>Example:</strong>
      <p className='examples'><strong>2 deals =</strong> <span>$25 bonus</span></p>
      <p className='examples'><strong>5 deals =</strong> <span>$50 bonus</span></p>
      <p className='examples'><strong>6 deals =</strong> <span>$75 bonus</span></p>
      <p>You will be able to track your progress on the USEA Partner Portal.</p>
    </div>
  );
};