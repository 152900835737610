import { City, Country, State } from "country-state-city";

export const states = () => {
    return State.getStatesOfCountry('US') && State.getStatesOfCountry('US').length > 0 ? State.getStatesOfCountry('US') : [];
}

export const cities = (codeState) => {
    return City.getCitiesOfState('US', codeState) && City.getCitiesOfState('US', codeState).length > 0 ? City.getCitiesOfState('US', codeState) : [];
}

// ------------------------- property states for loan applications
export const checkPropertyStates = (state) => {
    const propertystates = ['AZ', 'CA', 'ID', 'LA', 'NV', 'NM', 'TX', 'WA', 'WI'];
    const found = propertystates.find(element => element === state);
    return found ? true : false;
}

// ------------------------- zip codes 
export const zipCodesStates = (state, zipCode) => {
    var states = {
        'AL': { min: 35004, max: 36925, code: 'AL', long: "Alabama" },
        'AK': { min: 99501, max: 99950, code: 'AK', long: "Alaska" },
        'AZ': { min: 85001, max: 86556, code: 'AZ', long: "Arizona" },
        'AR': { min: 71601, max: 72959, code: 'AR', long: "Arkansas" },
        'CA': { min: 90001, max: 96162, code: 'CA', long: "California" },
        'CO': { min: 80001, max: 81658, code: 'CO', long: "Colorado" },
        'CT': { min: 6001, max: 6928, code: 'CT', long: "Connecticut" },
        'DE': { min: 19701, max: 19980, code: 'DE', long: "Deleware" },
        'FL': { min: 32003, max: 34997, code: 'FL', long: "Florida" },
        'GA': { min: 30002, max: 39901, code: 'GA', long: "Georgia" },
        'HI': { min: 96701, max: 96898, code: 'HI', long: "Hawaii" },
        'ID': { min: 83201, max: 83877, code: 'ID', long: "Idaho" },
        'IL': { min: 60001, max: 62999, code: 'IL', long: "Illinois" },
        'IN': { min: 46001, max: 47997, code: 'IN', long: "Indiana" },
        'IA': { min: 50001, max: 52809, code: 'IA', long: "Iowa" },
        'KS': { min: 66002, max: 67954, code: 'KS', long: "Kansas" },
        'KY': { min: 40003, max: 42788, code: 'KY', long: "Kentucky" },
        'LA': { min: 70001, max: 71497, code: 'LA', long: "Louisiana" },
        'ME': { min: 3901, max: 4992, code: 'ME', long: "Maine" },
        'MD': { min: 20588, max: 21930, code: 'MD', long: "Maryland" },
        'MA': { min: 1001, max: 5544, code: 'MA', long: "Massachusetts" },
        'MI': { min: 48001, max: 49971, code: 'MI', long: "Michigan" },
        'MN': { min: 55001, max: 56763, code: 'MN', long: "Minnesota" },
        'MS': { min: 38601, max: 39776, code: 'MS', long: "Mississippi" },
        'MO': { min: 63001, max: 65999, code: 'MO', long: "Missouri" },
        'MT': { min: 59001, max: 59937, code: 'MT', long: "Montana" },
        'NC': { min: 27006, max: 28909, code: 'NC', long: "North Carolina" },
        'ND': { min: 58001, max: 58856, code: 'ND', long: "North Dakota" },
        'NE': { min: 68001, max: 69367, code: 'NE', long: "Nebraska" },
        'NV': { min: 88901, max: 89883, code: 'NV', long: "Nevada" },
        'NH': { min: 3031, max: 3897, code: 'NH', long: "New Hampshire" },
        'NJ': { min: 7001, max: 8989, code: 'NJ', long: "New Jersey" },
        'NM': { min: 87001, max: 88439, code: 'NM', long: "New Mexico" },
        'NY': { min: 501, max: 14925, code: 'NY', long: "New York" },
        'OH': { min: 43001, max: 45999, code: 'OH', long: "Ohio" },
        'OK': { min: 73001, max: 74966, code: 'OK', long: "Oklahoma" },
        'OR': { min: 97001, max: 97920, code: 'OR', long: "Oregon" },
        'PA': { min: 15001, max: 19640, code: 'PA', long: "Pennsylvania" },
        // 'PR': { min: 300, max: 999, code: 'PR', long: "Puerto Rico" },
        'RI': { min: 2801, max: 2940, code: 'RI', long: "Rhode Island" },
        'SC': { min: 29001, max: 29945, code: 'SC', long: "South Carolina" },
        'SD': { min: 57001, max: 57799, code: 'SD', long: "South Dakota" },
        'TN': { min: 37010, max: 38589, code: 'TN', long: "Tennessee" },
        'TX': { min: 73301, max: 88595, code: 'TX', long: "Texas" },
        // 'TX': { min: 88500, max: 88599, code: 'TX', long: "Texas" },
        'UT': { min: 84001, max: 84791, code: 'UT', long: "Utah" },
        'VT': { min: 5001, max: 5907, code: 'VT', long: "Vermont" },
        'VA': { min: 20101, max: 24658, code: 'VA', long: "Virgina" },
        'DC': { min: 20000, max: 20599, code: 'DC', long: "Washington DC" },
        'WA': { min: 98001, max: 99403, code: 'WA', long: "Washington" },
        'WV': { min: 24701, max: 26886, code: 'WV', long: "West Virginia" },
        'WI': { min: 53001, max: 54990, code: 'WI', long: "Wisconsin" },
        'WY': { min: 82001, max: 83414, code: 'WY', long: "Wyoming" }
    };
    let flag = "The zip code doesn't belong to the state";
    if (states[state] && (zipCode >= states[state].min && zipCode <= states[state].max)) {
        flag = null;
    }
    return flag;
}