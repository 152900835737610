import React from "react";
import { Header } from "semantic-ui-react";
import { FormatCashDownToPrice, FormatNumberToPrice } from "../../helpers/general";
import { MonthlyTooltip } from "../tooltips/MonthlyTooltip";
import { WeeklyTooltip } from "../tooltips/WeeklyTooltip";

const WeeklyHead = (props) => (
    <div>
        <Header className='payment' as='h3'>
            <span className="pointer" onClick={() => props.nextStep()} style={{ cursor: props.abledNext ? 'pointer' : 'default' }}>
                <span>{FormatNumberToPrice(props.quots.weekly.payment)} Weekly</span>
            </span>
            <WeeklyTooltip finalQuots={props.quots} />
        </Header>
        <div className="ui-mobile">
            {
                !props.isDetail ?
                    <span className="chas-down">{FormatCashDownToPrice(parseInt(props.quots.monthly.down_payment))} DOWN</span>
                    : null
            }
        </div>
        <Header className='monthlyPayment' as='h4' style={{ display: "flex", justifyContent: "space-evenly" }}>
            <span>
                {FormatNumberToPrice(props.quots.monthly.payment)} Monthly
                <MonthlyTooltip finalQuots={props.quots} annual_interest_rate={props.interest} />
            </span>
            <span>For {props.quots.monthly.contracted_term} Months</span>
        </Header>
    </div>
)

export default WeeklyHead;