import React, { Component } from 'react';
import { Grid, Form, Button, Dimmer, Loader } from "semantic-ui-react"

// IMPORT COMPONENTS
import AlertModal from "../alertModal/modal";
import CodeModal from "../codeModal/codeModal";

import './changePassword.sass'
import {editPassword} from "../requests";

class ChangePassword extends Component{
    constructor(props){
        super(props);

        this.state = {
            current_password:   '',
            password:           '',
            confirmPassword:    '',
            completeForm:       false,
            code:               '',
            error: {},
            loading: false,
            validCode: false,
            msnErrors: {
                current_password:   '',
                password:           '',
                confirmPassword:    '',
                phone_number:       '',
                code: '',
            },
            modal: {},
            disabled: false,

        };
        this.onEditInput = this.onEditInput.bind(this);
    }

    switchLabel(label, value, msnErrors) {
        switch (label) {
            case 'current_password': 
                msnErrors.current_password = value.length == '' ? 'Current password is required' : '';
                break;
            case 'password':
                msnErrors.password = value.length < 8 ? 'Password must be at least 8 characters long' : '';
                break;
            case 'confirmPassword':
                msnErrors.confirmPassword = value != this.state.password ? 'Passwords do not match' : '';
                break;
            default:
              break;
        }
        return msnErrors;
    }

    validateForm = () => {
        let valid = true;
        let errors = this.state.msnErrors;
        let array = ['current_password', 'password', 'confirmPassword'];
        for (const iterator of array) {
            errors = this.switchLabel(iterator, this.state[iterator], errors);
        }
        for (let i = 0; i < array.length; i++) {
            if (errors[array[i]].length > 0) {
                valid = false
            }
        }
        this.setState({completeForm: valid});
        return valid;
    };

    onEditInput(label, value){
        let msnErrors = this.state.msnErrors;
        msnErrors = this.switchLabel(label, value, msnErrors);
        this.setState({[label]: value, msnErrors}, () => this.validateForm());
    }

    // ------------------------------ save password ----------------------------
    save = () => {
        if(!this.validateForm()) {
            console.log('entree flase');
            return;
        }
        this.setState({loading: true, error: {}, modal: {}})
        let data = {
            current_password: this.state.current_password,
            password: this.state.password,
            password_confirmation: this.state.confirmPassword,
        }
        let msnErrors = this.state.msnErrors
        if (this.state.validCode) {
            if (this.state.code == '') {
                msnErrors.code = 'Code is required'
                this.setState({msnErrors});
                return;
            } else {
                msnErrors.code = ''
            }
            data['code'] = this.state.code;
        }
        this.setState({msnErrors, loading: true, disabled: true});
        editPassword(data).then((response)=>{
            console.log(response);
            this.setState({loading: false})
            if (response.status === 200) {
                this.setState({validCode: true, disabled: false});
            } else {
                this.setState({validCode: false, completeForm: false, modal: {
                        status: 200,
                        message: "Password was updated"
                    }, current_password: '', password: '', confirmPassword: ''
                })
            }
        }).catch((error)=>{
            this.setState({loading: false, disabled: false})
            if (error.response) {
                if (error.response.status === 422) {
                    this.setState({loading: false, modal: {
                            status: 422,
                            message: "Sorry. Fields are missing to save your information"
                        }})
                        
                } else if(error.response.status === 401){
                    this.setState({loading: false, modal: {
                            status: 401,
                            message: error.response.data.message
                        }})
                } else if (error.response.status == 400) {
                    this.setState({loading: false, modal: { message: error.response.data.message, status: 400 }})
                } else {
                    this.setState({loading: false, validCode: false, modal: {
                            status: 500,
                            message: "Sorry. An error occurred, please try again later"
                        }})
                }
            }
        });
    }

    hideModal = () => {
        this.setState({modal: {}});
    }

    render() {
        return(
            <Grid stackable className="wrapper-password">
                <Grid.Column width={16}>
                    <h3 className="title">Change Password</h3>
                    <Form>
                        <Form.Group>
                            <Form.Input
                                value={this.state.current_password}
                                label={<label>Current Password <span className="required-inut">*</span></label>}
                                placeholder={'Enter Current Password'}
                                onChange={(e) => this.onEditInput('current_password', e.target.value)}
                                width={8}
                                fluid
                                type="password"
                                error={this.state.msnErrors.current_password.length > 0 ? this.state.msnErrors.current_password : null}
                                maxLength="100" disabled={this.state.flagEdit}
                            />
                            <Form.Input
                                value={this.state.password}
                                label={<label>New Password <span className="required-inut">*</span></label>}
                                placeholder={'Enter New Password'}
                                onChange={(e) => this.onEditInput('password', e.target.value)}
                                width={8}
                                fluid
                                type="password"
                                error={this.state.msnErrors.password.length > 0 ? this.state.msnErrors.password : null}
                                maxLength="100" disabled={this.state.flagEdit}
                            />
                            <Form.Input
                                value={this.state.confirmPassword}
                                label={<label>Confirm New Password <span className="required-inut">*</span></label>}
                                placeholder={'Confirm New Password'}
                                onChange={(e) => this.onEditInput('confirmPassword', e.target.value)}
                                width={8}
                                fluid
                                type="password"
                                error={this.state.msnErrors.confirmPassword.length > 0 ? this.state.msnErrors.confirmPassword : null}
                                maxLength="100" disabled={this.state.flagEdit}
                            />
                        </Form.Group>
                    </Form>
                    <Button className="btn-continue"
                        floated={'left'}
                        content={'Save'}
                        onClick={this.save}
                        disabled={!this.state.completeForm}
                    />
                </Grid.Column>
                {
                    this.state.validCode ?
                        <CodeModal logged={true} phone={this.props.user.phone_number} updateCode={this.onEditInput} action={this.save} hideModalCode={() => this.setState({validCode: false, disabled: false})} disabled={this.state.disabled} />
                    :null
                }
                {
                    this.state.loading ?
                        <Dimmer className="loading" active>
                            <Loader />
                        </Dimmer>
                    :null
                }
                {
                    this.state.modal.message ?
                        <AlertModal hideModal={this.hideModal} modal={this.state.modal}/>
                    :null
                }
            </Grid>
        )
    }
}

export default ChangePassword;