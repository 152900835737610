import React, { Component } from "react";
import { Breadcrumb, Grid, Pagination, Table, Button } from "semantic-ui-react";
import QuotationDetails from "../quotationDetails/quotationDetails";
import { FormatDate } from "../../helpers/general";
import { LoanDetails } from "../loanDetails/LoanDetails";
import { MessagesModal } from "../messages/MessagesModal";
/*
*  PROPS:
*   - quotes:       List of quotes to show.
*   - totalPages:   Total of the search pages.
*   - changePage:   Function to change the current page.
*
* */

class ListQuotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedQuote: null,
            breadCrumbName: null
        }
        this.showDetails = this.showDetails.bind(this);
        this.hideDetails = this.hideDetails.bind(this);
        this.setName = this.setName.bind(this);
    }

    componentDidMount() {
        if (this.props.defaultCar) {
            this.setState({
                selectedQuote: this.props.defaultCar,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.defaultCar !== null && this.props.defaultCar !== this.state.selectedQuote) {
            this.setState({
                selectedQuote: this.props.defaultCar,
            });
        }
    }

    showDetails(id, name) {
        this.setState({ selectedQuote: id, breadCrumbName: name });
    }

    hideDetails() {
        this.setState({ selectedQuote: null });
    }

    setName(name) {
        this.setState({ breadCrumbName: name });
        if (this.props.changeDefault) {
            this.props.changeDefault(null);
        }
    }

    updateCreatedQuots = (status, id, pending) => {
        this.props.updateCreatedQuots(status, id, pending);
    }

    render() {
        const { selectedQuote } = this.state;
        const { user, role } = this.props;

        return (
            <div className={'body'} style={{ display: this.props.isActive ? 'block' : 'none' }}>
                <Grid>
                    <Grid.Column width={16}>
                        {
                            !selectedQuote ?
                                <Breadcrumb size={'large'} style={{ marginRight: "1rem" }}>
                                    <Breadcrumb.Section active>Deals list</Breadcrumb.Section>
                                </Breadcrumb>
                                :
                                <Breadcrumb size={'large'} style={{ marginRight: "1rem" }}>
                                    <Breadcrumb.Section onClick={this.hideDetails} link>Deals list</Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron' />
                                    <Breadcrumb.Section active>#{selectedQuote}: {this.state.breadCrumbName}</Breadcrumb.Section>
                                </Breadcrumb>
                        }

                        {
                            role === 'customer' && (
                                <MessagesModal role="customer" unreadMessages={this.props.user.customer.messages_count || 0} />
                            )
                        }
                    </Grid.Column>
                </Grid>
                {
                    selectedQuote ?
                        <div className="widthQuoteDetail">
                            {/*<Button icon labelPosition={'left'} primary onClick={this.hideDetails}>
                                                <Icon name={'angle left'}/>
                                                Back
                                            </Button>*/}
                            {
                                this.props.role == 'customer' || this.props.role == 'retailer' ?
                                    <QuotationDetails insideDashboard={true} quotId={selectedQuote} getName={this.setName} updateCreatedQuots={this.updateCreatedQuots} />
                                    :
                                    <QuotationDetails adminUserList={true} quotId={selectedQuote} getName={this.setName} />
                            }
                        </div>
                        :
                        <div>
                            <Grid>
                                <Grid.Column width={16} className="overflow-x">
                                    <Table className={'customTable'} unstackable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    {this.props.role == 'customer' || this.props.role == 'admin' ? 'Retailer' : 'Customer'} name
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Stock #/Make/Model/Year
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Quotation Date
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Status
                                                </Table.HeaderCell>
                                                {
                                                    (this.props.role === 'customer' || this.props.role === 'retailer') && (
                                                        <Table.HeaderCell textAlign="center">
                                                            {
                                                                this.props.role === 'customer' ?
                                                                    "Payment Plan"
                                                                    :
                                                                    "Loan Details"
                                                            }
                                                        </Table.HeaderCell>
                                                    )
                                                }
                                                <Table.HeaderCell>

                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {
                                                this.props.quotes.map(({ id, retailer, customer, car, created_at, status, payment_plan }, key) => (
                                                    <Table.Row key={key}>
                                                        <Table.Cell>
                                                            {
                                                                this.props.role == 'customer' || this.props.role == 'admin' ?
                                                                    retailer.name
                                                                    :
                                                                    customer.name + " " + customer.second_name
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {`#${car.stock_number} ${car.make} ${car.model} ${car.year}`}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {FormatDate(created_at)}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {
                                                                status == 0 ? ' Pending' : status == 1 ? ' Created' :
                                                                    status == 2 ? ' Accepted' : status == 3 ? ' Cancelled' :
                                                                        status == 4 ? ' Expired' : null
                                                            }
                                                        </Table.Cell>
                                                        {
                                                            (this.props.role === 'customer' || this.props.role === 'retailer') && (
                                                                <Table.Cell textAlign="center">
                                                                    {
                                                                        this.props.role === 'customer' ?
                                                                            payment_plan ?
                                                                                <LoanDetails id={payment_plan?.id} quote={{ id, car }} customer={user} type={"customer"} retailer={retailer} />
                                                                                :
                                                                                !user.customer.account ?
                                                                                    "Funding source is not added"
                                                                                    :
                                                                                    user.customer.account.verified ?
                                                                                        <LoanDetails id={payment_plan?.id} quote={{ id, car }} customer={user} type={"customer"} retailer={retailer} />
                                                                                        :
                                                                                        "Funding source is not verified"
                                                                            :
                                                                            <LoanDetails id={payment_plan?.id} quote={{ id, car }} customer={customer} type={"retailer"} retailer={retailer} />

                                                                    }
                                                                </Table.Cell>
                                                            )
                                                        }
                                                        <Table.Cell>
                                                            <span className={'detailsQuote'} onClick={() => this.showDetails(id, `${car.make} ${car.model}`)}>
                                                                See more...
                                                            </span>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))
                                            }
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                            </Grid>
                            {
                                this.props.totalPages && this.props.changePage &&
                                <div style={{ textAlign: 'Center' }}>
                                    <Pagination
                                        defaultActivePage={1}
                                        totalPages={this.props.totalPages}
                                        onPageChange={(e, { activePage }) => this.props.changePage(activePage)} />
                                </div>
                            }
                        </div>
                }
            </div>
        )
    }
}

export default ListQuotes;