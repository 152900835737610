import React from "react";
import Video from "../video/Video";

const SliderHeader = (props) => {

    // const title = "You're Prequalified Based On The Conditions Below";
    const title = {1: "Your Prequalification Is Based On The Conditions Below", 2: "Caution: Changes made to the calculator below could change your prequalification status"};

    const renderVideo = () => {
        return (
            (props.fromLanding && props.title === 1) && (
                <Video flag={false} video={'retailer'} videos={props.videos}/>
            )
        );
    }

    const renderText = () => {
        if (props.title === 1) {
            return <span>If you have a trade-in enter it here. Otherwise, hit <span className="blue-text">Continue</span> to learn how you can afford more vehicle safety, performance features or a better vehicle protection package. We'll even show you how to eliminate late payment fees and build equity faster while paying less interest over the term of this loan.</span>
        } else {
            return <span>Lowering your cash down, credit score or loan length may impact your loan eligibility. Call or text <span className="blue-text">{props.retailer.phone_number}</span> for dealer finance help</span>
        }
    }

    return (
        <div className="description-slider" id="hrefFocus" style={props.style}>
            <div className="ui-computer">
                <h2 style={props.title === 2 ? {marginBottom: 0, marginTop: '1em'} : null}>{title[props.title]}</h2>
                {renderVideo()}
                <h3 style={{marginTop: props.title === 1 ? '1em' : 0}}>{renderText()}</h3>
            </div>
            <div className="ui-mobile">
                <h3 style={props.title === 2 ? {marginBottom: 0, marginTop: '1em'} : null}>{title[props.title]}</h3>
                {renderVideo()}
                <p style={{marginTop: props.title === 1 ? '1em' : 0}}>{renderText()}</p>
            </div>
        </div>
    );
}

export default SliderHeader;
