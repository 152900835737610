import React, { useEffect } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { states } from '../../helpers/usaStates';

const usaState = states().map(item => {
  return {
    key: item.isoCode, text: item.name, value: item.isoCode
  }
});

export const DriverLicenceInformation = (props) => {
  const { form, errors } = props;

  useEffect(() => {
    
  }, [form, errors])

  return (
    <Form.Group>
      <Form.Input
        label={<label>Driver License</label>}
        placeholder='Driver License'
        fluid width={8}
        value={form.driverLicense}
        onChange={(e) => props.onEditInput('driverLicense', e.target.value)}
        error={form.driverLicense || errors.driverLicense ? errors.driverLicense : null}
        disabled={props.flagEdit} minLength={1} maxLength={15}
      />
      <Form.Field width={8}>
        <Form.Dropdown
          label={<label>Driver License State</label>}
          search
          options={usaState}
          value={form.driverLicenseState}
          onChange={(e, { value }) => props.onEditInput('driverLicenseState', value)}
          error={form.driverLicenseState || errors.driverLicenseState ? errors.driverLicenseState : null}
          disabled={props.flagEdit}
          selection clearable
        />
      </Form.Field>
    </Form.Group>
  );
};