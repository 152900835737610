import React from 'react';
import { Step } from 'semantic-ui-react';

export const ContactSteps = (props) => {
  const {step} = props;
  return (
    <Step.Group style={{ width: "100%" }}>
      <Step active={step === 0}>
        <Step.Content>
          <Step.Title>Confirm Name</Step.Title>
        </Step.Content>
      </Step>
      <Step active={step === 1}>
        <Step.Content>
          <Step.Title>Address</Step.Title>
        </Step.Content>
      </Step>
      <Step active={step === 2}>
        <Step.Content>
          <Step.Title>Personal Contact Number</Step.Title>
        </Step.Content>
      </Step>
      <Step active={step === 3}>
        <Step.Content>
          <Step.Title>Work Contact Number</Step.Title>
        </Step.Content>
      </Step>
      <Step active={step === 4}>
        <Step.Content>
          <Step.Title>Unique Identifiers</Step.Title>
        </Step.Content>
      </Step>
      <Step active={step === 5}>
        <Step.Content>
          <Step.Title>User</Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  );
};