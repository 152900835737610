import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Form, Header, Icon, Loader, Modal } from 'semantic-ui-react';
import ModalComponent from '../alertModal/modal';
import { getDealRetailers } from '../requests';
import { Messages } from './Messages';

export const MessagesModal = ({ role, customer, unreadMessages }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({});
  const [user, setUser] = useState('')
  const [retailers, setRetailers] = useState([]);
  const [retailersData, setRetailersData] = useState([]);
  const [messages, setMessages] = useState(unreadMessages)

  // ----------------------------- select retailer
  const changeRetailer = (value) => {
    let auxRetailer = { value, text: '' }
    for (const item of retailers) {
      if (value === item.value) {
        auxRetailer.text = item.text;
        break;
      }
    }
    setUser(auxRetailer)
  }

  // -------------------------------- get retailers for customer
  const getRetailers = () => {
    setIsLoading(true);
    getDealRetailers().then(response => {
      setIsLoading(false);
      setRetailersData(response.data);
      updateRetailer(response.data, true);
      setOpen(true)
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
      }

    });
  }

  // ---------------------------- clear unread messages
  const clearMessages = (count) => {
    if (count > 0) {
      if (role === 'customer') {
        let axuRetailers = [...retailersData]
        for (let i = 0; i < axuRetailers.length; i++) {
          if ( user.value === axuRetailers[i].id) {
            axuRetailers[i].messages_count = 0;
            break;
          }
        }
        setRetailersData(axuRetailers);
        updateRetailer(axuRetailers, false);
      } else {
        let auxMessages = 0;
        setMessages(auxMessages);
      }
    }
  }

  // ----------------------------- update unread messages and list of retailers
  const updateRetailer = (axuRetailers, flagSelect) => {
    let unread = 0;
    for (const retailer of axuRetailers) {
      unread += retailer.messages_count;
    }
    let retailers = axuRetailers.map(item => {
      return {
        value: item.id,
        text: item.name + " - " + item.messages_count + " msg"
      }
    });
    setMessages(unread);
    setRetailers(retailers);
    if (retailers.length > 0 && flagSelect) {
      setUser(retailers[0]);
    }
  }

  const openModal = () => {
    if (role === 'customer') {
      getRetailers();
    } else {
      setUser(customer);
      setOpen(true);
    }
  }

  useEffect(() => {
    setMessages(unreadMessages);
  }, [unreadMessages])
  

  return (
    <div style={{ display: "inline-block" }}>
      <Modal size="small" open={open} closeOnEscape={false} closeOnDimmerClick={false}
        trigger={
          <Button className="creditButton" onClick={openModal}>
            <span className='alert-messages'>{messages}</span>
            Messages <Icon name="paper plane outline" />
          </Button>
        }>
        <Modal.Content className='message-modal'>
          <div className='messages'>
            <div className='header'>
              {
                role === 'customer' ?
                  <Form>
                    <Form.Select
                      label={"Retailer"}
                      fluid width={6}
                      options={retailers}
                      value={user.value}
                      onChange={(e, { value }) => changeRetailer(value)}
                    />
                  </Form>
                  :
                  <Header as={'h4'}>{user.text}</Header>
              }

              <Button icon="close" onClick={() => setOpen(false)} />
            </div>
            <Messages id={user.value} name={user.text} role={role} clearMessages={clearMessages}/>
          </div>
        </Modal.Content>
      </Modal>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
    </div>
  );
};