import React, { useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { PaymentPlans } from './PaymentPlans';

export const PaymentPlansModal = ({ item }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal size="large" open={open} closeOnEscape={false} closeOnDimmerClick={false} trigger={
      <Button style={{ marginBottom: "1em", minWidth: "150px" }} primary onClick={() => setOpen(true)}>Payment Plans</Button>
    }>
      <Modal.Header>
        {item.name + " " + item.second_name}
      </Modal.Header>
      <Modal.Content className='loanDetails' scrolling>
        <PaymentPlans customer={item} type={'admin'} />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => setOpen(false)} >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};