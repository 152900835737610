import React from 'react';
import { Page1 } from './Page1';
import { Page2 } from './Page2';

import "./DealerAgreement.sass";

export const DealerAgreement = ({ fee, retailer, step }) => {

  return (
    <div className='dealer-agreement'>
      {
        (step === 1) && (
          <Page1 />
        )
      }
      {
        (step === 2) && (
          <Page2 retailer={retailer} fee={fee}/>
        )
      }
    </div>
  );
};