import React, { useEffect } from 'react';
import { Form, Grid, Input, Label } from 'semantic-ui-react';

export const UserInformation = (props) => {
  const { form, msnErrors, flagEdit, register, noPadding } = props;

  useEffect(() => {
  }, [form, msnErrors, flagEdit, register]);

  return (
    <Grid>
      <Grid.Column mobile={16} tablet={16} computer={16} style={noPadding ? { padding: "0" } : {}}>
        <Form.Group>
          <Form.Input
            id={'email'}
            value={form.email}
            label={<label>E-mail Address <span className="required-inut">*</span></label>}
            placeholder={'E-mail Address'}
            onChange={(e) => props.onEditInput('email', e.target.value)}
            width={8}
            fluid
            error={msnErrors.email.length > 0 ? msnErrors.email : null}
            maxLength="100" disabled={flagEdit}
          />
          <Form.Field width={8}>
            <div style={{ width: "100%", fontWeight: "bold" }}>
              <label>Phone Number <span className="required-inut">*</span></label>
              <Input
                label='+1'
                value={form.phone_number}
                placeholder='Enter Phone Number'
                onChange={(e) => props.onEditInput('phone_number', e.target.value)}
                maxLength="100"
                min="12"
                width={16}
                disabled={flagEdit}
              />
              {
                msnErrors.phone_number.length > 0 ?
                  <Label basic color='red' pointing>
                    {msnErrors.phone_number}
                  </Label>
                  : null
              }
            </div>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          {
            register && (
              <Form.Input className="form-register"
                id="password"
                value={form.password}
                label={<label>Password <span className="required-inut">*</span></label>}
                type={'password'}
                placeholder={'Enter Password'}
                onChange={(e) => props.onEditInput('password', e.target.value)}
                error={msnErrors.password.length > 0 ? msnErrors.password : null}
                maxLength="50"
                width={8}
              />
            )
          }
          {
            register && (
              <Form.Input className="form-register"
                id="confirmPassword"
                value={form.confirmPassword}
                label={<label>Password Confirmation <span className="required-inut">*</span></label>}
                type={'password'}
                placeholder={'Password Confirmation'}
                onChange={(e) => props.onEditInput('confirmPassword', e.target.value)}
                error={msnErrors.confirmPassword.length > 0 ? msnErrors.confirmPassword : null}
                maxLength="50"
                width={8}
              />
            )
          }
        </Form.Group>
      </Grid.Column>
    </Grid>
  );
};