import React, { useState } from 'react';
import { Button, Grid, Header, Icon, Popup } from 'semantic-ui-react';
import { FormatNumberToPrice, getAPPROVED, infoPaymentBenefit, infoWeeklyPayment, infoWeeklyPlusPayment, purchasingPowerInfo, returnInfoPayments, ScheduleText } from '../../../helpers/general';
import Buttons from './Buttons';

import "./payment.sass"

const Payments = (props) => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || "{}");
    const { car } = props;

    const renderPayments = (payment) => {
        let style = { color: payment == 'monthly' ? "red" : "#00D367", fontWeight: payment == 'monthly' ? "normal" : "bold" };
        return (
            <div>
                <div style={{textAlign: 'center'}}>
                    {
                        payment === 'monthly' ?
                            <span>12 payments in 12 months <small>(Greater Equity Risk)</small></span>
                            :
                            payment === 'weekly' ?
                                <span>Makes <strong>13</strong> monthly payments In 12 months</span>
                                :
                                <span>Makes <strong>14</strong> monthly payments in 12 months</span>
                    }
                </div>
                <Grid className={!props.onlyPayments ? "paymentOptions paymentsBorder" : "paymentOptions"}>
                    {
                        (!props.onlyPayments) && (
                            <Grid.Column mobile={16} tablet={16} computer={16} style={{ padding: 0 }}>
                                <Button className="btn-text" >
                                    <h3>
                                        <span onClick={(e) => props.selectedPayment(payment, payment !== 'monthly' ? 'plan' : false, car)}>
                                            {
                                                payment !== 'monthly' ?
                                                    <span className="advantageGreen">Advantage2U<span>TM</span> </span>
                                                    :
                                                    " Banker's Preferred "
                                            }
                                            Repayment Plan
                                        </span>
                                        <Popup wide={100}
                                            content={returnInfoPayments(payment)}
                                            trigger={<Icon link name="info circle" style={{ marginLeft: "10px" }} />}
                                        />
                                    </h3>
                                </Button>
                            </Grid.Column>
                        )
                    }
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Header as='h3' className="title-payments">{payment == 'monthly' ? "Monthly payment" : 'Weekly'}  {
                            payment == 'weeklyPlus' ? <img className="icon-payments" src={require("../../../../images/pp+.png")} />
                                : payment == 'weekly' ? <img className="icon-payments" src={require("../../../../images/pp.png")} /> : <img className="icon-payments monthly-image" src={require("../../../../images/pp+.png")} />}
                            {
                                payment == 'weekly' ?
                                    <Popup
                                        content={infoWeeklyPayment()}
                                        trigger={<Icon link name="info circle" />}
                                    />
                                    :
                                    payment == 'weeklyPlus' ?
                                        <Popup
                                            content={infoWeeklyPlusPayment()}
                                            trigger={<Icon link name="info circle" />}
                                        />
                                        : null
                            }
                        </Header>
                    </Grid.Column>
                    <Grid.Column mobile={10} tablet={9} computer={9}>
                        <span>{payment == 'monthly' ? "Monthly" : 'Weekly'} payment {payment == 'weeklyPlus' ? '+' : ''}</span>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={7} computer={7}>
                        <span>
                            {FormatNumberToPrice(props.quots[payment].payment)}
                            {
                                payment != 'monthly' && (
                                    <Popup wide position={"left center"}
                                        content={FormatNumberToPrice(props.quots[payment].payment) + " = " + FormatNumberToPrice(props.quots[payment].paymentNoFeeWeek) + " (Loan)" + " + " + FormatNumberToPrice(props.quots[payment].debitFeeWeek) + " (Program Fee)"}
                                        trigger={<Icon link name="info circle" />}
                                    />
                                )
                            }
                        </span>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <div className="border-bottom"></div>
                    </Grid.Column>
                    <Grid.Column mobile={10} tablet={9} computer={9}>
                        <span>Purchasing power</span>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={7} computer={7}>
                        <span style={style}>
                            {FormatNumberToPrice(props.quots[payment].purchasing_power)}
                            <span style={{ color: "#555555" }}>
                                {
                                    payment !== 'monthly' && (
                                        <Popup wide position={"left center"}
                                            content={<span dangerouslySetInnerHTML={{ __html: purchasingPowerInfo(props.quots, payment) }}></span>}
                                            trigger={<Icon link name="info circle" />}
                                        />
                                    )
                                }
                            </span>
                        </span>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <div className="border-bottom"></div>
                    </Grid.Column>
                    <Grid.Column mobile={10} tablet={9} computer={9}>
                        <span>Term Reduction</span>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={7} computer={7}>
                        <span style={style}>{props.quots[payment].term_reduction}</span>
                    </Grid.Column>
                    <Grid.Column mobile={10} tablet={9} computer={9}>
                        <span className="color-blue-ligth">Contracted Term</span>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={7} computer={7}>
                        <span style={style}>{props.quots[payment].contracted_term}</span>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <div className="border-bottom"></div>
                    </Grid.Column>
                    <Grid.Column mobile={10} tablet={9} computer={9}>
                        <span className="color-blue-ligth">Effective Term</span>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={7} computer={7}>
                        <span style={style}>{props.quots[payment].effective_term}</span>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <div className="border-bottom"></div>
                    </Grid.Column>
                    <Grid.Column mobile={10} tablet={9} computer={9}>
                        <span className="color-blue-ligth">Payment Benefit Accelerated by
                            {
                                payment == 'weekly' || payment == 'weeklyPlus' ?
                                    <Popup
                                        content={infoPaymentBenefit()}
                                        trigger={<Icon link name="info circle" />}
                                    />
                                    : null
                            }
                        </span>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={7} computer={7}>
                        <span style={style}>{FormatNumberToPrice(props.quots[payment].payment_benefit)}</span>
                    </Grid.Column>
                    <Grid.Column mobile={10} tablet={9} computer={9}>
                        <span className="color-blue-ligth">Interest Charges Reduced by</span>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={7} computer={7}>
                        <span style={style}>{FormatNumberToPrice(props.quots[payment].interest_charges)}</span>
                    </Grid.Column>
                    <Grid.Column mobile={10} tablet={9} computer={9}>
                        <span className="color-blue-ligth">Late Payment Fees</span>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={7} computer={7}>
                        <span style={style}>{payment === 'monthly' ? "Unprotected" : 'MPP Protects Against'}</span>
                    </Grid.Column>
                    {
                        // props.paymentCars ?
                        //     <Button className="btn-payment-static">{payment == 'monthly' ? 'Bank-Advantaged Payments' : 'Advantage2U Payment Plan'}</Button>
                        //     :
                        !props.onlyPayments ?
                            <Buttons selectedPayment={props.selectedPayment} payment={payment} car={car} />
                            :
                            (!props.quoteDetail && currentUser.customer) && (
                                <Button className="btn-payment cursor-pointer" onClick={(e) => props.createQuot()}>
                                    <h3>Finalize</h3>
                                </Button>
                            )
                    }
                </Grid>
            </div>
        );
    }

    return (
        props.onlyPayments ?
            renderPayments(props.paymentType)
            :
            <Grid className="grapperPayments">
                <Grid.Column tablet={5} computer={5}>
                    {renderPayments('monthly')}
                </Grid.Column>
                <Grid.Column tablet={5} computer={5}>
                    {renderPayments('weekly')}
                </Grid.Column>
                <Grid.Column tablet={5} computer={5}>
                    {renderPayments('weeklyPlus')}
                </Grid.Column>
            </Grid>
    );
}

export default Payments;