import React, { useState } from 'react';
import { Button, Dimmer, Form, Grid, Icon, Loader, Modal } from 'semantic-ui-react';
import ModalComponent from '../../alertModal/modal';
import CustomCarousel from '../../carousel/carousel';
import { putCar } from '../../requests';
import { BasicData } from './BasicData';

// ------------------- styles
import "./EditCarButton.sass"

export const EditCarButton = (props) => {
  const { inventory,inventoryBackUp } = props;
  const [car, setCar] = useState(props.car);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  // ------------------------------ update car ---------------------------
  const updateCar = (car) => {
    let auxForm = { ...car };
    setCar({ ...auxForm });
  }

  // ------------------------------- send data to backend ----------------
  const senData = () => {
    setIsLoading(true);
    setDisabled(true);
    putCar(car).then(response => {
      setDisabled(false);
      setIsLoading(false);
      // ----------------- update inventory list -------------------------
      let auxInventory = [...inventory].map((item) => {
        return item.id === car.id
          ? response.data
          : item;
      });
      let auxInventoryBackUp = [...inventoryBackUp].map((item) => {
        return item.id === car.id
          ? response.data
          : item;
      });
      props.updateIventoryList(auxInventory, auxInventoryBackUp);
      setModal({ status: 201, message: "The car was updated." });
    }).catch(error => {
      setDisabled(false);
      setIsLoading(false);
      console.log(error);
      if (error.response) {
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else if (error.response.status === 404) {
          setModal({ status: 406, message: "Sorry. Element was not found" });
        } else if (error.response.status === 422) {
          setModal({ status: 422, message: "Sorry. Fields are missing to save the information" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please verify your information or try again later. If This Issue Continues, Contact Support." });
        }
      }
    });
  }

  const openDetail = () => {
    setOpen(true);
    setCar(props.car);
  }

  return (
    <div>
      <Modal
        className='edit-car'
        closeOnEscape={false}
        closeOnDimmerClick={false}
        size='large'
        open={open}
        trigger={
          <Button primary icon onClick={openDetail}>
            <Icon name='edit' />
          </Button>
        }
      >
        <Modal.Header>Car Details</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Column width={4}>
              <CustomCarousel {...car} />
            </Grid.Column>
            <Grid.Column width={12}>
              <BasicData car={car} setDisabled={(flag) => setDisabled(flag)} updateCar={updateCar} />
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button primary onClick={senData} disabled={disabled}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
    </div>
  );
};