import moment from 'moment';
import React, { useState } from 'react';
import { Button, Dimmer, Form, Grid, Header, Loader, Modal } from 'semantic-ui-react';
import ModalComponent from '../../alertModal/modal';
import { postPoll, putPoll } from '../../requests';

const columnOptions = [
  { value: '', text: "..." },
  { value: 'year', text: "Year" },
  { value: 'make', text: "Make" },
  { value: 'model', text: "Model" },
  { value: 'trim', text: "Trim" },
  { value: 'stock_number', text: "Stock Number" },
  { value: 'vin', text: "VIN" },
  { value: 'miles', text: "Mileage" },
  { value: 'mfgcode', text: "Mfg Code" },
  { value: 'stock_date', text: "Stock Date" },
  { value: 'msrp', text: "MSRP Price" },
  { value: 'price', text: "Price" },
  { value: 'cost', text: "Cost" },
  { value: 'adjusted_price', text: "Adjusted price" },
  { value: 'bodystyle', text: "Body Style" },
  { value: 'exterior_color', text: "Exterior Color" },
  { value: 'exterior_color_code', text: "Exterior Color Code" },
  { value: 'interior_color', text: "Interior Color" },
  { value: 'interior_color_code', text: "Interior Color Code" },
  { value: 'engine', text: "Engine" },
  { value: 'doors', text: "Doors" },
  { value: 'cylinders', text: "Cylinders" },
  { value: 'transmission', text: "Transmission" },
  { value: 'comments', text: "Comments" },
  { value: 'images', text: "Images" },
  { value: 'images_modified_date', text: "Images Modified Date" },
  { value: 'manufacturer_rebates', text: "Manufacturer Rebates" },
  { value: 'condition', text: "Condition" },
  { value: 'certified', text: "Certified" },
  { value: 'dealer_id', text: "Dealer ID" },
];

const defaultPoll = { name: "", header: false, delimiter: ",", fileName: "", retailer: "" }
export const PollingManager = (props) => {
  const { polls, type, poll } = props;
  const retailers = props.retailers.map(item => {
    return {
      value: item.key,
      text: item.text,
    }
  });
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(defaultPoll);
  const [errors, setErrors] = useState({ name: null, delimiter: null, retailer: null, fileName: null });
  const [disabled, setDisabled] = useState(true);
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    let auxErrors = { ...errors };
    auxErrors = validateForm(value, label, auxErrors, auxForm);
    setErrors({ ...auxErrors });
    setForm({ ...auxForm });
    validation(auxForm, auxErrors);
  }

  const validation = (auxForm, auxErrors) => {
    for (const key in auxForm) {
      if (auxForm.hasOwnProperty.call(auxForm, key)) {
        const element = auxForm[key];
        auxErrors = validateForm(element, key, auxErrors, auxForm);
      }
    }
    let auxDisabled = false;
    for (const key in auxErrors) {
      if (auxErrors.hasOwnProperty.call(auxErrors, key)) {
        const element = auxErrors[key];
        if (element != null) {
          auxDisabled = true;
          break;
        }
      }
    }
    if (!auxDisabled) {
      auxDisabled = true;
      for (let i = 0; i < 50; i++) {
        let label = 'column_' + (i + 1);
        const element = auxForm[label];
        if (element && element !== '') {
          auxDisabled = false;
          break;
        }
      }
    }
    setDisabled(auxDisabled);
  }

  const validateForm = (value, label, errors, form) => {
    let auxLabel = label;
    label = label.includes("column") ? "column" : label;
    switch (label) {
      case 'name':
      case 'delimiter':
      case 'fileName':
        errors[label] = !value || value.split(" ").join("") === "" ? "The field must be required" : null;
        break;
      case 'retailer':
        errors[label] = null;
        if (value) {
          const countPolls = polls.filter(item => item.retailer.id === value).length;
          if (countPolls >= 2 && ((type === 'edit' && poll.retailer.id !== value) || type !== 'edit')) {
            errors[label] = "The retailer can not have more than two polls";
          }
        } else {
          errors[label] = "The field must be required";
        }
        break;
      case "column":
        errors[auxLabel] = null;
        for (let i = 0; i < 50; i++) {
          const element = form['column_' + (i + 1)];
          if (element && element === value && auxLabel !== 'column_' + (i + 1)) {
            errors[auxLabel] = "You have already selected that value";
            break;
          }
        }
        break;
      default:
        break;
    }
    return errors;
  }

  // ----------------------------------- send data to backend --------------------
  const sendData = () => {
    let columns = "";
    for (let i = 1; i < 51; i++) {
      const element = form['column_' + i];
      if (element) {
        columns += element;
      }
      columns += form.delimiter;
    }
    let params = {
      name: form.name,
      delimiter: form.delimiter,
      file_name: form.fileName,
      retailer_id: form.retailer,
      header: form.header,
      columns
    }
    const errorResponse = (error) => {
      console.log(error);
      setDisabled(false);
      setIsLoading(false);
      if (error.response) {
        console.log(error, error.response.status);
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else if (error.response.status === 404) {
          setModal({ status: 406, message: "Sorry. Element was not found" });
        } else if (error.response.status === 422) {
          setModal({ status: 422, message: "Sorry. Fields are missing to save the information" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please verify your information or try again later. If This Issue Continues, Contact Support." });
        }
      }
    }
    setIsLoading(true);
    setDisabled(true);
    if (type === 'edit') {
      putPoll(form.id, params).then(response => {
        setDisabled(false);
        setIsLoading(false);
        // ----------------- update polls list -------------------------
        let auxPolls = [...polls].map((item) => {
          return item.id === form.id
            ? response.data
            : item;
        });
        props.updatePolls(auxPolls, type);
        setModal({ status: 201, message: "The poll was updated." });
      }).catch(error => {
        errorResponse(error);
      })
    } else {
      postPoll(params).then(response => {
        setDisabled(false);
        setIsLoading(false);
        // ----------------- update polls list -------------------------
        let auxPolls = [...polls];
        auxPolls.unshift(response.data);
        props.updatePolls(auxPolls, type);
        setModal({ status: 201, message: "The poll was created." });
      }).catch(error => {
        errorResponse(error);
      })
    }
  }

  const hideAlertModal = () => {
    if (modal.status === 201) {
      setOpen(false)
    }
    setModal({})
  }

  const openModal = () => {
    setOpen(true)
    if (type === 'edit' || type === 'clone') {
      let auxForm = {
        id: poll.id,
        name: poll.name,
        delimiter: poll.delimiter,
        fileName: poll.file_name,
        header: poll.header,
        retailer: poll.retailer_id
      };
      let columns = poll.columns.split(poll.delimiter);
      for (let i = 0; i < columns.length; i++) {
        const element = columns[i];
        auxForm['column_' + (i + 1)] = element;
      }
      setForm({ ...auxForm });
      validation(auxForm, errors);
    } else {
      setForm(defaultPoll);
    }
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        size='small'
        open={open}
        trigger={
          <Button color={type === 'edit' ? 'blue' : 'green'} style={{marginBottom: "1em", minWidth: "100px"}} icon onClick={openModal}>
            {
              type === 'edit' ?
                'Edit'
                :
                type === 'clone' ?
                  'Clone'
                  :
                  'Add Polling Manager'
            }
          </Button>
        }
      >
        <Modal.Header>
          {
            type === 'edit' ?
              'Edit ' + poll.name
              :
              type === 'clone' ?
                'Clone Polling Manager'
                :
                'Add Polling Manager'
          }
        </Modal.Header>
        <Modal.Content className='poll-manager'>
          <Form>
            <Grid>
              <Grid.Column width={16}>
                <Header size='medium'>Poll Setup</Header>
                <Form.Group>
                  <Form.Input
                    label={<label>Name:<span className="required-inut"> *</span></label>}
                    onChange={(e) => onEditInput(e.target.value, "name")}
                    placeholder={'Name'}
                    value={form.name}
                    width={8}
                    error={form.name || errors.name ? errors.name : null}
                  /><Form.Input
                    label={<label>FileName:<span className="required-inut"> *</span></label>}
                    onChange={(e) => onEditInput(e.target.value, "fileName")}
                    placeholder={'FileName'}
                    value={form.fileName}
                    inline
                    width={8}
                    error={form.fileName || errors.fileName ? errors.fileName : null}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    label={<label>Delimiter:<span className="required-inut"> *</span></label>}
                    onChange={(e) => onEditInput(e.target.value, "delimiter")}
                    placeholder={'delimiter'}
                    value={form.delimiter}
                    width={5}
                    error={form.delimiter || errors.delimiter ? errors.delimiter : null}
                  />
                  <Form.Field style={{ display: "flex", alignItems: "center" }} width={3}>
                    <Form.Checkbox
                      label={<label>Header</label>}
                      checked={form.header}
                      onChange={(e, value) => onEditInput(value.checked, 'header')}
                    />
                  </Form.Field>
                  <Form.Select
                    label={<label>Retailer:<span className="required-inut"> *</span></label>}
                    onChange={(e, { value }) => onEditInput(value, 'retailer')}
                    placeholder="Retailer"
                    value={form.retailer}
                    options={retailers}
                    width={8}
                    error={form.retailer || errors.retailer ? errors.retailer : null}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid>
            <Header size='medium'>Define Import Fields</Header>
            <Grid style={{ maxHeight: "45vh", overflowY: "auto" }}>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                {Array.from(Array(25), (e, i) => {
                  return <Form.Group>
                    <Form.Dropdown
                      inline
                      search
                      selection
                      label={<label>Column {i + 1}:</label>}
                      onChange={(e, { value }) => onEditInput(value, 'column_' + (i + 1))}
                      placeholder={'Column ' + (i + 1)}
                      value={form['column_' + (i + 1)]}
                      options={columnOptions}
                      error={form['column_' + (i + 1)] || errors['column_' + (i + 1)] ? errors['column_' + (i + 1)] : null}
                    />
                  </Form.Group>
                })}
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                {Array.from(Array(25), (e, i) => {
                  return <Form.Group>
                    <Form.Dropdown
                      inline
                      search
                      selection
                      label={<label>Column {i + 26}:</label>}
                      onChange={(e, { value }) => onEditInput(value, 'column_' + (i + 26))}
                      placeholder={'Column ' + (i + 26)}
                      value={form['column_' + (i + 26)]}
                      options={columnOptions}
                      error={form['column_' + (i + 26)] || errors['column_' + (i + 26)] ? errors['column_' + (i + 26)] : null}
                    />
                  </Form.Group>
                })}
              </Grid.Column>
            </Grid>
          </Form>
          {
            type === 'edit' && (
              <div style={{ marginTop: "2rem" }}>
                <Header size='medium'>Latest Poll Summary</Header>
                <Grid>
                  <Grid.Column width={8}>
                    <label><strong>Last poll completed on:</strong></label>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <label>{poll.date ? moment(poll.date).format("LLL") : 'Inventory not updated'}</label>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <label><strong>Vehicles updated in inventory:</strong></label>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <label>{poll.updated_cars ? poll.updated_cars : 0}</label>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <label><strong>Vehicles added in inventory:</strong></label>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <label>{poll.created_cars ? poll.created_cars : 0}</label>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <label><strong>All vehicles inactived in inventory:</strong></label>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <label>{poll.deleted_cars ? poll.deleted_cars : 0}</label>
                  </Grid.Column>
                </Grid>
              </div>
            )
          }
          {
            isLoading &&
            <Dimmer className="loading" active>
              <Loader />
            </Dimmer>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button primary disabled={disabled} onClick={sendData}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={hideAlertModal} />
      }
    </div>
  );
};