import React from "react";
import { Button, Grid, Icon, Popup } from "semantic-ui-react";
import { getAPPROVED, ScheduleText } from "../../../helpers/general";

const Buttons = (props) =>{
    const {car} = props;

    return(
    <Grid.Column mobile={16} tablet={16} computer={16}>
        <Button className="btn-payment-green" >
            <h3><span onClick={(e) => props.selectedPayment(props.payment, props.payment !== 'monthly' ? 'plan' : false, car)}>Approve Me For This Now</span>
                <Popup wide={100}
                    content={getAPPROVED()}
                    trigger={<Icon link name="info circle" style={{ marginLeft: "10px" }} />}
                />
            </h3>
        </Button>
        <Button className="btn-payment-default" >
            <Grid verticalAlign="center">
                <Grid.Column onClick={(e) => props.selectedPayment(props.payment, 'dealerHelp', car)} mobile={14} tablet={14} computer={14} textAlign="left"
                    style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: "1rem", justifyContent: "space-around" }}>
                    <img className="img-1" src={require("../../../../images/ico-dealer-blue.png")} alt="" />
                    <img className="img-2" src={require("../../../../images/ico-dealer-white.png")} alt="" />
                    <h4>
                        <p>Dealer Help Live Connect Me Now</p>
                        <small>No personal contact info will be shared</small>
                    </h4>
                </Grid.Column>
                <Grid.Column mobile={2} tablet={2} computer={2} textAlign="center" style={{ display: "flex", alignItems: "center" }}>
                    <h3>
                        <Popup wide={100}
                            content={ScheduleText()}
                            trigger={<Icon link name="info circle" />}
                        />
                    </h3>
                </Grid.Column>
            </Grid>
        </Button>
        <Button className="btn-payment" >
            <Grid verticalAlign="center">
                <Grid.Column onClick={(e) => props.selectedPayment(props.payment, 'schedule', car)} mobile={14} tablet={14} computer={14} textAlign="left"
                    style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: "1rem", justifyContent: "space-around" }}>
                    <img className="img-1" src={require("../../../../images/ico-calendar-white.png")} alt="" />
                    <img className="img-2" src={require("../../../../images/ico-calendar-gray.png")} alt="" />
                    <h4 >
                        <p className="nomargin">Schedule Live Video Call For Later</p>
                        <small>No personal contact info will be shared</small>
                    </h4>
                </Grid.Column>
                <Grid.Column mobile={2} tablet={2} computer={2} textAlign="center" style={{ display: "flex", alignItems: "center" }}>
                    <h3>
                        <Popup wide={100}
                            content={ScheduleText()}
                            trigger={<Icon link name="info circle" />}
                        />
                    </h3>
                </Grid.Column>
            </Grid>
        </Button>
    </Grid.Column>
);}

export default Buttons;