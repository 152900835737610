// @flow 
import React, { useEffect, useState } from 'react';
import { Form, Header } from 'semantic-ui-react';
import { validAddress, validZipCode } from '../../helpers/general';
import { checkPropertyStates, zipCodesStates } from '../../helpers/usaStates';
import Address from '../address/Address';

const maritalStatusOptions = [
  { value: "Single", text: "Single" },
  { value: "Married", text: "Married" },
  { value: "Divorced", text: "Divorced" },
  { value: "Widowed", text: "Widowed" },
  { value: "Unmarried", text: "Unmarried" },
  { value: "Separated", text: "Separated" },
];

const defaultForm = { firstName: null, lastName: null, address: null, city: null, zip_code: null, state: null }

export const MaritalStatus = (props) => {
  const { form, errors, loanProcess } = props;
  const [spouse, setSpouse] = useState(defaultForm);
  const [spouseErrors, setSpouseErrors] = useState(defaultForm);

  // ---------------------------- update form and validate -----------------
  const onEditInput = (label, value) => {
    let auxForm = { ...spouse };
    let auxErrors = { ...spouseErrors };
    if (label === 'fullAddress') {
      auxForm.address = value.address;
      auxForm.state = value.state;
      auxForm.city = value.city;
      auxForm.zip_code = value.zip_code;
      auxErrors = validateForm(value.address, "address", auxErrors, auxForm);
      auxErrors = validateForm(value.state, "state", auxErrors, auxForm);
      auxErrors = validateForm(value.city, "city", auxErrors, auxForm);
      auxErrors = validateForm(value.zip_code, "zip_code", auxErrors, auxForm);
    } else {
      auxForm[label] = value;
      auxErrors = validateForm(value, label, auxErrors, auxForm);
    }
    setSpouse({ ...auxForm });
    props.onEditInput('spouse', auxForm)
    setSpouseErrors({ ...auxErrors });
  }

  const validateForm = (value, label, errors, form) => {
    switch (label) {
      case 'state':
        errors[label] = (value === '' || !value) ? 'The field must be required' : null;
        errors['zip_code'] = zipCodesStates(value, form.zip_code);
        break;
      case 'firstName':
      case 'lastName':
      case 'city':
        errors[label] = !value || value.split(" ").join("") === "" ? "The field must be required" : null;
        break;
      case 'address':
        errors[label] = validAddress(value);
        break;
      case 'zip_code':
        errors[label] = !value || value.split(" ").join("") === "" || !validZipCode(value) ? "The field must be 5 characters long" : zipCodesStates(form.state, value);
        break;
      default:
        break;
    }
    return errors;
  }

  useEffect(() => {
    if (form.spouse) {
      setSpouse(form.spouse);
    }
  }, [form, errors])

  return (
    <div>
      <Form.Group>
        <Form.Field width={8}>
          <Form.Dropdown
            options={maritalStatusOptions}
            value={form.maritalStatus}
            onChange={(e, { value }) => props.onEditInput('maritalStatus', value)}
            error={form.maritalStatus || errors.maritalStatus ? errors.maritalStatus : null}
            disabled={props.flagEdit}
            selection
          />
        </Form.Field>
      </Form.Group>
      {
        (form.maritalStatus === 'Married' && checkPropertyStates(loanProcess ? form.customerState : form.state)) && (
          <div>
            <Form.Group style={{ display: "block" }}>
              <Form.Checkbox
                width={16}
                label={<label>{loanProcess ? 'Does your spouse agree to the loan application?' : 'Does spouse agree?'}</label>}
                checked={form.spouseAgree}
                onChange={(e, value) => props.onEditInput('spouseAgree', value.checked)}
                error={form.spouseAgree || errors.spouseAgree ? errors.spouseAgree : null}
                disabled={props.flagEdit}
              />
              <Form.Field width={16}>
                <small>{loanProcess ? 'Your spouse must agree to the loan application if you are ' : 'Spouse must agree if the co-applicant is '}
                  from Arizona, California, Idaho, Louisiana, Nevada, New Mexico, Texas, Washington or Wisconsin
                </small>
              </Form.Field>
            </Form.Group>
            {
              form.status === 0 ?
                (loanProcess ? form.customerState : form.state) === 'WI' && (
                  <div>
                    <Header size="medium">Tell us about your spouse</Header>
                    <Form.Group>
                      <Form.Input
                        value={spouse.firstName}
                        label={<label>First Name <span className="required-inut">*</span></label>}
                        placeholder={'First Name'}
                        onChange={(e) => onEditInput('firstName', e.target.value)}
                        width={8}
                        fluid
                        error={spouse.firstName || spouseErrors.firstName ? spouseErrors.firstName : null}
                        disabled={props.flagEdit}
                      />
                      <Form.Input
                        value={spouse.lastName}
                        label={<label>Last Name <span className="required-inut">*</span></label>}
                        placeholder={'Last Name'}
                        onChange={(e) => onEditInput('lastName', e.target.value)}
                        width={8}
                        fluid
                        error={spouse.lastName || spouseErrors.lastName ? spouseErrors.lastName : null}
                        disabled={props.flagEdit}
                      />
                    </Form.Group>
                    <Address address={spouse} onChangeState={onEditInput} msnErrors={spouseErrors} />
                  </div>
                )
                :
                form.spouseName && (
                  <Header size="medium" style={{ margin: "3rem 0", textAlign: "left" }} textAlign="center">Spouse: {form.spouseName}</Header>
                )
            }
          </div>
        )
      }
    </div>
  );
};