import React from 'react';
import { Button, Icon, Segment } from 'semantic-ui-react';

export const Loan = ({ step, section }) => {
  return (
    <div className={section}>
      {/* <Icon name="arrow left" /> */}
      <div className='text'>
        <Segment>
          <h4>In this section you will see your last 5 created deals.</h4>
          <ul>
            <li>In the button <a>Loan Application...</a> you will able to start a loan application</li>
            <li>In the button <img src={require("../../../images/tutorial/buttonApplicant.png")} /> you will able to create a co-applicant</li>
          </ul>
        </Segment>
        <div>
          <Button onClick={() => step('quotes')}>Previous</Button>
          <Button onClick={() => step('contact')}>Next</Button>
        </div>
      </div>
    </div>
  );
};