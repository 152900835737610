import React, { Component } from "react";
import { Button, Image, Menu } from "semantic-ui-react";


import './menu.sass'

import { ReactComponent as DashIcon } from "../../../images/dashboard.svg";
import { ReactComponent as UserIcon } from "../../../images/user.svg";
import { ReactComponent as RetailerIcon } from  "../../../images/retailers.svg";
import { ReactComponent as LogOutIcon } from "../../../images/logout.svg";
import { ReactComponent as QuotesIcon } from "../../../images/quotes.svg"
import { ReactComponent as RangesIcon } from "../../../images/settings-money.svg"
import { ReactComponent as BuyersIcon } from "../../../images/buyer-blue.svg"
import { ReactComponent as GroupBank } from "../../../images/Grupo-bank.svg"
import { ReactComponent as Inventory } from "../../../images/Group-car-inventory.svg"
import { ReactComponent as Loan } from "../../../images/group-loan.svg"
import { ReactComponent as Video } from "../../../images/Group-up-video.svg"
import { ReactComponent as CreditRequests } from "../../../images/Group-credit.svg"
import { Icon } from 'semantic-ui-react'
import {logOut} from "../requests";
import { TermsConditions } from "../termsConditions/TermsConditions";



class UserMenu extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeItem: this.props.activeDefault,
        };
        this.handleItemClick = this.handleItemClick.bind(this);
        this.deleteToken = this.deleteToken.bind(this);
        this.logOut = this.logOut.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.activeDefault !== this.state.activeItem){
            this.setState({activeItem: this.props.activeDefault})
        }
    }

    handleItemClick = (e, { name }) => {
        this.props.handleClick(name);
        this.props.openMenu();
        //this.setState({ activeItem: name }, ()=> );
    }

    deleteToken(){
        localStorage.removeItem('currentUser');
        window.location.replace(`${window.location.origin}/login`);
    }

    logOut(){
        logOut().then((response)=> {
            this.deleteToken();
        }).catch((error)=>{
            this.deleteToken();
        });
    }

    renderIcon(icon){
        switch(icon){
            case 'dashboard':
                return(
                    <DashIcon/>
                );
            case 'quotes':
                return(
                    <QuotesIcon/>
                );
            case 'retailers':
                return(
                    <RetailerIcon/>
                );
            case 'buyers':
                return(
                    <BuyersIcon/>
                );
            case 'contact':
                return(
                    <UserIcon/>
                )
            case 'ranges':
                return(
                    <RangesIcon/>
                )
            case 'logOut':
                return(
                    <LogOutIcon/>
                )
            case 'account':
                return(
                    <GroupBank/>
                )
            case 'inventory':
                return(
                    <Inventory/>
                )
            case 'loan':
                return(
                    <Loan/>
                )
            case 'videos':
                return(
                    <Video/>
                )
            case 'creditRequests':
                return(
                    <CreditRequests/>
                )
            default:
                return(
                    <div>404</div>
                )
        }
    }

    render(){
        const { activeItem } = this.state;
        return (
            <div className={!this.props.menuOpened ? 'dashboardMenu close-menu' : 'dashboardMenu'}>
                <div className={'dashImage'}>
                    <Image src={require("../../../images/icon.png")} />
                </div>
                <Menu className={'customMenu'} compact size={"massive"} borderless vertical >
                    {
                        this.props.elements.map(({ name, label }, k) => (
                            <Menu.Item
                                name={name}
                                active={activeItem === name}
                                onClick={this.handleItemClick}
                                key={k}
                            >
                                <div className={'svgContainer'}>
                                    {this.renderIcon(name)}
                                </div>
                                <span>
                                    {label}
                                </span>
                            </Menu.Item>
                        ))
                    }

                    <Menu.Item
                        active={false}
                        position={'right'}
                        className={'termCondictions'}
                    >
                        <TermsConditions show={true}/>
                    </Menu.Item>
                    <Menu.Item
                        name='logOut'
                        active={false}
                        onClick={this.logOut}
                        position={'right'}
                        className={'logOut'}
                    >
                        <div className={'svgContainer'}>
                            {this.renderIcon('logOut')}
                        </div>
                        <span>
                            Log out
                        </span>
                    </Menu.Item>
                </Menu>
            </div>
        )
    }
}

export default UserMenu;