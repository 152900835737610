import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';

export const PollFilterSection = (props) => {
  const { pollsBackUp } = props;
  const retailers = props.retailers.map(item => {
    return {
      value: item.key,
      text: item.text,
    }
  });
  const [form, setForm] = useState({ retailer: "", keyword: "" })

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    props.search(auxForm);
    setForm({ ...auxForm });
  }

  return (
    <Form className='search-form'>
      <Form.Group>
        <Form.Select
          label={<label>Retailer:</label>}
          onChange={(e, { value }) => onEditInput(value, "retailer")}
          placeholder={'Retailer'}
          options={retailers}
          value={form.retailer}
          width={8}
          clearable
        />
        <Form.Input
          label={<label>Keyword:</label>}
          onChange={(e) => onEditInput(e.target.value, "keyword")}
          placeholder={'Keyword'}
          value={form.keyword}
          width={8}
        />
      </Form.Group>
    </Form>
  );
};