import React, { Component } from "react";
import _ from 'lodash';
import { Button, Header, Icon, Modal, Responsive } from 'semantic-ui-react'

// IMPORT CSS STYLES
import './modal.sass';

const url = process.env.REACT_APP_WEB_URL;

class ModalComponent extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        };
    }

    renderInMobile() {

    }

    renderInDesktop() {
    }

    render() {
        return (
            <Modal size="mini" className="alertModal" open={this.props.modal.message ? true : false} closeOnEscape={false} closeOnDimmerClick={false}>
                {/* <Header icon={this.props.icon} /> */}
                <Modal.Content>
                    <h3>
                        {
                            this.props.modal.status >= 200 && this.props.modal.status < 300 ?
                                'Good News!'
                                :
                                'Error!'
                        }
                    </h3>
                    <div style={{ width: '100%', textAlign: "center" }}>
                        {/* <img src="/images/mivi_azul_vertical.png" style={{width: "100px"}}/> */}
                        <h2 dangerouslySetInnerHTML={{ __html: this.props.modal.message }}></h2>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    {
                        this.props.modal.quoteProcess && (
                            <Button primary inverted as="a" href={url + "/login"} target="_blank" style={{ marginBottom: "1rem" }}>Take me to my Vehicle Vault</Button>
                        )
                    }
                    {
                        this.props.modal.quoteProcess && this.props.modal.status >= 200 && this.props.modal.status < 300 ?
                            <div>
                                <Responsive {...Responsive.onlyMobile}>
                                    <Button as={'a'} color='green' href={`tel:${this.props.modal.phoneNumber}`} inverted>
                                        <Icon name='phone' />
                                        Continue with a call
                                    </Button>
                                </Responsive>
                                <Responsive {...Responsive.onlyTablet}>
                                    <Button style={{userSelect: "text"}} active={true} color='green' inverted>
                                        <Icon name='phone' />
                                        Continue with a call
                                        <p>{this.props.modal.phoneNumber}</p>
                                    </Button>
                                </Responsive>
                                <Responsive {...Responsive.onlyComputer}>
                                    <Button style={{userSelect: "text"}} active={true} color='green' inverted>
                                        <Icon name='phone' />
                                        Continue with a call
                                        <p>{this.props.modal.phoneNumber}</p>
                                    </Button>
                                </Responsive>
                            </div>
                            :
                            <Button secondary onClick={this.props.hideModal} >
                                Accept
                            </Button>
                    }
                </Modal.Actions>
            </Modal>
        );
    }
}

export default ModalComponent;