import React from 'react';
import { Checkbox, Form, Table } from 'semantic-ui-react';

export const DebitInformation = ({ plan }) => {
  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3' textAlign='center'>
              DEBIT INFORMATION
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan='2'>
              <Checkbox label='Weekly' checked={true} disabled style={{ marginRight: "10px" }} />
              <Checkbox label='Biweekly' disabled style={{ marginRight: "10px" }} />
              <Checkbox label='Bimonthly (2x/month)' disabled style={{ marginRight: "10px" }} />
              <Checkbox label='Monthly' disabled style={{ marginRight: "10px" }} />
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Day of the week ACH/Debits Occur:
            </Table.Cell>
            <Table.Cell>
              {plan.transferDay}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Day of First Debit:
            </Table.Cell>
            <Table.Cell>
              {/* {plan.transferDay} */}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              First Debit Amount:
            </Table.Cell>
            <Table.Cell>
              {/* {plan.transferDay} */}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Start Date of Recurring ACH/Debits:
            </Table.Cell>
            <Table.Cell>
              {/* {plan.transferDay} */}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Total Recurring ACH/Debit Amount:
            </Table.Cell>
            <Table.Cell>
              {/* {plan.transferDay} */}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <p>
        AutoPayPlus debits your account according to your above election and at a
        minimum makes a monthly payment to your lender. If you have chosen an
        accelerated schedule, over a 12 month period an extra amount equivalent to a
        monthly payment is accumulated and paid toward the loan principal. The timing and
        number of principal payments varies with debit schedule and begins after the
        program fee has been collected from the initial extra debits.
      </p>
    </div>
  );
};