
import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { CreditSolutionRequests } from './CreditSolutionRequests';

export const CreditSolutionModal = ({ retailer }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal size="large" open={open} closeOnEscape={false} closeOnDimmerClick={false}
      trigger={
        <Button className="creditButton" onClick={() => setOpen(true)}>Credit Solution Requests</Button>
      }>
      <Modal.Header>
        Credit Solution Requests
      </Modal.Header>
      <Modal.Content className='height-credit-table' style={{overflowY: "auto", height: "auto"}}>
        <CreditSolutionRequests retailer={retailer} adminSection callBackError={() => setOpen(false)}/>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary className='close' onClick={() => setOpen(false)} ><h3>Close</h3></Button>
      </Modal.Actions>
    </Modal>
  );
};