import React, { useState } from 'react';
import { Button, Dimmer, Loader } from 'semantic-ui-react';
import ModalComponent from '../../alertModal/modal';
import { ConfirmModal } from '../../confirmModal/ConfirmModal';
import { putInventory } from '../../requests';

export const UpdateInventory = ({ retailer, polls, poll, updatePolls, inventoryRetailer, updateInventoryCallBack  }) => {
  const [confirmModal, setConfirmModal] = useState({});
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // ------------------------------ update inventory
  const confirmUpdate = (flag) => {
    setConfirmModal(false);
    if (flag) {
      update();
    }
  }

  const update = () => {
    setIsLoading(true);
    putInventory(retailer.id).then(response => {
      setIsLoading(false);
      // ----------------- update polls list -------------------------
      let auxPolls = [...polls].map((item) => {
        return item.id === poll.id
          ? response.data.poll
          : item;
      });
      updatePolls(auxPolls, 'edit');
      if (inventoryRetailer === retailer.dealer_id) {
        updateInventoryCallBack();
      } else {
        setModal({ status: 200, message: "Inventory was updated" });
      }
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else if (error.response.status === 404) {
          if (error.response.data.path) {
            setModal({ status: 406, message: error.response.data.message + ": " + error.response.data.path });
          } else {
            setModal({ status: 406, message: "Sorry. Element was not found" });
          }
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
        }
      }

    })
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Button primary style={{ marginBottom: "1em", minWidth: "100px" }}
        onClick={() => setConfirmModal({
          title: "Update Inventory",
          message: "This action may take a long time. Are you sure to continue?"
        })}
      >Update Inventory</Button>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
      {
        confirmModal.message &&
        <ConfirmModal modal={confirmModal} afterEvent={confirmUpdate} />
      }
    </div>
  );
};