import React, { Component } from 'react';
import {Button, Modal, Grid} from "semantic-ui-react";

import './userModal.sass'

class UserModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
        this.modalState = this.modalState.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && prevState.modal) {
            this.setState({modal: false});
            this.props.setOpen(true);
        }
    }

    modalState(state){
        this.setState({modal: state});
    }

    render(){
        return(
            <Modal
                closeIcon
                open= {this.state.modal}
                onClose= {()=> this.modalState(false)}
                onOpen= {()=> this.modalState(true)}
                closeOnEscape={false} closeOnDimmerClick={false}
                trigger={<Button positive>+ Add Retailers</Button>} size={this.props.size ? this.props.size : 'small'}
            >
                <Modal.Content className={'customModal'} scrolling={true}>
                    <div className={'modalTitle'}>
                        {this.props.title}
                    </div>
                    <div className={'modalContent'}>
                        <Grid stackable>
                            <Grid.Column width={16}>
                                {this.props.children}
                            </Grid.Column>
                        </Grid>
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}

export default UserModal;