// IMPORT LIBRARIES/EXTERNAL COMPONENTS
import React, { Component } from 'react';
import {Grid, Button, Table, Pagination, Card, Image, Statistic, Dimmer, Loader} from "semantic-ui-react";
import DatePicker from 'react-datepicker'

// IMPORT LOCAL COMPONENTS
import BarChart from "../utilities/barChart/BarChart";
import UserMenu from "../utilities/menu/menu";
import UserHeader from "../utilities/userHeader/userHeader";
import VariablesModal from "../utilities/variablesModal/variablesModal";
import RetailerDetails from "../utilities/retailerDetails/retailerDetails";
import ListQuotes from "../utilities/ListQuotes/listQuotes";
import ListBuyers from "../utilities/listBuyers/listBuyers";

// IMPORT HELPERS
import {CustomDatePicker} from "../helpers/general";
import { FormatChartData } from "../helpers/general";
import { adminMenu } from '../helpers/menus';

// IMPORT STYLES AND IMAGES
import './admin.sass'
import background from '../../images/background-chromacars.png'

// REQUEST FUNCTIONS.
import {
    searchQuotes, getCustomersAdmin,
    statusQuotes, getRetailers, adminData
} from "../utilities/requests";
import { Inventory } from '../utilities/inventory/Inventory';
import { BackMenu } from '../utilities/menu/BackMenu';
import { Videos } from '../utilities/videos/Videos';
import { AddRetailer } from '../utilities/retailerDetails/AddRetailer';
import { CreditSolutionModal } from '../utilities/creditSolutionRequests/CreditSolutionModal';
import { Accounts } from '../utilities/accounts/Accounts';

class Admin extends Component{
    constructor(props){
        super(props);

        const today = new Date();
        let start = new Date();
        start = new Date(start.setMonth(start.getMonth() - 6));
        this.state = {
            loading: false,
            activeItem:         'dashboard',
            carIdByUrl:         null,
            bestRetailers:      [],
            chartDateStart:     start,
            chartDateEnd:       today,
            chartData:          null,
            lastQuotes:         [],
            retailers:          [],
            retailersPage:      1,
            retailersTotal:     1,
            quotes:             {
                data: [],
                total: 0,
                last_page: 1
            },
            page:               1,
            totalPages:         1,
            last_week: 0,
            // -------------------------- show variables modal -----------------------
            variablesModal: false,
            retailer: {},
            // -------------------------- show retailer details ----------------------
            retailerDetail: false,
            // --------------------------- buyers -----------------------
            buyers: {
                data: [],
                total: 0
            },
            menuOpened: false
        };
        this.changeActiveItem = this.changeActiveItem.bind(this);
        this.onEditDate = this.onEditDate.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        adminData().then((response)=>{
            const recent = response.data.quotes.data.slice(0, 7);
            let chartData = FormatChartData(response.data.getStatusQuotes);
            this.setState({
                quotes:      response.data.quotes,
                lastQuotes: recent,
                last_week: response.data.last_week,
                // totalPages: response.data.quotes.last_page,
                bestRetailers: response.data.bestRetailers,
                retailers:      response.data.retailers.data,
                retailersTotal: response.data.retailers.last_page,
                chartData: chartData, loading: false, buyers: response.data.buyers
            }, () => console.log(this.state.chartData));
        }).catch(error=>{
            this.setState({loading: false});
        });
    }


    // ------------------------------------ get quote status --------------------
    searchStatusQuotes(){
        const params = {
            'startDate':    `${this.state.chartDateStart.getFullYear()}-${this.state.chartDateStart.getMonth()+1}-${this.state.chartDateStart.getDate()}`,
            'endDate':      `${this.state.chartDateEnd.getFullYear()}-${this.state.chartDateEnd.getMonth()+1}-${this.state.chartDateEnd.getDate()}`
        };
        this.setState({loading: true});
        statusQuotes(params).then(response=>{
            let chartData = FormatChartData(response.data)
            this.setState({chartData: chartData, loading: false}, () => console.log(this.state.chartData))
        }).catch(error =>{
            this.setState({loading: false});

        });
    }

    onEditInput(e){
        this.setState({[e.target.id]: e.target.value});
    }

    onEditDate(date, id){
        this.setState({[id]: date}, this.searchStatusQuotes);
    }

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    // Handle for the menu component.
    changeActiveItem(selected){
        this.setState({activeItem: selected});
    }

    changePage = (page=1, startDate=null, endDate=null, search=null) => {
        let params = {page};
        if(startDate)
            params['startDate'] = startDate;
        if(endDate)
            params['endDate'] = endDate;
        if(search)
            params['search'] = search;
        this.setState({loading: true});
        searchQuotes(params).then(response=>{
            this.setState({
                quotes:     response.data,
                // page:       response.data.current_page,
                // totalPages: response.data.last_page,
                loading: false
            });
        }).catch(error=>{
            this.setState({loading: false});
        });
    }

    defaultCars(){

    }

    // -------------------------------------- change retailers page ---------------------------------
    chageRetailerPage(page=1) {
        console.log(page);
        let params = {page};
        this.setState({loading: true});
        getRetailers({page}).then(response =>{
            console.log(response.data);
            this.setState({
                retailers:      response.data.data,
                retailersTotal: response.data.last_page,
                loading: false
            })
        }).catch(error=>{
            console.log(error);
            this.setState({
                loading: false
            })
        });
    }

    // ---------------------------------------- show retailer variables ------------------------------
    showVariables = (retailer) => {
        this.setState({retailer: retailer, variablesModal: true});
    }
    // ---------------------------------------- show retailer -----------------------------------------
    showRetailer = (retailer) => {
        this.setState({retailer: retailer, retailerDetail: true})
    }

    updateRetailer = (retailer) => {
        let retailers = this.state.retailers;
        for (let i = 0; i < retailers.length; i++) {
            if (retailers[i].id == retailer.id) {
                retailers[i] = retailer;
                break;
            }
        }
        this.setState({retailers});
    }

    addNewRetailer = (retailer) => {
        console.log(retailer);
        let retailers = this.state.retailers;
        retailers.push(retailer);
        this.setState({retailers});
    }

    hideModal = (label, value) => {
        this.setState({[label]: value});
    }
    // ------------------------------------- get buyers list paginate ------------------------------------------------
    changePageBuyers = (page=1, startDate=null, endDate=null, search=null) => {
        let params = {page};
        if(startDate)
            params['startDate'] = startDate;
        if(endDate)
            params['endDate'] = endDate;
        if(search)
            params['search'] = search;
        this.setState({loading: true});
        getCustomersAdmin(params).then(response=>{
            this.setState({
                buyers:     response.data,
                loading: false
            });
        }).catch(error=>{
            console.log(error);
            this.setState({
                loading: false
            })
        });
    }
    
    render() {
        // Active item for menu
        const { activeItem } = this.state;
        return(
            <div className={'dashboard'}>
                <UserMenu handleClick={this.changeActiveItem} activeDefault ={this.state.activeItem} elements={adminMenu} menuOpened={this.state.menuOpened}
                openMenu={() => this.setState({menuOpened: false})}/>
                <BackMenu menuOpened={this.state.menuOpened} openMenu={() => this.setState({menuOpened: false})}/>
                <div className={'dashboardContent'} style={{background: "white"}}>
                    <UserHeader title={'SECTION TITLE'} name={'ADMIN'} admin openMenu={() => this.setState({menuOpened: true})} addNewRetailer={this.addNewRetailer}/>
                    {
                        activeItem === 'dashboard' ?
                            <div className={'body'}>
                                <Grid stackable>
                                    <Grid.Column className={'generalInfo transparent'} width={16}>
                                        <div className={'customStatistic cursor-pointer'} style={{backgroundColor: '#003f69'}} onClick={() => this.changeActiveItem('buyers')}>
                                            <Statistic>
                                                <Statistic.Label>
                                                    BUYERS
                                                </Statistic.Label>
                                                <Statistic.Value>
                                                    {this.state.buyers.total}
                                                </Statistic.Value>
                                            </Statistic>
                                        </div>
                                        <div className={'customStatistic cursor-pointer'} style={{backgroundColor: '#2295e2'}} onClick={() => this.changeActiveItem('quotes')}>
                                            <Statistic>
                                                <Statistic.Label>
                                                    TOTAL DEALS
                                                </Statistic.Label>
                                                <Statistic.Value>
                                                    {this.state.quotes.total}
                                                </Statistic.Value>
                                            </Statistic>
                                        </div>
                                        <div className={'customStatistic'} style={{backgroundColor: '#9ad6ff'}}>
                                            <Statistic>
                                                <Statistic.Label>
                                                    CURRENT WEEK
                                                </Statistic.Label>
                                                <Statistic.Value>
                                                    {this.state.last_week}
                                                </Statistic.Value>
                                            </Statistic>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column className={'graph'} width={10}>
                                        <div className={'chartDates'}>
                                            <div style={{flex:1}}>

                                            </div>
                                            <div style={{width: "max-content"}}>
                                                <DatePicker
                                                    selected={this.state.chartDateStart}
                                                    onChange={date => this.onEditDate(date, 'chartDateStart')}
                                                    customInput={<CustomDatePicker/>}
                                                />
                                            </div>
                                            <div style={{width: "max-content"}}>
                                                <DatePicker
                                                    selected={this.state.chartDateEnd}
                                                    onChange={date => this.onEditDate(date, 'chartDateEnd')}
                                                    customInput={<CustomDatePicker/>}
                                                />
                                            </div>
                                        </div>
                                        {
                                            this.state.chartData !== null &&
                                                <BarChart
                                                    data={this.state.chartData}
                                                />
                                        }
                                    </Grid.Column>
                                    <Grid.Column className={'infoList transparent'} width={6}>
                                        <Table className={'customTable last-deals'}>
                                            <Table.Header>
                                                <Table.Row className={'upperHeader'}>
                                                    <Table.HeaderCell colSpan={3} >
                                                        BEST RETAILERS
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {
                                                    this.state.bestRetailers.map((retailer, k)=>(
                                                        <Table.Row key={k}>
                                                            <Table.Cell>
                                                                {retailer.name}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {retailer.quots_count}
                                                            </Table.Cell>
                                                            <Table.Cell textAlign={"center"}>
                                                                <a style={{cursor: "pointer"}} onClick={() => this.showRetailer(retailer)}>
                                                                    See Details
                                                                </a>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                }
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid>
                            </div>
                        : null
                    }
                    {
                        activeItem === 'inventory' && (
                            <Inventory userType={'admin'}/>
                        )
                    }
                    {
                        <ListBuyers buyers={this.state.buyers.data} totalPages={this.state.buyers.last_page}
                                    changePage={this.changePageBuyers} isActive={activeItem === 'buyers'}/>
                    }
                    {
                        <ListQuotes role={'admin'} quotes={this.state.quotes.data} totalPages={this.state.quotes.last_page}
                                    changePage={this.changePage} defaultCar={this.state.carIdByUrl}
                                    isActive={activeItem === 'quotes'}/>
                    }

                    <div className={'body'} style={{display: activeItem == 'retailers' ? 'block': 'none'}}>
                        <Grid className='ui-mobile'>
                            <AddRetailer addNewRetailer={this.addNewRetailer}/>
                        </Grid>
                        <Grid columns={4} stackable>
                            {
                                this.state.retailers.map((retailer, k)=>(
                                    <Grid.Column>
                                        <Card>
                                            {
                                                retailer.logo == null || retailer.logo == '' ?
                                                    <Image  src={require("../../images/no-image-available.png")}/>
                                                :
                                                    <Image src={retailer.logo} wrapped ui={false} />
                                            }
                                            <Card.Content>
                                                <Card.Header>{retailer.name}</Card.Header>
                                                <Card.Meta>
                                                    <span className='date'>Number of deals: {retailer.quots_count}</span>
                                                </Card.Meta>
                                            </Card.Content>
                                            <Card.Content extra>
                                                <Button className="variablesButton" fluid onClick={() => this.showVariables(retailer)}>System Variables</Button>
                                                <CreditSolutionModal retailer={retailer}/>
                                                <Button className="retailerButton" fluid onClick={() => this.showRetailer(retailer)}>See Details</Button>
                                            </Card.Content>
                                        </Card>
                                    </Grid.Column>
                                ))
                            }
                        </Grid>
                        <div style={{textAlign: 'Center'}}>
                            <Pagination defaultActivePage={1} totalPages={this.state.retailersTotal} onPageChange={(e, { activePage })=> this.chageRetailerPage(activePage)}/>
                        </div>
                        {
                            this.state.variablesModal ?
                                <VariablesModal {...this.state.retailer} hideModal={this.hideModal} updateRetailer={this.updateRetailer}/>
                            :null
                        }
                    </div>
                    {
                        this.state.retailerDetail ?
                            <RetailerDetails {...this.state.retailer} hideModal={this.hideModal}  updateRetailer={this.updateRetailer}/>
                        :null
                    }
                    {
                        activeItem === 'account' && (
                            <Accounts />
                        )
                    }
                    {
                        activeItem === 'videos' && (
                            <Videos retailersList={this.state.retailers}/>
                        )
                    }
                </div>
                {
                    this.state.loading ?
                        <Dimmer className="loading" active>
                            <Loader />
                        </Dimmer>
                    :null
                }
            </div>
        )
    }
}


export default Admin;