import axios from 'axios';
const axiosApiInstance = axios.create();
// ARRAY OF ORIGIN ROUTES WHICH THE RESPONSE INTERCEPTOR SHOULD AVOID TO PROCESS.
// SET IT ALWAYS AS LOWERCASE.
const skipRedirect = [
    '/quotation',
    '/cars',
]

axiosApiInstance.interceptors.request.use(
    function(config){
        const storedInfo = JSON.parse(localStorage.getItem('currentUser'));
        config.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        if(storedInfo !== null && storedInfo.hasOwnProperty('access_token') && storedInfo.access_token){
            config.headers.Authorization = `Bearer ${storedInfo.access_token}`;
        }
        return config;
    },
    function(error){
        return Promise.reject(error);
    }
);

axiosApiInstance.interceptors.response.use(
    (response) => {

        return response;
    },
     function(error){
        //console.log(error);
         let redirect = true;
         const current = window.location.href;
         const site = window.location.origin.toLowerCase();
         for(let url of skipRedirect){
             if(current.indexOf(`${site}${url}`) !== -1){
                 redirect = false;
                 break;
             }
         }
        switch(error.response.status){
            case 401:
                //window.location.replace(`${window.location.origin}/login`);
                break;
            case 403:
                if(localStorage.getItem('currentUser') !== null)
                    localStorage.removeItem('currentUser');
                if(redirect)
                    window.location.replace(`${window.location.origin}/login?authError=1`);
                break;
            default:
                break;
        }
        return Promise.reject(error);
    }
);/**/

export default axiosApiInstance;