import React, { Component } from 'react';
import {Button, Modal, Grid, Form, Dimmer, Loader, Label, Input} from "semantic-ui-react";
import {validAddress, validEmail, validPhoneNumber, validZipCode} from "../../helpers/general";

// REQUEST FUNCTIONS.
import { editRetailer } from "../requests";
// IMPORT COMPONENTS
import ModalError from "../alertModal/modal";
import './retailerDetails.sass'
import Address from '../address/Address';

class RetailerDetails extends Component{
    constructor(props) {
        super(props);
        this.state = {
            completeForm:       true,
            flagEdit:           true,
            modal:              {},
            retailer:           props,
            email:              props.user.email,
            phone_number:       props.user.phone_number,
            zip_code:           props.zip_code,
            address:            props.address,
            state:              props.state,
            city:               props.city,
            name:               props.name,
            dealer_id:          props.dealer_id,
            partnerdealerID:    props.partnerdealerID,
            logo:               props.logo,
            previewImg:         props.logo,
            msnErrors: {
                name:           '',
                zip_code:       null,
                dealer_id:      '',
                logo:           '',
                email:          '',
                phone_number:   '',
                partnerdealerID: null,
                address:         null,
                state:          null,
                city:           null,
            }
        }
    }

    // -------------------------------------- cancel edit ---------------------------
    cancelEdit = () => {
        console.log(this.state.retailer);
        this.setState({
            completeForm: true,
            name: this.state.retailer.name,
            zip_code: this.state.retailer.zip_code ? this.state.retailer.zip_code : "",
            dealer_id: this.state.retailer.dealer_id,
            email: this.state.retailer.user.email,
            phone_number: this.state.retailer.user.phone_number,
            logo: this.state.retailer.logo,
            previewImg: this.state.retailer.logo,
            partnerdealerID: this.state.retailer.partnerdealerID,
            address: this.state.retailer.address,
            state: this.state.retailer.state,
            city: this.state.retailer.city,
            logoFile: null,
            msnErrors: {
                name:           '',
                zip_code:       null,
                dealer_id:      '',
                logo:           '',
                email:          '',
                phone_number:   '',
                partnerdealerID: null,
                address:         null,
                state:          null,
                city:           null,
            },
            flagEdit: true,
        });
    }

    // ------------------------------------ change input values --------------------
    onEditInput = (label, value) => {
        let msnErrors = this.state.msnErrors;
        if (label == 'logo') {
            msnErrors = this.switchLabel(label, value, msnErrors);
        } else if (label === 'fullAddress') {
            msnErrors = this.switchLabel("address", value.address, msnErrors);
            msnErrors = this.switchLabel("state", value.state, msnErrors);
            msnErrors = this.switchLabel("city", value.city, msnErrors);
            msnErrors = this.switchLabel("zip_code", value.zip_code, msnErrors);
            this.setState({address: value.address, state: value.state, city: value.city, zip_code: value.zip_code, msnErrors}, () => this.validateForm());
        } else {
            msnErrors = this.switchLabel(label, value, msnErrors);
            this.setState({[label]: value, msnErrors}, () => this.validateForm());
        }
    }

    // ------------------------------------ valid inputs ---------------------------
    switchLabel(label, value, msnErrors) {
        switch (label) {
            case 'name': 
                msnErrors.name = value.length < 3 ? 'Name must be at least 3 characters long' : '';
                break;
            case 'address':
                msnErrors[label] = validAddress(value);
                break;
            case 'state':
            case 'city':
            case 'partnerdealerID':
                msnErrors[label] = (value === '' || value.split(" ").join("") === "") ? 'The field must be required' : null;
                break;
            case 'zip_code': 
                msnErrors.zip_code = !validZipCode(value) ? 'Zip Code must be 5 characters long' : null;
                break;
            case 'dealer_id': 
                msnErrors.dealer_id = value.length < 3 ? 'Dealer ID must be at least 3 characters long': '';
                break;
            case 'email':
                msnErrors.email = validEmail(value) ? '' : 'Email is not valid';
                break;
            case 'phone_number':
                msnErrors.phone_number = validPhoneNumber(value) ? '' : 'Phone number must be 10 digits';
                break;
            case 'logo':
                if (value.value != '') {
                    var fuData = value;
                    var FileUploadPath = fuData.value;
                    var Extension = FileUploadPath.substring(FileUploadPath.lastIndexOf('.') + 1).toLowerCase();
                    //The file uploaded is an image
                    if (Extension == "png" || Extension == "jpeg" || Extension == "jpg") {
                        msnErrors.logo = "";
                        if (fuData.files && fuData.files[0]) {
                            let previewImg = URL.createObjectURL(fuData.files[0]);
                            this.setState({[label]: FileUploadPath, logoFile: fuData.files[0], msnErrors, previewImg});
                        }
                    } else {
                        msnErrors.logo = "Logo must be a PNG, JPG or JPEG";
                        this.setState({[label]: '', msnErrors});
                    }
                } else {
                    let previewImg = this.state.retailer.logo;
                    msnErrors.logo = "";
                    this.setState({[label]: '', logoFile: null, msnErrors, previewImg});
                }
                break;
            default:
              break;
        }
        return msnErrors;
    }

    validateForm = () => {
        let valid = false;
        let msnErrors = this.state.msnErrors;
        let array = ['name', 'zip_code', 'dealer_id', 'logo', 'email', 'phone_number', 'address', 'state', 'city', 'partnerdealerID' ];
        for (let i = 0; i < array.length; i++) {
            if (msnErrors[array[i]] != '' && msnErrors[array[i]] != null) {
                valid = true
            }
        }
        this.setState({completeForm: !valid});
        return valid;
    };

    // ---------------------------------------- save retailer data ------------------------
    save = () => {
        if (this.validateForm()) {
            return;
        }
        let formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('zip_code', this.state.zip_code);
        formData.append('partnerdealerID', this.state.partnerdealerID);
        formData.append('address', this.state.address);
        formData.append('state', this.state.state);
        formData.append('city', this.state.city);
        formData.append('dealer_id', this.state.dealer_id);
        formData.append('email', this.state.email);
        formData.append('phone_number', this.state.phone_number);
        formData.append('logo', this.state.logoFile);
        this.setState({loading: true});
        editRetailer(this.state.retailer.id, formData).then((response)=>{
            let retailer = {...this.state.retailer};
            retailer['name'] = this.state.name;
            retailer['partnerdealerID'] = this.state.partnerdealerID;
            retailer['address'] = this.state.address;
            retailer['state'] = this.state.state;
            retailer['city'] = this.state.city;
            retailer['zip_code'] = this.state.zip_code;
            retailer['dealer_id'] = this.state.dealer_id;
            retailer['logo'] = response.data.logo;
            retailer['user']['email'] = this.state.email;
            retailer['user']['phone_number'] = this.state.phone_number;
            this.setState({loading: false, retailer, modal: {
                status: 200,
                message: "The retailer was updated"
            }, flagEdit: true, previewImg: response.data.logo, logo: response.data.logo})
            this.props.updateRetailer(retailer);
        }).catch((error)=>{
            if (error.response) {
                if (error.response.status === 422) {
                    this.setState({loading: false, modal: {
                            status: 422,
                            message: "Sorry. Field is missing to save the retailer"
                        }})
                } else if (error.response.status === 406) {
                    this.setState({loading: false, modal: {
                            status: 406,
                            message: error.response.data.message
                        }})
                } else {
                    this.setState({loading: false, modal: {
                            status: 500,
                            message: "Sorry. An error occurred, please try again later"
                        }})
                }
            }
            console.log(error.response);
        });
    }

    // ---------------------------- hide modal -----------------------------------
    hideModal = () => {
        this.setState({modal: {}});
    }

    render(){
        return(
            <div>
                <Modal open={true} onClose= {()=> this.props.hideModal('retailerDetail', false)}>
                    <Modal.Content className={'customModal'} scrolling>
                        <div className={'modalTitle'}>
                            Retailer Details
                        </div>
                        <div className={'modalContent'} >
                            <Grid stackable>
                                <Grid.Column width={16}>
                                    <Form>
                                        <Form.Group>
                                            <Form.Input
                                                value={this.state.name}
                                                label={<label>Name <span className="required-inut">*</span></label>}
                                                placeholder={'Name'}
                                                onChange={(e) => this.onEditInput('name', e.target.value)}
                                                width={6}
                                                fluid
                                                error={this.state.msnErrors.name.length > 0 ? this.state.msnErrors.name : null}
                                                maxLength="50" disabled={this.state.flagEdit}
                                            />
                                            <Form.Input
                                                value={this.state.dealer_id}
                                                label={<label>Dealer ID <span className="required-inut">*</span></label>}
                                                placeholder={'Dealer ID'}
                                                onChange={(e) => this.onEditInput('dealer_id', e.target.value)}
                                                width={6}
                                                fluid
                                                error={this.state.msnErrors.dealer_id.length > 0 ? this.state.msnErrors.dealer_id : null}
                                                maxLength="50" disabled={this.state.flagEdit}
                                            />
                                            <Form.Input
                                                value={this.state.partnerdealerID}
                                                label={<label>Partner dealer ID <span className="required-inut">*</span></label>}
                                                placeholder={'Partner dealer ID'}
                                                onChange={(e) => this.onEditInput('partnerdealerID', e.target.value)}
                                                width={4}
                                                fluid
                                                error={this.state.msnErrors.partnerdealerID ? this.state.msnErrors.partnerdealerID : null}
                                                maxLength="50" disabled={this.state.flagEdit}
                                            />
                                        </Form.Group>
                                        <Address address={
                                            { address: this.state.address, city: this.state.city, zip_code: this.state.zip_code, state: this.state.state }
                                        } onChangeState={this.onEditInput} flagEdit={this.state.flagEdit} msnErrors={this.state.msnErrors}
                                        />
                                        <Form.Group>
                                            <Form.Input
                                                value={this.state.email}
                                                label={<label>Email <span className="required-inut">*</span></label>}
                                                placeholder={'Email'}
                                                onChange={(e) => this.onEditInput('email', e.target.value)}
                                                width={8}
                                                fluid
                                                error={this.state.msnErrors.email.length > 0 ? this.state.msnErrors.email : null}
                                                maxLength="50" disabled={this.state.flagEdit}
                                            />
                                            <Form.Field width={8}>
                                                <label>Phone Number<span className="required-inut"> *</span></label>
                                                <Input 
                                                    value={this.state.phone_number}
                                                    label='+1'
                                                    placeholder='Phone Number'
                                                    onChange={(e) => this.onEditInput('phone_number', e.target.value)}
                                                    maxLength="15"
                                                    min="12" disabled={this.state.flagEdit}
                                                />
                                                {
                                                    this.state.msnErrors.phone_number.length > 0 ?
                                                        <Label basic color='red' pointing>
                                                            {this.state.msnErrors.phone_number}
                                                        </Label>
                                                        : null
                                                }
                                            </Form.Field>
                                        </Form.Group>
                                        {
                                            this.state.flagEdit ?
                                                <div>
                                                    <label>Logo</label>
                                                    {
                                                        this.state.logo != null && this.state.logo != '' ?
                                                            <img className="logo" src={this.state.logo} />
                                                            :
                                                            <img className="logo" src={require("../../../images/no-image-available.png")} />
                                                    }
                                                </div>
                                                :
                                                <div>
                                                    <Form.Group>
                                                        <Form.Input
                                                            // value={this.state.logo}
                                                            label={<label>Logo</label>}
                                                            placeholder={'Logo'}
                                                            onChange={(e) => this.onEditInput('logo', e.target)}
                                                            width={8}
                                                            fluid
                                                            accept="image/png,image/jpg,image/jpeg"
                                                            type="file"
                                                            error={this.state.msnErrors.logo.length > 0 ? this.state.msnErrors.logo : null}
                                                        />
                                                    </Form.Group>
                                                    <img className="logo" src={this.state.previewImg} />
                                                </div>
                                        }
                                    </Form>
                                    <div style={{width: "100%", textAlign: "center", padding: "1.5rem 0 0 0"}}>
                                        <Button className="closeButton" onClick={()=> this.props.hideModal('retailerDetail', false)}>Close</Button>
                                        {
                                            this.state.flagEdit ?
                                                <Button className="editButton" onClick={() => this.setState({flagEdit: false})}>Edit</Button>
                                            :
                                                <div style={{display: "inline-block"}}>
                                                    <Button className="cancelButton" onClick={() => this.cancelEdit()}>Cancel</Button>
                                                    <Button className="acceptButton" onClick={() => this.save()} disabled={!this.state.completeForm}>Save</Button>
                                                </div>
                                        }
                                    </div>
                                </Grid.Column>
                            </Grid>
                        </div>
                    </Modal.Content>
                    {
                        this.state.loading ?
                            <Dimmer className="loading" active>
                                <Loader />
                            </Dimmer>
                        :null
                    }
                </Modal>
                {
                    this.state.modal.message ?
                        <ModalError hideModal={this.hideModal} modal={this.state.modal}/>
                    :null
                }
            </div>
        )
    }
}

export default RetailerDetails;