import * as React from 'react';
import { Header } from 'semantic-ui-react';
import { FormatNumberToPrice, returnMonthlySummary, returnQuoteSummary } from '../../../helpers/general';
import { TermsConditions } from '../../../utilities/termsConditions/TermsConditions';

import "./footer.sass"

const Footer = (props) => (
    <div className="footer">
        {/* <p>
            Learn more about adding 2 extra payments a year to help your monthly budget and improve your equity and debt to income ratios:
                        <a href="Https://Advantage2U.com/weekly-vehicle_loan-payment-calculator.html"> Https://Advantage2U.com/weekly-vehicle_loan-payment-calculator.html</a>
        </p> */}
        <p className="color-dark">
            {
                props.finalQuots.monthly && props.finalQuots.monthly.carid ?
                    <div>
                        <Header className="a2uWeekly" style={{ marginTop: 0 }}>Weekly: {FormatNumberToPrice(props.finalQuots.weekly.payment)}</Header>
                        {returnQuoteSummary(props.finalQuots)}
                        <div>
                            {returnMonthlySummary(props.finalQuots, props.finalQuots.monthly.annual_interest_rate)}
                        </div>
                        <div>
                            <TermsConditions show={true} />
                        </div>
                    </div>
                    : null
            }
        </p>
    </div>
);

export default Footer;