//IMPORT OBJECTS/LIBRARIES
import React, { Component } from 'react';
import { Grid, Dimmer, Loader, Message, Button, Header } from "semantic-ui-react";
import { FormatNumberToPrice, calculatePayments, calculatePaymentsWeekly, interestRageBuild, getCarPrice, calculateMaxDownPayment, calculateMinDownPayment } from "../../helpers/general";
import { getCar, getRetailerQuote, getCarsQuote, postNewQuot, editQuot } from "../../utilities/requests";

// IMPORT COMPONENTS
import Sliders from "../../utilities/sliders/sliders";
import Car from '../../utilities/car/Car'
import Login from "../../session/login/login";
import Register from "../../session/register/register";
import Contact from "../../utilities/contact/contact";
import Modal from "../../utilities/alertModal/modal";
// IMPORT CSS STYLES
import 'rc-slider/assets/index.css';
import './quotation.sass'
import PreQualified from '../../utilities/preQualified/PreQualified';
import HeaderQuote from './headerQuote/HeaderQuote';
import Footer from './footer/Footer';
import Video from './video/Video';
import Cars from './cars/Cars';
import Payments from './payments/Payments';
import CarSearch from './carSearch/CarSearch';
import SliderHeader from './sliderHeader/SliderHeader';
import Cox from '../cox/Cox';
import JumpButton from './jumpButton/JumpButton';
import { TradeModal } from '../../utilities/car/TradeModal';

const defaultMinPayment = 0;
const defaultMaxPayment = 2000;
class Quotation extends Component {

    constructor(props) {
        super(props);
        let quots = {
            monthly: {},
            weekly: {},
            weeklyPlus: {}
        }
        this.state = {
            loading: false,
            error: {},
            step: -1,
            retailer: { id: '' },
            videos: {},
            // ---------------------- slider values --------------
            min_monthly_payment: 0,
            max_monthly_payment: 1000,
            steps_monthly_payment: {
                0: FormatNumberToPrice(0),
                1000: FormatNumberToPrice(1000),
            },
            // ---------------------------------------------------------------
            retailer_credit_range: {},
            monthly_payment: 1000,
            monthly_payment_landing: 1000,
            down_payment_landing: 2000,
            loan_length_landing: 84,
            trade_in: 0,
            money_owed: 0,
            loan_length: 84,
            down_payment: 20,
            min_down_payment: defaultMinPayment,
            max_down_payment: defaultMaxPayment,
            default_loan_length: 84,
            min_loan_length: 1,
            max_loan_length: 84,
            credit_score: 4,
            credit_range: {
                min: 0,
                max: 1000,
                ranges: {},
                select: []
            },
            interestRage: {},
            // ------------------------ car information -------------------
            car: {},
            selectedCar: {},
            cars: {},
            carsPayment: [],
            activePage: {
                monthly: 1,
                weekly: 1,
                weeklyPlus: 1
            },
            // ------------------------ formula information -------------------
            formula: {},
            // ------------------------ user loged -----------------------------
            currentUser: {},
            // ------------------------ payment results -------------------------
            paymentCars: false,
            // ----------------------- save payment method ----------------------
            paymentType: '',
            insurance_protection: false,
            finalQuots: quots,
            modal: {},
            // ------------------------ save crated quot ------------------------
            createdQuot: {},
            // ------------------------ form pre qualified ----------------------
            preQualifiedForm: { annualIncome: 0, firstName: "", lastName: "", gender: "", address: "", address2: "", postalCode: "", state: "", city: "", type: "" },
            isAPIStep: { type: false, step: 0 },
            // ------------------------ cox data --------------------------------
            coxProcess: false,
            continueCoxData: {},
            openSession: false,
            cox: null,
            coxBackup: null,
            resetValues: null
        };
    }

    componentDidMount() {
        let vin = '', retailerId = '';
        if (this.props.match.params.vin) {
            vin = this.props.match.params.vin;
            this.setState({ loading: true, fromLanding: false })
            getCar(vin).then((response) => {
                if (response.data.retailer.formula == null) {
                    this.setState({ loading: false, error: { message: 'Car was not found' } })
                    return;
                }
                this.renderQuote(response);
            }).catch((error) => {
                console.log(error.response);
                this.setState({ loading: false, error: { message: 'Car was not found' } })
            });
        } else if (this.props.match.params.retailer) {
            // -------------------------- from landing page -----------------------------------
            retailerId = this.props.match.params.retailer;
            this.setState({ loading: true, fromLanding: true })
            getRetailerQuote(retailerId).then((response) => {
                let retailer = response.data;
                if (retailer.formula == null || retailer.credit_range == null) {
                    console.log('entreeee');
                    this.setState({ loading: false, error: { message: 'Cars were not found' } })
                    return;
                }
                let result = {
                    data: {
                        car: {},
                        retailer: retailer,
                        cars: {}
                    }
                }
                this.setState({ step: 0 });
                console.log(response);
                this.renderQuote(result);
            }).catch((error) => {
                console.log(error.response);
                this.setState({ loading: false, error: { message: 'Cars were not found' } })
            });
        } else {
            this.setState({ loading: false, error: { message: 'Car was not found' } })
        }
        // vin = "KNDMB5C12M6671184";
    }

    renderQuote(response) {
        // ------------------------------- update credit range --------------------------------
        let finalQuots = this.state.finalQuots;
        let credit_range = this.state.credit_range;
        let retailer_credit_range = response.data.retailer.credit_range;
        let monthly_payment = this.state.monthly_payment;
        let max_monthly_payment = this.state.max_monthly_payment;
        let min_monthly_payment = this.state.min_monthly_payment;
        let down_payment = this.state.down_payment;
        let min_down_payment = this.state.min_down_payment;
        let max_down_payment = this.state.max_down_payment;
        let default_loan_length = this.state.default_loan_length, default_down_payment = 20;
        let min_loan_length = this.state.min_loan_length;
        let max_loan_length = this.state.max_loan_length;
        let interestRage = this.state.interestRage;
        let formula = Object.assign({}, response.data.retailer.formula);
        let car_price = max_down_payment;
        if (response.data.car.id) {
            car_price = getCarPrice(response.data.car, formula, false);
            max_down_payment = car_price;
        }
        // --------------------------------- add ranges -----------------------------------------
        if (retailer_credit_range != null) {
            credit_range.min = retailer_credit_range.poor;
            credit_range.max = retailer_credit_range.exceptional;
            credit_range.ranges = {
                [0]: <span>Under {retailer_credit_range.poor}<p>Poor</p></span>,
                [1]: <span>{retailer_credit_range.poor}-{retailer_credit_range.fair}<p>Fair</p></span>,
                [2]: <span>{retailer_credit_range.fair + 1}-{retailer_credit_range.good}<p>Good</p></span>,
                [3]: <span>{retailer_credit_range.good + 1}-{retailer_credit_range.very_good}<p>Very Good</p></span>,
                [4]: <span>{retailer_credit_range.very_good + 1}-{retailer_credit_range.exceptional}<p>Exceptional</p></span>,
            }
            credit_range.select = [
                { value: retailer_credit_range.poor, text: "Under " + retailer_credit_range.poor + " Poor" },
                { value: retailer_credit_range.fair, text: retailer_credit_range.poor + "-" + retailer_credit_range.fair + " Fair" },
                { value: retailer_credit_range.good, text: (retailer_credit_range.fair + 1) + "-" + retailer_credit_range.very_good + " Good" },
                { value: retailer_credit_range.very_good, text: (retailer_credit_range.very_good + 1) + "-" + retailer_credit_range.very_good + " Very Good" },
                { value: retailer_credit_range.exceptional, text: (retailer_credit_range.very_good + 1) + "-" + retailer_credit_range.exceptional + " Exceptional" },
            ]
            // -------------------------- set min and max values in sliders -----------------------------
            if (response.data.car.id) {
                min_down_payment = calculateMinDownPayment(car_price, retailer_credit_range);
                max_down_payment = calculateMaxDownPayment(car_price, retailer_credit_range);
            }
            if (retailer_credit_range.min_loan_length && retailer_credit_range.min_loan_length >= 0) {
                min_loan_length = retailer_credit_range.min_loan_length;
            }
            if (retailer_credit_range.max_loan_length && retailer_credit_range.max_loan_length > 0) {
                max_loan_length = retailer_credit_range.max_loan_length;
            }
            // ------------------------------------ match credit score with annual interest rate -------------------
            interestRage = interestRageBuild(formula);
            if (retailer_credit_range.default_loan_length && default_loan_length != '') {
                default_loan_length = retailer_credit_range.default_loan_length;
            }
            if (retailer_credit_range.default_down_payment && default_down_payment != '') {
                default_down_payment = retailer_credit_range.default_down_payment;
            }
        }
        if (response.data.car.id && response.data.car.condition === 'new') {
            max_loan_length += 12;
        }
        // ---------------------------------------------------- valid down payments against loan length -------------
        if (response.data.car.id) {
            // -------------------------- default cash down
            down_payment = (car_price * (default_down_payment / 100));
            if (response.data.car.condition === 'used') {
                max_loan_length = 72;
                if (default_loan_length > 72) default_loan_length = 72;
                if (min_loan_length > max_loan_length) min_loan_length = max_loan_length;
            } else {
                // default_loan_length = 84
                if (max_loan_length < 84) {
                    default_loan_length = max_loan_length;
                }
            }
            // ------------------------ get max
            let auxQuotes = calculatePayments(response.data.car, default_loan_length, min_down_payment, formula, interestRage[4], this.state.insurance_protection, this.state.trade_in, this.state.money_owed);
            max_monthly_payment = auxQuotes.monthly.payment;
            // ------------------------ get real
            finalQuots = calculatePayments(response.data.car, default_loan_length, down_payment, formula, interestRage[4], this.state.insurance_protection, this.state.trade_in, this.state.money_owed);
            monthly_payment = finalQuots.monthly.payment;
            // ------------------------ get min
            auxQuotes = calculatePayments(response.data.car, default_loan_length, max_down_payment, formula, interestRage[4], this.state.insurance_protection, this.state.trade_in, this.state.money_owed);
            min_monthly_payment = auxQuotes.monthly.payment;
        } else {
            down_payment = min_down_payment;
            default_loan_length = max_loan_length;
        }
        // ---------------------------------------------------------------------------------------
        this.setState({
            loading: false,
            credit_range,
            car: response.data.car, selectedCar: response.data.car, formula: formula, loan_length: default_loan_length, loan_length_landing: default_loan_length, down_payment,
            credit_score: 4, min_down_payment, max_down_payment, min_loan_length, max_loan_length,
            retailer: Object.assign({}, response.data.retailer), monthly_payment, max_monthly_payment: max_monthly_payment,
            steps_monthly_payment: { [min_monthly_payment]: FormatNumberToPrice(min_monthly_payment), [max_monthly_payment]: FormatNumberToPrice(max_monthly_payment) },
            interestRage, cars: response.data.cars, finalQuots, min_monthly_payment, retailer_credit_range, videos: response.data.retailer.videos
        })
    }

    // -------------------------- create quot ----------------------------------
    createQuot = () => {
        let cox = this.state.cox;
        if (this.state.cox != null) {
            cox.amountFinanced = this.state.finalQuots.monthly.final_price;
        }
        this.setState({ loading: true })
        const data = {
            "formula_id": this.state.formula.id,
            "retailer_id": this.state.retailer.id,
            "car_id": this.state.selectedCar.id,
            "monthly_payment": this.state.monthly_payment,
            "loan_length": this.state.loan_length,
            "down_payment": this.state.down_payment,
            "credit_score_range": this.state.credit_score,
            "type": this.state.paymentType,
            "status": 1,
            "insurance_protection": this.state.insurance_protection,
            "trade_in": this.state.trade_in == null || this.state.trade_in == '' ? 0 : this.state.trade_in,
            "money_owed": this.state.money_owed == null || this.state.money_owed == '' ? 0 : this.state.money_owed,
            "plan": this.state.preQualifiedForm.type === 'plan' ? true : false,
            "cox": this.state.cox
        };
        const success = (response) => {
            console.log(response);
            // ---------------------------------------------------------------------------------------
            this.setState({
                loading: false,
                createdQuot: response.data,
                modal: {
                    status: 202,
                    message: "Your deal has been updated.",
                    quoteProcess: true,
                    phoneNumber: this.state.retailer.phone_number
                    // message: "Your deal was created and you can continue editing it. We sent an email with your deal details.",
                }
            })
        }
        const errorResponse = (error) => {
            console.log(error);
            if (error.response) {
                if (error.response.status == 422) {
                    this.setState({
                        loading: false, modal: {
                            message: "Sorry. Fields are missing to save the information",
                            status: 422
                        }
                    })
                } else if (error.response.status == 403) {
                    this.setState({
                        loading: false, modal: {
                            message: "Sorry. You don't have permissions",
                            status: 403
                        }
                    })
                } else if (error.response.status == 406) {
                    console.log(error.response);
                    let currentUser = this.state.currentUser;
                    currentUser['formula'] = error.response.data.formula;
                    currentUser['quot'] = this.state.createdQuot;
                    this.setState({
                        loading: false, currentUser, modal: {
                            status: 406,
                            message: "The interest percentages have changed, it is necessary to start the process from the beginning"
                        }
                    })
                } else {
                    this.setState({
                        loading: false, modal: {
                            message: "Sorry. An error occurred, please try again later",
                            status: 500
                        }
                    })
                }
            } else {
                this.setState({
                    loading: false, modal: {
                        message: "Sorry. An error occurred, please try again later",
                        status: 500
                    }
                })
            }
        }
        if (this.state.createdQuot && this.state.createdQuot.id) {
            editQuot(this.state.createdQuot.id, data).then((response) => {
                success(response);
            }).catch((error) => {
                errorResponse(error);
            });
        } else {
            postNewQuot(data).then((response) => {
                success(response);
            }).catch((error) => {
                errorResponse(error);
            });
        }
    }

    // ----------------------------- hide modal when create quot --------------------
    hideModal = () => {
        this.setState({ modal: {} });
        if (this.state.modal.status == 403) {
            localStorage.removeItem('currentUser');
            // ------------------- go back to login or sign up -----
            this.updateUser({}, this.state.fromLanding ? 1.5 : -1);
        } else if (this.state.modal.status == 202) {
            this.setState({ modal: {} });
        } else if (this.state.modal.status == 406) {
            this.getUser(this.state.currentUser, 200, this.state.fromLanding ? 2 : 0);
        }
    }

    // -------------------------- reset scroll ---------------------------------
    scrollReset() {
        var myDiv = document.getElementsByClassName('quot-content')[0];
        myDiv.scrollTop = 0;
    }

    // -------------------------- function in props after login -----------------
    getUser = (currentUser, status, step) => {
        this.scrollReset();
        // ---------------------------------------- validate if fomrulas change -----------------------------------
        let formula = (status == 201 ? this.state.formula : currentUser.formula), interestRage = this.state.interestRage, retailer = this.state.retailer;
        if (status == 200) {
            retailer.formula = { ...currentUser.formula };
            // ------------------------------------ match credit score with annual interest rate -------------------
            interestRage = interestRageBuild(currentUser.formula);
            // --------------------- not change the vlaues from Cox
            if (this.state.cox != null) {
                let cox = this.state.cox;
                interestRage = cox.interestRage;
                formula.tax_rate = cox.tax_rate;
                formula.dealer_fees = cox.dealer_fees;
            }
        }
        let auxStep = status == 201 ? step ? step : 5 : 0;
        // ---------------------------------------------------------------------------------------------------------
        this.setState({ currentUser, step, createdQuot: currentUser.quot, formula, interestRage, retailer }, () => {
            if (step === 0 || step === 2) {
                this.setState({
                    ...this.calculateQuoteCar(this.state.selectedCar, this.state.finalQuots, this.state.min_down_payment,
                        this.state.max_down_payment, this.state.trade_in, this.state.money_owed)
                });
            }
        });
    }

    updateUser = (currentUser, step) => {
        this.scrollReset();
        this.setState({ currentUser, step: step, openSession: true });
    }

    // -------------------------- function in props two show sign up -----------------
    showSignup = (user) => {
        this.scrollReset();
        this.setState({ step: this.state.step + 1 });
    }

    // ------------------------- select car continue ----------------------------
    carContinue = (selectedCar, similarCar) => {
        this.scrollReset();
        console.log(selectedCar);
        let finalQuots = this.state.finalQuots;
        let trade_in = this.state.trade_in;
        let money_owed = this.state.money_owed;
        let down_payment = this.state.down_payment;
        let max_down_payment = this.state.max_down_payment;
        let min_down_payment = this.state.min_down_payment;
        let flagDown = false, max_loan_length = this.state.retailer_credit_range.max_loan_length, min_loan_length = this.state.retailer_credit_range.min_loan_length;
        let step = Math.floor(this.state.step);
        step = step + 1;
        if (similarCar) {
           step = 2.5; 
        }
        let newCar = this.calculateCarPrice(selectedCar, this.state.car, trade_in, money_owed, min_down_payment, max_down_payment, flagDown);
        min_down_payment = newCar.min_down_payment;
        max_down_payment = newCar.max_down_payment;
        flagDown = newCar.flagDown;
        if (this.state.fromLanding || flagDown) {
            if (selectedCar.condition === 'new') {
                max_loan_length += 12;
            } else {
                max_loan_length = 72;
                if (min_loan_length > max_loan_length) min_loan_length = max_loan_length;
            }
            if (similarCar) {
                // -------------------------------------- request to Cox for new car
                this.setState({ max_loan_length, min_loan_length, selectedCar, paymentCars: false, max_down_payment, min_down_payment, continueCoxData: this.createCoxData(selectedCar), coxProcess: true});
            } else {
                this.setState({ max_loan_length, min_loan_length, step, selectedCar, paymentCars: false, max_down_payment, min_down_payment, ...this.calculateQuoteCar(selectedCar, finalQuots, min_down_payment, max_down_payment, trade_in, money_owed) });
            }
        } else {
            finalQuots = calculatePayments(selectedCar, this.state.loan_length, down_payment, this.state.formula, this.state.interestRage[this.state.credit_score], this.state.insurance_protection, trade_in, money_owed);
            let monthly_payment = finalQuots.monthly.payment;
            this.setState({ step, selectedCar, finalQuots, monthly_payment, paymentCars: false, max_down_payment, down_payment, min_down_payment });
        }
    }

    // ----------- get car price 
    calculateCarPrice = (selectedCar, car, trade_in, money_owed, min_down_payment, max_down_payment, flagDown) => {
        let car_price = selectedCar.price * (this.state.formula.tax_rate / 100) + selectedCar.price;
        if (this.state.formula.dealer_fees) {
            car_price += this.state.formula.dealer_fees;
        }
        let new_trade_in = +trade_in - +money_owed;
        if (new_trade_in < 0) {
            // ----------------- add diference trade in to cr price
            car_price = car_price - new_trade_in;
        }
        if (this.state.retailer_credit_range.min_down_payment != null && this.state.retailer_credit_range.max_down_payment != null) {
            min_down_payment = (this.state.retailer_credit_range.min_down_payment / 100) * car_price;
            max_down_payment = (this.state.retailer_credit_range.max_down_payment / 100) * car_price;
        }
        if (selectedCar.id != car.id) {
            if (this.state.retailer_credit_range.min_down_payment != null && this.state.retailer_credit_range.max_down_payment != null) {
                min_down_payment = (this.state.retailer_credit_range.min_down_payment / 100) * car_price;
                max_down_payment = (this.state.retailer_credit_range.max_down_payment / 100) * car_price;
            } else {
                max_down_payment = car_price;
                min_down_payment = 0;
            }
            flagDown = true;
        }
        return {car_price, min_down_payment, max_down_payment, flagDown}
        
    }

    // ---------- get payment of differten cars
    calculateQuoteCar = (selectedCar, finalQuots, min_down_payment, max_down_payment, trade_in, money_owed) => {
        let aux_trade_in = trade_in, aux_money_owed = +money_owed;
        if (+trade_in >= +money_owed) {
            aux_money_owed = 0;
            aux_trade_in = 0
        }
        let loan_length = this.state.loan_length;
        if (selectedCar.condition === 'used' && loan_length > 72) loan_length = 72;
        if (loan_length > this.state.max_loan_length) loan_length = this.state.max_loan_length
        finalQuots = calculatePayments(selectedCar, loan_length, min_down_payment, this.state.formula, this.state.interestRage[this.state.credit_score], this.state.insurance_protection, aux_trade_in, aux_money_owed);
        let max_monthly_payment = finalQuots.monthly.payment;
        let auxQuotes = calculatePayments(selectedCar, loan_length, max_down_payment, this.state.formula, this.state.interestRage[this.state.credit_score], this.state.insurance_protection, trade_in, money_owed);
        let min_monthly_payment = auxQuotes.monthly.payment;
        let down_payment = this.state.down_payment;
        if (this.state.down_payment > max_down_payment) {
            down_payment = max_down_payment;
        }
        if (this.state.down_payment < min_down_payment) {
            down_payment = min_down_payment;
        }
        finalQuots = calculatePayments(selectedCar, loan_length, down_payment, this.state.formula, this.state.interestRage[this.state.credit_score], this.state.insurance_protection, trade_in, money_owed);
        let monthly_payment = finalQuots.monthly.payment;
        return {finalQuots, max_down_payment, down_payment, monthly_payment, max_monthly_payment, min_monthly_payment, min_down_payment, loan_length}
    }

    // --------------------------- get type payment ---------------------------------
    selectedPayment = (type, apiStep = false) => {
        this.scrollReset();
        let preQualifiedForm = this.state.preQualifiedForm;
        preQualifiedForm['type'] = apiStep;
        let step = 6;
        let currentUser = JSON.parse(localStorage.getItem('currentUser') || "{}");
        if (currentUser.user_id && currentUser.role === 'Customer') {
            step = 5;
        } else {
            currentUser = {};
        }
        this.setState({ step, paymentType: type, currentUser, preQualifiedForm });
    }
    // ------------------------ manage nex step in widget
    backHeaderStep = () => {
        this.onChangeState('less', 'step')
    }

    // -------------------------- continue after save contact data -------------------------------
    saveContact = (customer, email, phone_number) => {
        this.scrollReset();
        let currentUser = this.state.currentUser;
        currentUser['customer'] = customer;
        currentUser['email'] = email;
        currentUser['phone_number'] = phone_number;
        localStorage.setItem("currentUser", JSON.stringify(currentUser));
        this.setState({ step: 6, currentUser });
    }

    // --------------------------- get cars -------------------------------------------------
    getCars = (carType = 'monthly', pagination) => {
        let quots = calculatePaymentsWeekly(null, this.state.monthly_payment, this.state.formula, this.state.loan_length);
        let data = {
            monthly: quots.monthly.purchasing_power + this.state.down_payment,
            weekly: quots.weekly.purchasing_power + this.state.down_payment,
            weeklyPlus: quots.weeklyPlus.purchasing_power + this.state.down_payment,
            retailer_id: this.state.retailer.id
        }
        if (pagination) {
            data = {
                retailer_id: this.state.retailer.id,
                [carType]: data[carType],
                page: pagination
            }
        }
        this.setState({ loading: true })
        getCarsQuote(data).then((response) => {
            if (!pagination && response.data.monthly.length == 0 && response.data.weekly.length == 0 && response.data.weeklyPlus.length == 0) {
                this.setState({ loading: false, modal: { status: 404, message: 'Cars were not found' } })
            } else {
                if (pagination) {
                    let carsPayment = { ...this.state.carsPayment };
                    carsPayment[carType] = response.data;
                    let activePage = this.state.activePage;
                    activePage[carType] = pagination;
                    this.setState({ loading: false, step: 1, carsPayment: { ...carsPayment }, activePage })
                } else {
                    let activePage = this.state.activePage;
                    activePage = {
                        monthly: 1,
                        weekly: 1,
                        weeklyPlus: 1
                    };
                    this.setState({ loading: false, step: 1, carsPayment: response.data, activePage })
                }

            }
        }).catch((error) => {
            console.log(error.response);
            this.setState({ loading: false, modal: { status: 400, message: 'Cars were not found' } })
        });
    }

    // --------------------------- reload payments value when slider change for similar cars --------------------------------------------
    searchCars(step) {
        let finalQuots = this.state.finalQuots;
        // finalQuots = calculatePayments(this.state.car, this.state.loan_length, this.state.down_payment, this.state.formula, this.state.interestRage[this.state.credit_score], this.state.insurance_protection, this.state.trade_in, this.state.money_owed);
        this.setState({
            step, finalQuots
        });
    }

    // ---------------------------- change value state ---------------------------
    onChangeState = (value, label) => {
        if (label === 'step') {
            this.scrollReset();
            if (this.state.currentUser && this.state.currentUser.user_id) {
                if (this.state.step === 5 && value === 'less') {
                    value = this.state.step - 3;
                } else {
                    value = value === 'less' ? this.state.step - 1 : this.state.step + 1;
                }
            } else {
                // ------------------ start fromlanding and return to initial sliders
                if (this.state.fromLanding && this.state.step === 1.5 && value === 'less') {
                    let max_down_payment = defaultMaxPayment;
                    let min_down_payment = defaultMinPayment;
                    let max_monthly_payment = 1000;
                    let min_monthly_payment = 0;
                    let loan_length = 12;
                    let monthly_payment = this.state.monthly_payment;
                    let down_payment = this.state.down_payment;
                    down_payment = this.state.down_payment_landing;
                    monthly_payment = this.state.monthly_payment_landing;
                    if (this.state.loan_length_landing <= this.state.max_loan_length) {
                        loan_length = this.state.loan_length_landing;
                    }
                    this.setState({ [label]: 1, selectedCar: {}, max_down_payment, down_payment: down_payment, monthly_payment, max_monthly_payment, min_monthly_payment, min_down_payment, loan_length });
                    return;
                }
                value = value === 'less' ? this.state.step - 1 : this.state.step + 1;
            }
            // ------------------------------------ back final step to car views with no sesion
            if (this.state.step === 6 && value === 5 && !this.state.currentUser.user_id) {
                value = this.state.car.id !== this.state.selectedCar.id && !this.state.fromLanding ? 2.5 : 2;
            }
            // -------------------------------- set original vlaue fromstep back
            if (this.state.step === 2.5 && !this.state.fromLanding) {
                value = 2;
            }
            if (this.state.fromLanding && this.state.step === 2 && value == 1) {
                this.setState({ [label]: 1.5 });
                return;
            }
            if (this.state.step === 1.5 && this.state.fromLanding) {
                this.setState({ [label]: 1 });
                return;
            }
            // ------------------------------------------ go to show option car if user selects them from user components
            if (this.state.car.id !== this.state.selectedCar.id && !this.state.fromLanding && (this.state.step === 3 || this.state.step === 5)) {
                value = 2.5;
                this.setState({ [label]: value });
                return;
            }
            // ------------------------ goo to step with optioncars 
            if ((value == 1 && this.state.step == 0) && !this.state.fromLanding) {
                this.searchCars(2);
                return;
            }
            // --------------------- back optiosn cars from user components
            if ((value === 'less' || value === 1) && this.state.step == 2 && !this.state.fromLanding) {
                console.log(value, label, this.state.step);
                this.searchCars(0);
                return;
            }
            if (value == 2 && this.state.step == 1) {
                let finalQuots = this.state.finalQuots;
                finalQuots = calculatePayments(this.state.selectedCar, this.state.loan_length, this.state.down_payment, this.state.formula, this.state.interestRage[this.state.credit_score], this.state.insurance_protection, this.state.trade_in, this.state.money_owed)
                this.setState({ [label]: value, finalQuots });
                return;
            }
            // ---------------------------------- reset values to previous car
            if ((value === 2 || value === 'less') && this.state.step > 2) {
                let finalQuots = this.state.finalQuots;
                let down_payment = this.state.down_payment;
                let trade_in = this.state.trade_in;
                let money_owed = this.state.money_owed;
                let max_down_payment = this.state.max_down_payment;
                let min_down_payment = this.state.min_down_payment;
                let flagDown = false;
                console.log(this.state.selectedCar.id , this.state.car.id);
                if (this.state.selectedCar.id != this.state.car.id || this.state.step == 1) {
                    let newCar = this.calculateCarPrice(this.state.car, this.state.selectedCar, trade_in, money_owed, min_down_payment, max_down_payment, true);
                    min_down_payment = newCar.min_down_payment;
                    max_down_payment = newCar.max_down_payment;
                    flagDown = true;
                }
                if (flagDown) {
                    // ----------------------- reset formula for original car
                    let formula = this.state.formula, interestRage = this.state.interestRage, max_down_payment = this.state.max_down_payment, cox = null;
                    if (this.state.coxBackup != null) {
                        formula.tax_rate = this.state.coxBackup.tax_rate;
                        formula.dealer_fees = this.state.coxBackup.dealer_fees;
                        interestRage = this.state.coxBackup.interestRage;
                        max_down_payment = this.state.coxBackup.amountFinanced;
                        let aux_money_owed = money_owed, aux_trade_in = trade_in;
                        if (+trade_in >= +money_owed) {
                            aux_money_owed = 0;
                            aux_trade_in = 0
                        }
                        if (this.state.insurance_protection) {
                            min_down_payment = calculateMinDownPayment(max_down_payment, this.state.retailer_credit_range);
                            let auxQuotes = calculatePayments(this.state.car, this.state.loan_length, 0, formula, interestRage[this.props.credit_score], this.state.insurance_protection, aux_trade_in, aux_money_owed);
                            max_down_payment = calculateMaxDownPayment(auxQuotes.monthly.final_price, this.state.retailer_credit_range);
                        }
                        cox = Object.assign({}, this.state.coxBackup);
                        if (cox.incentives?.totalDealerCash > 0 || cox.incentives?.totalRebate > 0) {
                            formula.incentives = Object.assign({}, cox.incentives);
                            let auxQuotes = calculatePayments(this.state.car, this.state.loan_length, 0, formula, interestRage[this.props.credit_score], this.state.insurance_protection, aux_trade_in, aux_money_owed);
                            max_down_payment = calculateMaxDownPayment(auxQuotes.monthly.final_price, this.state.retailer_credit_range);
                        }
                    } else {
                        formula = Object.assign({}, this.state.retailer.formula);
                        interestRage = interestRageBuild(formula);
                        max_down_payment = getCarPrice(this.state.car, formula, this.state.insurance_protection);
                        let tradeAux = +this.state.trade_in - +this.state.money_owed;
                        if (tradeAux < 0) {
                            max_down_payment += -tradeAux;
                        }
                        cox = null;
                    }
                    min_down_payment = calculateMinDownPayment(max_down_payment, this.state.retailer_credit_range);
                    max_down_payment = calculateMaxDownPayment(max_down_payment, this.state.retailer_credit_range);
                    let max_loan_length = this.state.retailer_credit_range.max_loan_length, min_loan_length = this.state.retailer_credit_range.min_loan_length;
                    if (this.state.car.condition === 'new') {
                        max_loan_length += 12;
                    } else {
                        max_loan_length = 72;
                        if (min_loan_length > max_loan_length) min_loan_length = max_loan_length;
                    }
                    this.setState({formula, interestRage, max_down_payment, min_down_payment, max_loan_length, min_loan_length}, () => {
                        this.setState({selectedCar: this.state.car, [label]: 0, cox, ...this.calculateQuoteCar(this.state.car, finalQuots, min_down_payment, max_down_payment, trade_in, money_owed) });
                    });
                } else {
                    finalQuots = calculatePayments(this.state.car, this.state.loan_length, down_payment, this.state.formula, this.state.interestRage[this.state.credit_score], this.state.insurance_protection, trade_in, this.state.money_owed);
                    this.setState({ [label]: 2, finalQuots, max_down_payment, down_payment, min_down_payment, selectedCar: this.state.car });
                }
                return;
            }
            // ---------------------------------- get cars when you came from retailer button ---------------------
            if (this.state.fromLanding && this.state.step == 0 && value == 1) {
                this.getCars();
                return;
            }
        }
        this.setState({ [label]: value });
        if (label == 'monthly_payment' && this.state.fromLanding && this.state.step == 0) {
            this.setState({ "monthly_payment_landing": value });
        }
        if (label == 'loan_length' && this.state.fromLanding && this.state.step == 0) {
            this.setState({ "loan_length_landing": value });
        }
        if (label == 'down_payment' && this.state.fromLanding && this.state.step == 0) {
            this.setState({ "down_payment_landing": value });
        }
    }

    // --------------------------------- get score from 700solution API
    nextPreQualified = (form, user) => {
        this.scrollReset();
        this.setState({ currentUser: user });
        let creditRange = this.state.retailer_credit_range, score = form.score, finalQuots = this.state.finalQuots, 
        interestRage = this.state.interestRage, formula = this.state.formula, down_payment = this.state.down_payment, 
        max_down_payment = this.state.max_down_payment, min_down_payment = this.state.min_down_payment;
        let credit_score = 4;
        form['creditScore'] = creditRange.exceptional;
        if (!score || isNaN(score)) {
            score = 780;
        }
        form['creditScore'] = score;
        if (score < creditRange.poor) {
            credit_score = 0;
        } else if (score >= creditRange.poor && score <= creditRange.fair) {
            credit_score = 1;
        } else if (score > creditRange.fair && score <= creditRange.good) {
            credit_score = 2;
        } else if (score > creditRange.good && score <= creditRange.very_good) {
            credit_score = 3;
        } else if (score > creditRange.very_good) {
            credit_score = 4;
        }
        let cox = null;
        // --------------------------- validate cox response
        let formulaCox = this.createCoxFormula(score, form.coxQuotes, credit_score);
        formula = formulaCox.formula;
        cox = formulaCox.cox;
        interestRage = formulaCox.interestRage;
        credit_score = formulaCox.credit_score;
        down_payment = formulaCox.down_payment;
        max_down_payment = formulaCox.max_down_payment;
        min_down_payment = formulaCox.min_down_payment;
        let coxBackup = cox == null ? null : Object.assign({}, cox);
        form['type'] = false;
        form['credit_score_value'] = credit_score;
        let trade_in = this.state.trade_in, money_owed = +this.state.money_owed;
        let aux_trade_in = trade_in, aux_money_owed = +money_owed;
        if (+trade_in >= +money_owed) {
            aux_money_owed = 0;
            aux_trade_in = 0
        }
        // ------------------------ get max
        let auxQuotes = calculatePayments(this.state.selectedCar, this.state.loan_length, min_down_payment, formula, interestRage[credit_score], this.state.insurance_protection, aux_trade_in, aux_money_owed);
        let max_monthly_payment = auxQuotes.monthly.payment;
        // ------------------------ get value
        finalQuots = calculatePayments(this.state.selectedCar, this.state.loan_length, down_payment, formula, interestRage[credit_score], this.state.insurance_protection, trade_in, money_owed);
        let monthly_payment = finalQuots.monthly.payment;
        // ------------------------ get min
        auxQuotes = calculatePayments(this.state.selectedCar, this.state.loan_length, max_down_payment, formula, interestRage[credit_score], this.state.insurance_protection, trade_in, money_owed);
        let min_monthly_payment = auxQuotes.monthly.payment;
        // ------------------------ save values for resetingthe sliders
        let resetValues = { monthly_payment, credit_score, loan_length: this.state.loan_length, down_payment, min_monthly_payment, max_monthly_payment };
        this.setState({ preQualifiedForm: form, credit_score, finalQuots, monthly_payment, max_monthly_payment, min_monthly_payment, max_down_payment,
            min_down_payment, coxBackup, openSession: false, interestRage, formula, down_payment, cox, step: this.state.step === 1.5 ? 2 : 0, paymentCars: false, resetValues });
    }

    // --------------------------------------- get cox response after similar cars
    afterCox = (result) => {
        this.setState({...this.createCoxFormula(this.state.preQualifiedForm.creditScore, result, this.state.credit_score, true), coxProcess: false}, () => {
            this.setState({
                step: 2.5,
                ...this.calculateQuoteCar(this.state.selectedCar, this.state.finalQuots, this.state.min_down_payment, this.state.max_down_payment,this.state.trade_in, this.state.money_owed)
            })
        });
    }

    // --------------------------------------- validate cox response
    createCoxFormula = (score, coxQuotes, credit_score, flagCashDown) => {
        let cox = null, interestRage = this.state.interestRage, formula = this.state.formula, down_payment = this.state.down_payment, 
        max_down_payment = this.state.max_down_payment, min_down_payment = this.state.min_down_payment;
        this.setState({coxBackup: this.state.cox == null ? null : Object.assign({}, this.state.cox)});
        if (coxQuotes != null) {
            // if (score <= 659) {
            //     credit_score = 0;
            // } else if (score > 659 && score <= 679) {
            //     credit_score = 1;
            // } else if (score > 679 && score <= 699) {
            //     credit_score = 2;
            // } else if (score > 699 && score <= 759) {
            //     credit_score = 3;
            // } else if (score > 759) {
            //     credit_score = 4;
            // }
            let i = 0;
            formula.incentives = false;
            for (const item of coxQuotes) {
                interestRage[i] = item.rate.apr;
                formula.dealer_fees = item.feeSummary.totalFee;
                formula.tax_rate = item.taxSummary.taxItems[0].taxRate * 100;
                if (item.incentiveSummary.totalDealerCash > 0 || item.incentiveSummary.totalRebate > 0) {
                    formula.incentives = item.incentiveSummary;
                }
                max_down_payment = item.payment.amountFinanced;
                i++;
            }
            cox = {
                interestRage,
                tax_rate: formula.tax_rate,
                dealer_fees: formula.dealer_fees,
                amountFinanced: max_down_payment,
                incentives: formula.incentives ? Object.assign({}, formula.incentives) : null,
            }
            if (formula.incentives) {
                let trade_in = +this.state.trade_in, money_owed = +this.state.money_owed;
                if (+trade_in >= +money_owed) {
                    money_owed = 0;
                    trade_in = 0
                }
                let auxQuotes = calculatePayments(this.state.selectedCar, this.state.loan_length, min_down_payment, formula, interestRage[credit_score], this.state.insurance_protection, trade_in, money_owed);
                max_down_payment = calculateMaxDownPayment(auxQuotes.monthly.final_price, this.state.retailer_credit_range);
            }
            let downPayment = this.setDownPaymentSlider(flagCashDown, max_down_payment);
            max_down_payment = downPayment.max_down_payment;
            min_down_payment = downPayment.min_down_payment;
            down_payment = downPayment.down_payment;
            cox.amountFinanced = max_down_payment;
        } else {
            formula = Object.assign({}, this.state.retailer.formula);
            formula.incentives = false;
            interestRage = interestRageBuild(formula);
            max_down_payment = getCarPrice(this.state.selectedCar, formula, this.state.insurance_protection);
            let tradeAux = +this.state.trade_in - +this.state.money_owed;
            if (tradeAux < 0) {
                max_down_payment += -tradeAux;
            }
            let downPayment = this.setDownPaymentSlider(flagCashDown, max_down_payment);
            min_down_payment = downPayment.min_down_payment;
            max_down_payment = downPayment.max_down_payment;
            down_payment = downPayment.down_payment;
            cox = null;
        }
        return { cox, formula, interestRage, down_payment, credit_score, max_down_payment, min_down_payment }
    }

    setDownPaymentSlider = (flagCashDown, carPrice) => {
        let default_down_payment = 20, down_payment = this.state.down_payment, max_down_payment = 0, min_down_payment = 0;
        if (this.state.retailer_credit_range.default_down_payment && this.state.retailer_credit_range.default_down_payment != '') {
            default_down_payment = this.state.retailer_credit_range.default_down_payment;
        }
        down_payment = flagCashDown ? this.state.down_payment : (carPrice * (default_down_payment / 100));
        max_down_payment = calculateMaxDownPayment(carPrice, this.state.retailer_credit_range);
        min_down_payment = calculateMinDownPayment(carPrice, this.state.retailer_credit_range);
        return {down_payment, max_down_payment, min_down_payment}
    }

    createCoxData = (selectedCar) => {
        let continueData = {};
        continueData.user = {};
        let loan_length = this.state.loan_length;
        if (selectedCar.condition === 'used' && loan_length > 72) loan_length = 72;
        let finalQuots = calculatePayments(selectedCar, loan_length, 0, this.state.formula, this.state.interestRage[this.state.credit_score], this.state.insurance_protection, this.state.trade_in, this.state.money_owed);
        continueData.user['postalCode'] = this.state.preQualifiedForm.postalCode;
        continueData.user['creditScore'] = this.state.preQualifiedForm.creditScore;
        continueData.quote = finalQuots.monthly;
        continueData.car = selectedCar;
        continueData.retailer = this.state.retailer;
        continueData.tradeIn = this.state.trade_in;
        continueData.moneyOwed = this.state.money_owed;
        continueData.loan_length = loan_length;
        return continueData;
    }

    // ------------------------- reset slider values
    resetSilderValues = () => {
        let values = this.state.resetValues;
        let finalQuots = calculatePayments(this.state.selectedCar, values.loan_length, values.down_payment, this.state.formula, this.state.interestRage[values.credit_score], this.state.insurance_protection, this.state.trade_in, this.state.money_owed);
        this.setState({
            monthly_payment: values.monthly_payment, credit_score: values.credit_score, loan_length: values.loan_length, down_payment: values.down_payment, finalQuots,
            max_monthly_payment: values.max_monthly_payment, min_monthly_payment: values.min_monthly_payment
        })
    }

    render() {
        return (
            <div className="container">
                <HeaderQuote {...this.state} onChangeState={(e) => this.backHeaderStep()} fromLanding={this.state.fromLanding}/>
                {
                    this.state.error.message ?
                        <Message error header='404' content={this.state.error.message} />
                        :
                        <div className="quot-content">
                            {
                                (this.state.step == -1 || this.state.step == 1.5) && this.state.selectedCar.id && (
                                    <Grid className="PreQualified">
                                        <PreQualified car={this.state.selectedCar} preQualifiedForm={this.state.preQualifiedForm} videos={this.state.videos} 
                                            finalQuots={this.state.finalQuots} next={this.nextPreQualified} openSession={this.state.openSession} retailer={this.state.retailer}
                                            trade_in={this.state.trade_in} money_owed={this.state.money_owed}/>
                                        <Footer finalQuots={this.state.finalQuots} />
                                    </Grid>
                                )
                            }
                            {
                                this.state.step == 0 ?
                                    this.state.car.id || this.state.retailer.id ?
                                        <div>
                                            {
                                                this.state.car.id && !this.state.fromLanding ?
                                                    <Grid>
                                                        <Grid><Header as='h2' className="congratulations-text">Congratulations, You're Pre Qualified!</Header></Grid>
                                                        <Grid.Column mobile={16} tablet={16} computer={16} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <Car insurance_protection={this.state.insurance_protection} retailer={this.state.retailer} type={'firstCar'} interest={this.state.interestRage[this.state.credit_score]} car={this.state.car} formula={this.state.formula} quots={this.state.finalQuots} trade_in={this.state.trade_in}
                                                                max_down_payment={this.state.max_down_payment} insuranceChange={this.onChangeState} money_owed={this.state.money_owed}
                                                                nextStep={() => this.onChangeState('plus', 'step')} tradeText={this.state.tradeText}
                                                            />
                                                        </Grid.Column>
                                                        <JumpButton step={this.state.step} />
                                                        <Grid.Column mobile={16} tablet={16} computer={16} style={{ display: "flex", alignItems: "center" }}>
                                                            {this.renderDescSlider({})}
                                                        </Grid.Column>
                                                    </Grid>
                                                    :
                                                    this.renderDescSlider({ marginBottom: "20px" })
                                            }
                                            <Sliders showPayment {...this.state} quotation={this.onChangeState} noCalculate={!this.state.fromLanding ? false : true} reset={!this.state.fromLanding} resetSilderValues={this.resetSilderValues}/>
                                            {this.renderButtons()}
                                            <Footer finalQuots={this.state.finalQuots} />
                                        </div>
                                        : null
                                    : null
                            }
                            {/* {
                                (this.state.step === 1 && !this.state.fromLanding) && (
                                    <Cars {...this.state} carContinue={(car) => this.carContinue(car)} onChangeState={this.onChangeState} selectedPayment={this.selectedPayment}/>
                                )
                            } */}
                            {
                                (this.state.step === 1 && this.state.fromLanding) && (
                                    <CarSearch {...this.state} getCars={(type, page) => this.getCars(type, page)} carContinue={(car) => {this.carContinue(car); this.setState({ step: 1.5, car: car, selectedCar: car })}} />
                                )
                            }
                            {
                                this.state.step === 2 ?
                                    <Grid>
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Grid>
                                                <Grid.Column mobile={16} tablet={16} computer={8}>
                                                    <Car insurance_protection={this.state.insurance_protection} retailer={this.state.retailer} type={!this.state.fromLanding ? 'selectedCar' : 'selectedCarLanding'}
                                                        car={this.state.selectedCar} interest={this.state.interestRage[this.state.credit_score]} formula={this.state.formula}
                                                        quots={this.state.finalQuots} selectedPayment={this.selectedPayment} trade_in={this.state.trade_in} money_owed={this.state.money_owed}
                                                        max_down_payment={this.state.max_down_payment} insuranceChange={this.onChangeState} tradeText={this.state.tradeText}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={16} computer={8} style={{display: "grid"}}>
                                                    <div className="ui-computer">
                                                        <JumpButton step={this.state.step} noColumn={true}/>
                                                    </div>
                                                    <Video newVideos={true} video={'plan'} videos={this.state.videos}/>
                                                </Grid.Column>
                                            </Grid>
                                            {
                                                this.state.fromLanding && (
                                                    <TradeModal trade_in={this.state.trade_in} money_owed={this.state.money_owed} disabledTrade={false}
                                                        tradeChange={this.onChangeState}
                                                    />
                                                )
                                            }
                                            {
                                                !this.state.fromLanding && (
                                                    <div className="ui-mobile">
                                                        <Cars {...this.state} carContinue={this.carContinue} onChangeState={this.onChangeState} selectedPayment={this.selectedPayment} />
                                                    </div>
                                                )
                                            }
                                            <div className="ui-computer" id="hrefFocus">
                                                <Payments quots={this.state.finalQuots} step={this.state.step} selectedPayment={this.selectedPayment} car={this.state.selectedCar} />
                                                {
                                                    !this.state.fromLanding && (
                                                        <Cars {...this.state} carContinue={this.carContinue} onChangeState={this.onChangeState} selectedPayment={this.selectedPayment} />
                                                    )
                                                }
                                                <Sliders showPayment {...this.state} quotation={this.onChangeState} reset={this.state.fromLanding} resetSilderValues={this.resetSilderValues}/>
                                            </div>
                                        </Grid.Column>
                                        <div style={{ padding: "0 1rem" }}>
                                            <Footer finalQuots={this.state.finalQuots} />
                                        </div>
                                    </Grid>
                                    : null
                            }
                            {
                                this.state.step === 2.5 ? 
                                    <Grid>
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Grid>
                                                <Grid.Column mobile={16} tablet={8} computer={8}></Grid.Column>
                                                <Grid.Column mobile={16} tablet={8} computer={8} textAlign="center">
                                                    <Header className='change-car-text' as='h2'>Nice Pick! And,<b> Your Pre-qualified</b> For This Vehicle...</Header>
                                                </Grid.Column>
                                            </Grid>
                                            <Grid>
                                                <Grid.Column mobile={16} tablet={16} computer={8}>
                                                    <Car insurance_protection={this.state.insurance_protection} retailer={this.state.retailer} type={'selectedCarLanding'}
                                                        car={this.state.selectedCar} interest={this.state.interestRage[this.state.credit_score]} formula={this.state.formula}
                                                        quots={this.state.finalQuots} selectedPayment={this.selectedPayment} trade_in={this.state.trade_in} money_owed={this.state.money_owed}
                                                        max_down_payment={this.state.max_down_payment} insuranceChange={this.onChangeState}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={16} computer={8} style={{display: "flex", alignItems: "center"}}>
                                                    <div className="ui-computer">
                                                        <JumpButton step={this.state.step} noColumn={true} />
                                                    </div>
                                                </Grid.Column>
                                            </Grid>
                                            <div className="ui-computer" id="hrefFocus">
                                                <Payments quots={this.state.finalQuots} step={this.state.step} selectedPayment={this.selectedPayment} car={this.state.selectedCar} />
                                                <Sliders showPayment {...this.state} quotation={this.onChangeState} />
                                            </div>
                                        </Grid.Column>
                                        <div style={{ padding: "0 1rem" }}>
                                            <Footer finalQuots={this.state.finalQuots} />
                                        </div>
                                    </Grid>
                                :null
                            }
                            {
                                this.state.step === 3 && (
                                    <div className="register-grapper">
                                        <Header className='register-title'>Credit Approval Information</Header>
                                        <div style={{ display: "table-cell", verticalAlign: "middle", paddingTop: "1rem" }}>
                                            <div className='ui-computer'>
                                                <Video flag={true} video={'approval'} videos={this.state.videos}/>
                                            </div>
                                            <div className="register-background" style={{ background: "transparent", boxShadow: "none" }}>
                                                <Register getUser={this.getUser} goToLogin={() => this.setState({ step: this.state.step + 1 })}
                                                    retailer={this.state.retailer} car={this.state.selectedCar} monthly_payment={this.state.monthly_payment}
                                                    loan_length={this.state.loan_length} down_payment={this.state.down_payment} credit_score={this.state.credit_score}
                                                    paymentType={this.state.paymentType} formula={this.state.formula} trade_in={this.state.trade_in} money_owed={this.state.money_owed}
                                                    insurance_protection={this.state.insurance_protection} preQualified={this.state.preQualifiedForm} />
                                            </div>
                                            <Footer finalQuots={this.state.finalQuots} />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                this.state.step === 4 && (
                                    <div className="register-grapper">
                                        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                                            <Video flag={true} video={'approval'} videos={this.state.videos}/>
                                            <div className="register-background" style={{ background: "transparent", boxShadow: "none" }}>
                                                <Login getUser={this.getUser} showSignup={this.showSignup}
                                                    retailer={this.state.retailer} car={this.state.selectedCar} monthly_payment={this.state.monthly_payment}
                                                    loan_length={this.state.loan_length} down_payment={this.state.down_payment} credit_score={this.state.credit_score}
                                                    paymentType={this.state.paymentType} createdQuot={this.state.createdQuot} formula={this.state.formula}
                                                    trade_in={this.state.trade_in} money_owed={this.state.money_owed} insurance_protection={this.state.insurance_protection} quotationProccess={true}/>
                                            </div>
                                            <Footer finalQuots={this.state.finalQuots} />
                                        </div>
                                    </div>

                                )
                            }
                            {
                                this.state.step === 5 && (
                                    <div className="register-grapper">
                                        <Header className='register-title'>Credit Approval Information</Header>
                                        <div style={{ display: "table-cell", verticalAlign: "middle", paddingTop: "1rem"  }}>
                                            <div className='ui-computer'>
                                                <Video flag={true} video={'approval'} videos={this.state.videos} />
                                            </div>
                                            <div className="register-background">
                                                <Contact user={this.state.currentUser} videos={this.state.videos} continue={this.saveContact} 
                                                updateUser={() => this.updateUser({}, this.state.fromLanding ? 1.5 : -1)} quotationProccess={true} 
                                                acceptedPlan={this.state.preQualifiedForm.type === 'plan' ? true : false} />
                                            </div>
                                            <Footer finalQuots={this.state.finalQuots} />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                this.state.step === 6 ?
                                    <div>
                                        <Car insurance_protection={this.state.insurance_protection} type={'finishedCar'} retailer={this.state.retailer} car={this.state.selectedCar} formula={this.state.formula} interest={this.state.interestRage[this.state.credit_score]} quots={this.state.finalQuots} 
                                        trade_in={this.state.trade_in} disabledTrade={true} money_owed={this.state.money_owed} />
                                        <TradeModal trade_in={this.state.trade_in} money_owed={this.state.money_owed} disabledTrade={true}
                                            tradeChange={this.onChangeState} 
                                        />
                                        <Grid className="grapperPayments">
                                            <Grid.Column className="ui-computer" mobile={16} tablet={8} computer={8} >
                                                <Sliders vertical={true} {...this.state} showPayment disabled={true} quotation={this.onChangeState} />
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={8} computer={8} className="borderFinal">
                                                <Payments quots={this.state.finalQuots} paymentType={this.state.paymentType} step={this.state.step} car={this.state.selectedCar}
                                                    createQuot={() => this.createQuot()} onlyPayments />
                                                    {
                                                        !this.state.currentUser.user_id && (
                                                            <Grid style={{paddingTop: "2rem"}} textAlign='center'>
                                                                <Button className='btn-continue' onClick={() => this.updateUser(this.state.currentUser, this.state.fromLanding ? 1.5 : -1)}>
                                                                    <h3>Create My Account</h3>
                                                                </Button>
                                                            </Grid>
                                                        )
                                                    }
                                            </Grid.Column>
                                        </Grid>
                                        <Footer finalQuots={this.state.finalQuots} />
                                    </div>
                                    : null
                            }
                            {
                                this.state.modal.message ?
                                    <Modal hideModal={this.hideModal} modal={this.state.modal} />
                                    : null
                            }
                            {
                                this.state.coxProcess && (
                                    <Cox type={this.state.coxProcess} data={this.state.continueCoxData}
                                        continue={this.afterCox} />
                                )
                            }
                        </div>
                }
                {
                    this.state.loading ?
                        <Dimmer className="loading" active>
                            <Loader />
                        </Dimmer>
                        : null
                }
            </div>
        )
    }

    renderDescSlider(style) {
        return (
            <div>
                <SliderHeader fromLanding={this.state.fromLanding} step={this.state.step} style={style} 
                    preQualifiedForm={this.state.preQualifiedForm} videos={this.state.videos} retailer={this.state.retailer} title={1}/>
                <TradeModal trade_in={this.state.trade_in} money_owed={this.state.money_owed} disabledTrade={false}
                    tradeChange={this.onChangeState}
                />
                <SliderHeader fromLanding={this.state.fromLanding} step={this.state.step} style={style} 
                    preQualifiedForm={this.state.preQualifiedForm} videos={this.state.videos} retailer={this.state.retailer} title={2}/>
            </div>
        );
    }

    renderButtons() {
        return (
            <div className="grapperButtons">
                <Button className="btn-continue" onClick={(e) => this.onChangeState('plus', 'step')} ><h3>
                    {
                        !this.state.fromLanding ? 'CONTINUE: Reveal My Payment Power' : 'CONTINUE'
                    }
                </h3></Button>
            </div>
        );
    }
}


export default Quotation;