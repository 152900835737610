import React, { Component } from "react";
import {Breadcrumb, Grid, Pagination, Table} from "semantic-ui-react";

// IMPORT COMPONENTS
import BuyerInformation from "../buyerInformation/buyerInformation";
import { MessagesModal } from "../messages/MessagesModal";
/*
*  PROPS:
*   - buyers:       List of buyers to show.
*   - totalPages:   Total of the search pages.
*   - changePage:   Function to change the current page.
*
* */

class ListBuyers extends Component{
    constructor(props){
        super(props);
        this.state = {
            detail: {},
            buyerModal: false,
            buyer: {}
        }
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
        // if(this.props.defaultCar !== null && this.props.defaultCar !== this.state.selectedQuote){
        //     this.setState({
        //         selectedQuote:  this.props.defaultCar,
        //     });
        // }
    // }

    showDetails(id, name){
        this.setState({selectedQuote: id, breadCrumbName: name});
    }

    hideDetails(){
        this.setState({selectedQuote: null});
    }

    render(){
        const { selectedQuote } = this.state;
        return(
            <div className={'body'} style={{display: this.props.isActive ? 'block': 'none'}}>
                <Grid stackable>
                    <Grid.Column width={16}>
                        <Breadcrumb size={'large'}>
                            <Breadcrumb.Section active>Buyers list</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid>
                <div>
                    <Grid stackable>
                        <Grid.Column width={16} style={{overflowX: "auto"}}>
                            <Table className={'customTable'} unstackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            Buyer name
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Email
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Total deals
                                        </Table.HeaderCell>
                                        {
                                            this.props.role === 'retailer' && (
                                                <Table.HeaderCell>
                                                </Table.HeaderCell>
                                            )
                                        }
                                        <Table.HeaderCell>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        this.props.buyers.map((buyer, key)=>(
                                            <Table.Row key={key}>
                                                <Table.Cell>
                                                    {buyer.name + " " + buyer.second_name}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {buyer.user.email}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {buyer.quots_count}
                                                </Table.Cell>
                                                {
                                                    this.props.role === 'retailer' && (
                                                        <Table.Cell>
                                                            <MessagesModal role="retailer" customer={{value: buyer.id, text: buyer.name + " " + buyer.second_name}} unreadMessages={buyer.messages_count}/>
                                                        </Table.Cell>
                                                    )
                                                }
                                                <Table.Cell>
                                                    <span className={'detailsQuote'} onClick={() => this.setState({buyerModal: true, buyer})}>
                                                        Buyer Information
                                                    </span>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid>
                    {
                        this.props.totalPages && this.props.changePage &&
                            <div style={{textAlign: 'Center'}}>
                                <Pagination
                                    defaultActivePage={1}
                                    totalPages={this.props.totalPages}
                                    onPageChange={(e, { activePage }) => this.props.changePage(activePage)}/>
                            </div>
                    }
                </div>
                {
                    this.state.buyerModal ?
                        <BuyerInformation customer={this.state.buyer}  close={() => this.setState({ buyerModal: false })}/>
                    :null
                }
            </div>
        )
    }
}

export default ListBuyers;