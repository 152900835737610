import React from 'react';
import { Button, Segment } from 'semantic-ui-react';

export const Account = ({ step, section }) => {
  return (
    <div className={section}>
      {/* <Icon name="arrow left" /> */}
      <div className='text'>
        <Segment>
          <h4>In this section you will able to create your ban account.</h4>
          <ul>
            <li>The first step is create your Dwolla Profile. You have to accept the terms and conditions and to click in the button <img src={require("../../../images/tutorial/buttonDwolla.png")} /></li>
            <li>The second step is create your funding account. You have to link you bank account with the button <img src={require("../../../images/tutorial/buttonFunding.png")} /></li>
            <li>The third step is create your lender funding accounts. You have to click in the button <img src={require("../../../images/tutorial/buttonLender.png")} /></li>
            <li>Then you will be able to create payment plans with the button <img src={require("../../../images/tutorial/buttonPlan.png")} /></li>
          </ul>
        </Segment>
        <Button onClick={() => step('contact')}>Previous</Button>
      </div>
    </div>
  );
};