import React from 'react';
import { Table } from 'semantic-ui-react';
import { FormatNumberToPrice } from '../../../../helpers/general';

export const FeeInformation = ({ plan, fee }) => {
  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3' textAlign='center'>
              FEE DISCLOSURE AND ESTIMATED TOTAL COSTS
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row >
            <Table.Cell colSpan='2' className='blue'>
              One time Fees:
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Enrollment Fee:
            </Table.Cell>
            <Table.Cell>
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Account/Identity Verification Fee:
            </Table.Cell>
            <Table.Cell>
              {FormatNumberToPrice(fee)}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell colSpan='2' className='blue'>
              Recurring Fees:
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              ACH/Convenience Fee (per debit):
            </Table.Cell>
            <Table.Cell>
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell colSpan='2' className='blue'>
              Fees over Loan Term:
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell colSpan='2'>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <p>
        There are no up front costs. The enrollment fee will be deducted from the initial
        automatic debits and calculated in the amortization of your loan. See below
        for more detail. At any time, you may choose to cancel the program with no
        cancellation fee. However, any part of the enrollment fee that AutoPayPlus
        has collected cannot be refunded and you may owe AutoPayPlus the
        balance thereof.
      </p>
    </div>
  );
};