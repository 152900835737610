import React, { useEffect } from 'react';
import { Button, Form, Header } from 'semantic-ui-react';
import { CoApplicantForm } from '../coApplicant/CoApplicantForm';

export const SelectCoApplicant = (props) => {
  const { form, options, newOne, type } = props;

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const showApplicant = () => {
    let auxType = type === 'coApplicant' ? 'co_applicant' : type
    if (form[auxType]) {
      return form[auxType].firstName + " " + form[auxType].lastName;
    } else {
      auxType = type === 'coApplicant' ? 'Co-Applicant' : type
      return capitalizeFirstLetter(auxType) + " not selected";
    }
  }

  useEffect(() => {
  }, [])

  return (
    <div>
      {
        form.status === 0 ?
          <div>
            <Header size="medium">Do you want to add a {type}?</Header>
            {
              newOne[type] ?
                <div>
                  <Button positive onClick={() => props.showNewOne(type, false)}>Previously created {type}</Button>
                  <CoApplicantForm applyProcess={true} prev={props.prevButton} coApplicant={form[type]}
                    finalStep={props.finalStepCoApplicant} type={type} />
                </div>
                :
                <div>
                  <Form.Select
                    value={form[type + "Id"]}
                    label={<label>{capitalizeFirstLetter(type)}</label>}
                    onChange={(e, { value }) => props.onEditInput(type + 'Id', value)}
                    width={16}
                    fluid
                    options={options}
                    disabled={props.formDisabled}
                  />
                  {
                    form.status === 0 && (
                      <Button positive onClick={() => props.showNewOne(type, true)}>Add a new {type}</Button>
                    )
                  }
                </div>
            }
          </div>
          :
          <Header size="medium" style={{margin: "3rem 0"}} textAlign="center">{showApplicant()}</Header>
      }
    </div>
  );
};