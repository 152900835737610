import api from "./apiMethods";

// LOGIN FUNCTIONS
export const login =                (body) => api.post('auth/login', body); // TESTED
export const signUp =               (body) => api.post('auth/signup', body); //TESTED
export const logOut =               () => api.get('auth/logout');

// USER DATA
export const userData =             () => api.get('user');
// CHANGE PASSWORD
export const editPassword =         (body) => api.put('updatePassword', body); //TESTED
// RESEND SMS
export const resendSMSAuth =        (body) => api.put('auth/smsAuth', body); //TESTED
export const resendSMS =            () => api.put('sms'); //TESTED

// RESEND SMS
export const checkPhone =           (body) => api.put('auth/phoneNumber', body); //TESTED
export const resetPassword =        (body) => api.put('auth/password', body); //TESTED

// ADMIN FUNCTIONS
// GET
export const adminData =            () => api.get('admin/admin'); //TESTED
export const getRetailers =         (params) => api.get('admin/retailers', params); //TESTED
export const getRetailer =          (id) => api.get(`admin/retailer/${id}`); //TESTED
export const getRetailerFormula =   (id) => api.get(`admin/formula/${id}`);  //TESTED
export const searchQuotes =         (params) => api.get('admin/quotes', params);
// export const bestRetailers =     () => api.get('admin/bestRetailers');
export const statusQuotes =         (params) => api.get('admin/statusQuotes', params);
export const getCustomersAdmin =    (params) => api.get('admin/customers', params); //TESTED
export const getRetailersList =     () => api.get('admin/retailers/list'); //TESTED
export const getPolls =             () => api.get('admin/polls');
export const getVideos =            () => api.get('admin/videos');
export const putInventory =         (id) => api.put(`admin/inventory/${id}`);
export const getAccounts =          (params) => api.get(`admin/accounts`, params);

// POST
export const newRetailer =          (body) => api.post('admin/retailer', body); //TESTED
export const editRetailer =         (id, body) => api.post(`admin/retailer/${id}`, body);
export const addRetailerFormula =   (body) => api.post('admin/formula', body); //TESTED
export const postPoll =              (body) => api.post('admin/poll', body);
export const postVideo =            (body) => api.post('admin/video', body);
export const postDocument = (body) => api.post('admin/document', body);

// PUT
export const editRetailerFormula =  (id, body) => api.put(`admin/formula/${id}`, body); //TESTED
export const editRetailerQuoteExpiration =  (id, body) => api.put(`admin/quoteExpiration/${id}`, body); //TESTED
export const putPoll =              (id, body) => api.put(`admin/poll/${id}`, body);

// DELETE
export const deleteRetailer =       (id) => api.delete(`admin/formula/${id}`);
export const deletePoll =           (id) => api.delete(`admin/poll/${id}`);
export const deleteVideo =          (id) => api.delete(`admin/video/${id}`);


// RETAILER FUNCTIONS
// GET
export const retailerData =         () => api.get('retailer/retailerData'); //TESTED
export const searchQuotesRetailer = (params) => api.get('retailer/quotes', params); //TESTED
// export const getQuotRetailer =      (id) => api.get(`retailer/quot/${id}`); //TESTED
export const getCustomers =         (params) => api.get('retailer/customers', params); //TESTED
export const getCreditRange =       () => api.get('retailer/creditRange') //TESTED
export const retailerStatusQuotes = (params) => api.get('retailer/statusQuotes', params);

// POST
export const postNewCreditRange =   (body) => api.post('retailer/creditRange', body); //TESTED

// PUT
export const editCreditRange =      (id, body) => api.put(`retailer/creditRange/${id}`, body); //TESTED
export const editQuotStatusRetailer =      (id, body) => api.put(`retailer/quot/status/${id}`, body);


// CUSTOMER FUNCTIONS
//GET
export const searchQuotesCustomer = (params) => api.get('customer/quotes', params);
// export const getQuotCustomer =      (id) => api.get(`customer/quot/${id}`);
export const getCar =               (vin) => api.get(`car/${vin}`);
export const getContactData =       () => api.get('customer/contact');
export const getRetailerQuote =      (retailer) => api.get(`retailer/${retailer}`);
export const getCarsQuote =          (params) => api.get(`cars`, params);
export const getCoxAPR =             (params) => api.get(`coxAPR`, params);
export const get700Credit =          (params) => api.get(`700Credit`, params);
export const getLenders =             () => api.get("customer/lenders");
export const getCoApplicants =       (params = {}) => api.get("customer/coApplicants", params);
export const getDealRetailers =       (params = {}) => api.get("customer/retailers", params);
export const getPaymentPlans = (params) => api.get(params.type === 'customer' ? "customer/paymentPlan" : `admin/paymentPlan/${params.id}`, params);
export const getPayments = (params, id, type) => api.get(`${type === 'global' ? 'admin' : type}/payments/${id}`, params);
export const getEvents = (params) => api.get('admin/events', params);
export const getPlaidToken = () => api.get('customer/plaidToken');

//POST
export const postNewQuot =          (body) => api.post('customer/quot', body); //TESTED
export const postContactData =      (body) => api.post('customer/contact', body); //TESTED
export const createDwollaProfile =  (body) => api.post('customer/profile', body);
export const createFundingAccount =      (body) => api.post('customer/funding', body);
export const postPaymentPlan =      (body) => api.post('paymentPlan', body);
export const postLender =           (body) => api.post("customer/lender", body);
export const postCoApplicant =       (body) => api.post("customer/coApplicant", body);
export const postLoanApplication =    (body) => api.post("customer/loan", body);

// PUT
export const editQuot =             (id, body) => api.put(`customer/quot/${id}`, body); //TESTED
export const editQuotStatusCustomer =      (id, body) => api.put(`customer/quot/status/${id}`, body);
export const editStatusQuotClient = (id, body) => api.put(`customer/quot/status/${id}`, body); //TESTED
export const editContactData =      (body) => api.put('customer/contact', body); //TESTED
export const verifyFundingAccount =      (body) => api.put('customer/funding', body);
export const stopPaymentPlan =      (id) => api.put(`customer/paymentPlan/stop/${id}`);
export const restartPaymentPlan = (id) => api.put(`customer/paymentPlan/restart/${id}`);
export const putCoApplicant =        (body) => api.put("customer/coApplicant", body);

// DELETE
export const deleteFundingAccount =      () => api.delete('customer/funding');
export const deleteLender =              (id) => api.delete(`customer/lender/${id}`);
export const deleteCoApplicant =          (id) => api.delete(`customer/coApplicant/${id}`);

// QUOTE FUNCTIONS
export const getQuot =      (id) => api.get(`quot/${id}`);

// routes for retailer and customer
export const getPaymentPlan =        (body) => api.get(`paymentPlan/${body.id}`, body);
export const putPaymentPlan =      (body) => api.put(`paymentPlan/${body.id}`, body);
export const getLendersPlan =        (body = {}) => api.get("lenders", body);
export const getMessages = (params) => api.get(`${params.role}/messages/${params.id}`, {});
export const postMessage = (params) => api.post(`${params.role}/messages`, params);

// routes for retailer and admin
export const putCar =      (body) => api.put(`car/${body.id}`, body);
export const getInventory =  (params) => api.get('inventory', params);
export const getCreditRequests = (params) => params.admin ? api.get(`admin/creditRequests/${params.id}`, params) : api.get(`retailer/creditRequests`, params);
export const getMessageCategories =  (params) => api.get('messageCategories', params);


// routes for every user
export const getDocument = (id) => api.get(`document/${id}`);