// @flow 
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Dimmer, Form, Grid, Icon, Loader, Message, Modal, Pagination, Table } from 'semantic-ui-react';
import ModalComponent from '../alertModal/modal';
import { getCoApplicants } from '../requests';
import { CoApplicantData } from './CoApplicantData';
import { DeleteCoApplicant } from './DeleteCoApplicant';

export const CoApplicant = (props) => {
  const { type } = props;
  const sizePagination = 8;
  const [pagination, setPagination] = useState({ totalPages: 0, currentPage: 1, startPage: 0, endPage: sizePagination })
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [coApplicants, setCoApplicants] = useState([]);
  const [coApplicantsBackUp, setCoApplicantsBackUp] = useState([]);
  const [form, setForm] = useState({ name: "" });

  // ---------------------------- search -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    search(auxForm);
    setForm({ ...auxForm });
  }

  // ------------------ get page -------------------------
  const paginationClick = (currentPage, totalPages) => {
    let startPage = (currentPage - 1) * sizePagination;
    let endPage = startPage + sizePagination;
    let auxPagination = { ...pagination };
    auxPagination.currentPage = currentPage;
    auxPagination.startPage = startPage;
    auxPagination.endPage = endPage;
    if (totalPages) {
      auxPagination.totalPages = totalPages;
    }
    setPagination({ ...auxPagination });
  }

  const calculatePages = (array) => {
    let totalPages = Math.ceil(array.length / sizePagination);
    paginationClick(1, totalPages);
  }

  // ------------------------------- search method --------------------------
  const search = (form, auxCoApplicantsBackUp = coApplicantsBackUp, resetPage = true) => {
    let auxCoApplicants = [];
    if (form.name) {
      for (const item of auxCoApplicantsBackUp) {
        let flag = { name: true };
        let key = form.name.toLowerCase().split(' ').join('');
        let name = item.firstName.toLowerCase().split(' ').join('') + item.lastName.toLowerCase().split(' ').join('');
        if (name.toLowerCase().includes(key)) {
          flag.name = true;
        } else {
          flag.name = false;
        }
        if (flag.name) {
          auxCoApplicants.push({ ...item });
        }
      }
      calculatePages(auxCoApplicants);
    } else {
      auxCoApplicants = [...auxCoApplicantsBackUp]
      if (resetPage) {
        calculatePages(auxCoApplicants);
      }
    }
    setCoApplicants(auxCoApplicants);
  }

  // -------------------------------------------- update list ------
  const updateList = (element, type) => {
    let auxCoApplicantsBackUp = [...coApplicantsBackUp];
    if (type === 'add') {
      auxCoApplicantsBackUp.unshift(element);
    } else if (type === 'edit') {
      auxCoApplicantsBackUp = [...coApplicantsBackUp].map((item) => {
        return item.id === element.id
          ? element
          : item;
      });
    } else {
      let i = 0;
      for (const item of auxCoApplicantsBackUp) {
        if (item.id === element) {
          auxCoApplicantsBackUp.splice(i, 1);
          break;
        }
        i++;
      }
    }
    setCoApplicantsBackUp(auxCoApplicantsBackUp);
    search(form, auxCoApplicantsBackUp, type !== 'edit' ? true : false);
  }

  // -------------------------------------------- open modal
  const openModal = () => {
    setIsLoading(true);
    setForm({ name: "" });
    getCoApplicants({ type }).then(response => {
      setIsLoading(false);
      setOpen(true)
      setCoApplicants(response.data);
      setCoApplicantsBackUp(response.data);
      calculatePages(response.data);
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 404) {
          setModal({ status: 406, message: "Sorry. Element was not found" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
        }
      }
    });
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Modal open={open} closeOnEscape={false} closeOnDimmerClick={false}
        trigger={
          <Button primary onClick={openModal}>
            {type === 'coApplicant' ? 'Co-Applicants' : type === 'guarantor' ? 'Guarantors' : 'Drivers'}
          </Button>
        }>
        <Modal.Header>
          {type === 'coApplicant' ? 'Co-Applicants' : type === 'guarantor' ? 'Guarantors' : 'Drivers'}
        </Modal.Header>
        <Modal.Content >
          <Form className='search-form' style={{marginBottom: "1em"}}>
            <Form.Group>
              <Form.Input
                label={<label>Name:</label>}
                onChange={(e) => onEditInput(e.target.value, "name")}
                placeholder={'Search by Name'}
                value={form.name}
                width={8}
              />
            </Form.Group>
          </Form>
          <div className='table-wrapper' style={{overflowX: "auto"}}>
            <Table celled unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>First Name</Table.HeaderCell>
                  <Table.HeaderCell>Last Name</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  coApplicants.slice(pagination.startPage, pagination.endPage).map(item => (
                    <Table.Row>
                      <Table.Cell>{item.firstName}</Table.Cell>
                      <Table.Cell>{item.lastName}</Table.Cell>
                      <Table.Cell>{moment(item.created_at).format('LLL')}</Table.Cell>
                      <Table.Cell>
                        <CoApplicantData coApplicant={item} type={type} updateList={updateList} />
                        <DeleteCoApplicant id={item.id} updateList={updateList} />
                      </Table.Cell>
                    </Table.Row>
                  ))
                }
              </Table.Body>
            </Table>
            {
              coApplicants.length === 0 && (
                <Message>
                  <Message.Header style={{ textAlign: "center" }}>
                    You don't have
                    {type === 'driver' ? ' drivers' : type === ' guarantor' ? ' guarantos' : ' co-applicants'} created
                  </Message.Header>
                </Message>
              )
            }
          </div>
          <br />
          {
            coApplicants.length > 0 && (
              <Grid centered>
                <Pagination onPageChange={(e, data) => paginationClick(data.activePage)} size='mini'
                  activePage={pagination.currentPage} defaultActivePage={1} totalPages={pagination.totalPages}
                />
              </Grid>
            )
          }

        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => setOpen(false)} >
            Close
          </Button>
          <CoApplicantData type={type} updateList={updateList} />
        </Modal.Actions>
      </Modal>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
    </div>
  );
};