import React, { Component } from 'react';
import { Redirect, withRouter } from "react-router-dom";
import { Form, Button, Dimmer, Loader, Message, Modal, Icon} from "semantic-ui-react";
import { login } from "../../utilities/requests";

// IMPORT COMPONENTS
import AlertModal from "../../utilities/alertModal/modal";
import CodeModal from "../../utilities/codeModal/codeModal";
import ResetPassword from "../../utilities/resetPassword/resetPassword";


// IMPORT CSS STYLES
import './login.sass';

class Login extends Component{
    constructor(props){
        super(props);

        this.state = {
            email:          '',
            password:       '',
            phone_number: '',
            loading:        false,
            msnError:       {},
            user:           {},
            modal:          {},
            resetPassword:  false,
            // --------------------- show code modal -----------------------
            validCode:      false,
            msnErrors: {
                code: '',
            },
            disabled: false,
        };
    }

    parseLocation(props){
        let parsed = props.substr(1, props.length-1);
        parsed = parsed.split('&');
        parsed = parsed.filter(itm => itm.split('=').length === 2);
        let response = {};
        for (let value of parsed){
            value = value.split('=');
            if(!response.hasOwnProperty(value[0]))
                response[value[0]] = value[1];
        }
        return response;
    }

    componentDidMount() {
        const parsedProps = this.parseLocation(this.props.location.search);
        if(parsedProps.hasOwnProperty('authError') && parsedProps['authError'] === "1"){
            this.setState({modal: {
                    status: 403,
                    message: "Sorry. You don't have permissions"
            }});
        }
    }

    onEditInput = (e) => {
        let msnErrors = this.state.msnErrors;
        this.setState({[e.target.id]: e.target.value, msnErrors});
    }

    updateCode = (label, value) => {
        this.setState({[label]: value});
    }

    // -------------------------- hide modal alert in quotation proccess ----------
    hideModal = () => {
        if (this.state.modal.status == 200) {
            this.props.getUser(this.state.user, 200);
        }
        this.setState({modal: {}, disabled: false});
    }

    // -------------------------- log in -----------------------------------------
    login = (e) => {
        if (this.state.loading) return;
        e.preventDefault();
        if(!this.state.email || !this.state.password){
            this.setState({msnError: {
                message: "Please, enter your credentials"
            }})
            return;
        }
        let data = {
            email: this.state.email,
            password: this.state.password
        }
        if (this.state.validCode) {
            let msnErrors = this.state.msnErrors;
            if (this.state.code == '') {
                msnErrors.code = 'The code is required';
                return;
            }
            msnErrors.code = '';
            data['code'] = this.state.code;
            if (this.props.quotationProccess) {
                data["formula_id"] = this.props.formula.id;
                data["retailer_id"] = this.props.retailer.id;
                data["car_id"] = this.props.car.id;
                data["monthly_payment"] = this.props.monthly_payment;
                data["loan_length"] = this.props.loan_length;
                data["down_payment"] = this.props.down_payment;
                data["credit_score_range"] = this.props.credit_score;
                data["insurance_protection"] = this.props.insurance_protection;
                data["trade_in"] = this.props.trade_in == null || this.props.trade_in == '' ? 0 : this.props.trade_in;
                data["money_owed"] = this.props.money_owed == null || this.props.money_owed == '' ? 0 : this.props.money_owed;
                data["type"] = this.props.paymentType;
                if (this.props.createdQuot && this.props.createdQuot.id) {
                    data["quot_id"] = this.props.createdQuot.id;
                }
            }
        }
        this.setState({loading: true, disabled: true})
        if (this.props.prequalified) {
            this.setState({validCode: false});
        }
        login(data).then((response)=>{
            this.setState({loading: false});
            if (response.status === 202) { // send code to phone number
                this.setState({validCode: true, phone_number: response.data.phone, phone_verified_at: response.data.phone_verified_at});
                this.register(null);
            } else {
                let currentUser = JSON.stringify(response.data);
                localStorage.setItem("currentUser", currentUser);
                if (this.props.prequalified) {
                    this.props.getUser(response.data);
                } else {
                    this.props.history.push(this.redirectUser(currentUser));
                }
            }
        }).catch((error) => {
            this.setState({loading: false, disabled: false});
            if (error.response) {
                if (error.response.status === 401) {
                    this.setState({
                        loading: false,
                        msnError: {
                            message: "Invalid Login Credentials"
                        }
                    });
                } else if (error.response.status == 400) {
                    this.setState({validCode: true});
                    this.setState({loading: false, modal: { message: 'The code is invalid', status: 400 }})
                } else if (error.response.status === 422) {
                    this.setState({
                        loading: false,
                        msnError: {
                            message: "Sorry. Fields are missing to save the information"
                        }
                    });
                } else {
                    this.setState({loading: false, validCode: false, msnError: {
                        status: 500,
                        message: "Sorry. An error occurred, please try again later"
                    }})
                }
            }
        });
    }

    redirectUser = (currentUser) => {
        let appendUrl = '';
        if (this.props.location.search != '') {
            let views = new URLSearchParams(this.props.location.search);
            if (views.get("view") && views.get("id")) {
                appendUrl = views.get("view") + "/" + views.get("id")
                return appendUrl;
            }
        }
        return currentUser.role.toLowerCase();
    }
    
    render() {
        if (this.props.location.pathname == "/login" && localStorage.getItem("currentUser")) {
            const currentUser = JSON.parse(localStorage.getItem("currentUser"));
            let url = this.redirectUser(currentUser);
            return <Redirect to={url} />;
        } else if (this.props.quotationProccess && localStorage.getItem("currentUser")) {
            const currentUser = JSON.parse(localStorage.getItem("currentUser"));
            if (currentUser.role == 'Customer') {
                this.props.getUser(currentUser, 201)
            }
        }
        return(
            <div className={this.props.location.pathname != "/login" ? "login" : "login login-background" }>
                <div className="login-content">
                    {
                        !this.state.resetPassword ?
                            <div className="login-body">
                                <h2 className="title">Log in</h2>
                                {
                                    this.state.msnError.message ?
                                        <Message error visible header='Login Failed' content={this.state.msnError.message} />
                                    :null
                                }
                                <Form>
                                    <Form.Input className="form-login"
                                        id={'email'}
                                        icon={'user'}
                                        iconPosition={'left'}
                                        type={'text'}
                                        placeholder={'Enter Email or Phone Number'}
                                        onChange={this.onEditInput}
                                        required={true}
                                        disabled={this.state.validCode}
                                        value={this.state.email}
                                    />
                                    <Form.Input className="form-login"
                                        id={'password'}
                                        icon={'lock'}
                                        iconPosition={'left'}
                                        type={'password'}
                                        placeholder={'Enter Password'}
                                        onChange={this.onEditInput}
                                        required={true}
                                        disabled={this.state.validCode}
                                        value={this.state.password}
                                    />
                                    <p className="p-forgot" onClick={() => this.setState({resetPassword: true})}>Forgot Password?</p>
                                    {
                                        this.props.prequalified && (
                                            <Button className="btn-continue" onClick={this.props.back}>
                                                <h3>Sign Up</h3>
                                            </Button>
                                        )
                                    }
                                    <Button className="btn-continue" disabled={this.state.disabled}
                                        onClick={(e) => this.login(e)}
                                    ><h3>Log In</h3></Button>
                                </Form>
                            </div>
                        :
                            <ResetPassword returnLogin={() => this.setState({resetPassword: false, msnError: {}})}/>
                    }
                </div>
                {
                    this.state.modal.message ?
                    <AlertModal hideModal={this.hideModal} modal={this.state.modal}/>
                    :null
                }
                {
                    this.state.validCode ?
                        <CodeModal email={this.state.email} password={this.state.password} phone={this.state.phone_number} phone_verified_at={this.state.phone_verified_at} updateCode={this.updateCode} action={this.login} hideModalCode={() => this.setState({validCode: false, disabled: false})} disabled={this.state.disabled}/>
                    :null
                }
                {
                    this.state.loading ?
                        <Dimmer className="loading" active>
                            <Loader />
                        </Dimmer>
                    :null
                }
            </div>
        )
    }
}


export default withRouter(Login);