import React, { useEffect, useState } from 'react';
import { Form, Grid } from 'semantic-ui-react';

const saleSatatusOptions = [
  { value: "used", text: "Pre-owned" },
  { value: "new", text: "New" },
]
const certifiedOptions = [
  { value: "yes", text: "Yes" },
  { value: "no", text: "No" },
]

export const BasicData = (props) => {
  const [car, setCar] = useState(props.car);
  const [errors, setErrors] = useState({})

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...car };
    auxForm[label] = value;
    setCar({ ...auxForm });
    props.updateCar(auxForm);
    validation(auxForm);
  }

  const validation = (auxForm) => {
    let auxErrors = { ...errors };
    for (const key in auxForm) {
      if (auxForm.hasOwnProperty.call(auxForm, key)) {
        const element = auxForm[key];
        auxErrors = validateForm(element, key, auxErrors);
      }
    }
    setErrors({ ...auxErrors });
    props.setDisabled(false);
    for (const key in auxErrors) {
      if (auxErrors.hasOwnProperty.call(auxErrors, key)) {
        const element = auxErrors[key];
        if (element != null) {
          props.setDisabled(true);
          break;
        }
      }
    }
  }

  const validateForm = (value, label, errors) => {
    switch (label) {
      case 'msrp':
      case 'cost':
      case 'price':
      case 'adjusted_price':
        errors[label] = !value || isNaN(value) ? "The field must be a number" : null;
        break;
      default:
        break;
    }
    return errors;
  }

  useEffect(() => {
    validation(props.car);
  }, [])

  return (
    <Grid>
      <Grid.Column width={16}>
        <Form>
          <Form.Group>
            <Form.Input
              label={<label>VIN:</label>}
              value={car.vin}
              width={6}
              disabled
            />
            <Form.Input
              label={<label>STOCK #:</label>}
              value={car.stock_number}
              width={4}
              disabled
            />
            <Form.Input
              label={<label>Mfg Code:</label>}
              value={car.mfgcode}
              width={4}
              disabled
            />
            <Form.Input
              label={<label>Year:</label>}
              value={car.year}
              width={5}
              disabled
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label={<label>MAKE:</label>}
              value={car.make}
              width={5}
              disabled
            />
            <Form.Input
              label={<label>Model:</label>}
              value={car.model}
              width={5}
              disabled
            />
            <Form.Input
              label={<label>TRIM LEVEL:</label>}
              value={car.trim}
              width={6}
              disabled
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label={<label>MSRP Price($):</label>}
              onChange={(e) => onEditInput(e.target.value, "msrp")}
              placeholder={'MSRP Price'}
              value={car.msrp}
              width={4}
              error={car.msrp || errors.msrp ? errors.msrp : null}
            />
            <Form.Input
              label={<label>Price($):<span className="required-inut"> *</span></label>}
              onChange={(e) => onEditInput(e.target.value, "price")}
              placeholder={'Price'}
              value={car.price}
              width={4}
              error={car.price || errors.price ? errors.price : null}
            />
            <Form.Input
              label={<label>Cost($):<span className="required-inut"> *</span></label>}
              onChange={(e) => onEditInput(e.target.value, "cost")}
              placeholder={'Cost'}
              value={car.cost}
              width={4}
              error={car.cost || errors.cost ? errors.cost : null}
            />
            <Form.Input
              label={<label>Adjusted Price($):<span className="required-inut"> *</span></label>}
              onChange={(e) => onEditInput(e.target.value, "adjusted_price")}
              placeholder={'Adjusted Pric'}
              value={car.adjusted_price}
              width={4}
              error={car.adjusted_price || errors.adjusted_price ? errors.adjusted_price : null}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label={<label>Engine:</label>}
              value={car.engine}
              width={5}
              disabled
            />
            <Form.Input
              label={<label>MILES:</label>}
              value={car.miles}
              width={5}
              disabled
            />
            <Form.Input
              label={<label>Transmission:</label>}
              value={car.transmission}
              width={6}
              disabled
            />
          </Form.Group>
          <Form.Group>
            <Form.TextArea
              label={<label>Body Style:</label>}
              value={car.bodystyle}
              width={8}
              disabled
            />
            <Form.Input
              label={<label>Doors:</label>}
              value={car.doors}
              width={6}
              disabled
            />
            <Form.Input
              label={<label>Cylinders:</label>}
              value={car.cylinders}
              width={6}
              disabled
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label={<label>Interior Color:</label>}
              value={car.interior_color}
              width={5}
              disabled
            />
            <Form.Input
              label={<label>Interior Color Code:</label>}
              value={car.interior_color_code}
              width={5}
              disabled
            />
            <Form.Input
              label={<label>Exterior Color:</label>}
              value={car.exterior_color}
              width={5}
              disabled
            />
            <Form.Input
              label={<label>Exterior Color Code:</label>}
              value={car.exterior_color_code}
              width={5}
              disabled
            />
          </Form.Group>
          <Form.Group>
            <Form.Select
              label={<label>Condition:</label>}
              value={car.condition}
              options={saleSatatusOptions}
              width={8}
              disabled
            />
            <Form.Select
              label={<label>Certified:</label>}
              value={car.certified}
              options={certifiedOptions}
              width={8}
              disabled
            />
          </Form.Group>
        </Form>
      </Grid.Column>
    </Grid>
  );
};