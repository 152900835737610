// @flow 
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Dimmer, Grid, Header, Loader, Message, Pagination, Table } from 'semantic-ui-react';
import ModalComponent from '../alertModal/modal';
import { getCreditRequests } from '../requests';
import { CreditSolutionFilter } from './CreditSolutionFilter';
import { CreditSolutionReport } from './CreditSolutionReport';

import "./CreditSolution.sass";

export const CreditSolutionRequests = ({ retailer, adminSection, callBackError }) => {
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState({ data: [], last_page: 0, total: 0 });
  const [defaultFilter, setDefaultFilter] = useState({score: [0, 2000], option: "", key: ''});
  const [page, setPage] = useState(1)

  // --------------------- hide alert modal
  const hideModal = () => {
    console.log(modal);
    if (modal.status === 500 && adminSection) {
      callBackError();
    }
    setModal({});
  }

  const getList = (page = 1, filter = defaultFilter) => {
    const params = {
      admin: adminSection,
      id: retailer?.id,
      page,
      ...(filter.option !== 'range' ?
        { date: filter.option }
        : filter.startDate && filter.startDate &&{
          startDate: moment(filter.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
          endDate: moment(filter.endDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
        }
      ),
      startScore: filter.score[0],
      endScore: filter.score[1],
      key: filter.key
    }
    setDefaultFilter(filter);
    setIsLoading(true);
    getCreditRequests(params).then(response => {
      setIsLoading(false);
      if (response.data.data) {
        setRequests(response.data);
        setPage(page);
      }
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response);
        setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
      }
    });
  }

  // ---------------------------- open modal -----------------------------------------
  useEffect(() => {
    getList();
  }, [])


  return (
    <div className={'body credit-solution'} style={{display: requests.data.length > 9 ? 'grid' : 'block'}}>
      <Breadcrumb size={'large'} >
        <Breadcrumb.Section active>Credit Score Requests</Breadcrumb.Section>
      </Breadcrumb>
      <CreditSolutionFilter filter={(form) => getList(1, form)} />
      <Header size='medium'>{requests.total + " requests listed"}</Header>
      <div className='table-wrapper'>
        {
          requests.data.length > 0 ?
            <Table className={'customTable'} celled unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Address</Table.HeaderCell>
                  <Table.HeaderCell>State</Table.HeaderCell>
                  <Table.HeaderCell>City</Table.HeaderCell>
                  <Table.HeaderCell>Zip Code</Table.HeaderCell>
                  <Table.HeaderCell>Score</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  requests.data.map(item => (
                    <Table.Row>
                      <Table.Cell>{item.name}</Table.Cell>
                      <Table.Cell>{item.address}</Table.Cell>
                      <Table.Cell>{item.state}</Table.Cell>
                      <Table.Cell>{item.city}</Table.Cell>
                      <Table.Cell>{item.zip_code}</Table.Cell>
                      <Table.Cell>{item.score}</Table.Cell>
                      <Table.Cell>{moment(item.created_at).format('LLL')}</Table.Cell>
                      <Table.Cell><CreditSolutionReport request={item}/></Table.Cell>
                    </Table.Row>
                  ))
                }
              </Table.Body>
            </Table>
            :
            <Message style={{ textAlign: 'center', margin: "1em 0" }}>
              <Message.Header style={{ textAlign: "center" }}>There are not requests</Message.Header>
            </Message>
        }
      </div>
      {
        requests.data.length > 0 && (
          <div style={{ textAlign: 'center', margin: "1em 0" }}>
            <Pagination defaultActivePage={1} activePage={page} totalPages={requests.last_page} onPageChange={(e, { activePage }) => getList(activePage)} />
          </div>
        )
      }
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={hideModal} />
      }
    </div>
  );
};