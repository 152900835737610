import moment from 'moment';
import React, { useState } from 'react';
import { DateInput, YearInput } from 'semantic-ui-calendar-react';
import { Form, Grid } from 'semantic-ui-react';

export const FilterSection = (props) => {
  const { options } = props;
  const [form, setForm] = useState({ year: "", make: "", status: "", condition: "", stock_date_1: "", stock_date_2: "", keyword: "" })

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    props.search(auxForm);
    setForm({ ...auxForm });
  }

  return (
    <Form className='search-form'>
      <Form.Group>
        <YearInput
          maxDate={moment().add(2, 'years')}
          label={<label>Year:</label>}
          placeholder={'Year'}
          onChange={(event, { name, value }) => {
            onEditInput(value, 'year');
          }}
          value={form.year}
          width={5}
          popupPosition={"right center"}
          animation='none'
          clearable
        />
        <Form.Select
          label={<label>Make:</label>}
          onChange={(e, { value }) => onEditInput(value, "make")}
          placeholder={'Make'}
          options={options.make}
          value={form.make}
          width={5}
          clearable
        />
        <Form.Select
          label={<label>Model:</label>}
          onChange={(e, { value }) => onEditInput(value, "model")}
          placeholder={'Model'}
          options={options.model}
          value={form.model}
          width={5}
          clearable
        />
      </Form.Group>
      <Form.Group>
        <Form.Select
          label={<label>Vehicle Condition:</label>}
          onChange={(e, { value }) => onEditInput(value, "condition")}
          placeholder={'Vehicle Condition'}
          options={options.condition}
          value={form.condition}
          width={5}
          clearable
        />
        <Form.Field width={5}>
          <label>Date:</label>
          <Grid>
            <Grid.Column width={8} style={{ padding: 0 }}>
              <DateInput
                dateFormat="MM-DD-YYYY"
                onChange={(event, { name, value }) => {
                  onEditInput(value, 'stock_date_1');
                }}
                value={form.stock_date_1}
                popupPosition={"right center"}
                animation='none'
                clearable
              />
            </Grid.Column>
            <Grid.Column width={8} style={{ padding: 0 }}>
              <DateInput
                dateFormat="MM-DD-YYYY"
                onChange={(event, { name, value }) => {
                  onEditInput(value, 'stock_date_2');
                }}
                value={form.stock_date_2}
                popupPosition={"right center"}
                animation='none'
                clearable
              />
            </Grid.Column>
          </Grid>
        </Form.Field>
        <Form.Input
          label={<label>Keyword:</label>}
          onChange={(e) => onEditInput(e.target.value, "keyword")}
          placeholder={'Keyword'}
          value={form.keyword }
          width={5}
          clearable
        />
      </Form.Group>
    </Form>
  );
};