import React from 'react';

export const Footer = ({ page }) => {
  return (
    <div className='footer'>
      <p><small>Copyright 2019 US Equity Advantage, Inc. All Rights Reserved. Revised 12/19</small></p>
      <p className='page'>(Page {page} of 2)</p>
      <p className='contact'>
        800 N. Magnolia Avenue, Suite 1275, Orlando, Florida 32803 - Toll Free: (866) 600-2400
        Fax: (407) 897-8553 or (800) 409-4799 - Email: agreements@usequiadvantage.com
      </p>
    </div>
  );
};