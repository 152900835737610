import React from 'react';
import { Header } from 'semantic-ui-react';

import "./FrequentlyAskedQuestions.sass"

export const FrequentlyAskedQuestions = ({ plan, step }) => {
  return (
    <div className='asked-questions'>
      {
        (step === 3) && (
          <div id="page4">
            <Header as="h2" className='title'>CUSTOMER FREQUENTLY ASKED QUESTONS</Header>
            <Header as="h3" className='ask'>Is there any upfront cost to the customer to enroll in AutoPayPlus?</Header>
            <p>
              There is no upfront cost to the customer. The enrollment fee is up to $399, which is automatically deducted
              from the first payment to principal, so there are no out-of-pocket costs. Other fees include a $2.45 bank
              account verification fee and a $2.45 ACH/debit fee for each withdrawal.
            </p>
            <Header as="h3" className='ask'>Is there a cancellation fee to the customer if they decide not to participate in AutoPayPlus?</Header>
            <p>
              We do not charge any cancellation fees. However, if your enrollment fee has already been deducted from your
              account (this happens at the first extra payment of the calendar year), we cannot refund it. Still, you can always
              add another household loan to continue taking advantage of program benefits.
            </p>
            <Header as="h3" className='ask'>What is the primary reason customers enroll with AutoPayPlus?</Header>
            <p>
              Customers tell us the no. 1 reason they enroll in AutoPayPlus is for budgeting and convenience.
            </p>
            <Header as="h3" className='ask'>Are there any benefits for a customer who has a 0% APR?</Header>
            <p>
              Yes. They will enjoy a better equity position and pay off their loan early. Even at 0%, a long-term loan with little
              down payment often results in customers being upside down for several years. Keep in mind that there is no
              interest savings to offset the fees. We also offer select credit and vehicle services backed by national brand
              partners.
            </p>
            <Header as="h3" className='ask'>Are there any benefits for a customer who has a lease to enroll in AutoPayPlus?</Header>
            <p>
              Yes. There may be two benefits for leases. One is the lease will be paid in full approximately 10% sooner
              (usually 2 or 3 months). Second, for those that drive excess miles, it may give them the option to drop the
              lease off early to avoid a few months of expensive excess mileage charges. Be aware, leases don't have
              interest, so there is no interest savings to offset the fees and the fees could exceed any excess mileage savings.
              We also offer select credit and vehicle services backed by national brand partners.
            </p>
            <Header as="h3" className='ask'>How does paying a loan biweekly instead of monthly save the customer money?</Header>
            <p>
              We debit the account for 1/2 of the monthly loan payment every two weeks which gives you the equivalent of
              13 full payments by the end of a year. The extra payment is applied to principal and creates potential interest
              savings and shortens the term of the loan and possibly builds equity sooner.
            </p>
            <Header as="h3" className='ask'>Can a customer set up bimonthly or monthly debits instead of biweekly?</Header>
            <p>
              Yes. If the customer chooses bimonthly or monthly debits AutoPayPlus will customize the debit amount to
              achieve the acceleration benefits of the program by adding a small amount to their debits. If the customer
              chooses not to accelerate payoff, APP will waive the $399 enrollment fee as there will be no financial benefit for
              the customer.
            </p>
          </div>
        )
      }
      {
        (step === 4) && (
          <div id="page5">
            <Header as="h3" className='ask'>When a customer pays off their loan or trades in their vehicle does AutoPayPlus automatically stop
              future debits?</Header>
            <p>
              No. If the customer trades-in or sells their car, they must notify APP immediately to stop debits.
            </p>
            <Header as="h3" className='ask'>Once a customer is enrolled with AutoPayPlus can they change their debit schedule?</Header>
            <p>
              Yes. AutoPayPlus requires 5 business days notice before a scheduled debit to make any changes.
            </p>
            <Header as="h3" className='ask'>If a customer wants to enroll additional loans in AutoPayPlus will they need to pay a $399
              enrollment fee for each loan?</Header>
            <p>
              New customers can add loans during the 6-month open enrollment period and only pay the ACH/debit fee for
              student loans, credit cards, mortgages and more.
            </p>
            <Header as="h3" className='ask'>Can a customer achieve the same benefits that AutoPayPlus provides on their own?</Header>
            <p>
              Yes, and no. Some of the pitfalls a customer may encounter are they may have to call the lender each time they
              make a payment to principal to ensure the payment is posted properly; the lender may not be able to process
              partial payments; and the lender may just apply any additional payments towards paying the account ahead
              instead of principal only which would not affect any interest savings and improved equity. The value
              AutoPayPlus bring is that we automate these laborious processes for the customer. Assuming a lender will
              accept partial payments, a customer can achieve the same results and save the fees paying biweekly on 0%
              loans and leases since there is no need for principal only payments.
            </p>
            <Header as="h3" className='ask'>
              If a customer is going to pay off their loan 4 months early, would the savings to the customer equal
              their monthly payment multiplied by 4?
            </Header>
            <p>
              No. Never try to calculate savings manually. We have a benefits tool on the portal that will calculate any savings
              to the customer correctly including, term reduction, improved equity and any interest savings. NEVER overstate
              savings, always utilize the benefits tool located on the portal.
            </p>
            <Header as="h3" className='ask'>
              How many payments will be reflected on the customer's loan statement they receive each month
              from their lender.
            </Header>
            <p>
              Generally, one. A couple of times a year they will likely see one and a half payments posted.
            </p>
            <Header as="h3" className='ask'>Where is the customer's money held before it is sent to the lender?</Header>
            <p>
              In a non-interest-bearing account at an accredited bank chosen by AutoPayPlus.
            </p>
            <div className='footer'>
              <p><strong>FOR MORE INFORMATION CONCERNING AUTOPAYPLUS VISIT:</strong> <a href="https://autopayplus.com/" target="_blank" rel="noopener noreferrer">www.autopayplus.com</a></p>
              <p>Or contact Dealer Services at (866) 600-2400 ext. 282 or <a href="mailto: dealerservices@usequityadvantage.com">dealerservices@usequityadvantage.com</a></p>
            </div>
          </div>
        )
      }
    </div>
  );
};