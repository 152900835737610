import moment from 'moment';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { FormatNumberToPrice } from '../../../../helpers/general';

export const LoanInformation = ({ plan, amortization }) => {
  console.log(plan, amortization);
  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3' textAlign='center'>
              LOAN INFORMATION
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row >
            <Table.Cell>
              Lender to be Paid:
            </Table.Cell>
            <Table.Cell>
              {plan.lender.name}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Lender Address:
            </Table.Cell>
            <Table.Cell>
              {plan.lender.address}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Loan Account Number:
            </Table.Cell>
            <Table.Cell>
              {plan.lender.accountNumber}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Amount Financed:
            </Table.Cell>
            <Table.Cell>
              {FormatNumberToPrice(amortization?.total?.sum_of_installments || 0)}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Loan Term (in months):
            </Table.Cell>
            <Table.Cell>
              {plan.loanTerm}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Rate:
            </Table.Cell>
            <Table.Cell>
              {plan.rate}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Monthly Payment Amount:
            </Table.Cell>
            <Table.Cell>
              {plan.loanMonthlyPayment}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Date of 1 Loan Payment:
            </Table.Cell>
            <Table.Cell>
              {plan.firstDate}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};