import React from "react"
import { Button, Grid, Icon, Popup } from "semantic-ui-react"
import Payments from "../../customer/quotation/payments/Payments"
import { returnInfoPayments } from "../../helpers/general"

const PaymentMethods = (props) => (
  <Grid.Column mobile={16} tablet={16} computer={5} className={props.paymentType === props.type ? 'borderFinal' : ''}>
    <Payments quots={props.quots} paymentType={props.type} onlyPayments quoteDetail />
    <Button className={props.paymentType === props.type ? 'btn-payment' : 'btn-option'} onClick={(e) => props.changeMethod(props.type)}>
      <h3>Select To Pay {props.type === 'monthly' ? 'Monthly' : props.type === 'weekly' ? 'Weekly' : 'Weekly+' }
        <Popup
          content={returnInfoPayments(props.type)}
          trigger={<Icon link name="info circle" style={{ marginLeft: "10px" }} />}
          position='top right'
        />
      </h3>
    </Button>
  </Grid.Column>
)

export default PaymentMethods