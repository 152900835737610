export const getCountry = (addressArray) => {
  let area = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0]) {
      for (let j = 0; j < addressArray[i].types.length; j++) {
        if ('country' === addressArray[i].types[j]) {
          area = addressArray[i].long_name;
          return area;
        }
      }
    }
  }
};

export const getState = (addressArray) => {
  let state = '';
  for (let i = 0; i < addressArray.length; i++) {
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
        state = addressArray[i].long_name;
        return state;
      }
    }
  }
};

export const getCity = (addressArray) => {
  let city = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
      city = addressArray[i].long_name;
      return city;
    }
  }
};

export const getPostalCode = (addressArray) => {
  let postalCode = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && "postal_code" === addressArray[i].types[0]) {
      postalCode = addressArray[i].long_name;
      return postalCode;
    }
  }
};

export const getNumber = (addressArray) => {
  let result = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && "street_number" === addressArray[i].types[0]) {
      result = addressArray[i].long_name;
      break;
    }
  }
  return result;
};

export const getStreet = (addressArray) => {
  let result = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
      result = addressArray[i].long_name;
      break;
    }
  }
  return result;
};

export const getNeighborood = (addressArray) => {
  let result = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && "sublocality_level_1" === addressArray[i].types[0]) {
      result = addressArray[i].long_name;
      break;
    }
  }
  return result;
};

// ----------------------- get google ubication ------------------------
export const getUbication = (entity) => {
  let location = '';
  if ((entity.locationCity && entity.locationCity != '') && (entity.locationState && entity.locationState != '') && (entity.locationCountry != '' && entity.locationCountry != '')) {
    location = entity.locationCity + ', ' + entity.locationState + ', ' + entity.locationCountry;
  } else if ((entity.locationCity == null || entity.locationCity == '') && (entity.locationState != null && entity.locationState != '') && (entity.locationCountry != null && entity.locationCountry != '')) {
    location = entity.locationState + ', ' + entity.locationCountry;
  } else if ((!entity.locationCity || entity.locationCity == '') && (!entity.locationState || entity.locationState == '') && entity.locationCountry && entity.locationCountry != '') {
    location = entity.locationCountry;
  }
  return location;
}