import React, { useState } from 'react';
import { Button, Dimmer, Form, Icon, Loader, Modal } from 'semantic-ui-react';
import { verifyFundingAccount } from '../requests';
import ModalComponent from '../alertModal/modal';

const ValidateAccount = (props) => {
  const [form, setForm] = useState({ amount1: "", amount2: "" });
  const [errors, setErrors] = useState({ amount1: null, amount2: null });
  const [disabled, setDisabled] = useState(true);
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    let auxErrors = { ...errors };
    auxErrors = validateForm(value, label, auxErrors);
    setErrors({ ...auxErrors });
    setForm({ ...auxForm });
    for (const key in auxForm) {
      if (auxForm.hasOwnProperty.call(auxForm, key)) {
        const element = auxForm[key];
        auxErrors = validateForm(element, key, auxErrors);
      }
    }
    setDisabled(false);
    for (const key in auxErrors) {
      if (auxErrors.hasOwnProperty.call(auxErrors, key)) {
        const element = auxErrors[key];
        if (element != null) {
          setDisabled(true);
        }
      }
    }
  }

  const validateForm = (value, label, errors) => {
    switch (label) {
      case 'amount1':
        errors[label] = !value || isNaN(value) ? "The field must be a numeric" : null;
        break;
      case 'amount2':
        errors[label] = !value || isNaN(value) ? "The field must be a numeric" : null;
        break;
      default:
        break;
    }
    return errors;
  }

  // -------------------------- sen data to backend
  const sendData = () => {
    setIsLoading(true);
    setDisabled(true);
    console.log(form);
    verifyFundingAccount(form).then(response => {
      setDisabled(false);
      setIsLoading(false);
      setModal({ status: 202, message: "Funding source account was validated", account: response.data });
    }).catch(error => {
      setDisabled(false);
      setIsLoading(false);
      console.log(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else {
          let msn = "Please verify your information or try again later";
          if (error.response.data.message) {
            const message = error.response.data.message;
            if (message.includes('400')) {
              msn = 'InvalidAmount, “Wrong amount(s).”'
            } else if (message.includes('403')) {
              msn = 'Bank already verified.';
            } else if (message.includes('404')) {
              msn = 'Funding source account not found.';
            } else if (message.includes('202')) {
              msn = 'Micro-deposits have not have not settled to destination bank. A Customer can verify these amounts after micro-deposits have processed to their bank.';
            }
          }
          setModal({ status: 500, message: "Sorry. An error occurred. " + msn });
        }
      }
    });
  }

  // ---------------------------- hide modal -----------------------------------
  const hideModal = () => {
    if (modal.status === 202) {
      props.closeModal(modal.account);
    }
    setModal({ modal: {} });
  }

  return (
    <Modal size="small" open={true} closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Content>
        <Form>
          <Form.Input
            label={<label>Amount 1:<span className="required-inut"> *</span></label>}
            onChange={(e) => onEditInput(e.target.value, "amount1")}
            placeholder={'Amount 1'}
            width={16}
            maxLength={50}
            value={form.amount1}
            error={form.amount1.length > 0 || errors.amount1 ? errors.amount1 : null}
          />
          <Form.Input
            label={<label>Amount 2:<span className="required-inut"> *</span></label>}
            onChange={(e) => onEditInput(e.target.value, "amount2")}
            placeholder={'Amount 2'}
            width={16}
            value={form.amount2}
            error={form.amount2.length > 0 || errors.amount2 ? errors.amount2 : null}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => props.closeModal(null)} >
          Cancel
        </Button>
        <Button color='green' onClick={sendData} disabled={disabled}>
          <Icon name='checkmark' /> Accept
        </Button>
      </Modal.Actions>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent hideModal={hideModal} modal={modal} />
      }
    </Modal>
  );
}

export default ValidateAccount;