import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";

const ProtectedRoute = ({ children, roles, ...rest }) => {
  return (
    <Route {...rest} render={({ location }) =>
      {
        if (localStorage.getItem("currentUser")) {
            const currentUser = JSON.parse(localStorage.getItem("currentUser"));
            if (currentUser.role != roles) {
              return <Redirect to={{pathname: "/" + currentUser.role.toLowerCase(),state: { from: location }}}/>
            }
            return children
          } else {
            return <Redirect to={{pathname: "/login",state: { from: location }}}/>
          }
        } 
      }
    />
  );
};
export default withRouter(ProtectedRoute);