import React, { useEffect, useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Payments from '../payments/Payments';
import SimilarCar from './SimilarCar';
import { calculatePayments } from '../../../helpers/general';

import "./cars.sass"

const Cars = (props) => {
  const { loan_length, formula, down_payment, trade_in, insurance_protection, interestRage, credit_score, money_owed } = props;
  const [paymentCars, setPaymentCars] = useState(false);
  const [open, setOpen] = useState(false);
  const [cars, setCars] = useState({});

  // ------------------------------------------- show detail of payment in good, better and best car --------------------
  const showPayments = (type) => {
    let auxPaymentCars = paymentCars;
    auxPaymentCars = type == auxPaymentCars ? false : type;
    console.log(cars[type]);
    setPaymentCars(auxPaymentCars);
    if (auxPaymentCars) {
      setTimeout(() => {
        document.getElementById("detailPayments").scrollIntoView();
      }, 500);
    }
  }

  // --------------------------- reload payments value when slider change for similar cars --------------------------------------------
  const searchCars = () => {
    let auxCars = { ...props.cars };
    if (auxCars.good_car) {
      auxCars.good_car['quot'] = calculatePayments(auxCars.good_car, loan_length, down_payment, formula, interestRage[credit_score], insurance_protection, trade_in, money_owed);
    }
    if (auxCars.better_car) {
      auxCars.better_car['quot'] = calculatePayments(auxCars.better_car, loan_length, down_payment, formula, interestRage[credit_score], insurance_protection, trade_in, money_owed);
    }
    if (auxCars.best_car) {
      auxCars.best_car['quot'] = calculatePayments(auxCars.best_car, loan_length, down_payment, formula, interestRage[credit_score], insurance_protection, trade_in, money_owed);
    }
    setCars({ ...auxCars });
  }

  // --------------------------------------- select car
  const selectCar = (payment, plan, car) => {
    console.log(car, payment, plan);
    props.carContinue(car, payment, plan);
  }

  useEffect(() => {
    searchCars(props.cars);
  }, [props])

  const validateCar = (car) => {
    if (cars[car] && cars[car].quot) {
      return <SimilarCar similarCar={cars[car]} carContinue={props.carContinue} carType={car} paymentCars={paymentCars} formula={props.formula} interestRage={props.interestRage}
        credit_score={props.credit_score} showPayments={(type) => showPayments(type)} />
    }
  }

  return (
    <Grid className="similarCars">
      <Grid.Column mobile={16} tablet={16} computer={16}>
        <div className='btn-collapse'>
          <Button className="btn-continue" onClick={() => setOpen(open => !open)}>
            <h3>
              Reveal Good, Better, Best Vehicle Options {open ? "-" : "+"}
            </h3>
          </Button>
        </div>
        {
          open && (
            cars.good_car || cars.better_car || cars.best_car ?
              <div>
                <Grid.Column mobile={16} tablet={16} computer={16} className="ui-computer">
                  <Grid style={{ display: "flex", justifyContent: "center" }}>
                    {validateCar('good_car')}
                    {validateCar('better_car')}
                    {validateCar('best_car')}
                  </Grid>
                </Grid.Column>
                <Carousel useKeyboardArrows autoPlay={false} infiniteLoop={true} showIndicators={false} showThumbs={false} swipeable={true} className={'generalCarousel ui-mobile'} showStatus={false}  >
                    {validateCar('good_car')}
                    {validateCar('better_car')}
                    {validateCar('best_car')}
                </Carousel>
              </div>
              :
              <div className="no-results">
                <h3>Sorry, We couldn't find any results</h3>
              </div>
          )
        }
        {
          paymentCars ?
            <div className="ui-computer" id="detailPayments">
              <Payments quots={cars[paymentCars].quot} step={props.step} paymentCars={paymentCars} selectedPayment={selectCar} car={cars[paymentCars]} />
            </div>
            : null
        }
      </Grid.Column>
      {/* <Footer finalQuots={props.finalQuots} /> */}
    </Grid>
  );
}

export default Cars;