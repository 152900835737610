import React from "react";
import { Button, Icon } from "semantic-ui-react";

// IMPORT CSS STYLES
import './headerQuote.sass'

const HeaderQuote = (props) => (
  <div className="header-chroma">
    <div className="icon-close">
      {
        (props.step > -1 && !props.fromLanding) || (props.step > 0 && props.fromLanding)?
          <div className="icon-grapper">
            <Icon onClick={(e) => props.onChangeState()} link name="angle left" />
          </div>
          : null
      }
    </div>
    <div className="images-grapper">
      <div className="logo">
        <div>
          <img src={require("../../../../images/MyPayPow.png")} />
        </div>
      </div>
      <div className="logo-retailer" >
        <div>
          <img src={props.retailer.logo} />
        </div>
      </div>
    </div>
  </div>
);

export default HeaderQuote;
