// @flow 
import React from 'react';
import { Step } from 'semantic-ui-react';

export const LoanHeader = ({step}) => {
  return (
    <div className='ui-computer'>
      <Step.Group style={{ width: "100%", marginBottom: "1em" }} >
        <Step active={step === 0}>
          <Step.Content>
            <Step.Title>Loan Amount</Step.Title>
          </Step.Content>
        </Step>
        <Step active={step === 1}>
          <Step.Content>
            <Step.Title>Loan Date</Step.Title>
          </Step.Content>
        </Step>
        <Step active={step === 2}>
          <Step.Content>
            <Step.Title>Lender Account</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    </div>
  );
};