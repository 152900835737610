import moment from 'moment';
import React, { useState } from 'react';
import { Button, Dimmer, Grid, Icon, Loader, Message, Modal, Pagination, Table } from 'semantic-ui-react';
import ModalComponent from '../../alertModal/modal';
import { getPolls } from '../../requests';
import { PollDelete } from './PollDelete';
import { PollFilterSection } from './PollFilterSection';
import { PollingManager } from './PollingManager';
import { UpdateInventory } from './UpdateInventory';
import "./Polls.sass";

export const Polls = ({retailers, inventoryRetailer, updateInventoryCallBack}) => {
  const sizePagination = 8;
  const [pagination, setPagination] = useState({ totalPages: 0, currentPage: 1, startPage: 0, endPage: sizePagination })
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [polls, setPolls] = useState([]);
  const [pollsBackUp, setPollsBackUp] = useState([]);
  const [form, setForm] = useState({ retailer: "", keyword: "" })

  // ------------------ get page -------------------------
  const paginationClick = (currentPage, totalPages) => {
    let startPage = (currentPage - 1) * sizePagination;
    let endPage = startPage + sizePagination;
    let auxPagination = { ...pagination };
    auxPagination.currentPage = currentPage;
    auxPagination.startPage = startPage;
    auxPagination.endPage = endPage;
    if (totalPages) {
      auxPagination.totalPages = totalPages;
    }
    setPagination({ ...auxPagination });
  }

  const calculatePages = (array) => {
    let totalPages = Math.ceil(array.length / sizePagination);
    paginationClick(1, totalPages);
  }

  // ------------------------------- search method --------------------------
  const search = (form, auxPollsBackUp = pollsBackUp, resetPage = true) => {
    setForm(form);
    let auxPolls = [];
    if (form.retailer || form.keyword) {
      for (const item of auxPollsBackUp) {
        let flag = { retailer: true, keyword: true };
        console.log(item.retailer_id, form.retailer);
        flag.retailer = !form.retailer ? true : item.retailer_id === form.retailer ? true : false;
        if (form.keyword) {
          let key = form.keyword.toLowerCase();
          if (item.name.toLowerCase().includes(key) || item.retailer.name.toLowerCase().includes(key)) {
            flag.keyword = true;
          } else {
            flag.keyword = false;
          }
        }
        if (flag.retailer && flag.keyword) {
          auxPolls.push({ ...item });
        }
      }
      calculatePages(auxPolls);
    } else {
      auxPolls = [...auxPollsBackUp]
      if (resetPage) {
        calculatePages(auxPolls);
      }
    }
    setPolls(auxPolls);
  }

  // ---------------------------- update polls from props ----------------------------
  const updatePolls = (pollsBackup, type) => {
    setPollsBackUp(pollsBackup);
    search(form, pollsBackup, type !== 'edit' ? true : false);
  }

  // ---------------------------- open modal -----------------------------------------
  const openModal = () => {
    setIsLoading(true);
    getPolls().then(response => {
      setIsLoading(false);
      console.log(response);
      setOpen(true);
      setPolls(response.data);
      setPollsBackUp(response.data);
      calculatePages(response.data);
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
      if (error.response) {
        console.log(error.response);
        setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
      }
    });
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Button primary onClick={openModal}>Polling Manager</Button>
      <Modal className='polls' size="large" open={open} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>
          Polling Manager
        </Modal.Header>
        <Modal.Content>
          <PollFilterSection retailers={retailers} pollsBackUp={pollsBackUp} search={search}/>
          <div className='table-wrapper'>
            <Table celled unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Retailer</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  polls.slice(pagination.startPage, pagination.endPage).map(item => (
                    <Table.Row>
                      <Table.Cell>{item.name}</Table.Cell>
                      <Table.Cell>{item.retailer.name}</Table.Cell>
                      <Table.Cell>{moment(item.created_at).format('LLL')}</Table.Cell>
                      <Table.Cell>
                        <PollDelete updatePolls={updatePolls} polls={pollsBackUp} poll={item}/>
                        <PollingManager retailers={retailers} updatePolls={updatePolls} polls={pollsBackUp} type="edit" poll={item} />
                        <PollingManager retailers={retailers} updatePolls={updatePolls} polls={pollsBackUp} type="clone" poll={item} />
                        <UpdateInventory retailer={item.retailer} inventoryRetailer={inventoryRetailer} polls={pollsBackUp} poll={item} updatePolls={updatePolls} updateInventoryCallBack={updateInventoryCallBack} />
                      </Table.Cell>
                    </Table.Row>
                  ))
                }
              </Table.Body>
            </Table>
            {
              polls.length === 0 && (
                <Message>
                  <Message.Header style={{ textAlign: "center" }}>You don't have polls created</Message.Header>
                </Message>
              )
            }
          </div>
          <br />
          {
            polls.length > 0 && (
              <Grid centered>
                <Pagination onPageChange={(e, data) => paginationClick(data.activePage)} size='mini'
                  activePage={pagination.currentPage} defaultActivePage={1} totalPages={pagination.totalPages}
                />
              </Grid>
            )
          }
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => setOpen(false)} >
            Close
          </Button>
          <PollingManager retailers={retailers} updatePolls={updatePolls} polls={polls} pollsBackUp={pollsBackUp} type="new"/>
        </Modal.Actions>
      </Modal>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
    </div>
  );
};