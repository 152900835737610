import React, { Component } from 'react';
import {Button, Modal, Form, Input, Header, Label, Dimmer, Loader} from "semantic-ui-react";
import {editRetailerQuoteExpiration, addRetailerFormula} from "../requests";
import {FormatNumberToPrice} from "../../helpers/general";

// IMPORT COMPONENTS
import ModalError from "../alertModal/modal";

import './variablesModal.sass'

class variablesModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            modal: {},
            loading: false,
            annual_interest_rate_exceptional: props.formula.annual_interest_rate_exceptional,
            annual_interest_rate_fair: props.formula.annual_interest_rate_fair,
            annual_interest_rate_good: props.formula.annual_interest_rate_good,
            annual_interest_rate_poor: props.formula.annual_interest_rate_poor,
            annual_interest_rate_very_good: props.formula.annual_interest_rate_very_good,
            debit_fee_week: props.formula.debit_fee_week,
            id: props.formula.id,
            credit_range_id: props.credit_range.id,
            insurance_protection: props.formula.insurance_protection,
            tax_rate: props.formula.tax_rate,
            dealer_fees: props.formula.dealer_fees,
            quote_expiration: props.credit_range.quote_expiration,
            phone_confirm: props.credit_range.phone_confirm,
            flagEdit: true,
            msnErrors: {
                annual_interest_rate_poor: '',
                annual_interest_rate_fair: '',
                annual_interest_rate_good: '',
                annual_interest_rate_very_good: '',
                annual_interest_rate_exceptional: '',
                tax_rate: '',
                dealer_fees: '',
                insurance_protection: '',
                debit_fee_week: '',
                quote_expiration: '',
            },
            retailer: props,
            // -------------------------- show confirm modal ----------------------
            confirmModal: false,
        }
        console.log(this.props);
    }

    onEditInput = (label, value) => {
        let msnErrors = this.state.msnErrors;
        let labels = [ "annual_interest_rate_poor", "annual_interest_rate_fair", "annual_interest_rate_good", "annual_interest_rate_very_good", "annual_interest_rate_exceptional", "tax_rate", "insurance_protection", "debit_fee_week", "quote_expiration", "dealer_fees"]
        for (const i of labels) {
            msnErrors = this.switchLabel(i, value, msnErrors, label);
        }
        this.setState({[label]: value, msnErrors});
    }

    // --------------------------- validate inputs -----------------------------
    switchLabel(labels, value, msnErrors, label) {
        let annual_interest_rate_poor, annual_interest_rate_fair, annual_interest_rate_good, annual_interest_rate_very_good, annual_interest_rate_exceptional;
        let val = 0;
        switch (labels) {
            case 'annual_interest_rate_poor':
                val = label == 'annual_interest_rate_poor' ? value : parseFloat(this.state.annual_interest_rate_poor);
                annual_interest_rate_fair = label == 'annual_interest_rate_fair' ? value : parseFloat(this.state.annual_interest_rate_fair);
                msnErrors.annual_interest_rate_poor = val <= 0 || val <= annual_interest_rate_fair || isNaN(val)? 'The field must be greater than 0 and greater than Rate Fair' : '';
                break;
            case 'annual_interest_rate_fair': 
                annual_interest_rate_good = label == 'annual_interest_rate_good' ? value :  parseFloat(this.state.annual_interest_rate_good);
                annual_interest_rate_poor = label == 'annual_interest_rate_poor' ? value :  parseFloat(this.state.annual_interest_rate_poor);
                val = label == 'annual_interest_rate_fair' ? value :  parseFloat(this.state.annual_interest_rate_fair);
                msnErrors.annual_interest_rate_fair = val <= annual_interest_rate_good || val >= annual_interest_rate_poor || isNaN(val)? 'The field must be greater than Rate Good and less than Rate Poor' : '';
                break;
            case 'annual_interest_rate_good': 
                annual_interest_rate_very_good = label == 'annual_interest_rate_very_good' ? value :  parseFloat(this.state.annual_interest_rate_very_good);
                annual_interest_rate_fair = label == 'annual_interest_rate_fair' ? value :  parseFloat(this.state.annual_interest_rate_fair);
                val = label == 'annual_interest_rate_good' ? value :  parseFloat(this.state.annual_interest_rate_good);
                msnErrors.annual_interest_rate_good = val <= annual_interest_rate_very_good || val >= annual_interest_rate_fair || isNaN(val)? 'The field must be greater than Rate Very Good and less than Rate Fair' : '';
                break;
            case 'annual_interest_rate_very_good':
                annual_interest_rate_exceptional = label == 'annual_interest_rate_exceptional' ? value :  parseFloat(this.state.annual_interest_rate_exceptional);
                annual_interest_rate_good = label == 'annual_interest_rate_good' ? value :  parseFloat(this.state.annual_interest_rate_good);
                val = label == 'annual_interest_rate_very_good' ? value :  parseFloat(this.state.annual_interest_rate_very_good);
                msnErrors.annual_interest_rate_very_good = val <= annual_interest_rate_exceptional || val >= annual_interest_rate_good || isNaN(val)? 'The field must be greater than Rate Exceptional and less than Rate Very Good' : '';
                break;
            case 'annual_interest_rate_exceptional':
                annual_interest_rate_very_good = label == 'annual_interest_rate_very_good' ? value :  parseFloat(this.state.annual_interest_rate_very_good);
                val = label == 'annual_interest_rate_exceptional' ? value :  parseFloat(this.state.annual_interest_rate_exceptional);
                msnErrors.annual_interest_rate_exceptional = val <= 0 || val >= annual_interest_rate_very_good || isNaN(val)? 'The field must be greater than 0 and less than Rate Very Good' : '';
                break;
            case 'tax_rate': 
                val = label == 'tax_rate' ? value :  parseFloat(this.state.tax_rate);
                msnErrors.tax_rate = val <= 0 || isNaN(val)? 'The field must be greater than 0' : '';
                break;
            case 'dealer_fees':
                val = label == 'dealer_fees' ? value :  this.state.dealer_fees != '' && this.state.dealer_fees != null ? parseFloat(this.state.dealer_fees) : this.state.dealer_fees;
                if (val != '') {
                    msnErrors.dealer_fees = val < 0 || isNaN(val) || val == 'e' ? 'The field must be a positive number' : '';
                } else {
                    msnErrors.dealer_fees = '';
                }
                break;
            case 'insurance_protection': 
                val = label == 'insurance_protection' ? value :  parseFloat(this.state.insurance_protection);
                msnErrors.insurance_protection = val <= 0 || isNaN(val)? 'The field must be greater than 0' : '';
                break;
            case 'debit_fee_week':
                val = label == 'debit_fee_week' ? value :  parseFloat(this.state.debit_fee_week);
                msnErrors.debit_fee_week = val <= 0 || isNaN(val)? 'The field must be greater than 0' : '';
                break;
            case 'quote_expiration':
                val = label == 'quote_expiration' ? value :  parseFloat(this.state.quote_expiration);
                msnErrors.quote_expiration = val <= 0 || val % 1 != 0 || isNaN(val)? 'The field must be greater than 0 and integer number' : '';
                break;
            default:
                break;
        }
        return msnErrors;
    }

    validateForm = (array) => {
        let valid = false;
        let msnErrors = this.state.msnErrors;
        for (let i = 0; i < array.length; i++) {
            if (msnErrors[array[i]].length > 0) {
                valid = true
            }
        }
        return valid;
    };

    cancelEdit = () => {
        this.setState({
            annual_interest_rate_exceptional: this.state.retailer.formula.annual_interest_rate_exceptional,
            annual_interest_rate_fair: this.state.retailer.formula.annual_interest_rate_fair,
            annual_interest_rate_good: this.state.retailer.formula.annual_interest_rate_good,
            annual_interest_rate_poor: this.state.retailer.formula.annual_interest_rate_poor,
            annual_interest_rate_very_good: this.state.retailer.formula.annual_interest_rate_very_good,
            debit_fee_week: this.state.retailer.formula.debit_fee_week,
            insurance_protection: this.state.retailer.formula.insurance_protection,
            tax_rate: this.state.retailer.formula.tax_rate,
            dealer_fees: this.state.retailer.formula.dealer_fees,
            quote_expiration: this.state.retailer.credit_range.quote_expiration,
            phone_confirm: this.state.retailer.credit_range.phone_confirm,
            flagEdit: true
        });
    }

    // ---------------------------------- update formula -----------------------------------
    addFormula = () => {
        console.log('hola');
        this.setState({loading: true, confirmModal: false});
        let data = {
            retailer_id: this.state.retailer.id,
            annual_interest_rate_poor: this.state.annual_interest_rate_poor,
            annual_interest_rate_fair: this.state.annual_interest_rate_fair,
            annual_interest_rate_good: this.state.annual_interest_rate_good,
            annual_interest_rate_very_good: this.state.annual_interest_rate_very_good,
            annual_interest_rate_exceptional: this.state.annual_interest_rate_exceptional,
            debit_fee_week: this.state.debit_fee_week,
            insurance_protection: this.state.insurance_protection,
            tax_rate: this.state.tax_rate,
            dealer_fees: this.state.dealer_fees,
        }
        addRetailerFormula(data).then((response)=>{
            console.log(response);
            let retailer = {...this.state.retailer};
            retailer['formula'] = response.data;
            this.setState({loading: false, retailer, modal: {
                status: 200,
                message: "The percentages of the formula were updated"
            }, flagEdit: true})
            this.props.updateRetailer(retailer);
        }).catch((error)=>{
            if (error.response) {
                if (error.response.status === 422) {
                    this.setState({loading: false, modal: {
                            status: 422,
                            message: "Sorry. Fields are missing to save the percentages of the formula"
                        }})
                } else {
                    this.setState({loading: false, modal: {
                            status: 500,
                            message: "Sorry. An error occurred, please try again later"
                        }})
                }
            }
            console.log(error.response);
        });
    }

    // -------------------------------- update quote expiration -------------------------------
    beforeConfirm = () => {
        if (this.state.retailer.credit_range.quote_expiration != this.state.quote_expiration) {
            this.setState({ confirmModal: 'quote' })
        } else {
            this.updateQuoteExpiration();
        }
    }

    updateQuoteExpiration = () => {
        this.setState({loading: true, confirmModal: false});
        let data = {
            quote_expiration: parseInt(this.state.quote_expiration),
            phone_confirm: this.state.phone_confirm,
        }
        editRetailerQuoteExpiration(this.state.credit_range_id, data).then((response)=>{
            let retailer = {...this.state.retailer};
            retailer['credit_range'] = response.data;
            this.setState({loading: false, retailer, modal: {
                status: 200,
                message: "The deal expiration was updated"
            }, flagEdit: true})
            this.props.updateRetailer(retailer);
        }).catch((error)=>{
            if (error.response) {
                if (error.response.status === 422) {
                    this.setState({loading: false, modal: {
                            status: 422,
                            message: "Sorry. Field is missing to save the deal expiration"
                        }})
                } else {
                    this.setState({loading: false, modal: {
                            status: 500,
                            message: "Sorry. An error occurred, please try again later"
                        }})
                }
            }
            console.log(error.response);
        });
    }

    // ---------------------------- hide modal -----------------------------------
    hideModal = () => {
        this.setState({modal: {}});
    }

    render(){
        return(
            <div>
                <Modal
                    open= {true}
                    onClose= {()=> this.props.hideModal('variablesModal', false)}>
                    <Modal.Content className={'customModal'} scrolling>
                        <h2 className={'modalTitle'}>System Variables</h2>
                        <div className={'modalContent'} >
                            <Form >
                                <Form.Group>
                                    <Form.Field width={5}>
                                        <label>Annual Interest Rate Poor</label>
                                        {
                                            this.state.flagEdit ?
                                                <span>{this.state.annual_interest_rate_poor} %</span>
                                                :
                                                <div>
                                                    <Input
                                                        value={this.state.annual_interest_rate_poor}
                                                        placeholder={'Annual Interest Rate Poor'}
                                                        onChange={(e) => this.onEditInput('annual_interest_rate_poor', e.target.value)}
                                                        label={{ basic: true, content: '%' }}
                                                        labelPosition='right'
                                                        type="number"
                                                        maxLength="50"
                                                    />
                                                    {
                                                        this.state.msnErrors.annual_interest_rate_poor.length > 0 ?
                                                            <Label basic color='red' pointing>
                                                                {this.state.msnErrors.annual_interest_rate_poor}
                                                            </Label>
                                                            : null
                                                    }
                                                </div>
                                        }
                                    </Form.Field>
                                    <Form.Field width={5}>
                                        <label>Annual Interest Rate Fair</label>
                                        {
                                            this.state.flagEdit ?
                                                <span>{this.state.annual_interest_rate_fair} %</span>
                                                :
                                                <div>
                                                    <Input
                                                        value={this.state.annual_interest_rate_fair}
                                                        placeholder={'Annual Interest Rate Fair'}
                                                        onChange={(e) => this.onEditInput('annual_interest_rate_fair', e.target.value)}
                                                        label={{ basic: true, content: '%' }}
                                                        labelPosition='right'
                                                        type="number"
                                                        maxLength="50"
                                                    />
                                                    {
                                                        this.state.msnErrors.annual_interest_rate_fair.length > 0 ?
                                                            <Label basic color='red' pointing>
                                                                {this.state.msnErrors.annual_interest_rate_fair}
                                                            </Label>
                                                            : null
                                                    }
                                                </div>
                                        }
                                    </Form.Field>
                                    <Form.Field width={5}>
                                        <label>Annual Interest Rate Good</label>
                                        {
                                            this.state.flagEdit ?
                                                <span>{this.state.annual_interest_rate_good} %</span>
                                                :
                                                <div>
                                                    <Input
                                                        value={this.state.annual_interest_rate_good}
                                                        placeholder={'Annual Interest Rate Good'}
                                                        onChange={(e) => this.onEditInput('annual_interest_rate_good', e.target.value)}
                                                        label={{ basic: true, content: '%' }}
                                                        labelPosition='right'
                                                        type="number"
                                                        maxLength="50"
                                                    />
                                                    {
                                                        this.state.msnErrors.annual_interest_rate_good.length > 0 ?
                                                            <Label basic color='red' pointing>
                                                                {this.state.msnErrors.annual_interest_rate_good}
                                                            </Label>
                                                            : null
                                                    }
                                                </div>
                                        }
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={5}>
                                        <label>Annual Interest Rate Very Good</label>
                                        {
                                            this.state.flagEdit ?
                                                <span>{this.state.annual_interest_rate_very_good} %</span>
                                                :
                                                <div>
                                                    <Input
                                                        value={this.state.annual_interest_rate_very_good}
                                                        placeholder={'Annual Interest Rate Very Good'}
                                                        onChange={(e) => this.onEditInput('annual_interest_rate_very_good', e.target.value)}
                                                        label={{ basic: true, content: '%' }}
                                                        labelPosition='right'
                                                        type="number"
                                                        maxLength="50"
                                                    />
                                                    {
                                                        this.state.msnErrors.annual_interest_rate_very_good.length > 0 ?
                                                            <Label basic color='red' pointing>
                                                                {this.state.msnErrors.annual_interest_rate_very_good}
                                                            </Label>
                                                            : null
                                                    }
                                                </div>
                                        }
                                    </Form.Field>
                                    <Form.Field width={5}>
                                        <label>Annual Interest Rate Exceptional</label>
                                        {
                                            this.state.flagEdit ?
                                                <span>{this.state.annual_interest_rate_exceptional} %</span>
                                                :
                                                <div>
                                                    <Input
                                                        value={this.state.annual_interest_rate_exceptional}
                                                        placeholder={'Annual Interest Rate Exceptional'}
                                                        onChange={(e) => this.onEditInput('annual_interest_rate_exceptional', e.target.value)}
                                                        label={{ basic: true, content: '%' }}
                                                        labelPosition='right'
                                                        type="number"
                                                        maxLength="50"
                                                    />
                                                    {
                                                        this.state.msnErrors.annual_interest_rate_exceptional.length > 0 ?
                                                            <Label basic color='red' pointing>
                                                                {this.state.msnErrors.annual_interest_rate_exceptional}
                                                            </Label>
                                                            : null
                                                    }
                                                </div>
                                        }
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={5}>
                                        <label>Tax Rate</label>
                                        {
                                            this.state.flagEdit ?
                                                <span>{this.state.tax_rate} %</span>
                                                :
                                                <div>
                                                    <Input
                                                        value={this.state.tax_rate}
                                                        placeholder={'Tax Rate'}
                                                        onChange={(e) => this.onEditInput('tax_rate', e.target.value)}
                                                        label={{ basic: true, content: '%' }}
                                                        labelPosition='right'
                                                        type="number"
                                                        maxLength="50"
                                                    />
                                                    {
                                                        this.state.msnErrors.tax_rate.length > 0 ?
                                                            <Label basic color='red' pointing>
                                                                {this.state.msnErrors.tax_rate}
                                                            </Label>
                                                            : null
                                                    }
                                                </div>
                                        }
                                    </Form.Field>
                                    <Form.Field width={5}>
                                        <label>Dealer Fees</label>
                                        {
                                            this.state.flagEdit ?
                                                <span>{FormatNumberToPrice(this.state.dealer_fees ? this.state.dealer_fees : 0)}</span>
                                                :
                                                <div>
                                                    <Input
                                                        value={this.state.dealer_fees}
                                                        placeholder={'Dealer Fees'}
                                                        onChange={(e) => this.onEditInput('dealer_fees', e.target.value)}
                                                        label={{ basic: true, content: '$' }}
                                                        labelPosition='left'
                                                        // type="number"
                                                        maxLength="50"
                                                    />
                                                    {
                                                        this.state.msnErrors.dealer_fees.length > 0 ?
                                                            <Label basic color='red' pointing>
                                                                {this.state.msnErrors.dealer_fees}
                                                            </Label>
                                                            : null
                                                    }
                                                </div>
                                        }
                                    </Form.Field>
                                    <Form.Field width={5}>
                                        <label>Debit Fee Week</label>
                                        {
                                            this.state.flagEdit ?
                                                <span>{FormatNumberToPrice(this.state.debit_fee_week)}</span>
                                                :
                                                <div>
                                                    <Input
                                                        value={this.state.debit_fee_week}
                                                        placeholder={'Debit Fee Week'}
                                                        onChange={(e) => this.onEditInput('debit_fee_week', e.target.value)}
                                                        label={{ basic: true, content: '$' }}
                                                        labelPosition='left'
                                                        type="number"
                                                        maxLength="50"
                                                    />
                                                    {
                                                        this.state.msnErrors.debit_fee_week.length > 0 ?
                                                            <Label basic color='red' pointing>
                                                                {this.state.msnErrors.debit_fee_week}
                                                            </Label>
                                                            : null
                                                    }
                                                </div>
                                        }
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={5}>
                                        <label>Insurance Protection</label>
                                        {
                                            this.state.flagEdit ?
                                                <span>{this.state.insurance_protection} %</span>
                                                :
                                                <div>
                                                    <Input
                                                        value={this.state.insurance_protection}
                                                        placeholder={'Insurance Protection'}
                                                        onChange={(e) => this.onEditInput('insurance_protection', e.target.value)}
                                                        label={{ basic: true, content: '%' }}
                                                        labelPosition='right'
                                                        type="number"
                                                        maxLength="50"
                                                    />
                                                    {
                                                        this.state.msnErrors.insurance_protection.length > 0 ?
                                                            <Label basic color='red' pointing>
                                                                {this.state.msnErrors.insurance_protection}
                                                            </Label>
                                                            : null
                                                    }
                                                </div>
                                        }
                                    </Form.Field>
                                </Form.Group>
                                <div style={{ width: "100%", textAlign: "left", padding: "10px 0 10px 0" }}>
                                    {
                                        !this.state.flagEdit ?
                                            <Button type="button" className="acceptButton" disabled={this.validateForm(["annual_interest_rate_poor", "annual_interest_rate_fair", "annual_interest_rate_good", "annual_interest_rate_very_good", "annual_interest_rate_exceptional", "tax_rate", "insurance_protection", "debit_fee_week", "dealer_fees"])}
                                                onClick={() => this.setState({ confirmModal: 'formula' })}>Save</Button>
                                            : null
                                    }
                                </div>
                                <Form.Group>
                                    <Form.Field width={5}>
                                        <label >Deal Expiration</label>
                                        {
                                            this.state.flagEdit ?
                                                <span>{this.state.quote_expiration} days</span>
                                                :
                                                <div>
                                                    <Input
                                                        value={this.state.quote_expiration}
                                                        placeholder={'Deal Expiration'}
                                                        onChange={(e) => this.onEditInput('quote_expiration', e.target.value)}
                                                        label={{ basic: true, content: 'days' }}
                                                        labelPosition='right'
                                                        type="number"
                                                        maxLength="50" disabled={this.state.flagEdit}
                                                    />
                                                    {
                                                        this.state.msnErrors.quote_expiration.length > 0 ?
                                                            <Label basic color='red' pointing>
                                                                {this.state.msnErrors.quote_expiration}
                                                            </Label>
                                                            : null
                                                    }
                                                </div>
                                        }
                                    </Form.Field>
                                    <Form.Field className={this.state.flagEdit ? 'disabled-check' : ''}>
                                        <label >Confirmation of phone number when registering a new customer in the widget</label>
                                        <Form.Checkbox
                                            toggle
                                            disabled={this.state.flagEdit}
                                            checked={this.state.phone_confirm}
                                            onChange={(e, value) => this.onEditInput('phone_confirm', value.checked)}
                                            label={this.state.phone_confirm ? 'Enabled' : 'Disabled'}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <div style={{ width: "100%", textAlign: "left", padding: "10px 0 10px 0" }}>
                                    {
                                        !this.state.flagEdit ?
                                            <Button type="button" className="acceptButton" disabled={this.validateForm(['quote_expiration'])} onClick={this.beforeConfirm}>Save</Button>
                                            : null
                                    }
                                </div>
                            </Form>
                            <div style={{width: "100%", textAlign: "center"}}>
                                <Button className="closeButton" onClick={()=> this.props.hideModal('variablesModal', false)}>Close</Button>
                                {
                                    this.state.flagEdit ?
                                        <Button className="editButton" onClick={() => this.setState({flagEdit: false})}>Edit</Button>
                                    :
                                        <Button className="cancelButton" onClick={() => this.cancelEdit()}>Cancel</Button>
                                }
                            </div>
                        </div>
                        {
                            this.state.confirmModal ?
                                <Modal size="mini" className="buyer-modal" open={true} closeOnEscape={false} closeOnDimmerClick={false}>
                                    <Modal.Content>
                                        <h2 className="title-info">
                                            {
                                                this.state.confirmModal == 'formula' ?
                                                    'Formula'
                                                :  this.state.confirmModal == 'quote' ?
                                                    'Deal Expiration'
                                                :null
                                            }
                                        </h2>
                                        <h3>
                                            {
                                                    this.state.confirmModal == 'quote' ?
                                                        'Are you sure to update the deal expiration?'
                                                    :  this.state.confirmModal == 'formula' ?
                                                        'Are you sure to update the percentages of the formula?'
                                                    :null
                                            }
                                        </h3>
                                        
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button className="btn-continue" onClick={() => this.setState({confirmModal: false})}>
                                            <h3>Cancel</h3>
                                        </Button>
                                        {
                                                this.state.confirmModal == 'quote' ?
                                                    <Button className="btn-continue" onClick={() => this.updateQuoteExpiration()}>
                                                        <h3>Accept</h3>
                                                    </Button>
                                                :  this.state.confirmModal == 'formula' ?
                                                    <Button className="btn-continue" onClick={() => this.addFormula()}>
                                                        <h3>Accept</h3>
                                                    </Button>
                                                :null
                                        }
                                    </Modal.Actions>
                                </Modal>
                            :null
                        }
                        {
                            this.state.loading ?
                                <Dimmer className="loading" active>
                                    <Loader />
                                </Dimmer>
                            :null
                        }
                    </Modal.Content>
                </Modal>
                {
                    this.state.modal.message ?
                        <ModalError hideModal={this.hideModal} modal={this.state.modal}/>
                    :null
                }
            </div>
        )
    }
}

export default variablesModal;