import React, { useEffect, useState } from 'react';
import { Container, Segment, Header, Grid, Form, Dimmer, Loader } from 'semantic-ui-react';
import { get700Credit } from "../requests";
import { FormatNumberToPrice, validAddress, validZipCode } from '../../helpers/general';
import { states, zipCodesStates } from '../../helpers/usaStates';

// IMPORT COMPONENTS
import CustomCarousel from '../carousel/carousel';
import AlertModal from "../alertModal/modal";
import Address from '../address/Address';
import Video from '../../customer/quotation/video/Video';
import { SimpleRegister } from '../../session/register/SimpleRegister';
import Cox from '../../customer/cox/Cox';
import { WeeklyTooltip } from '../tooltips/WeeklyTooltip';
import { MonthlyTooltip } from '../tooltips/MonthlyTooltip';

// IMPORT CSS STYLE
import './PreQualified.sass'

const defualtForm = { firstName: null, lastName: null, address: null, zip_code: null, state: null, city: null };

const usaState = states()
const PreQualified = (props) => {
  const { finalQuots, retailer, money_owed, trade_in } = props;
  const [car] = useState(props.car);
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(props.preQualifiedForm);
  const [isTypingDown, setIsTypingDown] = useState(false);
  const [errors, setErrors] = useState(defualtForm);
  const [disabled, setDisabled] = useState(true);
  const [logged, setLogged] = useState(false);
  const [user, setUser] = useState({});
  const [coxData, setCoxData] = useState({ show: false });
  const [seeMore, setSeeMore] = useState(false)

  // ----------------------- get form values
  const onChangeState = (value, label) => {
    const auxForm = { ...form };
    let auxErrors = { ...errors }
    if (label === 'annualIncome' && value.target) {
      let { min, max } = value.target; let val = value.target.value
      value = Math.max(Number(min), Math.min(Number(max), Number(val)));
      setIsTypingDown(!isTypingDown);
    } else if (label === 'fullAddress') {
      auxForm.address = value.address;
      auxForm.state = value.state;
      auxForm.city = value.city;
      auxForm.postalCode = value.zip_code;
      auxForm['zip_code'] = value.zip_code;
      auxErrors = typeData("address", value.address, auxErrors, auxForm);
      auxErrors = typeData("zip_code", value.zip_code, auxErrors, auxForm);
      auxErrors = typeData("postalCode", value.zip_code, auxErrors, auxForm);
      auxErrors = typeData("city", value.city, auxErrors, auxForm);
      auxErrors = typeData("state", value.state, auxErrors, auxForm);
    } else if (label === 'zip_code') {
      auxForm.postalCode = value;
      auxForm['zip_code'] = value;
      auxErrors = typeData(label, value, auxErrors, auxForm);
    } else {
      auxForm[label] = value;
      auxErrors = typeData(label, value, auxErrors, auxForm);
    }
    formValidate(auxForm);
    setForm({ ...auxForm });
    setErrors({ ...auxErrors });
  }

  // ------------------------------------------------- validate form
  const formValidate = (auxForm) => {
    let auxErrors = { ...defualtForm }, auxDisabled = false;
    for (const property in auxErrors) {
      auxErrors = typeData(property, auxForm[property], auxErrors, auxForm);
      if (auxErrors[property] !== null) {
        auxDisabled = true;
      }
    }
    setDisabled(auxDisabled);
  }

  const typeData = (label, value, auxErrors, form) => {
    switch (label) {
      case 'firstName':
        auxErrors.firstName = (value.length < 3) ? 'First Name must be at least 3 characters long' : null;
        break;
      case 'lastName':
        auxErrors.lastName = (value.length < 3) ? 'Full Name must be at least 3 characters long' : null;
        break;
      case 'address':
        auxErrors.address = validAddress(value);
        break;
      case 'zip_code':
        auxErrors.zip_code = (!validZipCode(value)) ? 'Zip Code must be 5 characters long' : zipCodesStates(form.state, value);
        break;
      case 'state':
        auxErrors.state = (value === '') ? 'State is required' : null;
        auxErrors.zip_code = zipCodesStates(value, form.zip_code);
        break;
      case 'city':
        auxErrors.city = (value === '') ? 'City is required' : null;
        break;
      default:
        break;
    }
    return auxErrors;
  }

  // --------------------- hide modal
  const hideModal = () => {
    setModal({ modal: {} });
  }

  const CreditSolutionRequest = (auxUser = user) => {
    setUser(auxUser);
    let state = form.state;
    for (const item of usaState) {
      if (form.state === item.isoCode) {
        state = item.name;
        break;
      }
    }
    setDisabled(true);
    const parameters = {
      name: form.firstName + " " + form.lastName,
      zip_code: form.postalCode,
      address: form.address,
      city: form.city,
      state: state,
      retailer_id: retailer.id,
      customer_id: form.customerId ? form.customerId : null
    }
    setIsLoading(true);
    get700Credit(parameters).then(response => {
      setIsLoading(false);
      let auxForm = form;
      auxForm['score'] = response.data.Score;
      auxForm['creditScore'] = response.data.Score;
      buildCoxProps(auxForm);
      setForm({ ...auxForm });
    }).catch(error => {
      let auxForm = form;
      auxForm['score'] = null;
      auxForm['creditScore'] = null;
      buildCoxProps(auxForm);
      setIsLoading(false);
    });
  }

  const buildCoxProps = (auxForm) => {
    let auxCoxData = { ...coxData };
    auxCoxData['user'] = auxForm;
    auxCoxData['quote'] = finalQuots.monthly;
    auxCoxData['retailer'] = retailer;
    auxCoxData['car'] = car;
    auxCoxData['show'] = true;
    auxCoxData['tradeIn'] = trade_in;
    auxCoxData['moneyOwed'] = money_owed;
    setCoxData({ ...auxCoxData });
  }

  const continueProcess = (coxQuotes) => {
    let auxForm = form;
    auxForm['coxQuotes'] = coxQuotes;
    props.next(auxForm, user);
  }

  useEffect(() => {
    let auxForm = props.preQualifiedForm;
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    setLogged(false);
    if (currentUser && currentUser.customer) {
      auxForm.firstName = currentUser.customer.name;
      auxForm.lastName = currentUser.customer.second_name;
      auxForm.postalCode = currentUser.customer.zip_code;
      auxForm.zip_code = currentUser.customer.zip_code;
      auxForm.address = currentUser.customer.address;
      auxForm.state = currentUser.customer.state;
      auxForm.city = currentUser.customer.city;
      auxForm.customerId = currentUser.customer.id;
      setLogged(true);
      setUser(currentUser);
    }
    setForm(auxForm);
    formValidate(auxForm);
    setErrors(defualtForm);
  }, [])

  return (
    <Container className='prequalified-container'>
      <Grid>
        <Grid.Column mobile={16} tablet={10} computer={10}>
          <Header as='h1'>Let's See Payments Specific To You...</Header>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={6} computer={6}>
          <Video newVideos={true} video={'prequalified'} videos={props.videos} />
        </Grid.Column>
      </Grid>
      <Segment>
        <Grid>
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <Header as='h2' content={car.year + " " + car.make + " " + car.model} style={{ marginBottom: 0 }}></Header>
            <Header as='h3' disabled style={{ marginTop: 0 }}>Get a more transparent & personalized shopping experience.</Header>
            <Form>
              <Form.Group widths='equal'>
                <Form.Input fluid label={<label>First name <span className="required-inut">*</span></label>} placeholder='First name'
                  value={form.firstName} onChange={(event) => onChangeState(event.target.value, 'firstName')}
                  error={form.firstName.length > 0 || errors.firstName ? errors.firstName : null} />
                <Form.Input fluid label={<label>Last name <span className="required-inut">*</span></label>} placeholder='Last name'
                  value={form.lastName} onChange={(event) => onChangeState(event.target.value, 'lastName')}
                  error={form.lastName.length > 0 || errors.lastName ? errors.lastName : null} />
              </Form.Group>
              <Address onChangeState={(label, value) => onChangeState(value, label)}
                address={{ address: form.address, city: form.city, zip_code: form.postalCode, state: form.state }}
                msnErrors={errors}
              />
              <br />
            </Form>
            <SimpleRegister data={form} logged={logged} openSession={props.openSession} CreditSolutionRequest={CreditSolutionRequest} retailer={retailer} disabled={disabled} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6}>
            <div className='title-a2u'>
              <p className='title'>As Low As</p>
              <h2 className='a2uWeekly'>
                {FormatNumberToPrice(finalQuots.weekly.payment)}/Week
                <WeeklyTooltip finalQuots={finalQuots} />
              </h2>
              <h4 className='orText'>OR</h4>
              <h4 className='a2uMonthly'>
                {FormatNumberToPrice(finalQuots.monthly.payment)}/Month
                <MonthlyTooltip finalQuots={finalQuots} annual_interest_rate={finalQuots.monthly.annual_interest_rate} />
              </h4>
            </div>
            <CustomCarousel images={car.images} carId={car.id} />
          </Grid.Column>
        </Grid>
      </Segment>
      {
        isLoading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )
      }
      {
        modal.message && (
          <AlertModal hideModal={() => hideModal()} modal={modal} />
        )
      }
      {
        coxData.show && (
          <Cox data={coxData} continue={continueProcess} />
        )
      }
    </Container>
  )
}

export default PreQualified;
