import React, { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { returnMonthlySummary, returnQuoteSummary } from '../../helpers/general';

export const WeeklyTooltip = ({ finalQuots }) => {
  const [seeMore, setSeeMore] = useState(false);
  
  return (
    <Popup
      hoverable size="small"
      onClose={() => setSeeMore(false)}
      content={
        <div>
          {returnQuoteSummary(finalQuots)}
          {
            !seeMore ?
              <span className='tooltip-button' onClick={() => setSeeMore(true)}>See more...</span>
              :
              <div>
                {returnMonthlySummary(finalQuots, finalQuots.monthly.annual_interest_rate)}
                <span className='tooltip-button' onClick={() => setSeeMore(false)}>See less...</span>
              </div>
          }
        </div>
      }
      trigger={<Icon className="infoIcon" link name="info circle" />}
    />
  );
};